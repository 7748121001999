
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SendMessageComponent from '../components/dashboards/contents/sendMessage/sendMessageComponent';

import {reset_sendMessageAction, callSendMessage_sendMessageAction, sendMessagesToPatient_sendMessageAction, sendMessagesToGroup_sendMessageAction} from '../actions/sendMessageAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class SendMessagesContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<SendMessageComponent 
					sendGroup = {this.props.sendMessage.sendGroup}
					show = {this.props.sendMessage.show}
					patientDetails = {this.props.patientDetails.data} 
					groupsPatient = {this.props.groupsPatient.data}
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		groupsPatient: __state.GroupsPatientReducer,
		sendMessage: __state.SendMessageReducer,
        patientDetails: __state.PatientDetailsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			sendMessagesToGroup_sendMessageAction: (__formData)=> {
				__dispatch(sendMessagesToGroup_sendMessageAction(__formData));
			},
			sendMessagesToPatient_sendMessageAction: (__formData)=> {
				__dispatch(sendMessagesToPatient_sendMessageAction(__formData));
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
			callSendMessage_sendMessageAction: (__show, __sendGroup) => {
				__dispatch(callSendMessage_sendMessageAction(__show, __sendGroup));
			},
			reset_sendMessageAction: () => {
				__dispatch(reset_sendMessageAction());
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SendMessagesContainer);