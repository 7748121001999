
import axios from 'axios';
import * as configs from '../lib/configs';

export default class httpClient{

    static request(__url, __method, __data, __headers){

        return new Promise (async (__reso,__reje)=>{

            try {
                console.log(__url)
                let response = await axios({
                    method: __method,
                    url: __url,
                    timeout: 29*1000,
                    // withCredentials: true,
                    headers: __headers === null ? {"Content-Type": "application/json", "Authorization": window.sessionStorage.getItem("token")} : __headers,
                    data: __data
                });

                return __reso(response.data);
    
            } catch (error) {
           
                if(error.response){

                    if(error.response.status==401){

                        window.sessionStorage.removeItem("token");
                        window.sessionStorage.removeItem("accesscode");
                        window.location.href = window.location.origin + "/login"

                    }

                    return __reje(new Error(error.response.data.message));
 
                }

                return __reje(new Error(error.message));

            };

        });
    };
    
    static dowloadFile(__url, __method, __data){

        return new Promise (async (__reso,__reje)=>{

            try {
                console.log(__url)
                let response = await axios({
                    method: __method,
                    url: __url,
                    timeout: 29*1000,
                    headers: {"Content-Type": "application/json", "Authorization": window.sessionStorage.getItem("token")},
                    responseType: "blob"
                });

                return __reso(window.URL.createObjectURL(new Blob([response.data])));
    
            } catch (error) {
  
                if(error.response){
                    return __reje(new Error(error.response.data.message));
                }

                return __reje(new Error(error.message));

            };

        });
    };

    static grecaptcha(__action){

        return new Promise (async (__reso,__reje)=>{

            window.grecaptcha.execute(configs.recaptcha_siteKey, {action: __action}).then((token) => {
                return __reso(token);
            });

        });
    };

}
