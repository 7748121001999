
import React, { Component } from 'react';

import './paginationStyle.css';

export default class PaginationComponent extends Component{

    constructor(__props) {

		super(__props);

	};

    handleFirstPage = () => {

        this.props.handleFirstPage();

    }

    handleLastPage = () => {

        this.props.handleLastPage();

    }

    handlePreviousPage = () => {

        this.props.handlePreviousPage();

    }

    handleNextPage = () => {

        this.props.handleNextPage();

    }

    handleChangePage = (__page) => {

        this.props.handleChangePage(__page);

    }

    handleLoadMorePage = (__pagination, __plus) => {

        this.props.handleLoadMorePage(__pagination, __plus);
        
    }

	render() {

        let {begin, length, pageSizes, page, totalRows} = this.props.pagination;
        let totalPages = Math.ceil(totalRows / pageSizes);
        let pages = [];

        for (let i = begin; i <= begin + length - 1 ; i++){

            if(i > totalPages){

                break;

            };

            pages.push(<button key={i} onClick={() => this.handleChangePage(i)} type="button" className={ page === i ? "btn btn-info btn-paging-active" : "btn btn-light btn-paging"} style={{width: "30px", padding: "5px"}}>{i}</button>);

        }
    
		return (
            <React.Fragment>
                <div hidden={totalPages <= 1} className="pagination" style={{float: "right", margin: "auto"}}>
                    <button disabled={page === 1} onClick={() => this.handleFirstPage()} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}><i className="la la-angle-double-left"></i></button>
                    <button disabled={page - 1 < 1} onClick={() => this.handlePreviousPage()} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}><i className="la la-angle-left"></i></button>
                    <button hidden={begin <= 1} onClick={() => this.handleLoadMorePage(this.props.pagination, false)} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}>. . .</button>
                    {pages}
                    <button hidden={begin + length - 1 >= totalPages} onClick={() => this.handleLoadMorePage(this.props.pagination, true)} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}>. . .</button>
                    <button disabled={page + 1 > totalPages} onClick={() => this.handleNextPage()} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}><i className="la la-angle-right"></i></button>
                    <button disabled={page === totalPages} onClick={() => this.handleLastPage()} type="button" className="btn btn-light btn-paging" style={{width: "30px", padding: "5px"}}><i className="la la-angle-double-right"></i></button>
                </div>
            </React.Fragment>
		);

	};

};
