import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import SystemAbnormalsContainer from '../../../../containers/systemAbnormalsContainer';
import BatteriesContainer from '../../../../containers/batteriesContainer';
import SystemStatusContainer from '../../../../containers/systemStatusContainer';
import SystemMonitorContainer from '../../../../containers/systemMonitorContainer';
import LogsSQSContainer from '../../../../containers/logsSQSContainer';
import ChartSummarySystemDataContainer from '../../../../containers/chartSummarySystemDataContainer';


import './dashboardsStyle.css';

const initialState = () => {

  return{
    formSearch:{
      device: ""
    }
  };

}

const _ref="DashboardsComponent";

export default class DashboardsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentDidMount(){
    
    let params      = window.location.pathname.split("/");
    let queryString = window.location.search;
    let parameters  = new URLSearchParams(queryString);

    this.setState({
      formSearch:{
        device: parameters.get('device')
      }
    });

    this.props.func.OnInit_dashboardsAction(this.state.formSearch.device);

  }

  componentWillUnmount(){

    this.props.func.resetPage_dashboardsAction();

  }

  handleOnChangeKeySearch = (__e) =>{

    this.setState({
      formSearch:{
        ...this.state.formSearch,
        [__e.target.name]: __e.target.value
      }
    })

  }

  handleOnCallMsgBox = (__button) => {


  }

  render() {

    return(
 
      <React.Fragment>
        
        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-grid--stretch">
          <div className="k-container k-content-wrapper  k-grid k-grid--ver" id="k_content_wrapper">
            <div className="k-content	k-grid__item k-grid__item--fluid k-grid k-grid--hor" id="k_content">

              <div className="k-content__head	k-grid__item">
                <div className="k-content__head-main">
                  <div className="k-content__head-breadcrumbs">
                    <a className="k-content__head-breadcrumb-home"><i className="flaticon-home-2"></i></a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <NavLink className="k-content__head-breadcrumb-link" activeClassName="k-content__head-breadcrumb-link--active" to="/dashboars/index">Dashboard</NavLink>
                    {/* <!-- <span className="k-content__head-breadcrumb-link k-content__head-breadcrumb-link--active">Active link</span> --> */}
                  </div>
                </div>
                <div className="k-content__head-toolbar">
                  <div className="k-header__topbar-item_v2 k-header__topbar-item_v2--search">
                    <div className="input-group">
                      <input value={this.state.formSearch.device || ""} name="device" onChange={this.handleOnChangeKeySearch} style={{borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}} type="text" className="form-control form-control-sm" placeholder="Search device . . . " autoComplete="off"/>
                      <div className="input-group-append">
                        <button onClick={()=>{this.props.func.OnInit_dashboardsAction(this.state.formSearch.device)}} style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}} className="btn btn-outline-secondary btn-sm" type="button"><i className="flaticon2-search"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
              <div className="row">
                <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#MonitorSQS" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-tv"></i></span>
                            <span className="nav-link-title">Monitor SQS</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="MonitorSQS" role="tabpanel">
                          <ChartSummarySystemDataContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-xl-4 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#Stauts" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-microchip"></i></span>
                            <span className="nav-link-title">Stauts</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#Battery" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-battery-full"></i></span>
                            <span className="nav-link-title">Battery</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#Abnormal" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-flag"></i></span>
                            <span className="nav-link-title">Abnormal</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="Stauts" role="tabpanel">
                          <SystemStatusContainer/>
                        </div>
                        <div className="tab-pane fade" id="Battery" role="tabpanel">
                          <BatteriesContainer/>
                        </div>
                        <div className="tab-pane fade" id="Abnormal" role="tabpanel">
                          <SystemAbnormalsContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 col-xl-8 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#LogError" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-receipt"></i></span>
                            <span className="nav-link-title">Log Error</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="LogError" role="tabpanel">
                          <LogsSQSContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#Summary" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-coins"></i></span>
                            <span className="nav-link-title">Summary System Data</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="Summary" role="tabpanel">
                          <SystemMonitorContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    )

  }

}