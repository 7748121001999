import React, {Component} from 'react';
import { connect } from 'react-redux';

import CustomersComponent from '../components/dashboards/contents/customers/customersComponent';

import {getCustomers_customersAction, create_customersAction, update_customersAction, delete_customersAction, loadMorePage_customersAction, reset_customersAction} from '../actions/customersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class CustomersContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <CustomersComponent
          data = {this.props.Customers.data} 
          filters = {this.props.Customers.filters} 
          pagination = {this.props.Customers.pagination} 
          sorts = {this.props.Customers.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    Customers: __state.CustomersReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getCustomers_customersAction: (__filters, __sorts, __pagination) => {
				__dispatch(getCustomers_customersAction(__filters, __sorts, __pagination))
			},
      create_customersAction: (__formData) => {
				__dispatch(create_customersAction(__formData))
			},
      update_customersAction: (__formData) => {
				__dispatch(update_customersAction(__formData))
			},
      delete_customersAction: (__formData) => {
				__dispatch(delete_customersAction(__formData))
			},
      loadMorePage_customersAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_customersAction(__pagination, __plus));
			},
      reset_customersAction: () => {
				__dispatch(reset_customersAction())
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(CustomersContainer);