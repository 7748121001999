

export const stateManagerUsers = {

    segment:            "",
    filters: {
        roleId:         "",
        groupId:        "",
        responderId:    "",
    },
    policies: []

};

const ManagerUsersReducer = (__state = stateManagerUsers, __action) => {

    switch(__action.type){
            
        case "IMPORT_POLICIES_MANAGER_USERS":

            return {
                ...__state,
                segment:        __action.payloads.segment,
                policies:       __action.payloads.data,
                filters: {
                    ...__state.filters,
                    roleId:         __action.payloads.roleId,
                    groupId:        __action.payloads.groupId,
                    responderId:    __action.payloads.responderId
                }
            };

        case "RESET_MANAGER_USERS":
            
            return stateManagerUsers;

        default: 

            return __state;

    };

};

export default ManagerUsersReducer;