import moment from "moment";
import httpClient from '../lib/httpClient';
 
import {stateSummaryPatients} from '../reducers/summaryPatientsReducer';
 
import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="SummaryPatientsComponent";
 
export const getSummary_summaryPatientsAction = (__filters = Object.assign({}, stateSummaryPatients.filters), __sorts = Object.assign({}, stateSummaryPatients.sorts), __pagination = Object.assign({}, stateSummaryPatients.pagination)) => async(__dispatch) => {
 
    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=getSummaryPatients" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importSummary_summaryPatientsAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importSummary_summaryPatientsAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: 'IMPORT_SUMMARY_PATIENTS',
        payloads: {
            data:           __data,
            totalRows:      __totalRows,
            month:          __filters.month || moment.utc().utcOffset(configs.timeZone).format("YYYY-MM"),
            device:         __filters.device || "",
            statusRPM:      __filters.statusRPM || "",
            typeNotify:     __filters.typeNotify || "",
            statusReadings: __filters.statusReadings || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const getGroupsOutSide_summaryPatientsAction = (__filters = Object.assign({}, stateSummaryPatients.goupsOutSide.filters), __sorts = Object.assign({}, stateSummaryPatients.goupsOutSide.sorts), __pagination = Object.assign({}, stateSummaryPatients.goupsOutSide.pagination)) => async(__dispatch) => {
 
    try {
        
        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/groupsPatient?func=getGroups" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importGroupsOutSide_summaryPatientsAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importGroupsOutSide_summaryPatientsAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;
    
    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_GROUPS_OUTSIDE_SUMMARY_PATIENTS",
        payloads: {
            data:           __data,
            totalRows:      __totalRows,
            notPatientId:   __filters.notPatientId || "", 
            groupName:      __filters.groupName || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const getGroupsInSide_summaryPatientsAction = (__filters = Object.assign({}, stateSummaryPatients.goupsInSide.filters), __sorts = Object.assign({}, stateSummaryPatients.goupsInSide.sorts), __pagination = Object.assign({}, stateSummaryPatients.goupsInSide.pagination)) => async(__dispatch) => {
 
    try {
        
        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/groupsPatient?func=getGroups" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importGroupsInSide_summaryPatientsAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importGroupsInSide_summaryPatientsAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;
    
    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_GROUPS_INSIDE_SUMMARY_PATIENTS",
        payloads: {
            data:           __data,
            totalRows:      __totalRows,
            byPatientId:    __filters.byPatientId || "", 
            groupName:      __filters.groupName || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}
 
export const mapGroupsToPatient_summaryPatientsAction = (__formData) => async(__dispatch) => {
 
    try {

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/patients?func=mapGroupsToPatient", "PUT", __formData, null);

        __dispatch(getGroupsOutSide_summaryPatientsAction({notPatientId: __formData.patientId}));
        __dispatch(getGroupsInSide_summaryPatientsAction({byPatientId: __formData.patientId}));
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const loadMorePage_summaryPatientsAction = (__pagination, __plus) => {

    let totalPage   = Math.ceil(__pagination.totalRows / __pagination.pageSizes);
    let begin       = 1;

    if(__plus){ 

        begin = __pagination.begin + __pagination.length;
        begin = begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : begin;

    }else{

        begin = __pagination.begin - __pagination.length;
        begin = begin < 1 ? 1 : begin;

    };

    return {
        type: 'LOAD_MORE_PAGE_SUMMARY_PATIENTS',
        payloads: begin
    };

}

export const reset_summaryPatientsAction = () => {

    return {
        type: "RESET_SUMMARY_PATIENTS",
        payloads: {}
    };

}