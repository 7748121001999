import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import crypto from "crypto";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import DateTimePickerComponent from '../dateTimePicker/dateTimePickerComponent';
import MsgBoxComponent from '../msgBox/msgBoxComponent';

import * as configs from '../../../lib/configs';
import func from '../../../lib/func';

const schema = {
    "groupName":        "^[A-Za-z0-9\\_\\-\\s]*$",
    "firstName":        "^[A-Za-z\\s]*$",
    "lastName":         "^[A-Za-z\\s]*$",
    "email":            "^([a-z0-9\\_\\.]{1,30}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2})*$",
    "mrn":              "^([A-Za-z0-9]{8})*$",
    "phoneMobile":      "^[0-9\\+\\s]*$",
}

const initialState = () => {

    return{
        idsInSide:              [],
        idsOutSide:             [],
        showFormSearch:         false,
        typeSearch:             null,
        PID:                    false,
        passWord:               "",
        checkedPID:             false,
        showFormConfirmPass:    false,
        formSearch: {
            groupName:          "",
            statusRPM:          "",
            firstName:          "",
            lastName:           "",
            dob:                "",
            email:              "",
            mrn:                "",
            phoneMobile:        ""
        },
        validation:{
            formSearch:         {},
        },
        msgBox:{
            show:               false,
            title:              "",
            message:            "",
            buttons:            [],
            type:               ""
        },
    };
  
}

export default class MappingComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    handelOnResetComponent = () =>{

        this.setState(initialState());

    }

    handleOnCloseMapping = () =>{

        this.handelOnResetComponent();
        this.props.close();

    }

    handleOnRefresh = () => {

        this.handelOnResetComponent();
        this.props.handleOnRefresh();

    }

    handleOnCallFormSearch = (__bool, __typeSearch) =>{

        this.setState({
            showFormSearch: __bool,
            typeSearch: __typeSearch,
            formSearch: {
                groupName:      "",
                statusRPM:      "",
                firstName:      "",
                lastName:       "",
                dob:            "",
                email:          "",
                mrn:            "",
                phoneMobile:    ""
            }
        });

    }

    handleOnSubmitSearch = () =>{

        let error = {};
        let formSearch  = {}

        Object.assign(formSearch, this.state.formSearch);
        func.validate(schema, formSearch, error);

        if(Object.keys(error).length > 0){

            this.setState({
                validation:{
                    ...this.state.validation,
                    formSearch: error
                } 
            });

            return;
        };

        this.props.handleOnSubmitSearch(formSearch, this.state.typeSearch);
        this.handleOnCallFormSearch(false, null);

    }
    
    handleOnNextPage= (__typeAction) => {

        this.props.handleOnNextPage(__typeAction);

    }   

    handleOnPreviousPage = (__typeAction) => {

        this.props.handleOnPreviousPage(__typeAction);

    }

    handleOnChangeformSearch = (__e) =>{

        if(__e.target.name in this.state.validation.formSearch){

            delete this.state.validation.formSearch[__e.target.name];

        };


        this.setState({
            validation: {
                ...this.state.validation,
                formSearch: {
                    ...this.state.validation.formSearch
                }
            },
            formSearch:{
                ...this.state.formSearch,
                [__e.target.name]: __e.target.value
            }
        });

    }

    handleOnChangeData = (__e) =>{
        
        let value = Array.from(__e.target.selectedOptions, option => option.value);

        this.setState({
            [__e.target.name]: value
        });

    }

    handleOnRemove = () =>{

        if(this.state.idsInSide.length === 0){

            return;

        };

        this.props.handleOnMapping("remove", this.state.idsInSide);

        this.setState({
            idsInSide:          []
        });

    }

    handleOnMapping = () =>{

        if(this.state.idsOutSide.length === 0){

            return;

        };

        this.props.handleOnMapping("push", this.state.idsOutSide);
        
        this.setState({
            idsOutSide:         []
        });

    }

    handleOnVerify = (__e) => {

        let pass    = window.sessionStorage.getItem("accesscode");
        let token   = window.sessionStorage.getItem("token");
        let buffer  = Buffer.from(token + this.state.passWord);
        let hash    = crypto.createHash("sha256").update(buffer).digest("base64");

        if(pass === hash){
    
          this.setState({
            passWord:               "",
            PID:                    true,
            showFormConfirmPass:    false
          });
    
          return;
    
        };
    
        this.setState({
            msgBox:{
                show:               true,
                title:              "Access",
                message:            "You don't have permission to access",
                buttons:            [{key: "btnClose", title: "Close"}],
                type:               "error"
            },
            passWord:                 "",
            PID:                      false,
            checkedPID:               false,
            showFormConfirmPass:      false
        });
    
    }

    handleOnChangeInputPass = (__e) => {

        if(__e.target.name === "PID"){
    
          let showForm = false;
          
          if(!this.state.checkedPID){
    
            showForm = true;
    
          };
    
          this.setState({
            ...this.state,
            passWord:               "",
            PID:                    false,
            checkedPID:             !this.state.checkedPID,
            showFormConfirmPass:    showForm
          });
    
          return;
    
        };
    
        this.setState({
    
            passWord: __e.target.value
          
        });
    
    }

    handleOnCallMsgBox = () => {
        this.setState({
            msgBox:{
                show:               false,
                title:              "",
                message:            "",
                buttons:            [],
                type:               ""
            },
        })
    }

    render() {

        return(
            <React.Fragment>

                <MsgBoxComponent key={uuidv4()} show={this.state.msgBox.show} id={uuidv4()} type={this.state.msgBox.type} title={this.state.msgBox.title} message={this.state.msgBox.message} buttons={this.state.msgBox.buttons} percent={this.state.msgBox.percent} process={this.state.msgBox.process} onClick={this.handleOnCallMsgBox}></MsgBoxComponent>
                
                <Modal enforceFocus={false} show={this.props.show} onHide={this.handleOnCloseMapping} backdrop="static" dialogClassName="modal-dialog list-groups-patient">
                    <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                        <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>Mapping</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                        !this.props.permissions?
                            <div className="k-iconbox k-iconbox--active k-iconbox--danger">
                                <div className="k-iconbox__icon">
                                <div className="k-iconbox__icon-bg"></div>
                                <i className="fa fa-lock"></i>
                                </div>
                                <div className="k-iconbox__title">
                                403 FORBIDDEN
                                </div>
                                <div className="k-iconbox__content">
                                You not enough rights to access to this item
                                </div>
                            </div>
                        :
                            <div className="row">
                                <div className="col-lg-5 col-xl-5 order-lg-1 order-xl-1">
                                    <div className="form-group" style={{marginBottom: "0px"}}>
                                        <label>Outside</label>
                                        <select onChange={this.handleOnChangeData} value={this.state.idsOutSide || []} name="idsOutSide" className="form-control" size="10" style={{width: "100%"}} multiple>
                                            {
                                                this.props.type === "group" ?
                                                    this.props.outSide.map((__r)=>(
                                                        <option key={__r.groupId} value={__r.groupId}>{__r.groupName}</option>
                                                    ))
                                                :
                                                    this.props.outSide.map((__r)=>(
                                                        <option key={__r.patientId} value={__r.patientId}>{!this.state.PID ? __r.mrn : __r.firstName + " " + __r.lastName}</option>
                                                    ))
                                            }
                                        </select>
                                    </div> 
                                    <div style={{display: "block",padding: "5px", textAlign: "center"}}>
                                        <div style={{display: "inline", margin: "auto"}}>
                                            <button disabled={this.props.paginationOutSide.page <= 1} type="button" onClick={()=>{this.handleOnPreviousPage("outSide")}} className="btn btn-secondary btn-sm" style={{width: "25%", marginRight: "5px", border: "none"}}><i className="la la-angle-left"></i></button>
                                            <button type="button" onClick={()=>{this.handleOnCallFormSearch(true, "outSide")}} className="btn btn-secondary btn-sm" style={{width: "30%", marginRight: "5px", border: "none"}}><i className="flaticon-search"></i></button>
                                            <button disabled={this.props.paginationOutSide.page >= Math.ceil(this.props.paginationOutSide.totalRows / this.props.paginationOutSide.pageSizes)} type="button" onClick={()=>{this.handleOnNextPage("outSide")}} className="btn btn-secondary btn-sm" style={{width: "25%", border: "none"}}><i className="la la-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-xl-2 order-lg-1 order-xl-1" style={{display: "block", margin: "auto"}}>
                                    <div>
                                        <button disabled={this.props.outSide.length === 0} type="button" onClick={()=>{this.handleOnMapping()}} className="btn btn-secondary btn-sm" style={{width: "100%", marginBottom: "10px"}}><i className="fa fa-arrow-right"></i></button>
                                        <button disabled={this.props.inSide.length === 0} type="button" onClick={()=>{this.handleOnRemove()}} className="btn btn-secondary btn-sm" style={{width: "100%"}}><i className="fa fa-arrow-left"></i> </button>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-5 order-lg-1 order-xl-1">
                                    <div className="form-group"  style={{marginBottom: "0px"}}>
                                        <label>Inside</label>
                                        <select onChange={this.handleOnChangeData} value={this.state.idsInSide || []} name="idsInSide" className="form-control" size="10" style={{width: "100%"}} multiple>
                                            {
                                                this.props.type === "group" ?
                                                    this.props.inSide.map((__r)=>(
                                                        <option key={__r.groupId} value={__r.groupId}>{__r.groupName}</option>
                                                    ))
                                                :
                                                    this.props.inSide.map((__r)=>(
                                                        <option key={__r.patientId} value={__r.patientId}>{!this.state.PID ? __r.mrn : __r.firstName + " " + __r.lastName}</option>
                                                    ))
                                            }
                                        </select>
                                    </div> 
                                    <div style={{display: "block",padding: "5px", textAlign: "center"}}>
                                        <div style={{display: "inline", margin: "auto"}}>
                                            <button disabled={this.props.paginationInSide.page <= 1} type="button" onClick={()=>{this.handleOnPreviousPage("inSide")}} className="btn btn-secondary btn-sm" style={{width: "25%", marginRight: "5px", border: "none"}}><i className="la la-angle-left"></i></button>
                                            <button type="button" onClick={()=>{this.handleOnCallFormSearch(true, "inSide")}} className="btn btn-secondary btn-sm" style={{width: "30%", marginRight: "5px", border: "none"}}><i className="flaticon-search"></i></button>
                                            <button disabled={this.props.paginationInSide.page >= Math.ceil(this.props.paginationInSide.totalRows / this.props.paginationInSide.pageSizes)} type="button" onClick={()=>{this.handleOnNextPage("inSide")}} className="btn btn-secondary btn-sm" style={{width: "25%", border: "none"}}><i className="la la-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    {
                        !this.props.permissions
                        ?
                        ""
                        :
                        <Modal.Footer style={{height: "50px"}}>
                            {
                                this.props.type !== "group" ?
                                    <OverlayTrigger trigger="focus" show={this.state.showFormConfirmPass} placement="right" overlay={
                                            <Popover id='popoverVerifyMapping'>
                                            <Popover.Title as='h3'>Verify</Popover.Title>
                                            <Popover.Content>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" onChange={this.handleOnChangeInputPass} value={this.state.passWord || ""} name="passWord" />
                                                </div>
                                                <button onClick={this.handleOnVerify} type="button" style={{float:"right", marginBottom: "10px", width: "100%"}} className="btn btn-pill btn-label-primary btn-sm">Submit</button>
                                            </Popover.Content>
                                        </Popover>
                                        }>
                                        <div className="mr-auto">
                                            <label style={{float: "right", margin: "auto", padding: "3px"}}>Shown/Hidden</label>
                                            <span className="k-switch k-switch--sm k-switch--outline k-switch--icon k-switch--primary" style={{float: "right"}}>
                                                <label style={{display: "unset"}}>
                                                    <input checked={this.state.checkedPID || false} onChange={this.handleOnChangeInputPass} type="checkbox" name="PID"/>
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </OverlayTrigger>
                                : ""
                            }
                            <button onClick={()=>{this.handleOnRefresh()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                        </Modal.Footer>
                    }
                </Modal>

                <Modal show={this.state.showFormSearch} onHide={()=>{this.handleOnCallFormSearch(false, null)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-300">
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "inherit"}}>Search</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>  
                            <div hidden={this.props.type !== "group"} className="form-group" style={{marginBottom: "0px"}}>
                                <label>Group Name</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.groupName || ""} type="text" className="form-control form-control-sm" name="groupName" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["groupName"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>Status RPM</label>
                                <select onChange={this.handleOnChangeformSearch} name="statusRPM" className="form-control form-control-sm">
                                    <option value="">All</option>
                                    <option value="enabled">Enabled</option>
                                </select>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>First Name</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.firstName || ""} type="text" className="form-control form-control-sm" name="firstName" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["firstName"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>Last Name</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.lastName || ""} type="text" className="form-control form-control-sm" name="lastName" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["lastName"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>DOB</label>
                                <DateTimePickerComponent today={false} onChange={this.handleOnChangeformSearch} times={false} type="text" value={this.state.formSearch.dob || ""} format={configs.formatDateOnSite} name="dob"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["dob"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>Email</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.email || ""} type="text" className="form-control form-control-sm" name="email" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["email"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "20px"}}>
                                <label>MRN</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.mrn || ""} type="text" className="form-control form-control-sm" name="mrn" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["mrn"]}</span>
                            </div>
                            <div hidden={this.props.type === "group"} className="form-group" style={{marginBottom: "0px"}}>
                                <label>Phone Mobile</label>
                                <input onChange={this.handleOnChangeformSearch} value={this.state.formSearch.phoneMobile || ""} type="text" className="form-control form-control-sm" name="phoneMobile" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formSearch["phoneMobile"]}</span>
                            </div>
                        </Modal.Body>
                    <Modal.Footer style={{height: "50px"}}>
                        <button style={{width: "100%"}} onClick={()=>{this.handleOnSubmitSearch()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-search"></i> Search</button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>  
        )

    }

}