
import React, { Component } from 'react';
import moment from "moment";

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';

import * as configs from '../../../../lib/configs';
import func from '../../../../lib/func';

import './timerLogsStyle.css'

const schema = {
    "mrn":        "^[A-Za-z0-9]{8}$",
    "category":   "^[A-Za-z0-9\\_\\-]+$",
    "sub_cat":    "^[A-Za-z0-9\\_\\-]+$",
    "date":       "^([12]\\d{3}\\-(0[1-9]|1[0-2])\\-(0[1-9]|[12]\\d|3[01]))\\s(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))$",
    "time":       "^([1-9]|[1-9][0-9]+)$",
    "notes":      "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$"
}

const initialState = () => {

    return{
        export:             true,
        method:             "",
        validation:         {
            formData:       {}
        },
        formData:{
            id:             "",
            mrn:            "",
            category:       "",
            sub_cat:        "",
            date:           "",
            time:           "",
            notes:          ""
        },
        showFormData:       false,
        subCategories:      []
    }

}

const _ref="TimerLogsComponent";

export default class TimerLogsComponent extends Component{

    constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    componentDidMount(){

    }

    handleOnFirstPage = () => {

        this.props.pagination.page = 1;
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnLastPage = () => {
        
        this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);


    }

    handleOnChangePage = (__page) => {
    
        this.props.pagination.page = __page;
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);

    }
    
    handleOnPreviousPage = () => {

        this.props.pagination.page = this.props.pagination.page - 1;
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnNextPage = () => {

        this.props.pagination.page = this.props.pagination.page + 1;
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnLoadMorePage = (__pagination, __plus) => {

        this.props.func.loadMorePage_timerLogsAction(__pagination, __plus);
        
    }

    handleOnSort = (__field) =>{
    
        if(this.props.data.length === 0){

            return;
      
        };

        let sortValue = "";

        if(__field === this.props.sorts.sortBy){

            if(this.props.sorts.sortValue === "ASC"){
      
                sortValue = "DESC";
      
            };
            
            if(this.props.sorts.sortValue === "DESC"){
      
      
                sortValue = "ASC";
              
            };
      
            this.props.sorts.sortValue = sortValue;

        }else{
      
            this.props.sorts.sortBy       = __field;
            this.props.sorts.sortValue    = "ASC";
      
        };
        
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnFilter = (__e) => {

        this.props.filters.month = __e.target.value;
        this.props.func.getLogs_timerLogsAction(this.props.filters, this.props.sorts);

    }

    handleOnCreate = () =>{

        this.handelOnCallFormData(true);
        this.setState({
            method:     "POST",
            formData:{
                ...this.state.formData,
                mrn:  this.props.filters.mrn,
            }
        });

    }

    handleOnUpdate = (__obj) =>{

        this.handelOnCallFormData(true);
        this.setState({
            subCategories:  this.props.categories.filter(function(cate){return cate.category === __obj.category}).sort((a, b) => {return -1}),
            method:         "PUT",
            formData:{
                id:         __obj.id,
                mrn:        __obj.mrn,
                category:   __obj.category,
                sub_cat:    __obj.subCategory,
                date:       moment.utc(__obj.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone),
                time:       __obj.times,
                notes:      __obj.notes
            }
        });

    }

    handleOnDelete = (__obj) =>{

        this.setState({
            method: "DELETE",
            formData:{
                id:         __obj.id,
                mrn:        __obj.mrn,
                category:   __obj.category,
                sub_cat:    __obj.subCategory,
                date:       moment.utc(__obj.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateOnSite),
                time:       __obj.times,
                notes:      __obj.notes
            }
        });

        this.props.func.create_MsgBoxAction(_ref,"Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);

    }

    handelOnCallFormData = (__show) => {

        if(!__show){

            this.handelOnResetComponent();
            
        };

        this.setState({
            showFormData: __show
        });

    }

    handelOnResetComponent = () =>{

        this.setState(initialState());

    }

    handelOnRefreshData = () => {

        this.props.func.getLogs_timerLogsAction({mrn: this.props.filters.mrn, month: this.props.filters.month});
        this.handelOnResetComponent();
    
    }
      
    handleOnCloseTimerLogs = () =>{

        this.handelOnResetComponent();
        this.props.func.callTimerLogs_timerLogsAction(false);

    }
    
    handleOnChangeFormData = (__e) => {

        if(__e.target.name === "category"){

            this.setState({
                
                subCategories: this.props.categories.filter(function(cate){return cate.category === __e.target.value}).sort((a, b) => {return -1})

            });

        };

        if(__e.target.name in this.state.validation.formData){

            delete this.state.validation.formData[__e.target.name];

        };

        this.setState({
            validation: {
                ...this.state.validation,
                formData: {
                    ...this.state.validation.formData
                }
            },
            formData:{
                ...this.state.formData,
                [__e.target.name]:  __e.target.value
            }
        });

    }
 
    handleOnSubmitFormData = () => {

        let error           = {};
        let formData        = Object.assign({}, this.state.formData);
            formData.date   = moment(formData.date + " " + configs.timeZone, configs.formatDateTimeTZ).utcOffset("+00:00").format(configs.formatDateTimeOutSite);

        func.validate(schema, formData, error);

        if(Object.keys(error).length > 0){

            this.setState({
                validation:{
                    ...this.state.validation,
                    formData: error
                }
            });

            return;

        };

        if(this.state.method === "POST"){
     
            this.props.func.create_timerLogsAction(formData);

        }else{

            this.props.func.update_timerLogsAction(formData);

        };

        this.handelOnCallFormData(false);
        
    }

    handleOnCallMsgBox = (__button) => {

        if(__button === "btnYes"){

            let formData        = Object.assign({}, this.state.formData);
                formData.date   = moment(formData.date + " " + configs.timeZone, configs.formatDateTimeTZ).utcOffset("+00:00").format(configs.formatDateTimeOutSite);

            this.props.func.delete_timerLogsAction(formData)
            this.handelOnCallFormData(false);

        };

    }

    handleOnExportBilling  = async() =>{

        let filters = {
            mrn:    this.props.filters.mrn,
            month:  this.props.filters.month
        }

        let url         = await this.props.func.dowloadFile_timerLogsAction(filters);
        let link        = document.createElement("a");
            link.href   = url;
            link.setAttribute("download","shortBill.csv");

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link)
        
    }

	render() {

		return (
            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                <Modal show={this.props.show} onHide={()=>{this.handleOnCloseTimerLogs()}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-xl">
                    <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                        <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>Timer Log Editor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!configs.scopes.readTimerLogs
                            ?
                                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                                <div className="k-iconbox__icon">
                                    <div className="k-iconbox__icon-bg"></div>
                                    <i className="fa fa-lock"></i>
                                </div>
                                <div className="k-iconbox__title">
                                    403 FORBIDDEN
                                </div>
                                <div className="k-iconbox__content">
                                    You not enough rights to access to this item
                                </div>
                                </div>
                        :
                            <React.Fragment>
                                <div style={{marginBottom: "50px"}}>
                                    <button onClick={()=>{this.handelOnRefreshData()}} type="button" className="btn btn-sm btn-secondary btn-pill" style={{float: "right", margin: "auto"}}><i className="fa fa-sync-alt"></i>Refresh</button>
                                    <select onChange={this.handleOnFilter} value={this.props.filters.month || ""} name="month" className="form-control form-control-sm" style={{float: "right", width: "150px", margin: "auto", marginRight: "5px"}}>
                                        <option value="" disabled hidden>Please Choose . . .</option>
                                        {
                                            this.props.months.map((__m)=>(
                                                <option key={__m.key} value={__m.key}>{__m.title}</option>
                                            ))
                                        }
                                    </select>
                                    <label className="col-form-label form-control-sm" style={{float: "right", width: "110px", margin: "auto"}}>Filter By Month</label>
                                </div>
                                <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%"}}>
                                    <table className="k-datatable__table list-database" style={{display: "block", maxHeight: "350px", overflow: "auto"}}>
                                        <thead className="k-datatable__head">
                                            <tr className="k-datatable__row"> 
                                                <th onClick={()=>{this.handleOnSort("mrn")}}  className={this.props.sorts.sortBy === "mrn" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "80px"}}>MRN {this.props.sorts.sortBy === "mrn" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th onClick={()=>{this.handleOnSort("category")}}  className={this.props.sorts.sortBy === "category" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "105px"}}>CATEGORY {this.props.sorts.sortBy === "category" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th onClick={()=>{this.handleOnSort("sub_cat")}}  className={this.props.sorts.sortBy === "sub_cat" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "135px"}}>SUB CATEGORY {this.props.sorts.sortBy === "sub_cat" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th onClick={()=>{this.handleOnSort("date")}}  className={this.props.sorts.sortBy === "date" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "140px"}}>DATE {this.props.sorts.sortBy === "date" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th onClick={()=>{this.handleOnSort("time")}}  className={this.props.sorts.sortBy === "times" ? "k-datatable__cell--center k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--center k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "70px"}}>TIMES {this.props.sorts.sortBy === "time" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th className="k-datatable__cell--left k-datatable__cell">
                                                    <span style={{width: "200px"}}>NOTES</span>
                                                </th>
                                                <th onClick={()=>{this.handleOnSort("recorded_by")}}  className={this.props.sorts.sortBy === "recorded_by" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                                    <span style={{cursor: "pointer", width: "125px"}}>RECORDED BY {this.props.sorts.sortBy === "recorded_by" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                                </th>
                                                <th className="k-datatable__cell--left k-datatable__cell">
                                                    <span style={{width: "80px"}}>ACTIONS </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="k-datatable__body">
                                            {   
                                                this.props.data.map((__r)=>(
                                                    <tr key={__r.id} className="k-datatable__row" style={{left: "0px"}}>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "80px"}}>{__r.mrn}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "105px"}}>{__r.category}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "135px"}}>{__r.subCategory}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "140px"}}>{moment.utc(__r.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--center k-datatable__cell">
                                                            <span style={{width: "70px"}}><span className="badge btn-brand">{__r.times}</span></span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "200px"}}>{__r.notes}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{width: "125px"}}>{__r.recordedBy}</span>
                                                        </td>
                                                        <td className="k-datatable__cell--left k-datatable__cell">
                                                            <span style={{overflow: "visible", position: "relative", width: "80px"}}>		
                                                                <a hidden={!configs.scopes.editTimerLogs} onClick={()=>{this.handleOnUpdate(__r)}} title="Edit details" className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-edit"></i></a>						
                                                                <a hidden={!configs.scopes.delTimerLogs} onClick={()=>{this.handleOnDelete(__r)}} title="Delete" className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-trash"></i></a>					
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <PaginationComponent pagination={this.props.pagination} handleLoadMorePage={this.handleOnLoadMorePage} handleChangePage={this.handleOnChangePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                            </React.Fragment>
                        }
                    </Modal.Body>
            
                    {configs.scopes.readTimerLogs
                        ?
                        <Modal.Footer style={{height: "50px"}}>
                            <label className="mr-auto" style={{marginTop: "auto"}}>
                                <span style={{fontSize: "larger", marginRight: "5px", fontWeight: "bold", display: "block", float: "left"}}>TOTAL: </span>
                                <span className="badge badge-pill badge-primary" style={{width: "120px"}}>{this.props.totalTimes + " minutes"}</span>
                            </label>

                            <button hidden={!moment(moment.utc()).isAfter(moment.utc(this.props.filters.month, "YYYY-MM"), "month")} onClick={()=>{this.handleOnExportBilling()}} className="btn btn-secondary btn-elevate btn-pill btn-sm" type="button"><i className="fa fa-file-export"></i> Export Billing as CSV</button>
                            <button hidden={!configs.scopes.addTimerLogs} onClick={()=>{this.handleOnCreate()}} className="btn btn-secondary btn-elevate btn-pill btn-sm" type="button"><i className="fa fa-plus"></i> Add New</button>
                            <button onClick={()=>{this.handleOnCloseTimerLogs()}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="fa fa-sign-out-alt"></i> Close</button>
                        </Modal.Footer>
                    : ""
                    }
               
                </Modal>

                <Modal show={this.state.showFormData} onHide={()=>{this.handelOnCallFormData(false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-300">
                        <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                            <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>{this.state.formData.mrn}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group" style={{marginBottom: "20px"}}>
                                <label>Categories</label>
                                <select onChange={this.handleOnChangeFormData} value={this.state.formData.category || ""} className="form-control form-control-sm" name="category">
                                    <option value="" disabled hidden>Please Choose . . .</option>
                                    {
                                        this.props.categories.sort().filter((v,i,a)=>a.findIndex(t=>(t.category===v.category))===i).map((__c)=>(
                                            <option key={__c.category} value={__c.category}>{__c.category}</option>
                                        ))
                                    }
                                </select>
                                <span className="k-font-danger">{this.state.validation.formData["category"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "20px"}}>
                                <label>Sub Categories</label>
                                <select onChange={this.handleOnChangeFormData} value={this.state.formData.sub_cat || ""} name="sub_cat" className="form-control form-control-sm">
                                    <option value="" disabled hidden>Please Choose . . .</option>
                                    {
                                        this.state.subCategories.map((__c)=>(
                                            <option key={__c.subCategory} value={__c.subCategory}>{__c.subCategory}</option>
                                        ))
                                    }
                                </select>
                                <span className="k-font-danger">{this.state.validation.formData["sub_cat"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "20px"}}>
                                <label>Date</label>
                                <DateTimePickerComponent tabIndex="9" today={true} onChange={this.handleOnChangeFormData} times={true} format={configs.formatDateTimeOnSite} value={this.state.formData.date || ""} name="date" />
                                <span className="k-font-danger">{this.state.validation.formData["date"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "20px"}}>
                                <label>Minutes</label>
                                <input onChange={this.handleOnChangeFormData} value={this.state.formData.time || 0} type="number"  min="0" step="1" name="time" className="form-control form-control-sm" autoComplete="off"/>
                                <span className="k-font-danger">{this.state.validation.formData["time"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "0px"}}>
                                <label>Notes</label>
                                <textarea onChange={this.handleOnChangeFormData} value={this.state.formData.notes || ""} name="notes" className="form-control form-control-sm" rows="3"></textarea>
                                <span className="k-font-danger">{this.state.validation.formData["notes"]}</span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{height: "50px"}}>
                            <button onClick={()=>{this.handleOnSubmitFormData()}} style={{width: "100%"}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="fa fa-save"></i> Save</button>
                        </Modal.Footer>
                </Modal>

       
            </React.Fragment>
		);

	};

};
