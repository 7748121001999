export const stateMsgBox = {
    data:[]
};

const MsgBoxReducer = (__state = stateMsgBox, __action) => {

    switch(__action.type){

        case "CREATE_MSGBOX":

            if(Object.keys(__action.payloads).length > 0){

                __state.data.push(__action.payloads);

            }

            return {
                ...__state,
                data: __state.data
            };

        case "REMOVE_MSGBOX":
 
            let index = __state.data.length > 0 ? __state.data.findIndex((__o)=>{

              return __o.id === __action.payloads.id;

            }) : -1;
  
            if(index > -1){
                __state.data.splice(index, 1);
            }

            return {
                ...__state,
                data: __state.data
            };
            
        case "RESET_MSGBOX":

            return stateMsgBox;

        default: 

            return __state;

    };

};

export default MsgBoxReducer;