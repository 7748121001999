import React, { Component } from 'react';

import NotifiesContainer from '../../../../containers/notifiesContainer';
import AbnormalFlagsContainer from '../../../../containers/abnormalFlagsContainer'
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

const _ref = "QuickPanelComponent";

export default class QuickPanelComponent extends Component{

	constructor(__props) {

		super(__props);

	};

  handleOnCallMsgBox = (__button) =>{


  }
  
  render() {

    return(

      <React.Fragment>
        
        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <div>
          <div style={{overflow: "hidden"}} className={this.props.show ? "k-quick-panel k-quick-panel--on" : "k-quick-panel"}>
            <div className="k-quick-panel__nav">
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item">
                  <a className={"nav-link " + (this.props.tab === 1 ? "show active" : "")} data-toggle="tab" href="#quick_panel_tab_notifications" role="tab">CONTACT REQUEST</a>
                </li>
                <li className="nav-item">
                  <a className={"nav-link " + (this.props.tab === 2 ? "show active" : "")} data-toggle="tab" href="#quick_panel_tab_abnormal" role="tab">ALERT ABNORMAL</a>
                </li>
              </ul>
              <button onClick={()=>{this.props.func.showMainPanel_quickPanelAction(false, 1)}} className="k-quick-panel__close" id="k_quick_panel_close_btn"><i className="la la-close"></i></button>
            </div>
            <div className="k-quick-panel__content">
              <div className="tab-content">
                <div className={"tab-pane fade " + (this.props.tab === 1 ? "show active" : "")} id="quick_panel_tab_notifications" style={{overflow: "unset"}} role="tabpanel">
                    <NotifiesContainer/>
                </div>
                <div className={"tab-pane fade " + (this.props.tab === 2 ? "show active" : "")} id="quick_panel_tab_abnormal" style={{overflow: "unset"}} role="tabpanel">
                    <AbnormalFlagsContainer/>
                </div>
              </div>
            </div>
          </div>
          <div style={this.props.subPanel.show ? {overflow: "hidden", right: "425px", zIndex: "1000"} : {overflow: "hidden", zIndex: "1000"}} className={this.props.subPanel.show  ? "k-quick-panel k-quick-panel--on" : "k-quick-panel"}>
              <div className="k-quick-panel__nav">
                <ul className="nav nav-pills" >
                  <li className="nav-item">
                    <a className="nav-link show active" data-toggle="tab" href="#quick_panel_tab_details">DETAILS</a>
                  </li>
                </ul>
                <button onClick={()=>{this.props.func.showSubPanel_quickPanelAction(false, [])}} className="k-quick-panel__close" id="k_quick_panel_close_btn"><i className="la la-close"></i></button>
              </div>
              <div className="k-quick-panel__content">
                <div className="tab-content">
                  <div className="tab-pane fade show active" style={{overflow: "unset"}} id="quick_panel_tab_details" role="tabpanel">
                    <div className="k-timeline">
                      {
                        this.props.subPanel.data.map((__el)=>(
                          __el
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </React.Fragment>
    )

  }

}