
import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import * as configs from '../../../../lib/configs';

const initialState = () => {
    return{
        deviceCall: null,
        connectionDevice: null,
        speakerDevices: [],
        ringtoneDevices: [],
        ringtoneDevicesSelect: "",
        speakerDevicesSelect: "",
        statusCall:{
            logs: [],
            hangup: false,
            scrollTop: ""
        },
        outputVolumeBar: {
            width: "", 
            background: ""
        },
        inputVolumeBar: {
            width: "", 
            background: ""
        },
        formData:{
            callTo: ""
        }
    }
}

const _ref="CallTwilioComponent";

export default class CallTwilioComponent extends Component{

    constructor(__props) {

		super(__props);

        this.state = initialState();

	};
 
    async componentWillReceiveProps(nextProps){

        if(nextProps.show && this.state.deviceCall === null){

            await this.createDevice();

        };

        if(!nextProps.show && (this.state.deviceCall !== null || this.state.connectionDevice !== null)){

            await this.disconnect();
            await this.setState(initialState());

        }

    }
          
    handleOnChangeformData = (__e) => {

        this.setState({
            formData:{
                ...this.state.formData,
                [__e.target.name]: __e.target.value
            }
        });

    }

    handleChangeDevice = (__e) => {

        if(String(__e.target.name).toLowerCase() === "ringtone"){

            this.state.deviceCall.audio.ringtoneDevices.set(__e.target.value);

            return;

        };

        this.state.deviceCall.audio.speakerDevices.set(__e.target.value);

    }
  
    createDevice = async() => {

        let Device      = (await  import('twilio-client')).Device
        let device      = new Device();
        let response    = await fetch(configs.twilioEndPoind)
        let data        = await response.json();

        device.setup(data.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true
        });

        device.on("error", async (error) => {

            await this.log("Error: " + error.message, "error");
            await this.setState({
                statusCall:{
                    ...this.state.statusCall,
                    hangup: false,
                }
            });

        });

        device.on("connect", async(conn) => {

            this.bindVolumeIndicators(conn);
            
            await this.log("Successfully established call!");

        });

        device.on("disconnect", async(conn) => {

            await this.log("Call ended.", "error");
            await this.setState({
                statusCall:{
                    ...this.state.statusCall,
                    hangup: false,
                }
            });

        });

        device.on("incoming", async (conn) => {

            await this.log("Incoming connection from " + conn.parameters.From);

            // Block phone numbers
            // var archEnemyPhoneNumber = "+17028198600";

            // if (conn.parameters.From === archEnemyPhoneNumber) {

            //   conn.reject();
            //   console.log("Rejected call.");

            // } else {

            conn.accept();

            // }

        });

        device.audio.on("deviceChange", await this.updateAllDevices.bind(device));

        await this.setState({
            deviceCall: device,
            formData:{
                callTo: this.props.phonebook.length > 0 ? this.props.phonebook[0].key : ""
            }
        });

    }
    
    connect = async() => {

        if(!new RegExp("^\\+[0-9]+$").test(this.state.formData.callTo)){

            this.log("Missing phone number!", "error");

            return;

        }

        let cleannum    = this.state.formData.callTo.replace(new RegExp("[^0-9]","g"), "");
        let lennum      = cleannum.length;
        let firstdigit  = cleannum.substring(0,1);
        let compresult  = firstdigit.localeCompare("1");
        let prefix      = "";

        if (lennum === 10) {
            
            if (compresult === 0) {

                prefix = "+";

            } else {

                prefix = "+1";

            }

        } else {

            prefix = "+";

        };

        let params = {
            To: prefix + cleannum
        }

        let connectionDevice = this.state.deviceCall.connect(params);
        
        await this.setState({
            connectionDevice: connectionDevice,
            statusCall:{
                ...this.state.statusCall,
                hangup: true,
                logs:[]
            }
        });

        await this.log("Calling: " + prefix + cleannum);

    }

    disconnect = async() => {

        this.state.deviceCall.disconnectAll();
    
        await this.setState({
            connectionDevice: null,
            statusCall:{
                ...this.state.statusCall,
                hangup: false
            }
        });

    }

    callSecond  = () => {

        this.state.connectionDevice.sendDigits("")

        // log("Ext:"+document.getElementById("exp").value);
    }

    bindVolumeIndicators = (__connection) => {

        __connection.on("volume", async(__inputVolume, __outputVolume) => {

            let inputVolumeBar = {
                width: "",
                background: ""
            };
            
            let outputVolumeBar = {
                width: "",
                background: ""
            };

            let inputColor = "red";

            if (__inputVolume < 0.5) {

                inputColor = "green";

            } else if (__inputVolume < 0.75) {

                inputColor = "yellow";

            };
        
            inputVolumeBar.width        = Math.floor(__inputVolume * 300) + "px";
            inputVolumeBar.background   = inputColor;

            let outputColor = "red";

            if (__outputVolume < 0.5) {

                outputColor = "green";

            } else if(__outputVolume < 0.75) {

                outputColor = "yellow";

            };

            outputVolumeBar.width = Math.floor(__outputVolume * 300) + "px";
            outputVolumeBar.background = outputColor;

            this.setState({

                outputVolumeBar: outputVolumeBar,
                inputVolumeBar: inputVolumeBar

            });

        });

    }

    updateAllDevices = async () => {

        await this.setState({

            ringtoneDevices: this.updateDevices(this.state.deviceCall.audio.ringtoneDevices.get()),
            speakerDevices: this.updateDevices(this.state.deviceCall.audio.speakerDevices.get())

        });

    }

    updateDevices = (__selectedDevices) => {

        let selectEl = [];
        
        this.state.deviceCall.audio.availableOutputDevices.forEach((__device, __id) => {

            let isActive = __selectedDevices.size === 0 && __id === "default";

            __selectedDevices.forEach(function(___device) {

                if (___device.deviceId === __id) {
                    isActive = true;
                }

            });

            selectEl.push(<option key={uuidv4()} value={__id}>{__device.label}</option>)

        });

        return selectEl;

    }

    log = async (__message, __status) => {

        let logs = this.state.statusCall.logs;

        logs.push(
            <p key={uuidv4()} style={{marginBottom: "0px"}}>
                <span className={__status === "error" ? "k-font-bold k-font-danger" : "k-font-primary"}>
                    {moment.utc().utcOffset(configs.timeZone).format("MM/DD/YYYY hh:mm A") + " @ " + __message}
                </span>
            </p>
        );

        await this.setState({

            statusCall:{
                ...this.state.statusCall,
                logs: logs
            }

        });

    }

    handleOnRefresh = async() => {

        await this.disconnect();
        await this.setState(initialState());
        await this.createDevice();

    }

    handleOnCallMsgBox = (__button) =>{


    }
    
	render() {
   
		return (
            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                <Modal show={this.props.show} onHide={this.props.close} backdrop="static" dialogClassName="modal-dialog modal-100w">
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "inherit"}}>Telephone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                        <div className="form-group">
                            <label>From Responder:</label>
                            <input type="text" className="form-control form-control-sm" name="fromPhone" value={this.props.responder} autoComplete="off" readOnly/>
                        </div>
                        <div className="form-group">
                            <label>To Phone:</label>
                            <select onChange={this.handleOnChangeformData} className="form-control form-control-sm" name="callTo">
                                {this.props.phonebook.filter(function(__obj)
                                    {
                                        return __obj.key !== undefined && __obj.key !== null;
                                    }).map((__p)=>{
                                        return(<option key={uuidv4()} value={__p.key}>{__p.title}</option>)
                                    })
                                }
                            </select>

                        </div>
                        <div className="form-group">
                            <label>Mic</label>
                            <div className="form-control form-control-sm" style={{height: "25px", maxWidth: "100%", width: this.state.inputVolumeBar.width, backgroundColor: this.state.inputVolumeBar.background}}></div>
                        </div>
                        <div className="form-group">
                            <label>Speaker</label>
                            <div className="form-control form-control-sm" style={{height: "25px", maxWidth: "100%", width: this.state.outputVolumeBar.width, backgroundColor: this.state.outputVolumeBar.background}}></div>
                        </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                            <div className="form-group">
                            <label>Ringtone Devices</label>
                            <select value={"default"} onChange={this.handleChangeDevice} name="ringtone" className="form-control form-control-sm" size="30" style={{height: "108px"}}>
                                {this.state.ringtoneDevices}
                            </select>
                            </div>
                            <div className="form-group">
                            <label>Speaker Devices</label>
                            <select value={"default"} onChange={this.handleChangeDevice} name="speaker" className="form-control form-control-sm" size="30" style={{height: "108px"}}>
                                {this.state.speakerDevices}  
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                        <div className="form-group" style={{marginBottom: "0px"}}>
                            <label>Status:</label>
                            <div className="form-control form-control-sm" style={{height: "100px", overflow: "auto", backgroundColor: "aliceblue"}}>
                            {this.state.statusCall.logs}
                            </div>
                        </div>
                        </div>
                    </div>        
                    </Modal.Body>
                    <Modal.Footer style={{height: "50px"}}>
                        <button onClick={()=>{this.handleOnRefresh()}} type="button" className="btn btn-sm btn-secondary btn-pill mr-auto"><i className="flaticon-refresh"></i>Refresh</button>
                        <button onClick={()=>{this.connect()}} type="button" className="btn btn-success btn-elevate btn-pill btn-sm" disabled={this.state.statusCall.hangup}><i className="fa fa-phone"></i>Calling</button>
                        <button onClick={()=>{this.disconnect()}} type="button" className="btn btn-danger btn-elevate btn-pill btn-sm"><i className="fa fa-phone-slash"></i>Hangup</button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
		);

	};

};
