
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {OnInit_patientsAction, resetPage_patientsAction} from '../actions/patientsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

import PatientsComponent from '../components/dashboards/contents/patients/patientsComponent';

class PatientsContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<PatientsComponent func={this.props.func}/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{

	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			OnInit_patientsAction: () => {
				__dispatch(OnInit_patientsAction())
			},
			resetPage_patientsAction: () => {
				__dispatch(resetPage_patientsAction())
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	}

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(PatientsContainer);