import React, {Component} from 'react';
import { connect } from 'react-redux';

import { 
	showMainPanel_quickPanelAction,
	showSubPanel_quickPanelAction,
} from '../actions/quickPanelAction';

import {redirect_patientDetailsAction} from '../actions/patientDetailsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

import LayoutComponent from '../components/dashboards/layouts/layoutComponent';

class LayoutContainer extends Component{

  render() {

    return(

      <React.Fragment>
 
        <LayoutComponent 
          children={this.props.children} 
          patientDetails={this.props.patientDetails} 
          abnormalFlags={this.props.abnormalFlags} 
          msgBox = {this.props.LayoutReducer.msgBox}
          property = {this.props.LayoutReducer.property}
          func={this.props.func}
        />
 
      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    abnormalFlags: __state.AbnormalFlagsReducer,
    patientDetails: __state.PatientDetailsReducer,
    LayoutReducer: __state.LayoutReducer
	}

}
 
const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
      redirect_patientDetailsAction: (__status, __url) => {
        __dispatch(redirect_patientDetailsAction(__status, __url));
      },
			showMainPanel_quickPanelAction: (__show, __tab) => {
				__dispatch(showMainPanel_quickPanelAction(__show, __tab));
			},
			showSubPanel_quickPanelAction: (__show, __data) => {
				__dispatch(showSubPanel_quickPanelAction(__show, __data));
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(LayoutContainer)