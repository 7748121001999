import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import RolesContainer from '../../../../containers/rolesContainer';
import GroupsResponderContainer from '../../../../containers/groupsResponderContainer';
import PermissionsContainer from '../../../../containers/permissionsContainer';
import CustomersContainer from '../../../../containers/customersContainer';
import RespondersContainer from '../../../../containers/respondersContainer';
import ScopesContainer from '../../../../containers/scopesContainer';

import './managerUsersStyle.css';

const initialState = () => {

  return{

  };

}

const _ref="ManagerUsersComponent";

export default class ManagerUsersComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentDidMount(){

    this.props.func.OnInit_managerUsersAction();

  }

  componentWillUnmount(){

    this.props.func.resetPage_managerUsersAction();

  }

  handleOnCallMsgBox = (__button) => {


  }
  
  render() {

    return(
 
      <React.Fragment>
        
        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-grid--stretch">
          <div className="k-container k-content-wrapper  k-grid k-grid--ver" id="k_content_wrapper">
            <div className="k-content	k-grid__item k-grid__item--fluid k-grid k-grid--hor" id="k_content">

              <div className="k-content__head	k-grid__item">
                <div className="k-content__head-main">
                  <div className="k-content__head-breadcrumbs">
                    <a className="k-content__head-breadcrumb-home"><i className="flaticon-home-2"></i></a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <NavLink className="k-content__head-breadcrumb-link" activeClassName="k-content__head-breadcrumb-link--active" to="/dashboars/index">Dashboard</NavLink>
                    {/* <!-- <span className="k-content__head-breadcrumb-link k-content__head-breadcrumb-link--active">Active link</span> --> */}
                  </div>
                </div>
                <div className="k-content__head-toolbar">

                </div>
              </div>

              <div className="row">

                <div className="col-lg-4 col-xl-4 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#groups" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-sitemap"></i></span>
                            <span className="nav-link-title">Groups</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="groups" role="tabpanel">
                            <GroupsResponderContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-xl-4 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#roles" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-user-shield"></i></span>
                            <span className="nav-link-title">Roles</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="roles" role="tabpanel">
                            <RolesContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-xl-4 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#permissions" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-shield-alt"></i></span>
                            <span className="nav-link-title">Permissions</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="permissions" role="tabpanel">
                            <PermissionsContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                  <div className="k-portlet k-portlet--height-fluid">
                    <div className="k-portlet__body">
                      <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#responders" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-users-cog"></i></span>
                            <span className="nav-link-title">Customers</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#customers" role="tab">
                            <span className="nav-link-icon"><i className="fa fa-hospital"></i></span>
                            <span className="nav-link-title">Responders</span>
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane fade active show" id="responders" role="tabpanel">
                          <CustomersContainer/>
                        </div>
                        <div className="tab-pane fade" id="customers" role="tabpanel">
                          <RespondersContainer/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScopesContainer/>

      </React.Fragment>

      
    )

  }

}