import React, { Component } from 'react';
import moment from "moment";

import func from '../../../lib/func';

import './dateTimePickerStyle.css';

export default class DateTimePickerComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = {
      callClock:    false,
      listYears:    [],
      listMonths:   [],
      listDays:     [],
      listHours:    [],
      listMinutes:  [],
      year:         null,
      month:        null,
      day:          null,
      hour:         null,
      minute:       null,
      clock:        null,
      value:        null
    }

    this.wrapperRef = React.createRef()

	};

async componentDidMount(){

  document.addEventListener('click', this.handleClickOutSide);
  document.addEventListener('keyup', this.handleClickOutSide);

  await this.renderClock(this.props.value , this.props.format);

  if(!this.props.today){

    return;

  };

  this.props.onChange({target:{name: this.props.name, value : this.state.value}});

}

async componentWillReceiveProps(nextProps){

  if(nextProps.value !== this.props.value){

    await this.renderClock(nextProps.value , nextProps.format);

  };

}

renderClock = async (__dateTime, __format) => {

  let days      = [];
  let months    = [];
  let years     = [];
  let hours     = [];
  let minutes   = [];
  let dateTime  = moment.utc().utcOffset(window.configs.timeZone);

  if(!func.isEmptyOrNull(__dateTime)){
  
    dateTime = moment.utc(__dateTime, __format);

    if(!dateTime.isValid()){

      await this.setState({
        listYears:    [],
        listMonths:   [],
        listDays:     [],
        listHours:    [],
        listMinutes:  [],
        year:         null,
        month:        null,
        day:          null,
        hour:         null,
        minute:       null,
        clock:        null,
        value:        null 
      });

    }

  };

  for(let i = 0; i <= 59; i++){

    minutes.push(<option key={i} value={i}>{i < 10 ? "0" + i : i }</option>);

  };

  for(let i = 1; i <= 12; i++){

    hours.push(<option key={i} value={i}>{i < 10 ? "0" + i : i }</option>);

  };

  for(let i = 1; i <= dateTime.daysInMonth(); i++){

    days.push(<option key={i} value={i}>{i < 10 ? "0" + i : i }</option>);

  };

  for(let i = 1; i <= 12; i++){

    months.push(<option key={i} value={i}>{i < 10 ? "0" + i : i }</option>);

  };

  for(let i = moment.utc().year(); i >= (moment.utc().year() - 130); i--){

    years.push(<option key={i} value={i}>{i}</option>);

  };

  await this.setState({
    listYears:    years,
    listMonths:   months,
    listDays:     days,
    listHours:    hours,
    listMinutes:  minutes,
    year:         !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.year()),
    month:        !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.month() + 1),
    day:          !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.date()),
    hour:         !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.hour() > 12 || dateTime.hour() === 0 ? Math.abs(dateTime.hour() - 12) : dateTime.hour()),
    minute:       !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.minute()),
    clock:        !this.props.today && func.isEmptyOrNull(__dateTime) ? null : String(dateTime.hour() >= 12 ? "pm" : "am" ),
    value:        !this.props.today && func.isEmptyOrNull(__dateTime) ? null : dateTime.format(this.props.format)   
  });

}

componentWillUnmount() {

  document.removeEventListener('click', this.handleClickOutSide);
  document.removeEventListener('keyup', this.handleClickOutSide);

}

handleClickOutSide = (__e) => {

  let { target } = __e

  if (!this.wrapperRef.current.contains(target)) {

    this.handleOnCallClock(false)
      
  };

}

handleOnCallClock = (__bool) =>{

  this.setState({ 
    callClock: __bool,
  });
  
}

handleOnChange = async(__e) => {

  let dateTime  = "";
  let now       = moment.utc().utcOffset(window.configs.timeZone);

  await this.setState({
    [__e.target.name]: __e.target.value
  });

  if(__e.target.name === "month"){

    let dt = moment.utc(__e.target.value, "M");

    if(this.state.day > dt.daysInMonth()){

      this.state.day = dt.daysInMonth();

    };

  };

  dateTime = (this.state.year || now.year())  + "-";
  dateTime += (this.state.month || (now.month() + 1)) + "-";
  dateTime += (this.state.day || now.date()) + " ";
  dateTime += (this.state.hour || (now.hour() > 12 || now.hour() === 0 ? Math.abs(now.hour() - 12) : now.hour())) + ":"; 
  dateTime += (this.state.minute || now.minute()) + " "; 
  dateTime += (this.state.clock || (now.hour() >= 12 ? "pm" : "am"));

  await this.renderClock(dateTime , "Y-M-D h:m a");

  this.props.onChange({target:{name: this.props.name, value : this.state.value}});

}

render() {
 
    return(

      <React.Fragment>

        <div style={{position: "relative"}} ref={this.wrapperRef}>

          <div style={{marginBottom: "3px", display: "flex"}}>
            <input tabIndex={this.props.tabIndex} type={this.props.type} placeholder={this.props.placeholder} onFocus={()=>{this.handleOnCallClock(true)}} style={{width: "100%"}} value={this.state.value || ""} name="value" className="form-control form-control-sm" autoComplete="off" readOnly/>
          </div>
          <div hidden={!this.state.callClock || this.props.readOnly} style={{zIndex: "999999", position: "absolute", backgroundColor: "white", minWidth: "265px", borderLeft: "1px solid #ebedf2", borderRight: "1px solid #ebedf2", borderBottom: "1px solid #ebedf2", padding: "5px", paddingBottom: "15px", borderBottomLeftRadius: "7px", borderBottomRightRadius: "7px", boxShadow: "rgb(225 225 239 / 62%) 2px 2px 8px 0px"}}>
            <div style={this.props.times ? {marginBottom: "10px" , display: "flex"} : {display: "flex"}}>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.month || ""} name="month" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                {this.state.listMonths}
              </select>
              <div style={{marginLeft: "1%", marginRight: "1.8%", paddingTop: "4px", paddingbottom: "4px"}}>
                <span>/</span>
              </div>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.day || ""} name="day" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                {this.state.listDays}
              </select>
              <div style={{marginLeft: "1%", marginRight: "1.8%", paddingTop: "4px", paddingbottom: "4px"}}>
                <span>/</span>
              </div>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.year || ""} name="year" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                {this.state.listYears}
              </select>
            </div>
            <div hidden={!this.props.times || !this.state.callClock} style={{display: "flex"}}>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.hour || ""} name="hour" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                {this.state.listHours}
              </select>
              <div style={{marginLeft: "2%", marginRight: "2%", paddingTop: "4px", paddingbottom: "4px"}}>
                <span>:</span>
              </div>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.minute || ""} name="minute" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                {this.state.listMinutes}
              </select>
              <div style={{marginLeft: "2%", marginRight: "2%", paddingTop: "4px", paddingbottom: "4px"}}>
                <span>:</span>
              </div>
              <select tabIndex="99" onChange={this.handleOnChange} style={{width: "30%", float: "left", border: "none", borderBottom: "1px solid #ebedf2", background: "transparent"}} value={this.state.clock || ""} name="clock" className="form-control form-control-sm">
                <option value={""} disabled hidden></option>
                <option value={"am"}>AM</option>
                <option value={"pm"}>PM</option>
              </select>
            </div>
          </div>
        </div>
       
      </React.Fragment>
    )

  }

}