
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {remove_MsgBoxAction, onclick_MsgBoxAction} from '../actions/msgBoxAction';

import MsgBoxComponent from '../components/features/msgBox/msgBoxComponent';

class MsgBoxContainer extends Component{

    onClick = (__btn) => {

        let btn = String(__btn).split("_")[0];
        let id = String(__btn).split("_")[1];
        let ref = this.props._ref;

        if(
            this.props.MsgBoxReducer.data.filter((el) =>
            {
                return el.ref === ref && el.id === id;
            }).length === 1
        ){

            this.props.onClick(btn);

        }else{

            this.props.onClick(null);

        }

        this.props.func.remove_MsgBoxAction(id);

    }

    onClose = (__id) => {

        this.props.func.remove_MsgBoxAction(__id);

    }

	render(){

        const _ref = this.props._ref;

		return(
            <React.Fragment>
                {
                    this.props.MsgBoxReducer.data.filter((el) =>
                    {
                      return el.ref === _ref;
                    }).map((__o)=>(
                            <MsgBoxComponent 
                                key={__o.id}
                                show={true} 
                                id={__o.id} 
                                type={__o.data.type} 
                                title={__o.data.title} 
                                message={__o.data.message} 
                                buttons={__o.data.buttons} 
                                onClick={this.onClick}
                                onClose={this.onClose}
                            />
                        ))
 
                }
            </React.Fragment>
		);

	};

};

const mapState = __state => {

	return{
		MsgBoxReducer: __state.MsgBoxReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			remove_MsgBoxAction: (__id, __ref) => {
				__dispatch(remove_MsgBoxAction(__id, __ref));
			},
            onclick_MsgBoxAction: (__btn) => {
                __dispatch(onclick_MsgBoxAction(__btn));
            }
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(MsgBoxContainer);