
import React, { Component } from 'react';

import { connect } from 'react-redux';

import VitalsComponent from '../components/dashboards/contents/vitals/vitalsComponent';

import {getVitals_vitalsAction,create_vitalsAction,loadMorePage_vitalsAction} from '../actions/vitalsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class VitalsContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<VitalsComponent 
					data = {this.props.vitals.data} 
					filters = {this.props.vitals.filters} 
					pagination = {this.props.vitals.pagination} 
					sorts = {this.props.vitals.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		vitals: __state.VitalsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getVitals_vitalsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getVitals_vitalsAction(__filters, __sorts, __pagination))
			},
			create_vitalsAction: (__formData) => {
				__dispatch(create_vitalsAction(__formData))
			},
			loadMorePage_vitalsAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_vitalsAction(__pagination, __plus))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(VitalsContainer);