

export const stateQuickPanel = {
    show:           false,
    tab:            1,
    subPanel:{
        show:       false,
        data:       []
    }

};

const QuickPanelReducer = (__state = stateQuickPanel, __action) => {

    switch(__action.type){

        case "SHOW_MAIN_QUICKPANEL":

            return {
                ...__state,
                show:        __action.payloads.show,
                tab:         __action.payloads.tab,
            };
            
        case "SHOW_SUB_QUICKPANEL":

            return {
                ...__state,
                subPanel:{
                    show:   __action.payloads.show, 
                    data:   __action.payloads.data 
                }
            };

        case "RESET_QUICKPANEL":

            return stateQuickPanel;

        default: 

            return __state;

    };

};

export default QuickPanelReducer;