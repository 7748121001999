import React, {Component} from 'react';
import { connect } from 'react-redux';

import SystemStatusComponent from '../components/dashboards/contents/systemStatus/systemStatusComponent';

import {getStatus_systemStatusAction, loadMorePage_systemStatusAction, reset_systemStatusAction} from '../actions/systemStatusAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class SystemStatusContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <SystemStatusComponent
            data = {this.props.SystemStatus.data} 
            filters = {this.props.SystemStatus.filters} 
            pagination = {this.props.SystemStatus.pagination} 
            sorts = {this.props.SystemStatus.sorts} 
            func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    SystemStatus: __state.SystemStatusReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getStatus_systemStatusAction: (__filters, __sorts, __pagination) => {
				__dispatch(getStatus_systemStatusAction(__filters, __sorts, __pagination))
			},
			loadMorePage_systemStatusAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_systemStatusAction(__pagination, __plus))
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SystemStatusContainer);