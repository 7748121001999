import React, { Component } from 'react';

import moment from "moment";

import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';

import * as configs from '../../../../lib/configs';

import func from '../../../../lib/func';

const initialState = () => {

  return{
    validation:{
      formFilter:               {}
    },
    formFilter:{
      uuid:                     "",
      msgType:                  "",
      status:                   "",
      fromDateMessages:         "",
      toDateMessages:           "",
      filterBy:                 "1"
    }
  };

}

const _ref="MessagesReportsComponent";

export default class MessagesReportsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};
  
  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

    this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {

    this.props.pagination.page = __page;
    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);
  
  }

  handleOnPreviousPage = () => {

    this.props.pagination.page = this.props.pagination.page - 1;
    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

    this.props.pagination.page = this.props.pagination.page + 1;
    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

    this.props.func.loadMorePage_messagesReportsAction(__pagination, __plus);

  }

  handleOnSort = (__field) =>{

    if(func.isEmptyOrNull(this.props.filters.target)){

      return;

    };

    let sortValue = "";

    if(__field === this.props.sorts.sortBy){

      if(this.props.sorts.sortValue === "ASC"){

        sortValue = "DESC";

      };

      if(this.props.sorts.sortValue === "DESC"){

        sortValue = "ASC";
        
      };

      this.props.sorts.sortValue = sortValue;

    }else{

      this.props.sorts.sortBy     = __field;
      this.props.sorts.sortValue  = "ASC";

    };

    this.props.func.getMessages_messagesReportsAction(this.props.filters, this.props.sorts, this.props.pagination);
 
  }

  handleOnCallMsgBox = (__button) => {


  }

  handleOnSubmitFilter = (__e) => {

    let error     = {};
    let formData  = Object.assign({}, this.state.formFilter);

    if(formData.filterBy === "1"){

      this.props.filters.msgType           = formData.msgType;
      this.props.filters.status            = formData.status;
      this.props.filters.uuid              = "";
      this.props.filters.fromDate          = "";
      this.props.filters.toDate            = "";

    };

    if(formData.filterBy === "2"){

      let fromDate  = moment.utc(formData.fromDateMessages, configs.formatDateOnSite);
      let toDate    = moment.utc(formData.toDateMessages, configs.formatDateOnSite);

      if(!fromDate.isValid() || fromDate > toDate){

        error.fromDateMessages = "Invalid";

      };

      if(!toDate.isValid()){

        error.toDateMessages = "Invalid";

      };

      if(Object.keys(error).length > 0){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: error
          }
        });

        return;

      };

      this.props.filters.fromDate      = fromDate.format(configs.formatDateOutSite);
      this.props.filters.toDate        = toDate.format(configs.formatDateOutSite);
      this.props.filters.status        = "";
      this.props.filters.uuid          = "";
      this.props.filters.msgType       = "";

    };

    if(formData.filterBy === "3"){

      func.validate({uuid: "^[A-Za-z0-9\\-\\_]+$"}, formData, error);

      if(Object.keys(error).length > 0){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: error
          }
        });

        return;

      };

      this.props.filters.uuid            = formData.uuid;
      this.props.filters.status          = "";
      this.props.filters.msgType         = "";
      this.props.filters.fromDate        = "";
      this.props.filters.toDate          = "";

    };

    this.props.func.getMessages_messagesReportsAction(this.props.filters);

  }

  handleOnChangeFilterData = (__e) => {

    if(__e.target.name in this.state.validation.formFilter){

      delete this.state.validation.formFilter[__e.target.name];

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formFilter: {
            ...this.state.validation.formFilter
        }
      },
      formFilter:{
        ...this.state.formFilter,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handelOnResetComponent = () =>{

    this.setState(initialState());

  }

  handelOnRefreshData = () => {

    this.props.func.getMessages_messagesReportsAction(this.props.filters);
    this.handelOnResetComponent();

  }

  handleOnSubmitConfimred = () => {

  }

  renderListMessages = (__data) => {

    return (__data.map((__r)=>{

        return(
            <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
                <td className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "150px"}}>{moment.utc(__r.createdAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "80px"}}>{__r.uuid}</span>
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "80px"}}>{__r.msgType}</span>
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "300px"}}>{__r.message}</span>
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "150px"}}>{!func.isEmptyOrNull(__r.receivedAt) ? moment.utc(__r.receivedAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite) : ""}</span>
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
                    {
                      String(__r.msgType).toLowerCase() === "not" ?
                        func.isEmptyOrNull(__r.receivedAt) ? 
                          <span style={{width: "150px"}}>
                            <span className="k-badge k-badge--danger k-badge--dot"></span>&nbsp;
                            <span className="k-font-bold k-font-danger">Not spoken</span>
                          </span>
                        :
                          <span style={{width: "150px"}}>
                            <span className="k-badge k-badge--accent k-badge--dot"></span>&nbsp;
                            <span className="k-font-bold k-font-accent">Spoken</span>
                          </span>
                      : 
                        String(__r.msgType).toLowerCase() === "rem" || String(__r.msgType).toLowerCase() === "spo" ?
                          func.isEmptyOrNull(__r.confirmBy) ? 
                            <span style={{width: "150px"}}>
                              <button onClick={()=>{this.handleOnSubmitConfimred()}} type="button" className="btn btn-sm btn-outline-danger btn-elevate btn-pill">Await confirm</button>
                            </span>
                          :
                            <span style={{width: "150px"}}>
                              <span className="k-badge k-badge--accent k-badge--dot"></span>&nbsp;
                              <span className="k-font-bold k-font-accent">Confimred</span>
                            </span>
                        :
                          <span style={{width: "150px"}}>
                            <span className="k-badge k-badge--metal k-badge--dot"></span>&nbsp;
                            <span className="k-font-bold k-font-metal">Noreply</span>
                          </span>
                    }
                </td>
            </tr>
        )
    }))
  }

  render() {  

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <div className="row">
          <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
            <button onClick={()=>{this.handelOnRefreshData()}} type="button" style={{float:"right", margin: "auto", marginLeft: "5px"}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
            <button tabIndex="10" onClick={()=>{this.handleOnSubmitFilter()}} type="button" style={{float: "right"}} className="btn btn-sm btn-secondary btn-pill"><i className="la la-filter"></i> Filter</button>
            <div className="form-group" style={{width: "200px", float: "right", marginRight: "5px"}}>
                <div style={{display: "flex"}}>
                  <span style={{padding: "5px", width: "100px"}}>Filter by:</span>
                  <select tabIndex="6" onChange={this.handleOnChangeFilterData} className="form-control form-control-sm" value={this.state.formFilter.filterBy || "1"} name="filterBy">
                    <option value="1">Type</option>
                    <option value="2">Date</option>
                    <option value="3">Uuid</option>
                  </select>
                </div>
            </div>
            <div hidden={this.state.formFilter.filterBy !== "1"} className="form-group" style={{float: "right", marginRight: "5px"}}>
              <div style={{display: "flex"}}>
                <span style={{padding: "5px"}}>Type:</span>
                <select onChange={this.handleOnChangeFilterData} value={this.state.formFilter.msgType || ""} name="msgType" className="form-control form-control-sm">
                  <option value="">All</option>
                  <option value="SPO">SPO</option>
                  <option value="NOT">Notification</option>
                  <option value="REM">Reminder</option>
                </select>
              </div>
            </div>
            <div hidden={this.state.formFilter.filterBy !== "1" || this.state.formFilter.msgType === ""} className="form-group" style={{float: "right", marginRight: "5px"}}>
                <div style={{display: "flex"}}>
                  <span style={{padding: "5px"}}>Status:</span>
                  <select tabIndex="6" onChange={this.handleOnChangeFilterData} className="form-control form-control-sm" value={this.state.formFilter.status || ""} name="status">
                    <option value="">All</option>
                    <option hidden={this.state.formFilter.msgType !== "NOT"} value="spoken">Spoken</option>
                    <option hidden={this.state.formFilter.msgType !== "NOT"} value="notSpoken">Not spoken</option>
                    <option hidden={this.state.formFilter.msgType === "NOT"} value="confirmed">Confirmed</option>
                    <option hidden={this.state.formFilter.msgType === "NOT"} value="unconfirmed">Unconfimred</option>
                  </select>
                </div>
            </div>
            <div hidden={this.state.formFilter.filterBy !== "2"} style={{display: "flex", float: "right", marginRight: "5px"}} >
              <span style={{padding: "5px"}}>From:</span>
              <div>
                <DateTimePickerComponent tabIndex="8" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite} value={this.state.formFilter.fromDateMessages || ""} name="fromDateMessages" />  
                <span className="k-font-danger">{this.state.validation.formFilter["fromDateMessages"]}</span>
              </div>
              <span style={{padding: "5px"}}>To:</span>
              <div>
                <DateTimePickerComponent tabIndex="9" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite}  value={this.state.formFilter.toDateMessages || ""} name="toDateMessages" />
                <span className="k-font-danger">{this.state.validation.formFilter["toDateMessages"]}</span>
              </div>
            </div>
            <div className="form-group" hidden={this.state.formFilter.filterBy !== "3"} style={{float: "right", marginRight: "5px"}}>
              <div style={{display: "flex"}}>
                <span style={{padding: "5px"}}>Uuid:</span>
                <div>
                  <input onChange={this.handleOnChangeFilterData} type="text" value={this.state.formFilter.uuid || ""} name="uuid" className="form-control form-control-sm" placeholder=" Enter UUID . . ." autoComplete="off"/>
                  <span className="k-font-danger">{this.state.validation.formFilter["uuid"]}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%"}}>
            <table className="k-datatable__table list-database" style={{display: "block", minHeight: "300px", overflow: "auto"}}>
              <thead className="k-datatable__head">
                <tr className="k-datatable__row">
                  <th onClick={()=>{this.handleOnSort("createdAt")}} className={this.props.sorts.sortBy === "createdAt" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                    <span style={{cursor: "pointer", width: "150px"}}>CREATED {this.props.sorts.sortBy === "createdAt" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                  </th>
                  <th onClick={()=>{this.handleOnSort("uuid")}}  className={this.props.sorts.sortBy === "uuid" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                      <span style={{cursor: "pointer", width: "80px"}}>UUID {this.props.sorts.sortBy === "uuid" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                  </th>
                  <th onClick={()=>{this.handleOnSort("msgType")}} className={this.props.sorts.sortBy === "msgType" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                    <span style={{cursor: "pointer", width: "80px"}}>TYPE {this.props.sorts.sortBy === "msgType" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                  </th>
                  <th className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "300px"}}>MESSAGES</span>
                  </th>
                  <th onClick={()=>{this.handleOnSort("receivedDate")}} className={this.props.sorts.sortBy === "receivedDate" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                    <span style={{cursor: "pointer", width: "150px"}}>RECEIVED {this.props.sorts.sortBy === "receivedDate" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                  </th>
                  <th className="k-datatable__cell--left k-datatable__cell">
                    <span style={{width: "150px"}}>STATUS</span>
                  </th>
                </tr>
              </thead>
              <tbody className="k-datatable__body">
                {   
                    this.renderListMessages(this.props.data)
                }
              </tbody>
            </table>
          </div>

          <div style={{width: "100%"}}>
            <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
          </div>
        </div>

      </React.Fragment>
    )

  }

}