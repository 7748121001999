
import React, { Component } from 'react';

import { connect } from 'react-redux';

import SchedulesComponent from '../components/dashboards/contents/schedules/schedulesComponent';

import {getSchedules_schedulesAction,delete_schedulesAction,loadMorePage_schedulesAction} from '../actions/schedulesAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class SchedulesContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<SchedulesComponent 
					data = {this.props.schedules.data} 
					filters = {this.props.schedules.filters} 
					pagination = {this.props.schedules.pagination} 
					sorts = {this.props.schedules.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		schedules: __state.SchedulesReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSchedules_schedulesAction: (__filters, __sorts, __pagination) => {
				__dispatch(getSchedules_schedulesAction(__filters, __sorts, __pagination))
			},
			delete_schedulesAction: (__formData) => {
				__dispatch(delete_schedulesAction(__formData))
			},
			loadMorePage_schedulesAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_schedulesAction(__pagination, __plus))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SchedulesContainer);