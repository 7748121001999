import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Redirect } from 'react-router'
import { connect } from 'react-redux';

import PatientsContainer from './containers/patientsContainer';
import PatientDetailsContainer from './containers/patientDetailsContainer';
import LoginComponent from './components/login/loginComponent';
import LayoutContainer from './containers/layoutContainer'
import ReportsContainer from './containers/reportsContainer';
import DashboardsContainer from './containers/dashboardsContainer';
import ManagerUsersContainer from './containers/managerUsersContainer';

import * as configs from './lib/configs';

class App extends Component{

  isLoggedIn = () =>{
    return true;
  }

  render() {

    configs.setupScopes();

    return(

      <React.Fragment>

        <div hidden={!this.props.loading.status} style={{height: "100%", display: "flex", backgroundColor: "rgb(0 0 0 / 6%)", zIndex: "9999999", position: "fixed", width: "100%"}}>
          <div style={{margin: "auto"}}>
            <img style={{width: "170px", height: "170px", display: "flex"}} src={process.env.PUBLIC_URL +"/images/Double-350px.svg"}/>
            <span className="badge badge-pill badge-light" style={{width: "100%"}}>Loading . . . . .</span>
          </div>
        </div>

          <Router basename='/'>
            <Switch>
              <Route exact path='/'>
                <Redirect to="/login"/>
              </Route>
              <Route exact path='/login' component={LoginComponent}></Route>
              <Route exact path='/dashboars/:path?'>
                <LayoutContainer>
                  <Switch>
                    <Route exact path='/dashboars/index' component={DashboardsContainer}/>
                    <Route exact path='/dashboars/patients' component={PatientsContainer}/>
                    <Route exact path='/dashboars/manager-users' component={ManagerUsersContainer}/>
                    <Route exact path='/dashboars/patient-details' component={PatientDetailsContainer}/>
                    <Route exact path='/dashboars/reports' component={ReportsContainer}/>
                  </Switch>
                </LayoutContainer>
              </Route>
            </Switch>
          </Router>

      </React.Fragment>

    );
  };
}

const mapState = __state => {

	return{
    loading: __state.LoadingReducer
	};

};

export default connect(mapState, null, null, {forwardRef: true})(App);
