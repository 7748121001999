
export const stateChartBatteries = {
 
    data: [],
    devices: [],
    filters: {
        option: 2,
        device: ""
    }

};

const ChartSummaryBatteriesReducer = (__state = stateChartBatteries, __action) => {

    switch(__action.type){

        case "IMPORT_SUMMARY_BATTERIES":

            return {
                ...__state,
                data:               __action.payloads.data,
                filters:{
                    device:         __action.payloads.device,
                    option:         __action.payloads.option
                }
            };
        
        case "IMPORT_DEVICES_SUMMARY_BATTERIES":

            return {
                ...__state,
                devices:            __action.payloads.devices
            };

        case "RESET_SUMMARY_BATTERIES":
            
            return stateChartBatteries;

        default: 

            return __state;

    };

};

export default ChartSummaryBatteriesReducer;