import React, {Component} from 'react';
import { connect } from 'react-redux';

import RespondersComponent from '../components/dashboards/contents/responders/respondersComponent';

import {getResponders_respondersAction, updateStatus_respondersAction, create_respondersAction, update_respondersAction, delete_respondersAction, loadMorePage_respondersAction, reset_respondersAction} from '../actions/respondersAction';
import {getPolicies_managerUsersAction} from '../actions/managerUsersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class RespondersContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <RespondersComponent
          groups = {this.props.Groups.data}
          roles = {this.props.Roles.data}
          customers = {this.props.Customers.data}
          data = {this.props.Responders.data} 
          filters = {this.props.Responders.filters} 
          pagination = {this.props.Responders.pagination} 
          sorts = {this.props.Responders.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    Responders: __state.RespondersReducer,
    Customers: __state.CustomersReducer,
    Groups: __state.GroupsResponderReducer,
    Roles: __state.RolesReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getResponders_respondersAction: (__filters, __sorts, __pagination) => {
				__dispatch(getResponders_respondersAction(__filters, __sorts, __pagination))
			},
      create_respondersAction: (__formData) => {
				__dispatch(create_respondersAction(__formData))
			},
      update_respondersAction: (__formData) => {
				__dispatch(update_respondersAction(__formData))
			},
      delete_respondersAction: (__formData) => {
				__dispatch(delete_respondersAction(__formData))
			},
      loadMorePage_respondersAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_respondersAction(__pagination, __plus));
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
      reset_respondersAction: () => {
				__dispatch(reset_respondersAction())
			},
      updateStatus_respondersAction: (__formData) => {
        __dispatch(updateStatus_respondersAction(__formData))
      },
      getPolicies_managerUsersAction: (__filters, __segment) => {
				__dispatch(getPolicies_managerUsersAction(__filters, __segment))
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(RespondersContainer);