import httpClient from '../lib/httpClient';

import {stateNotifies} from '../reducers/notifiesReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="NotifiesComponent";

export const getNewNotifies_notifiesAction = (__device, __filters = Object.assign({}, stateNotifies.new.filters), __sorts = Object.assign({}, stateNotifies.new.sorts), __pagination = Object.assign({}, stateNotifies.new.pagination)) => async(__dispatch) => {

    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("device=" + encodeURIComponent(__device));
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=getNotifies"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
   
        __dispatch(showLoading(false));
        __dispatch(import_new_notifiesAction(response.data, response.totalRows, __device, __filters, __sorts, __pagination));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_new_notifiesAction = (__data, __totalRows, __device, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };
    
    return {
        type: "IMPORT_NEW_NOTIFIES",
        payloads: {
            device:         __device || "",
            id:             __filters.id || "",
            fromDate:       __filters.fromDate || "",
            toDate:         __filters.toDate || "",
            data:           __data,
            totalRows:      __totalRows,
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const getHistory_notifiesAction = (__device, __filters = Object.assign({}, stateNotifies.history.filters), __sorts = Object.assign({}, stateNotifies.history.sorts), __pagination = Object.assign({}, stateNotifies.history.pagination)) => async(__dispatch) => {

    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("device=" + encodeURIComponent(__device));
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=getNotifies"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
   
        __dispatch(showLoading(false));
        __dispatch(import_history_notifiesAction(response.data, response.totalRows, __device, __filters, __sorts, __pagination));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_history_notifiesAction = (__data, __totalRows, __device, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };
    
    return {
        type: "IMPORT_HISTORY_NOTIFIES",
        payloads: {
            device:         __device || "",
            id:             __filters.id || "",
            fromDate:       __filters.fromDate || "",
            toDate:         __filters.toDate || "",
            data:           __data,
            totalRows:      __totalRows,
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const updateStatus_notifiesAction = (__formData) => async(__dispatch) => {

    try{
 
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/patients?func=updateStatusNotify", "PUT", __formData, null);

        __dispatch(showLoading(false));
        __dispatch(getNewNotifies_notifiesAction(__formData.device));
        __dispatch(getHistory_notifiesAction(__formData.device));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const deleteNotify_notifiesAction = (__formData) => async(__dispatch) => {

    try{

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/patients?func=deleteNotify", "DELETE", __formData, null);

        __dispatch(showLoading(false));
        __dispatch(getHistory_notifiesAction(__formData.device));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const reset_notifiesAction = () => {

    return {
        type: "RESET_NOTIFIES",
        payloads: {}
    };

}