
import React, { Component } from 'react';

import { connect } from 'react-redux';

import ChartSummaryVitalsComponent from '../components/dashboards/contents/chartSummaryVitals/chartSummaryVitalsComponent';

import {getSummary_chartSummaryVitalsAction, getSummaryByUser_chartSummaryVitalsAction, reset_chartSummaryVitalsAction} from '../actions/chartSummaryVitalsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ChartSummaryVitalsContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<ChartSummaryVitalsComponent 
					patientDetails = {this.props.PatientDetailsReducer.data}
					data = {this.props.chartSummaryVitalsReducer.data} 
					filters = {this.props.chartSummaryVitalsReducer.filters} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		chartSummaryVitalsReducer: __state.ChartSummaryVitalsReducer,
		PatientDetailsReducer: __state.PatientDetailsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummaryByUser_chartSummaryVitalsAction:(__device, __typeDevice, __option, __unit)=>{
				__dispatch(getSummaryByUser_chartSummaryVitalsAction(__device, __typeDevice, __option, __unit))
			},
			getSummary_chartSummaryVitalsAction: (__device, __typeDevice, __option, __unit) => {
				__dispatch(getSummary_chartSummaryVitalsAction(__device, __typeDevice, __option, __unit));
			},
			reset_chartSummaryVitalsAction: () => {
				__dispatch(reset_chartSummaryVitalsAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ChartSummaryVitalsContainer);