
import React, { Component } from 'react';

import { connect } from 'react-redux';

import MessagesReportsComponent from '../components/dashboards/contents/messagesReports/messagesReportsComponent';

import {getMessages_messagesReportsAction, loadMorePage_messagesReportsAction} from '../actions/messagesReportsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class MessagesReportsContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<MessagesReportsComponent 
					data = {this.props.messagesReportsReducer.data} 
					filters = {this.props.messagesReportsReducer.filters} 
					pagination = {this.props.messagesReportsReducer.pagination} 
					sorts = {this.props.messagesReportsReducer.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		messagesReportsReducer: __state.MessagesReportsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getMessages_messagesReportsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getMessages_messagesReportsAction(__filters, __sorts, __pagination));
			},
			loadMorePage_messagesReportsAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_messagesReportsAction(__pagination, __plus));
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
			
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(MessagesReportsContainer);