
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {mapGroupsToPatient_summaryPatientsAction, getSummary_summaryPatientsAction, getGroupsOutSide_summaryPatientsAction, getGroupsInSide_summaryPatientsAction, loadMorePage_summaryPatientsAction} from '../actions/summaryPatientsAction';
import{getLogs_timerLogsAction, callTimerLogs_timerLogsAction} from '../actions/timerLogsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

import SummaryPatientsComponent from '../components/dashboards/contents/summaryPatients/summaryPatientsComponent';

class SummaryPatientsContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<SummaryPatientsComponent 
					data = {this.props.summaryPatients.data} 
					filters = {this.props.summaryPatients.filters} 
					pagination = {this.props.summaryPatients.pagination} 
					sorts = {this.props.summaryPatients.sorts} 
					goupsInSide = {this.props.summaryPatients.goupsInSide}
					goupsOutSide = {this.props.summaryPatients.goupsOutSide}
					func={this.props.func}
				/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{
		summaryPatients: __state.SummaryPatientsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummary_summaryPatientsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getSummary_summaryPatientsAction(__filters, __sorts, __pagination));
			},
			getGroupsOutSide_summaryPatientsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getGroupsOutSide_summaryPatientsAction(__filters, __sorts, __pagination));
			},
			getGroupsInSide_summaryPatientsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getGroupsInSide_summaryPatientsAction(__filters, __sorts, __pagination));
			},
			loadMorePage_summaryPatientsAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_summaryPatientsAction(__pagination, __plus));
			},
			mapGroupsToPatient_summaryPatientsAction: (__formData) => {
				__dispatch(mapGroupsToPatient_summaryPatientsAction(__formData));
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},

			////////////////////////////////////////////////////////////

			getLogs_timerLogsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getLogs_timerLogsAction(__filters, __sorts, __pagination));
			},
			callTimerLogs_timerLogsAction: (__bool) => {
				__dispatch(callTimerLogs_timerLogsAction(__bool))
			}, 

		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SummaryPatientsContainer);