import {getAbnormal_systemAbnormalAction, reset_systemAbnormalAction} from './systemAbnormalsAction';
import {getStatus_systemStatusAction, reset_systemStatusAction} from './systemStatusAction';
import {getSummary_SystemMonitorAction, reset_SystemMonitorAction} from './systemMonitorAction';
import {getBatteries_batteriesAction, reset_batteriesAction} from './batteriesAction';
import {getLogsSQS_logsSQSAction,reset_logsSQSAction} from '../actions/logsSQSAction';
import {getSummary_chartSummarySystemDataAction, reset_chartSummarySystemDataAction} from '../actions/chartSummarySystemDataAction';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="DashboardsComponent";

export const OnInit_dashboardsAction = (__device) => async(__dispatch) => {

    try {

        if(!configs.scopes.readPatients){
            return;
        }
        
        __dispatch(import_dashboardsAction(__device));
        __dispatch(getAbnormal_systemAbnormalAction({device: __device}));
        __dispatch(getStatus_systemStatusAction({device: __device}));
        __dispatch(getSummary_SystemMonitorAction({device: __device}));
        __dispatch(getBatteries_batteriesAction({device: __device}));
        __dispatch(getLogsSQS_logsSQSAction());
        __dispatch(getSummary_chartSummarySystemDataAction());

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_dashboardsAction = (__device) => {

    return {
        type: "IMPORT_DASHBOARDS",
        payloads: {
            device:  __device || "",
        }
    };

}

export const reset_dashboardsAction = () => {

    return {
        type: "RESET_DASHBOARDS",
        payloads: {}
    };

}

export const resetPage_dashboardsAction = () => (__dispatch) => {

    __dispatch(reset_systemAbnormalAction());
    __dispatch(reset_systemStatusAction());
    __dispatch(reset_SystemMonitorAction());
    __dispatch(reset_batteriesAction());
    __dispatch(reset_logsSQSAction());
    __dispatch(reset_chartSummarySystemDataAction());

}
