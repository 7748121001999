import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

const initialState = () => {

  return{

  };

}

const _ref="SystemStatusComponent";

export default class SystemStatusComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    componentWillReceiveProps(nextProps){


    }

    handleOnFirstPage = () => {

        this.props.pagination.page = 1;
        this.props.func.getStatus_systemStatusAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnLastPage = () => {

        this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
        this.props.func.getStatus_systemStatusAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnChangePage = (__page) => {
    
        this.props.pagination.page = __page;
        this.props.func.getStatus_systemStatusAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnPreviousPage = () => {

        this.props.pagination.page = this.props.pagination.page - 1;
        this.props.func.getStatus_systemStatusAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnNextPage = () => {

        this.props.pagination.page = this.props.pagination.page + 1;
        this.props.func.getStatus_systemStatusAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnLoadMorePage = (__pagination, __plus) => {

        this.props.func.loadMorePage_systemStatusAction(__pagination, __plus);

    }

    handleOnCallMsgBox = (__button) => {


    }
 
    renderStatus = (__data) =>{
        return (__data.map((__r)=>{

          return(
            <div key={uuidv4()} className={__r.flags.length > 0 ? "k-timeline__item k-timeline__item--danger" : "k-timeline__item k-timeline__item--info"}>
                <div className="k-timeline__item-section">
                    <div className="k-timeline__item-section-border">
                        <div className="k-timeline__item-section-icon">
                            <i className={__r.flags.length > 0 ? "flaticon-bell k-font-danger" : "flaticon-bell k-font-info"}></i>
                        </div>
                    </div>
                    <span className="k-timeline__item-datetime">{__r.device}</span>
                </div>
                <div className="k-timeline__item-text">
                    <div className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            CPU:
                        </div>
                        <div className="col-sm-8">
                            <span style={__r.flags.includes("CPU") ? {background: "rgba(253, 57, 122, 0.2)", color: "#fd397a", padding: "3px 7px"} : {background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{parseFloat(__r.CPU).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            RAM:
                        </div>
                        <div className="col-sm-8">
                            <span style={__r.flags.includes("RAM") ? {background: "rgba(253, 57, 122, 0.2)", color: "#fd397a", padding: "3px 7px"} : {background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{parseFloat(__r.RAM).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            CARD:
                        </div>
                        <div className="col-sm-8">
                            <span style={__r.flags.includes("card_v") ? {background: "rgba(253, 57, 122, 0.2)", color: "#fd397a", padding: "3px 7px"} : {background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{parseFloat(__r.card_v).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            DISK:
                        </div>
                        <div className="col-sm-8">
                            <span style={__r.flags.includes("disk") ? {background: "rgba(253, 57, 122, 0.2)", color: "#fd397a", padding: "3px 7px"} : {background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{parseFloat(__r.disk).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            TEMP-C:
                        </div>
                        <div className="col-sm-8">
                            <span style={__r.flags.includes("tempC") ? {background: "rgba(253, 57, 122, 0.2)", color: "#fd397a", padding: "3px 7px"} : {background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{parseFloat(__r.tempC).toFixed(2)}</span>
                        </div>
                    </div>
                    <div hidden={func.isEmptyOrNull(__r.text)} className="row" style={{marginBottom: "10px"}}>
                        <div className="col-sm-4">
                            TEXT:
                        </div>
                        <div className="col-sm-8">
                            <span style={{background: "rgba(88, 103, 221, 0.1)", color: "#5867dd", padding: "3px 7px"}}>{__r.text}</span>
                        </div>
                    </div>
                </div>
            </div>
          );
    
        }));
    }

    render() {

        return(

            <React.Fragment>
                
                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
                
                {
                    !configs.scopes.readDashboards
                    ?
                        <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                        <div className="k-iconbox__icon">
                            <div className="k-iconbox__icon-bg"></div>
                            <i className="fa fa-lock"></i>
                        </div>
                        <div className="k-iconbox__title">
                            403 FORBIDDEN
                        </div>
                        <div className="k-iconbox__content">
                            You not enough rights to access to this item
                        </div>
                        </div>
                    :
                        <React.Fragment>
                            <div className="k-timeline tab-scroll" style={{marginBottom: "25px", height: "400px", overflow: "auto", overflowX: "hidden"}}>
                                {this.renderStatus(this.props.data)}
                            </div>
                            <div style={{display: "flex", width: "100%"}}>
                                <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        )

    }

}