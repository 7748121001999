import React, { Component } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from "moment";
import crypto from "crypto";

import { Redirect } from 'react-router'
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { NavLink } from "react-router-dom";

import * as configs from '../../../../lib/configs';
import func from '../../../../lib/func';

import AutoCompleteComponent from '../../../features/autoComplete/autoCompleteComponent'
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';
import FollowTaskComponent from './../followTask/followTaskComponent';
import CallTwilioComponent from '../callTwilio/callTwilioComponent';
import CallUrgenTimeComponent from '../callUrgenTime/callUrgenTimeComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import VitalsContainer from '../../../../containers/vitalsContainer';
import MessagesContainer from '../../../../containers/messagesContainer';
import SchedulesContainer from '../../../../containers/schedulesContainer';
import TimerLogsContainer from '../../../../containers/timerLogsContainer';
import SendMessagesContainer from '../../../../containers/sendMessagesContainer';
import ChartSummaryComplianceContainer from '../../../../containers/chartSummaryComplianceContainer';

import './patientDetailsStyle.css'

const schema = {
  "mrn":        "^[A-Za-z0-9]{8}$",
  "category":   "^[A-Za-z0-9\\_\\-]+$",
  "sub_cat":    "^[A-Za-z0-9\\_\\-]+$",
  "date":       "^([12]\\d{3}\\-(0[1-9]|1[0-2])\\-(0[1-9]|[12]\\d|3[01]))\\s(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))$",
  "time":       "^[1-9]+$",
  "notes":      "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$"
}
 
const initialState = () => {

  return{

    showLongTimerConfirm:   false,
    showTwilio:             false,
    showUrgenTime:          false,
    ShowDeviceId:           false,
    showTechnical:          false,
    blockLastName:          false,
    modeView:               false,
    validation:{
      formSearch:           {},
      formLongTimerConfirm: {}
    },
    passWord:               "",
    PID:                    false,
    checkedPID:             false,
    showFormConfirmPass:    false,
    formLongTimerConfirm:{
      comment:              "",
      level:                "",
    },
    formSearch:{
      firstName:            "",
      lastName:             "",
      dob:                  "",
      mrn:                  ""
    },
    intervalId:             null,
    timers: {
        minutes:            0,
        seconds:            0
    },
    pause:                  false,
    redirectToDashboards:   false
  };

}

const _ref="PatientDetailsComponent";

export default class PatientDetailsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();
    this.autoCompleteLastName = React.createRef();

	};

  componentDidMount(){
    
    let params      = window.location.pathname.split("/");
    let queryString = window.location.search;
    let parameters  = new URLSearchParams(queryString);

    this.setState({
      formSearch:{
        firstName:      parameters.get('firstName'),
        lastName:       parameters.get('lastName'),
        dob:            parameters.get('dob'),
        mrn:            parameters.get('mrn')
      }
    });

    this.props.func.setCurrentPage_layoutAction(params[params.length - 1])

    window.addEventListener('beforeunload', function (__e) { 
            
      __e.preventDefault();
      __e.returnValue="";

    }); 

  }

  componentWillUnmount(){
    
    this.props.func.setCurrentPage_layoutAction("")
    this.handleOnCancelCompleted();

  }

  componentWillReceiveProps(nextProps){

    if(nextProps.patientDetails.data.mrn !== null && this.state.intervalId === null){
      this.setState({
        modeView: true,
        pause: true,
        intervalId: this.state.modeView ? null : this.handleOnPlayInterval(),
        formSearch:{
          firstName:  nextProps.patientDetails.data.firstName,
          lastName:   nextProps.patientDetails.data.lastName,
          dob:        moment.utc(nextProps.patientDetails.data.dob, configs.formatDateOutSite).format(configs.formatDateOnSite),
          mrn:        nextProps.patientDetails.data.mrn
        }
      });

      if(configs.scopes.readTimerLogs && this.state.modeView === false && nextProps.patientDetails.data.mrn !== null){
        this.props.func.getLogs_timerLogsAction({mrn: nextProps.patientDetails.data.mrn, month: moment.utc().utcOffset(configs.timeZone).format("YYYY-MM")});
      }

    };
    
  }

  handleOnPlayInterval = () => {

    return setInterval(() => {

      let timers = this.state.timers;
          timers.seconds = timers.seconds + 1;

      if(timers.seconds >= 60){
          timers.seconds = 0;
          timers.minutes = timers.minutes + 1 ;
      }

      this.setState({timers});

    }, 1000);

  };

  handleOnStartInterval = () => {

    this.setState({
      intervalId: this.handleOnPlayInterval()
    });
    
  }

  handleOnPauseInterval = (__e) => {

    if(this.state.pause){
      this.handleOnStopInterval();
    }else{
      this.handleOnStartInterval()
    }

    this.setState({
      pause: !this.state.pause
    });

  }

  handleOnStopInterval = () => {

    clearInterval(this.state.intervalId);

    this.setState({
      intervalId: null
    });

  };

  handleOnChangeFormData = (__e) => {

    if(__e.target.name === "comment"){

      if(__e.target.name in this.state.validation.formLongTimerConfirm){

        delete this.state.validation.formLongTimerConfirm[__e.target.name];

      };

      this.setState({
        validation: {
          ...this.state.validation,
          formLongTimerConfirm: {
              ...this.state.validation.formLongTimerConfirm
          }
        },
        formLongTimerConfirm:{
          ...this.state.formLongTimerConfirm,
          [__e.target.name]: __e.target.value
        }
      });

      return;

    };

    if(__e.target.name in this.state.validation.formSearch){

      delete this.state.validation.formSearch[__e.target.name];

    };

    if(__e.target.name === "mrn"){

      this.state.validation.formSearch = {};

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formSearch: {
            ...this.state.validation.formSearch
        }
      },
      formSearch:{
        ...this.state.formSearch,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handleOnShowDeviceId = () =>{
    
    this.setState({

      ShowDeviceId: !this.state.ShowDeviceId

    });

  }

  handleOnSubmitSearchPatient = () => {

    // window.history.replaceState({}, null ,'/dashboars/patient-details');
    
    let error     = {};
    let formSearch  = Object.assign({}, this.state.formSearch);

    if(!func.isEmptyOrNull(formSearch.mrn)){
  
      func.validate({mrn: "^[A-Za-z0-9]{8}$"}, formSearch, error);

      if(Object.keys(error).length > 0){
        this.setState({
            validation: {
              ...this.state.validation,
              formSearch: error
            }
        });

        return;
  
      };

      this.props.filters.firstName   = ""
      this.props.filters.lastName    = ""
      this.props.filters.dob         = ""
      this.props.filters.mrn         = formSearch.mrn

      this.props.func.getPatientByMRN_patientDetailsAction(this.props.filters)

      return;

    };

    func.validate({
        firstName: "^[A-Za-z\\s]+$",
        lastName: "^[A-Za-z\\s]+$",
        dob: "^((0[1-9]|1[0-2])\\/(0[1-9]|[12]\\d|3[01])\\/[12]\\d{3})$"
      },formSearch, error);

    if(Object.keys(error).length > 0){

      this.setState({
        validation: {
          ...this.state.validation,
          formSearch: error
        }
      });

      return;

    };
    
    this.props.filters.firstName = formSearch.firstName;
    this.props.filters.lastName  = formSearch.lastName;
    this.props.filters.dob       = moment.utc(formSearch.dob, configs.formatDateOnSite).format(configs.formatDateOutSite);
    this.props.filters.mrn       = "";

    this.props.func.getPatientByPersonal_patientDetailsAction(this.props.filters);

  }

  handleOnLoadLastName = (__e) => {

    if(this.state.modeView){

      return;

    };

    let error = {};

    func.validate({firstName: "^[A-Za-z\\s]+$"}, {firstName: __e.target.value}, error);

    if(Object.keys(error).length > 0){
      this.setState({
          validation: {
            ...this.state.validation,
            formSearch: error
          }
      });

      return;

    };
    
    this.setState({

      blockLastName: true

    });

    this.props.lastName.filters.firstName = __e.target.value;
    this.props.func.getLastNameByFirstName_patientDetailsAction(this.props.lastName.filters);
    
    setTimeout(() => {

      this.setState({
        blockLastName: false,
        formSearch:{
          ...this.state.formSearch,
          lastName: ""
        }
      });

    }, 2000);

  }

  handleOnSubmitComplete = () =>{

    let error    = {};
    let formData = {
      category:   "99457",
      sub_cat:    "vitals",
      mrn:        this.props.patientDetails.data.mrn,
      date:       moment.utc().format(configs.formatDateTimeOutSite),
      time:       this.state.timers.minutes + Math.ceil(this.state.timers.seconds / 60),
      notes:      this.state.formLongTimerConfirm.comment
    };

    func.validate(schema, formData, error);

    if(Object.keys(error).length > 0){

        this.setState({
            validation:{
              ...this.state.validation,
              formLongTimerConfirm: error
            } 
        });

        return false;

    };

    this.handleOnCallLongTimerConfirm();
    this.props.func.complete_patientDetailsAction(formData);

  }

  handleOnConfirmCompleted = () =>{

    let level   = "";
    let minutes = 0;

    this.handleOnStopInterval();

    minutes = this.state.timers.minutes + Math.ceil(this.state.timers.seconds / 60);

    if(minutes > 5){


      if(10 > minutes && minutes > 5){

        level = "long";
    
      };

      if(10 <= minutes){

        level = "very long";

      };
 
      this.setState({
        formLongTimerConfirm:{
          ...this.state.formLongTimerConfirm,
          level: level
        }
      });

      this.handleOnCallLongTimerConfirm(true);

      return;

    };

    this.handleOnSubmitComplete();

  }

  handleOnCancelCompleted = () =>{

    this.props.func.resetPage_patientDetailsAction();
    this.props.func.reset_timerLogsAction();
    this.setState(initialState());

  }

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnContinue" && this.props.patientDetails.active == true){
        this.props.func.redirect_patientDetailsAction(true, "/dashboars/index?device=" + this.props.patientDetails.data.device);
    }

    if(__button === "btnOk" && this.props.patientDetails.active === true){
      this.handleOnCancelCompleted();
    };

    if(__button === "btnClose" && this.props.patientDetails.active === true){
        this.handleOnStartInterval();
    };

  }

  handleOnVerify = (__e) => {

    let pass    = window.sessionStorage.getItem("accesscode");
    let token   = window.sessionStorage.getItem("token");
    let buffer  = Buffer.from(token + this.state.passWord);
    let hash    = crypto.createHash("sha256").update(buffer).digest("base64");
  
    if(pass === hash){

      this.setState({
        passWord:             "",
        PID:                  true,
        showFormConfirmPass:  false
      });

      return;

    };

    this.props.func.create_MsgBoxAction(_ref, "Access",  "You don't have permission to access", [{key: "btnClose", title: "Close"}], "error", null, null);
  
    this.setState({
      passWord:             "",
      PID:                  false,
      checkedPID:           false,
      showFormConfirmPass:  false
    });

  }

  handleOnChangeInputPass = (__e) => {

    if(__e.target.name === "PID"){

      let showForm = false;

      if(!this.state.checkedPID){

        showForm = true;

      };

      this.setState({
        ...this.state,
        passWord:             "",
        PID:                  false,
        checkedPID:           !this.state.checkedPID,
        showFormConfirmPass:  showForm
      });

      return;

    };

    this.setState({

      passWord: __e.target.value
      
    });

  }

  handleOnCallTechnical = (__bool) =>{

    this.setState({
      showTechnical: __bool
    });

  }

  handelOnCallUrgenTime = (__bool) =>{

    this.setState({

      showUrgenTime: __bool

    });

  }

  handelOnCallTwilio = (__bool) =>{

    this.setState({

      showTwilio: __bool

    });

  }

  handelOnCallTimerLog = () => {

    if(configs.scopes.readTimerLogs){

      this.props.func.getLogs_timerLogsAction({mrn: this.props.patientDetails.data.mrn, month: moment.utc().utcOffset(configs.timeZone).format("YYYY-MM")});
      
    }

    this.props.func.callTimerLogs_timerLogsAction(true);

  }

  handelOnCallSendMessage = () => {

    this.props.func.callSendMessage_sendMessageAction(true, false);

  }

  handleOnCallLongTimerConfirm = (__bool) =>{

    this.setState({

      showLongTimerConfirm: __bool

    });

  }

  renderSystemCheck = (__data) => {

    let fields      = ["alert","last-boot","last_sync","disk%","tempC","card_tempC","card_v","status","internet","band","bars","rssi","sinr"];
    let systemCheck = [];

    for(let key of fields){

        let value = "";

        if(func.isEmptyOrNull(__data[key])){
            continue;
        };
        
        value = __data[key]

        if(key === "last-boot" || key=== "last_sync"){

          value = moment.utc(__data[key]).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)

        };
        
        if(func.isType(__data[key], "float")){
          value = Number(value).toFixed(2)
        }

        systemCheck.push(
          <div key={uuidv4()} className="k-widget-5__item k-widget-5__item--info">
            <div className="k-widget-5__item-info">
              <a href="#" className="k-widget-5__item-title">
                {String(key).toUpperCase()}
              </a>
              <div className="k-widget-5__item-datetime">
                {value}
              </div>
            </div>
            <div className="k-widget-5__item-check">

            </div>
          </div>
        );

    };

    return systemCheck;

  }

  handleOnRedirect = () =>{
    
    if(this.props.patientDetails.active){

      this.props.func.create_MsgBoxAction(_ref, "Info", "You need to save before you leave this page !", [{key: "btnContinue", title: "Continue"},{key: "btnCancel", title: "Cancel"}], "info", null, null)
      
    }else{

      this.props.func.redirect_patientDetailsAction(true, "/dashboars/index?device=" + this.props.patientDetails.data.device)
    
    }

  }

  render() {

    if(this.props.patientDetails.redirect.status) {

      this.handleOnCancelCompleted();

      return <Redirect to={this.props.patientDetails.redirect.url}/>
    
    };

    return(
 
      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <ul hidden={(!configs.scopes.addPatients && !configs.scopes.UrgenTime && !configs.scopes.Twilio) || !this.props.patientDetails.active} className="k-sticky-toolbar" style={{marginTop: "30px"}}>
          <li hidden={!configs.scopes.addPatients} onClick={()=>{this.handelOnCallSendMessage()}} className="k-sticky-toolbar__item k-sticky-toolbar__item--builder">
            <a href="#"><i className="fa fa-envelope-open-text"></i></a>
          </li>
          <li hidden={!configs.scopes.UrgenTime || (func.isEmptyOrNull(this.props.patientDetails.data.email) && (func.isEmptyOrNull(this.props.patientDetails.data.phoneMobile) || func.isEmptyOrNull(this.props.patientDetails.data.phoneVideo)))} className="k-sticky-toolbar__item k-sticky-toolbar__item--builder">
            <a onClick={()=>{this.handelOnCallUrgenTime(true)}} href="#"><i className="fa fa-video"></i></a>
          </li>
          <li hidden={!configs.scopes.Twilio || (func.isEmptyOrNull(this.props.patientDetails.data.phoneHome) && func.isEmptyOrNull(this.props.patientDetails.data.phoneBusiness) && func.isEmptyOrNull(this.props.patientDetails.data.phoneMobile))} className="lableMenu k-sticky-toolbar__item k-sticky-toolbar__item--builder">
            <a onClick={()=>{this.handelOnCallTwilio(true)}} href="#"><i className="fa fa-headphones"></i></a>
          </li>
        </ul>

        <TimerLogsContainer/>
        <SendMessagesContainer/>
        <CallTwilioComponent show={this.state.showTwilio} phonebook={[{key: this.props.patientDetails.data.phoneMobile, title: "Moblie"}, {key: this.props.patientDetails.data.phoneHome, title: "Home"}, {key: this.props.patientDetails.data.phoneMobile, title: "phoneBusiness"}]} responder={this.props.patientDetails.data.responderName} close={()=>{this.handelOnCallTwilio(false)}}/>
        <CallUrgenTimeComponent show={this.state.showUrgenTime} mobile={this.props.patientDetails.data.phoneMobile} email={this.props.patientDetails.data.email} func={this.props.func} close={()=>{this.handelOnCallUrgenTime(false)}}/>

        <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-grid--stretch">
          <div className="k-container k-content-wrapper  k-grid k-grid--ver" id="k_content_wrapper">
            <div className="k-content	k-grid__item k-grid__item--fluid k-grid k-grid--hor" id="k_content">

              <div className="k-content__head	k-grid__item">
                <div className="k-content__head-main">
                  <div className="k-content__head-breadcrumbs">
                    <a className="k-content__head-breadcrumb-home"><i className="flaticon-home-2"></i></a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <a href="#" className="k-content__head-breadcrumb-link">Dashboard</a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <NavLink className="k-content__head-breadcrumb-link" activeClassName="k-content__head-breadcrumb-link--active" to="#">Patient Details</NavLink>
                    {/* <!-- <span className="k-content__head-breadcrumb-link k-content__head-breadcrumb-link--active">Active link</span> --> */}
                  </div>
                </div>
                <div className="k-content__head-toolbar">
                  <label style={{float: "left", display: "block", margin: "auto", marginRight: "5px"}}>Time this month: </label>
                  <div className="input-group" style={{width: "150px", marginRight: "10px"}}>
                    <input style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className="form-control form-control-sm" name="typeDevice" value={this.props.timerLogs.totalTimes + " minute"} readOnly/>
                    <div className="input-group-append">
                      <button disabled={this.props.patientDetails.data.device === null} onClick={()=>{this.handelOnCallTimerLog()}} className="btn btn-secondary btn-sm" type="button" style={{width: "30px", padding: "2px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}}><i className="fa fa-caret-down" style={{padding: "5px"}}></i></button>
                    </div>
                  </div>
                  <label style={{float: "left", display: "block", margin: "auto", marginRight: "5px"}}>Current time: </label>
                  <div className="input-group" style={{width: "150px", marginRight: "10px"}}>
                    <input style={this.state.timers.minutes >= 10 ? {backgroundColor: "#fd397a", color: "#fff", borderRadius: "50px"} : this.state.timers.minutes >= 5 ? {backgroundColor: "#ffb822", color: "#212529", borderRadius: "50px"} : {borderRadius: "50px"}} className="form-control form-control-sm" name="typeDevice" autoComplete="off" value={(this.state.timers.minutes < 10 ? "0" + this.state.timers.minutes : this.state.timers.minutes) + ":" + (this.state.timers.seconds < 10 ? "0" + this.state.timers.seconds : this.state.timers.seconds)} readOnly/>
                    <span className="k-switch k-switch--sm k-switch--outline k-switch--icon k-switch--primary" style={{right: "0", height: "30px", position: "absolute"}}>
                      <label style={{margin: "auto"}}>
                        <input disabled={this.props.patientDetails.data.device === null} onChange={()=>{this.handleOnPauseInterval()}} type="checkbox" checked={this.state.pause} name="pause"/>
                        <span></span>
                      </label>
                    </span>
                  </div>
              </div>
              </div>
              
              <div hidden={this.state.pause || this.props.patientDetails.data.mrn === null} style={{height: "350px",display: "flex", backgroundColor: "inherit", zIndex: "99"}}>
                <div style={{margin: "auto"}}>
                  <img style={{width: "150px", height: "150px", display: "flex"}} src={process.env.PUBLIC_URL +"/images/Double-350px.svg"}/>
                  <span style={{fontSize: "inherit", textAlign: "center", display: "block"}}>Paused . . . . .</span>
                </div>
              </div>

              {
                !this.state.pause && this.props.patientDetails.data.mrn !== null ?
                  ""
                :
                  <React.Fragment>
                    <div className="row">
                      <div className="col-lg-3 col-xl-3 order-lg-2 order-xl-1">
                        <div className="k-portlet k-portlet--height-fluid">
                          <div className="k-portlet__body">
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-primary" role="tablist">
                              <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#finder" role="tab">
                                  <span className="nav-link-icon"><i className="fa fa-user"></i></span>
                                  <span className="nav-link-title">Finder</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#systemStatus" role="tab">
                                  <span className="nav-link-icon"><i className="fa fa-microchip"></i></span>
                                  <span className="nav-link-title">System Status</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="finder" role="tabpanel">
                                  <div className="form-group">
                                    <input tabIndex="1" className="form-control form-control-sm" onBlur={this.handleOnLoadLastName} onChange={this.handleOnChangeFormData} value={this.state.formSearch.firstName || ""} type={this.state.PID ? "text" : "password"} placeholder="First name" name="firstName" autoComplete="off" readOnly={this.state.modeView}/>
                                    <span className="k-font-danger">{this.state.validation.formSearch["firstName"]}</span>
                                  </div>
                                  <div className="form-group">
                                    <AutoCompleteComponent tabIndex="2" refChild={this.autoCompleteLastName} wait={this.state.blockLastName} readOnly={this.state.blockLastName||this.state.modeView} onChange={this.handleOnChangeFormData} useStrict={false} type={this.state.PID ? "text" : "password"} name="lastName" value={this.state.formSearch.lastName || ""} placeholder="Last name" resource={this.props.lastName.data}></AutoCompleteComponent>
                                    <span className="k-font-danger">{this.state.validation.formSearch["lastName"]}</span>
                                  </div>
                                  <div className="form-group">
                                    <div>
                                      <DateTimePickerComponent tabIndex="3" today={false} onChange={this.handleOnChangeFormData} times={false} type={this.state.PID ? "text" : "password"} placeholder="Date of birth" format={configs.formatDateOnSite} value={this.state.formSearch.dob || ""} name="dob" readOnly={this.state.modeView}/>
                                      <span className="k-font-danger">{this.state.validation.formSearch["dob"]}</span>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <input tabIndex="4" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formSearch.mrn || ""} type="text" placeholder="Code MRN" name="mrn" autoComplete="off" readOnly={this.state.modeView}/>
                                    <span className="k-font-danger">{this.state.validation.formSearch["mrn"]}</span>
                                  </div>
                                  <OverlayTrigger trigger="focus" show={this.state.showFormConfirmPass} placement="right" overlay={
                                      <Popover id='popoverVerify'>
                                      <Popover.Title as='h3'>Verify</Popover.Title>
                                      <Popover.Content>
                                          <div className="form-group">
                                              <label>Password</label>
                                              <input type="password" className="form-control" onChange={this.handleOnChangeInputPass} value={this.state.passWord} name="passWord" />
                                          </div>
                                          <button onClick={this.handleOnVerify} type="button" style={{float:"right", marginBottom: "10px", width: "100%"}} className="btn btn-pill btn-label-primary btn-sm">Submit</button>
                                      </Popover.Content>
                                    </Popover>
                                    }>
                                    <div>
                                      <span className="k-switch k-switch--sm k-switch--outline k-switch--icon k-switch--primary" style={{float: "right"}}>
                                        <label>
                                          <input checked={this.state.checkedPID} onChange={this.handleOnChangeInputPass} type="checkbox" name="PID"/>
                                          <span></span>
                                        </label>
                                      </span>
                                      <label style={{float: "right", marginRight: "10px", marginTop: "3px"}}>Shown/Hidden</label>
                                    </div>
                                  </OverlayTrigger>
                                  <div hidden={this.props.patientDetails.active} className="form-group">
                                    <button tabIndex="5" onClick={()=>{this.handleOnSubmitSearchPatient()}} type="button" style={{width: "100%"}} className="btn btn-secondary btn-pill"><i className="fa fa-search"></i> Find Patient</button>
                                  </div>
                                  <div hidden={!this.props.patientDetails.active} className="form-group">
                                    <button onClick={()=>{this.handleOnConfirmCompleted()}} style={{width: "100%"}} type="button" className="btn btn-danger btn-elevate btn-pill"><i className="flaticon-stopwatch"></i> Complete</button>
                                  </div>
                                  <React.Fragment>
                                    {
                                      !func.isEmptyOrNull(this.props.patientDetails.data.device)
                                      ?
                                        <React.Fragment>
                                          {
                                          !this.state.ShowDeviceId
                                          ?
                                            <div onClick={this.handleOnShowDeviceId} className='text-success' style={{cursor: "pointer", height: "40px", width: "150px", float: "left", margin: "auto", padding: "10px 5px"}}>Patient is RPM-ready</div>
                                          :
                                            <div onClick={this.handleOnShowDeviceId} className='text-success' style={{cursor: "pointer", height: "40px", width: "150px", float: "left", margin: "auto", padding: "10px 5px"}}>{"Patient is " + String(this.props.patientDetails.data.device).substring(String(this.props.patientDetails.data.device).length - 3, String(this.props.patientDetails.data.device).length) + "-ready"}</div>
                                          }
                                          <button onClick={()=>{this.handleOnRedirect()}} style={{height: "40px",width: "40px", margin: "auto", padding: "5px", textAlign: "center"}} type="button" className="btn btn-light"><i style={{margin: "auto", padding: "1px"}} className="flaticon-cogwheel"></i></button>
                                        </React.Fragment>
                                      :
                                        <React.Fragment>
                                            <div className='text-success' style={{cursor: "pointer", height: "30px", width: "150px", float: "left", margin: "auto", padding: "5px"}}>Not an RPM patient</div>
                                        </React.Fragment>
                                    }
                                  </React.Fragment>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.phoneHome)} style={{marginBottom: "5px"}}>
                                    <span><i className='la la-home' style={{paddingRight: "5px"}}></i>
                                    <a href={'tel:' + this.props.patientDetails.data.phoneHome}> {this.props.patientDetails.data.phoneHome}</a> 
                                    </span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.phoneBusiness)} style={{marginBottom: "5px"}}>
                                    <span><i className='la la-building' style={{paddingRight: "5px"}}></i>
                                    <a href={'tel:' + this.props.patientDetails.data.phoneBusiness}> {this.props.patientDetails.data.phoneBusiness}</a>
                                    </span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.phoneMobile)} style={{marginBottom: "5px"}}>
                                      <span><i className='la la-mobile-phone' style={{paddingRight: "5px"}}></i><a href={'tel:' + this.props.patientDetails.data.phoneMobile}> {this.props.patientDetails.data.phoneMobile}</a></span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.email)} style={{marginBottom: "5px"}}>
                                    <span><i className='flaticon-multimedia-2' style={{paddingRight: "5px"}}></i> <a href={'mailto:' + this.props.patientDetails.data.email}> {this.props.patientDetails.data.email}</a></span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.language)} style={{marginBottom: "5px"}}>
                                    <span><i className='la la-language' style={{paddingRight: "5px"}}></i>{this.props.patientDetails.data.language}</span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.countReminder)} style={{marginBottom: "5px"}}>
                                    <span><i className='la la-commenting-o' style={{paddingRight: "5px"}}></i> {this.props.patientDetails.data.countReminder}</span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.lastVitals)} style={{marginBottom: "5px"}}>
                                    <span><i className='la la-heart-o' style={{paddingRight: "5px"}}></i> {this.props.patientDetails.data.lastVitals !== null ? moment.utc(this.props.patientDetails.data.lastVitals.createdAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite) : ""}</span>
                                  </div>
                                  <div hidden={func.isEmptyOrNull(this.props.patientDetails.data.responderName)}>
                                    <span><i className='fa fa-headset' style={{paddingRight: "5px"}}></i> {this.props.patientDetails.data.responderName + (!func.isEmptyOrNull(this.props.patientDetails.data.responderRole) ? " | " + this.props.patientDetails.data.responderRole : "")}</span>
                                  </div>
                              </div>
                              <div className="tab-pane" id="systemStatus" role="tabpanel">
                                {
                                  !func.isEmptyOrNull(this.props.patientDetails.data.systemCheck)
                                  ? 
                                    <div className="k-widget-5" style={{overflow: "auto", height: "550px"}}>
                                      {this.renderSystemCheck(this.props.patientDetails.data.systemCheck)}
                                    </div>
                                  : ""
                                }
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-xl-9 order-lg-2 order-xl-1">
                        <div className="k-portlet k-portlet--height-fluid">
                          <div className="k-portlet__body">
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-primary" role="tablist">
                              <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#Vitals" role="tab">
                                  <span className="nav-link-icon"><i className="fa fa-user-circle"></i></span>
                                  <span className="nav-link-title">Vitals</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Messages" role="tab">
                                  <span className="nav-link-icon"><i className="fab fa-facebook-messenger"></i></span>
                                  <span className="nav-link-title">Messages</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#Voice" role="tab">
                                  <span className="nav-link-icon"><i className="fa fa-microphone"></i></span>
                                  <span className="nav-link-title">Voice</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane fade active show" id="Vitals" role="tabpanel">
                                <div className="container-table">
                                  <VitalsContainer/>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="Messages" role="tabpanel">
                                <div className="container-table">
                                  <MessagesContainer/>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="Voice" role="tabpanel">
                                <div className="container-table">
                                  <SchedulesContainer/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                          <div className="k-portlet k-portlet--height-fluid">
                              <div className="k-portlet__body">
                              <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-primary" role="tablist">
                                  <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#SummaryCompliance" role="tab">
                                      <span className="nav-link-icon"><i className="fa fa-chart-bar"></i></span>
                                      <span className="nav-link-title">Summary Compliance</span>
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content">
                                  <div className="tab-pane active" id="SummaryCompliance" role="tabpanel">
                                    <ChartSummaryComplianceContainer/>
                                  </div>
                                </div> 
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                          <div className="k-portlet k-portlet--height-fluid">
                              <div className="k-portlet__body" style={{minHeight: "250px"}}>
                                <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-primary" role="tablist">
                                  <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#FollowTaks" role="tab">
                                      <span className="nav-link-icon"><i className="fa fa-calendar-alt"></i></span>
                                      <span className="nav-link-title">Follow Taks</span>
                                    </a>
                                  </li>
                                </ul> 
                                <div className="tab-content">
                                  <div className="tab-pane active" id="FollowTaks" role="tabpanel">
                                    <FollowTaskComponent func={this.props.func} device={this.props.patientDetails.data.device} permission={configs.scopes.readPatients} data={this.props.patientDetails.data.followTask}/>
                                  </div>
                                </div>  
                              </div>
                          </div>
                      </div>
                    </div>
                  </React.Fragment>
              }

            </div>
          </div>
        </div>

        <Modal show={this.state.showLongTimerConfirm} style={{backgroundColor: "rgb(108 117 125 / 53%)"}} backdrop="static" dialogClassName="modal-dialog modal-width-350">
            <Modal.Header>
                <Modal.Title style={{fontSize: "inherit"}}>Long Timer Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                  <div className="alert-icon" style={{width: "50px", height: "50px", float: "left", marginRight: "15px", fontSize: "xx-large",textAlign: "center"}}>
                    <i className="fa fa-exclamation-circle" style={{color: "#5867dd"}}></i>
                  </div>
                  <div style={{marginBottom: "15px", listStyleType: "none"}} className="alert-text">
                    <li>You are recording a &nbsp;
                      <span className="k-font-bolder k-font-danger">{this.state.formLongTimerConfirm.level}</span> &nbsp;
                      timer:
                      <span className="badge badge-danger" style={{width: "60px", marginLeft: "5px", padding: "3px"}}>
                        {(this.state.timers.minutes < 10 ? "0" + this.state.timers.minutes : this.state.timers.minutes) + ":" + (this.state.timers.seconds < 10 ? "0" + this.state.timers.seconds : this.state.timers.seconds)}
                      </span>
                    </li>
                    <li>Please enter a justification. If the timer was left running accidentally, either</li>
                  </div>
                  <li>Click "Cancel" below to skip this transaction</li>
                  <li>Click "Save" and go to the Timer Log and edit this entry</li>
                </div>
                <div className="form-group" style={{marginBottom: "0px"}}>
                  <label>Comment</label>
                  <textarea onChange={this.handleOnChangeFormData} className="form-control" value={this.state.formLongTimerConfirm.comment || ""} name="comment" rows="4"></textarea>
                  <span className="k-font-danger">{this.state.validation.formLongTimerConfirm["comment"]}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={()=>{this.handleOnCancelCompleted()}} className="btn btn-secondary btn-sm">Cancel</button>
              <button onClick={()=>{this.handleOnSubmitComplete()}} type="button" className="btn btn-brand btn-sm">Save</button>
            </Modal.Footer>
      </Modal>

    </React.Fragment>
    )

  }

}