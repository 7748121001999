
import React, { Component } from 'react';

import { connect } from 'react-redux';

import MessagesComponent from '../components/dashboards/contents/messages/messagesComponent';

import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {getMessages_messagesAction, loadMorePage_messagesAction} from '../actions/messagesAction';

class MessagesContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<MessagesComponent 
					data = {this.props.messages.data} 
					filters = {this.props.messages.filters} 
					pagination = {this.props.messages.pagination} 
					sorts = {this.props.messages.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		messages: __state.MessagesReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getMessages_messagesAction: (__filters, __sorts, __pagination) => {
				__dispatch(getMessages_messagesAction(__filters, __sorts, __pagination))
			},
			loadMorePage_messagesAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_messagesAction(__pagination, __plus))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(MessagesContainer);