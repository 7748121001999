
import moment from 'moment';
import * as configs from '../lib/configs';

export default class func {

    static noteDateTime(__value){

        let dateTime  = moment.utc(__value).utcOffset(configs.timeZone);
        let current   = moment.utc().utcOffset(configs.timeZone);

        let y = parseInt((Math.abs(dateTime - current) /24 / 60 / 60/ 1000) / 365);
        let M = parseInt((Math.abs(dateTime - current) /24 / 60 / 60 / 1000) / 30);
        let d = parseInt(Math.abs(dateTime - current) / 24 / 60 / 60 / 1000);
        let h = parseInt(Math.abs(dateTime - current) / 60 / 60/ 1000);
        let m = parseInt(Math.abs(dateTime - current) / 60 / 1000);

        if(y > 0){
        
            return y + " years ago";
        
        };
        
        if(M > 0){
        
            return M + " months ago";
        
        };
        
        if(d > 0){
        
            return d + " days ago";
        
        };
        
        if(h > 0){
        
            return h + " hours ago";
        
        };
        
        if(m > 0){
        
            return m +" minutes ago";
        
        };
        
        if(m <= 0){
        
            return "Recently";
        
        };

    }

	static validate(__schema, __input, __error){

        for(let k of Object.keys(__schema)){

            if (func.isType(__schema[k], "object")){

                if(__input[k] === undefined || __input[k] === null || func.isType(__input[k], "object") === false){

                    throw new Error("Runtime error");

                };
        
                func.validate(__schema[k], __input[k], __error);

                continue;

            };

            let val = "";

            if(Object.keys(__input).includes(k)){
    
                if(!func.isEmptyOrNull(__input[k])){

                    val = String(__input[k]);

                }

                if(!new RegExp(__schema[k]).test(val)){

                    if(func.isEmptyOrNull(val)){

                        __error[k] = "Required";

                    }else{

                        __error[k] = "Invalid";

                    };

                    continue;

                };

                continue;

            };

            __error[k] = "Required";

        };

    }

    static objectIsEmpty(__obj){

        if (!func.isType(__obj, "object")){

            throw new Error("400");

        }
            
        if(Object.keys(__obj).length === 0){
        
            return true;

        };

        return false;


    }

    static isEmptyOrNull(__val){

        if(__val === undefined || __val === null || String(__val).trim() === ""){

            return true

        };

        // if (func.isType(__val, "string") === false && func.isType(__val, "number") === false){

        //     throw new Error("400");

        // }
        
        return false

    }

    static isType(__val, __type){

        switch(String(__type).toLowerCase()) {

            case 'string':

                if(typeof __val !== 'string'){

                    return false;

                }
                
                return true;

            case 'number':
    
                if(isNaN(Number(__val))){

                    return false;

                }

                return true;

            case 'int':
    
                if(isNaN(Number(__val)) === true || Number.isInteger(__val) === false){

                    return false;

                }

                return true;

            case 'float':

                if(isNaN(Number(__val)) === true || Number(__val) % 1 === 0){

                    return false;

                }

                return true;

            case 'date':

                if(__val instanceof Date === false || isNaN(__val) === true){

                    return false;

                }

                return true;

            case 'array':

                return Array.isArray(__val);

            case 'object':
    
                if(__val instanceof Date === true || typeof __val === 'function' || __val instanceof Object === false){

                    return false;

                }

                return true;

            case 'boolean':

                if(typeof __val !== 'boolean'){

                    return false;

                }

                return true;

            default:

               throw new Error('Type is not supported');

        }

    }

    static formatText(__val, __format){

            let arr         = [];
            let paragraph   = '';
        
            if(!func.isType(__val, "string") || !func.isType(__format, "string")){

                throw new Error("400");

            };

            switch(String(__format).toLowerCase()) {
        
                case 'upper':
        
                    return String(__val).toUpperCase();
        
                case 'lower':
     
                    return String(__val).toLowerCase();
        
                case 'upperword':
        
                    arr = String(__val).split(' ');
        
                    for(let i = 0; i < arr.length; i++){
        
                        let f, l = '';
        
                        if(String(arr[i]).trim() !== ''){
        
                            f = String(arr[i]).trim().substring(0, 1).toUpperCase();
                            l = String(arr[i]).trim().substring(1, String(arr[i]).length).toLowerCase();
        
                            arr[i] = f + l;
        
                        }
        
                    }
        
                    return arr.join(' ');
        
                case 'uppersentence':
        
                    paragraph   = String(__val).replace('\r\n', '\n').replace('\r', '\n');
                    arr         = paragraph.split('\n');
        
                    for(let i = 0; i < arr.length; i++){
        
                        let p = String(arr[i]).split('. ');
        
                        for(let y = 0; y < p.length; y++){
        
                            let f, l = '';
        
                            if(String(p[y]).trim() !== ''){
        
                                f = String(p[y]).trim().substring(0,1).toUpperCase();
                                l = String(p[y]).trim().substring(1,String(p[y]).length);
        
                                p[y] = f + l;
        
                            }
        
                        }
        
                        arr[i] = p.join('. ');
        
                    }
        
                    return arr.join('\n');
        
                default:
                    throw new Error('Format is not supported');
        
            }
    
    }
    
}

