import * as configs from '../lib/configs';

export const stateNotifies = {
        device:                 "",
        new:{
            data: [],
            filters: {
                id:             "",
                read:           0,
                fromDate:       "",
                toDate:         ""
            },
            pagination:{
                startMenu:      1,
                limitMenu:      configs.limitMenu,
                pageSizes:      configs.pageSizes,
                page:           1,
                totalRows:      0
            },
            sorts:{
                sortBy:         "notify_at",
                sortValue:      "DESC"
            }
        },
        history:{
            data: [],
            filters: {
                id:             "",
                read:           1,
                fromDate:       "",
                toDate:         ""
            },
            pagination:{
                startMenu:      1,
                limitMenu:      configs.limitMenu,
                pageSizes:      configs.pageSizes,
                page:           1,
                totalRows:      0
            },
            sorts:{
                sortBy:         "notify_at",
                sortValue:      "DESC"
            }
        }
    };

const NotifiesReducer = (__state = stateNotifies, __action) => {

    switch(__action.type){

        case "IMPORT_NEW_NOTIFIES":

            return {
                ...__state,
                device:            __action.payloads.device,
                new:{
                    data:          __action.payloads.data,
                    sorts:{
                        sortBy:    __action.payloads.sortBy, 
                        sortValue: __action.payloads.sortValue
                    }, 
                    filters:{
                        ...__state.new.filters, 
                        id:         __action.payloads.id,
                        fromDate:   __action.payloads.fromDate,
                        toDate:     __action.payloads.toDate
                    }, 
                    pagination:{
                        ...__state.new.pagination, 
                        page:      __action.payloads.page, 
                        pageSizes: __action.payloads.pageSizes, 
                        totalRows: __action.payloads.totalRows,
                        begin:     __action.payloads.begin
                    }
                }
            };

        case "IMPORT_HISTORY_NOTIFIES":

            return {
                ...__state,
                device:            __action.payloads.device,
                history:{
                    data:          __action.payloads.data,
                    sorts:{
                        sortBy:    __action.payloads.sortBy, 
                        sortValue: __action.payloads.sortValue
                    }, 
                    filters:{
                        ...__state.history.filters, 
                        id:         __action.payloads.id,
                        fromDate:   __action.payloads.fromDate,
                        toDate:     __action.payloads.toDate
                    }, 
                    pagination:{
                        ...__state.history.pagination, 
                        page:      __action.payloads.page, 
                        pageSizes: __action.payloads.pageSizes, 
                        totalRows: __action.payloads.totalRows,
                        begin:     __action.payloads.begin
                    }
                }
            };

        case "RESET_NOTIFIES":

            return stateNotifies;

        default: 

            return __state;

    };

};

export default NotifiesReducer;