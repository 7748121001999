import httpClient from '../lib/httpClient';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="SendMessageComponent";

export const sendMessagesToGroup_sendMessageAction = (__formData) => async(__dispatch) => {
    try {

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/messages?func=sendMessagesToGroup", "POST", __formData, null);

        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const sendMessagesToPatient_sendMessageAction = (__formData) => async(__dispatch) => {
    try {
 
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/messages?func=sendMessagesToPatient", "POST", __formData, null);

        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const callSendMessage_sendMessageAction = (__show, __sendGroup) =>{
    return {
        type: "SHOW_SENDMESSAGE",
        payloads:{
            show: __show || false,
            sendGroup: __sendGroup || false
        } 
    }
}

export const reset_sendMessageAction = () => {

    return {
        type: "RESET_SENDMESSAGE",
        payloads: {}
    };

}