import * as configs from '../lib/configs';

export const stateVitals = {
    data: [],
    filters: {
        device:         "",
        typeDevice:     "", 
        fromDate:       "",
        toDate:         ""
    },
    pagination:{
        begin:          1,
        length:         configs.limitMenu,
        pageSizes:      configs.pageSizes,
        page:           1,
        totalRows:      0
    },
    sorts:{
        sortBy:         "createdAt",
        sortValue:      "DESC"
    }
};

const VitalsReducer = (__state = stateVitals, __action) => {

    switch(__action.type){
 
        case "IMPORT_VITALS":

            return {
                ...__state,
                 data:          __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{
                    ...__state.filters, 
                    device:     __action.payloads.device,
                    typeDevice: __action.payloads.typeDevice, 
                    fromDate:   __action.payloads.fromDate,
                    toDate:     __action.payloads.toDate
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_VITALS":
            
            return stateVitals;

        case "LOAD_MORE_PAGE_VITALS":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        default: 

            return __state;

    };

};

export default VitalsReducer;