import { v4 as uuidv4 } from 'uuid';

export const create_MsgBoxAction = (__ref, __title, __message, __buttons, __type, __process, __percent) => {

    return {
        type: 'CREATE_MSGBOX',
        payloads: {
            id:                 uuidv4(),
            ref:                __ref,
            data:{
                title:          __title,
                message:        __message,
                buttons:        __buttons,
                type:           __type,
                process:        __process,
                percent:        __percent,
            }
        }
    };

}

export const remove_MsgBoxAction = (__id) => {

    return {
        type: 'REMOVE_MSGBOX',
        payloads: {
            id: __id
        }
    };

}

export const onclick_MsgBoxAction = (__btn) => {

    return {
        type: 'ONCLICK_MSGBOX',
        payloads: __btn
    };

}

export const reset_MsgBoxAction = () => {

    return {
        type: 'RESET_MSGBOX',
        payloads: {}
    };

}