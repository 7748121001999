import React, {Component} from 'react';
import { connect } from 'react-redux';

import RolesComponent from '../components/dashboards/contents/roles/rolesComponent';

import {getRoles_rolesAction, create_rolesAction, updateStatus_rolesAction, delete_rolesAction, reset_rolesAction} from '../actions/rolesAction';
import {getPolicies_managerUsersAction} from '../actions/managerUsersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {getCustomers_customersAction} from '../actions/customersAction';

class RolesContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <RolesComponent
          customers={this.props.Customers}
          data = {this.props.Roles.data} 
          filters = {this.props.Roles.filters} 
          pagination = {this.props.Roles.pagination} 
          sorts = {this.props.Roles.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    Customers: __state.CustomersReducer,
    Roles: __state.RolesReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
      getCustomers_customersAction: (__filters, __sorts, __pagination)=>{
				__dispatch(getCustomers_customersAction(__filters, __sorts, __pagination))
			},
			getRoles_rolesAction: (__filters, __sorts, __pagination) => {
				__dispatch(getRoles_rolesAction(__filters, __sorts, __pagination))
			},
      create_rolesAction: (__formData) => {
				__dispatch(create_rolesAction(__formData))
			},
      updateStatus_rolesAction: (__formData) => {
				__dispatch(updateStatus_rolesAction(__formData))
			},
      delete_rolesAction: (__formData) => {
				__dispatch(delete_rolesAction(__formData))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
      reset_rolesAction: () => {
				__dispatch(reset_rolesAction())
			},
      getPolicies_managerUsersAction: (__filters, __segment) => {
				__dispatch(getPolicies_managerUsersAction(__filters, __segment))
			}
    }
  }
}
 
export default connect(mapState, mapDispatch, null, {forwardRef: true})(RolesContainer);