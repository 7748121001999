
export const twilioEndPoind         = window.configs.twilioEndPoind
export const timeZone               = window.configs.timeZone
export const endPoint               = window.configs.endPoint
export const limitMenu              = window.configs.limitMenu
export const pageSizes              = window.configs.pageSizes
export const recaptcha_siteKey      = window.configs.recaptcha_siteKey
export const recaptcha_secret       = window.configs.recaptcha_secret
export const formatDateOutSite      = window.configs.formatDateOutSite
export const formatDateTimeOutSite  = window.configs.formatDateTimeOutSite
export const formatDateTimeTZ       = window.configs.formatDateTimeTZ
export const formatDateOnSite       = window.configs.formatDateOnSite
export const formatDateTimeOnSite   = window.configs.formatDateTimeOnSite
export const userPoolId             = window.configs.userPoolId
export const userPoolClientId       = window.configs.userPoolClientId
export const pages                  = window.configs.pages
export const logoDesktop            = window.configs.logoDesktop
export const logoMobile             = window.configs.logoMobile
export const favicon                = window.configs.favicon
export const verifyMF               = window.configs.verifyMF
 
export const color = [      
  "#EF5350",
  "#AB47BC",
  "#5C6BC0",
  "#29B6F6",
  "#26A69A",
  "#9CCC65",
  "#FFEE58",
  "#FFA726",
  "#78909C",
  "#F48FB1",
  "#B39DDB",
  "#90CAF9",
  "#80DEEA",
  "#A5D6A7",
  "#E6EE9C",
  "#FFE082"
  ]
  
export const borderColor = [
  "#EF5350",
  "#AB47BC",
  "#5C6BC0",
  "#29B6F6",
  "#26A69A",
  "#9CCC65",
  "#FFEE58",
  "#FFA726",
  "#78909C",
  "#F48FB1",
  "#B39DDB",
  "#90CAF9",
  "#80DEEA",
  "#A5D6A7",
  "#E6EE9C",
  "#FFE082"
]

export const scopes = {
  readPatients:           "",
  addPatients:            "",
  editPatients:           "",
  delPatients:            "",
  readTimerLogs:          "",
  addTimerLogs:           "",
  editTimerLogs:          "",
  delTimerLogs:           "",
  readDashboards:         "",
  addDashboards:          "",
  editDashboards:         "",
  delDashboards:          "",
  
  readGroupsResponder:    "",
  addGroupsResponder:     "",
  editGroupsResponder:    "",
  delGroupsResponder:     "",

  readRoles:              "",
  addRoles:               "",
  editRoles:              "",
  delRoles:               "",

  readPermissions:        "",
  addPermissions:         "",
  editPermissions:        "",
  delPermissions:         "",

  readScopes:             "",
  addScopes:              "",
  editScopes:             "",
  delScopes:              "",
  
  readResponders:         "",
  addResponders:          "",
  editResponders:         "",
  delResponders:          "",

  readCustomers:          "",
  addCustomers:           "",
  editCustomers:          "",
  delCustomers:           "",

  UrgenTime:              "",
  Twilio:                 ""
}

export const setupScopes = () => {

  scopes.readPatients           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "patients~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addPatients            = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "patients~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editPatients           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "patients~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delPatients            = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "patients~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readTimerLogs          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "timerlogs~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addTimerLogs           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "timerlogs~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editTimerLogs          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "timerlogs~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delTimerLogs           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "timerlogs~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readDashboards         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "dashboards~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addDashboards          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "dashboards~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editDashboards         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "dashboards~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delDashboards          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "dashboards~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readGroupsResponder    = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "groupsresponder~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addGroupsResponder     = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "groupsresponder~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editGroupsResponder    = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "groupsresponder~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delGroupsResponder     = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "groupsresponder~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readRoles              = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "roles~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addRoles               = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "roles~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editRoles              = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "roles~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delRoles               = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "roles~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readPermissions        = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "permissions~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addPermissions         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "permissions~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editPermissions        = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "permissions~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delPermissions         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "permissions~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
   
  scopes.readScopes             = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "scopes~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addScopes              = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "scopes~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editScopes             = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "scopes~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delScopes              = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "scopes~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
   
  scopes.readResponders         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "responders~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addResponders          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "responders~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editResponders         = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "responders~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delResponders          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "responders~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.readCustomers          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "customers~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.addCustomers           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "customers~create" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.editCustomers          = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "customers~modify" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.delCustomers           = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "customers~delete" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  
  scopes.UrgenTime              = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "urgentime~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false;
  scopes.Twilio                 = window.sessionStorage.getItem("scopes") !== null ? (window.sessionStorage.getItem("scopes").split(",").filter(key => key.toLowerCase() === "twilio~read" || key.toLowerCase() === "fullcontrol").length === 0 ? false : true) : false

}