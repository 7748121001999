
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChartSummarySystemDataComponent from '../components/dashboards/contents/chartSummarySystemData/chartSummarySystemDataComponent';

import {getSummary_chartSummarySystemDataAction,reset_chartSummarySystemDataAction} from '../actions/chartSummarySystemDataAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ChartSummarySystemDataContainer extends Component{

	render() {

		return(
   
			<ChartSummarySystemDataComponent 
				data = {this.props.ChartSystemData.data} 
				filters = {this.props.ChartSystemData.filters} 
				func = {this.props.func}
			/>

		);

	};

};

const mapState = __state => {

	return{
		ChartSystemData: __state.ChartSystemDataReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummary_chartSummarySystemDataAction: (__option) => {
				__dispatch(getSummary_chartSummarySystemDataAction(__option));
			},
			reset_chartSummarySystemDataAction: () => {
				__dispatch(reset_chartSummarySystemDataAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ChartSummarySystemDataContainer);