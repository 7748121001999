
import React, { Component } from 'react';

import { connect } from 'react-redux';

import ChartSummaryComplianceComponent from '../components/dashboards/contents/chartSummaryCompliance/chartSummaryComplianceComponent';

import {getSummary_chartSummaryComplianceAction, reset_chartSummaryComplianceAction} from '../actions/chartSummaryComplianceAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ChartSummaryComplianceContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<ChartSummaryComplianceComponent 
					data = {this.props.chartSummaryComplianceReducer.data} 
					filters = {this.props.chartSummaryComplianceReducer.filters} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		chartSummaryComplianceReducer: __state.ChartSummaryComplianceReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummary_chartSummaryComplianceAction: (__device, __option) => {
				__dispatch(getSummary_chartSummaryComplianceAction(__device, __option));
			},
			reset_chartSummaryComplianceAction: () => {
				__dispatch(reset_chartSummaryComplianceAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ChartSummaryComplianceContainer);