import React, { Component } from 'react';
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import crypto from "crypto";

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import ChartSummaryBatteriesContainer from '../../../../containers/chartSummaryBatteriesContainer';

import httpClient from '../../../../lib/httpClient';
import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

const initialState = () => {

  return{
    device:                 null,
    formResetCounters:{
      show:                 false,
      sensorId:             "",
      listModel:            []
    },
    passWord:               "",
    PID:                    false,
    checkedPID:             false,
    showFormConfirmPass:    false,
    showChartBatteries:     false
  };

}

const _ref="SystemMonitorComponent";

export default class SystemMonitorComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentWillReceiveProps(nextProps){


  }

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

      this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
      this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {

      this.props.pagination.page = __page;
      this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnPreviousPage = () => {

      this.props.pagination.page = this.props.pagination.page - 1;
      this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

      this.props.pagination.page = this.props.pagination.page + 1;
      this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

      this.props.func.loadMorePage_SystemMonitorAction(__pagination, __plus);

  }


  handleOnSort = (__field) => {

    let sortValue = "";

    if(this.props.data.length === 0){

      return;

    };

    if(__field === this.props.sorts.sortBy){


      if(this.props.sorts.sortValue === "ASC"){

        sortValue = "DESC";

      }

      if(this.props.sorts.sortValue === "DESC"){

        sortValue = "ASC";
        
      };

      this.props.sorts.sortValue = sortValue;

    }else{

      this.props.sorts.sortBy    = __field;
      this.props.sorts.sortValue = "ASC";

    };

    this.props.func.getSummary_SystemMonitorAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangeInputPass = (__e) => {

    if(__e.target.name === "PID"){

      let showForm = false;

      if(!this.state.checkedPID){

        showForm = true;

      };

      this.setState({
        ...this.state,
        passWord:             "",
        PID:                  false,
        checkedPID:           !this.state.checkedPID,
        showFormConfirmPass:  showForm
      });

      return;

    };

    this.setState({

      passWord: __e.target.value
      
    });

  }

  handleOnVerify = (__e) => {

    let pass    = window.sessionStorage.getItem("accesscode");
    let token   = window.sessionStorage.getItem("token");
    let buffer  = Buffer.from(token + this.state.passWord);
    let hash    = crypto.createHash("sha256").update(buffer).digest("base64");
  
    if(pass === hash){

      this.setState({
        passWord:             "",
        PID:                  true,
        showFormConfirmPass:  false
      });

      return;

    };

    this.props.func.create_MsgBoxAction(_ref, "Access",  "You don't have permission to access", [{key: "btnClose", title: "Close"}], "error", null, null);
  
    this.setState({
      passWord:             "",
      PID:                  false,
      checkedPID:           false,
      showFormConfirmPass:  false
    });

  }

  handleOnCallMsgBox = (__button) => {


  }

  handleOnCallChartBetteries = (__device, __show) => {

    if(__show){

      this.props.func.getSummary_chartSummaryBatteriesAction(__device);

    }else{

      this.props.func.reset_chartSummaryBatteriesAction();

    }

    this.setState({
      showChartBatteries: __show,
      device:             __device
    })

  }

  handleOnCallFormResetCounters = async () =>{

    let models  = [];

    for(let d of this.props.chartData.devices){
      models.push(
        <option key={uuidv4()} value={d["sensorId"]}>{d["model"]}</option>
      );
    }
    
    this.setState({
      formResetCounters:{
        show:                 true,
        sensorId:             this.props.chartData.devices[0]["sensorId"],
        listModel:            models
      }
    });

  }

  handleOnSubmitResetCounters = async() =>{

    try {

      this.props.func.showLoading(true);

      await httpClient.request(configs.endPoint + "/dashboards?func=resetBattery", "PUT", {sensorId: this.state.formResetCounters.sensorId}, null);

      this.props.func.showLoading(false);

      this.setState({
        formResetCounters:{
          show:                 false,
          listModel:            []
        }
      })

    } catch (error) {

      this.setState({
        formResetCounters:{
          show:                 false,
          listModel:            []
        }
      })
      
      this.props.func.showLoading(false);
      this.props.func.create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null);
            
    }

  }

  handleOnChangeSensor = (__e) =>{

    this.setState({

      formResetCounters:{
        ...this.state.formResetCounters,
        [__e.target.name]: __e.target.value

      }

    })

  }

  handleOnCloseFormResetCounters = () => {

    this.setState({
      formResetCounters:{
        ...this.state.formResetCounters,
        show: false
      }
    })

  }

  renderData = (__data) =>{

    return (__data.map((__r)=>{

      return(

          <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "100px"}}> {__r.MRN}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "150px"}}> {!this.state.PID? "**********" : __r.fullName}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "100px"}}> {!this.state.PID ? "**********" : moment.utc(__r.dob, configs.formatDateOutSite).format(configs.formatDateOnSite)}</span>
              </td>
              <td onDoubleClick={()=>{this.handleOnCallChartBetteries(__r.device_sn, true)}} className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "150px"}}> {!this.state.PID ? "*******" + String(__r.device_sn).substring(__r.device_sn.length - 3, __r.device_sn.length) : __r.device_sn}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.power) ? "" : moment.utc(__r.power, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.boot) ? "" : moment.utc(__r.power, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.sync) ? "" : moment.utc(__r.sync, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.syscheck) ? "" : moment.utc(__r.syscheck, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.alert) ? "" : moment.utc(__r.alert, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.vitals) ? "" : moment.utc(__r.vitals, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.file) ? "" : moment.utc(__r.file, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.spo_ack) ? "" : moment.utc(__r.spo_ack, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "140px"}}> {func.isEmptyOrNull(__r.rem_ack) ? "" : moment.utc(__r.rem_ack, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "250px"}}> {__r.comms}</span>
              </td>
              <td className='k-datatable__cell--left k-datatable__cell'>
                <span style={{width: "250px"}}> {__r.system}</span>
              </td>
          </tr>
      );

    }));
  }

  render() {

    return(

      <React.Fragment>
        
        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
                
        {
          !configs.scopes.readDashboards
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
            <React.Fragment>
              <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded">
                <table className="k-datatable__table list-database" style={{marginBottom: "15px", display: "block", minHeight: "300px", overflow: "auto"}}>
                    <thead className="k-datatable__head">
                    <tr className="k-datatable__row">
                        <th onClick={()=>{this.handleOnSort("MRN")}} className={this.props.sorts.sortBy === "MRN" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "100px"}}>MRN {this.props.sorts.sortBy === "MRN" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("fullName")}} className={this.props.sorts.sortBy === "fullName" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "150px"}}>FULL NAME {this.props.sorts.sortBy === "fullName" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("dob")}} className={this.props.sorts.sortBy === "dob" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "100px"}}>DOB {this.props.sorts.sortBy === "dob" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("device")}} className={this.props.sorts.sortBy === "device" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "150px"}}>DEVICE {this.props.sorts.sortBy === "device" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("power")}} className={this.props.sorts.sortBy === "power" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>POWER {this.props.sorts.sortBy === "power" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("boot")}} className={this.props.sorts.sortBy === "boot" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>BOOT {this.props.sorts.sortBy === "boot" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("sync")}} className={this.props.sorts.sortBy === "sync" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>SYNC {this.props.sorts.sortBy === "sync" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("syscheck")}} className={this.props.sorts.sortBy === "syscheck" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>SYSTEM CHECK {this.props.sorts.sortBy === "syscheck" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("alert")}} className={this.props.sorts.sortBy === "alert" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>ALERT {this.props.sorts.sortBy === "alert" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("vitals")}} className={this.props.sorts.sortBy === "vitals" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>VITALS {this.props.sorts.sortBy === "vitals" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("file")}} className={this.props.sorts.sortBy === "file" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>FILE {this.props.sorts.sortBy === "file" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("spo_ack")}} className={this.props.sorts.sortBy === "spo_ack" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>SPO ACK {this.props.sorts.sortBy === "spo_ack" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("rem_ack")}} className={this.props.sorts.sortBy === "rem_ack" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{cursor: "pointer", width: "140px"}}>REM ACK {this.props.sorts.sortBy === "rem_ack" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                        <span style={{width: "250px"}}>COMMS</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                        <span style={{width: "250px"}}>SYSTEM</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="k-datatable__body">
                      {this.renderData(this.props.data)}
                    </tbody>
                </table>
              </div>
              <OverlayTrigger trigger="focus" show={this.state.showFormConfirmPass} placement="right" overlay={
                <Popover id='dashboarVerify'>
                <Popover.Title as='h3'>Verify</Popover.Title>
                <Popover.Content>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" onChange={this.handleOnChangeInputPass} value={this.state.passWord} name="passWord" />
                    </div>
                    <button onClick={this.handleOnVerify} type="button" style={{marginBottom: "10px", width: "100%"}} className="btn btn-pill btn-label-primary btn-sm">Submit</button>
                </Popover.Content>
              </Popover>
              }>
                <div style={{display: "flex", float: "left"}}>
                  <label style={{float: "right", marginRight: "10px", marginTop: "3px"}}>Shown/Hidden</label>
                  <span className="k-switch k-switch--sm k-switch--outline k-switch--icon k-switch--primary" style={{float: "right"}}>
                    <label>
                      <input checked={this.state.checkedPID} onChange={this.handleOnChangeInputPass} type="checkbox" name="PID"/>
                      <span></span>
                    </label>
                  </span>
                </div>
              </OverlayTrigger>
              <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
              
              <Modal enforceFocus={false} show={this.state.showChartBatteries} onHide={()=>{this.handleOnCallChartBetteries(null, false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-xl">
                <Modal.Header>
                    <Modal.Title style={{fontSize: "inherit"}}>Monitor Batteries</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChartSummaryBatteriesContainer/>
                </Modal.Body>
                <Modal.Footer style={{height: "50px"}}>
                    <OverlayTrigger trigger="focus" show={this.state.formResetCounters.show} placement="left" overlay={
                      <Popover id='resetCounters'>
                      <Popover.Title as='h3' style={{backgroundColor: "#5867dd", color: "white"}}>Reset Counters
                        <button onClick={()=>{this.handleOnCloseFormResetCounters()}} type="button" className="close" data-dismiss="alert" aria-label="Close">
                          <span style={{color: "white"}} aria-hidden="true"><i className="la la-close"></i></span>
                        </button>
                      </Popover.Title>
                      <Popover.Content>
                          <div className="form-group">
                              <label>Sensors</label>
                              <select tabIndex="6" onChange={this.handleOnChangeSensor} className="form-control form-control-sm" value={this.state.formResetCounters.sensorId} name="sensorId">
                                {this.state.formResetCounters.listModel}
                              </select>
                          </div>
                          <button onClick={()=>{this.handleOnSubmitResetCounters()}} type="button" style={{float:"right", marginBottom: "10px", width: "100%"}} className="btn btn-secondary btn-elevate btn-pill btn-sm">Submit</button>
                      </Popover.Content>
                    </Popover>
                    }>
                    <button hidden={!configs.scopes.editDashboards} onClick={()=>{this.handleOnCallFormResetCounters()}} className="btn btn-secondary btn-elevate btn-pill btn-sm" type="button"><i className="fa fa-sync-alt"></i> Reset Counters</button>
                  </OverlayTrigger>
                    
                  <button onClick={()=>{this.handleOnCallChartBetteries(null, false)}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="fa fa-sign-out-alt"></i> Close</button>
                </Modal.Footer>
              </Modal>
            </React.Fragment>
        }
      </React.Fragment>
    )

  }

}