import React, { Component } from 'react';

import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
  } from 'chart.js';
  
import moment from "moment";
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func';
import * as configs from '../../../../lib/configs';

const optionsChartSummaryBatteries= {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        beginAtZero: true
      }
    }
};

const _ref="ChartSummaryBatteriesComponent";

export default class ChartSummaryBatteriesComponent extends Component{

    constructor(__props) {

      super(__props);

      this.state = {      
          data:{
              labels: [],
              datasets: []       
          },
          formFilterChart:{
            option:       0,
            device:       0
          }
      }

      this.chartReference = React.createRef();

    };

    componentWillReceiveProps(nextProps){

        this.setState({
          formFilterChart:{
            option: nextProps.filters.option,
            device: nextProps.filters.device
          }
        });
        
        if(nextProps.data !== this.props.data || nextProps.devices !== this.props.devices){
          let chart = this.chartReference.current
              chart.data = this.renderSummaryBatteries(nextProps.data, nextProps.devices, parseInt(nextProps.filters.option));
              chart.update();
        }
    }
    
    handleOnCallMsgBox = (__button) => {
  
    }

    renderSummaryBatteries = (__data, __devices, __option) => {

      if(__data.length === 0 || __devices.length === 0 || func.isEmptyOrNull(__option)){
  
        return {
          labels: [],
          datasets: []
        };
  
      }

      let start     = 0;
      let end       = 0;
      let collection = {}
      let labels    = {};
      let datasets  = [];

      for(let i = 0; i < __devices.length; i++){
        datasets.push(
          {
            label: __devices[i]["model"],
            backgroundColor: configs.color[i],
            borderColor: configs.borderColor[i],
            borderWidth: 1,
            data:[]
          }
        )
      }

      if(__option === 0){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "week"), "day");

      }

      if(__option === 1){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "week"), "day");

      }

      if(__option === 2){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "month"), "day");

      }

      if(__option === 3){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "month"), "day");

      }

      if(__option === 4){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "year"), "day");

      }

      for(let i = start; i < end; i++){

        let dt = moment.utc().subtract(i, "days").utcOffset(configs.timeZone);
            labels[dt.format(configs.formatDateOutSite)] = dt.format(configs.formatDateOnSite)

      };

      for(let d of __data){

        let createdAt = moment.utc(d["createdAt"], configs.formatDateOutSite).utcOffset(configs.timeZone).format(configs.formatDateOnSite);

        if(!Object.keys(collection).includes(d["model"])){

          collection[d["model"]] = {};

        }
        
        collection[d["model"]][createdAt] = parseInt(d["percent"]) < 0 ? 0 : parseInt(d["percent"]);

      }

      for(let c in collection){

        let arr = Object.keys(collection[c]);
        
        for(let l in labels){

          if(!arr.includes(labels[l])){

            collection[c][labels[l]] = 0;

          }

        }

      }

      for(let da of datasets){
        
        let label = da["label"];

        if(!Object.keys(collection).includes(label)){

          continue;

        }

        let obj = Object.keys(collection[label]).sort().reduce(

          (o, k) => { 

            o[k] = collection[label][k]; 

            return o;

          }, 

          {}

        );

        da["data"] = Object.values(obj)

      }

      let lab = Object.keys(labels).sort().reduce(

        (o, k) => { 

          o[k] = labels[k]; 

          return o;

        }, 

        {}

      );

      return{
          labels: Object.values(lab),
          datasets: datasets
      };
      
    }

    render() {

        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ArcElement,
            PointElement,
            LineElement
        );

        return(

          <React.Fragment>

            <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

            <Bar ref={this.chartReference} data={this.state.data} options={optionsChartSummaryBatteries} height={200} width={600} />

          </React.Fragment>
        )

    }

}