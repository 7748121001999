import moment from "moment";
import * as configs from '../lib/configs';

export const stateSummaryPatients = {

        data:                       [],
        filters: {
            statusRPM:              "enabled", 
            month:                  moment.utc().utcOffset(configs.timeZone).format("YYYY-MM"),
            device:                 "",
            typeNotify:             "",
            statusReadings:         ""
        },
        pagination:{
            begin:                  1,
            length:                 configs.limitMenu,
            pageSizes:              configs.pageSizes,
            page:                   1,
            totalRows:              0
        },
        sorts:{
            sortBy:                 "mrn",
            sortValue:              "ASC"
        },
        goupsOutSide:{
            data: [],
            filters: {
                notPatientId:       "", 
                groupName:          ""
            },
            pagination:{
                begin:              1,
                length:             configs.limitMenu,
                pageSizes:          configs.pageSizes,
                page:               1,
                totalRows:          0
            },
            sorts:{
                sortBy:             "groupName",
                sortValue:          "ASC"
            }
        },
        goupsInSide:{
            data: [],
            filters: {
                byPatientId:       "", 
                groupName:          ""
            },
            pagination:{
                begin:              1,
                length:             configs.limitMenu,
                pageSizes:          configs.pageSizes,
                page:               1,
                totalRows:          0
            },
            sorts:{
                sortBy:             "groupName",
                sortValue:          "ASC"
            }
        }
};

const SummaryPatientsReducer = (__state = stateSummaryPatients, __action) => {

    switch(__action.type){

        case "IMPORT_SUMMARY_PATIENTS":

            return {
                ...__state,
                 data:                  __action.payloads.data,
                 sorts:{
                     sortBy:            __action.payloads.sortBy, 
                     sortValue:         __action.payloads.sortValue
                }, 
                 filters:{
                     month:             __action.payloads.month, 
                     device:            __action.payloads.device, 
                     statusRPM:         __action.payloads.statusRPM, 
                     typeNotify:        __action.payloads.typeNotify, 
                     statusReadings:    __action.payloads.statusReadings
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:              __action.payloads.page, 
                     pageSizes:         __action.payloads.pageSizes, 
                     totalRows:         __action.payloads.totalRows,
                     begin:             __action.payloads.begin 
                }
            };

        case "IMPORT_GROUPS_OUTSIDE_SUMMARY_PATIENTS":

            return {
                ...__state,
                goupsOutSide:{
                    data:                  __action.payloads.data,
                    sorts:{
                        sortBy:            __action.payloads.sortBy, 
                        sortValue:         __action.payloads.sortValue
                    }, 
                    filters:{
                        notPatientId:       __action.payloads.notPatientId, 
                        groupName:          __action.payloads.groupName
                    }, 
                    pagination:{
                        ...__state.goupsOutSide.pagination, 
                        page:              __action.payloads.page, 
                        pageSizes:         __action.payloads.pageSizes, 
                        totalRows:         __action.payloads.totalRows,
                        begin:             __action.payloads.begin 
                    }
                }
            };

        case "IMPORT_GROUPS_INSIDE_SUMMARY_PATIENTS":

            return {
                ...__state,
                goupsInSide:{
                    data:                  __action.payloads.data,
                    sorts:{
                        sortBy:            __action.payloads.sortBy, 
                        sortValue:         __action.payloads.sortValue
                    }, 
                    filters:{
                        byPatientId:        __action.payloads.byPatientId, 
                        groupName:          __action.payloads.groupName
                    }, 
                    pagination:{
                        ...__state.goupsInSide.pagination, 
                        page:              __action.payloads.page, 
                        pageSizes:         __action.payloads.pageSizes, 
                        totalRows:         __action.payloads.totalRows,
                        begin:             __action.payloads.begin 
                    }
                }
            };

        case "RESET_SUMMARY_PATIENTS":
        
            return stateSummaryPatients;

        case "LOAD_MORE_PAGE_SUMMARY_PATIENTS":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };
            
        default: 

            return __state;

    };

};

export default SummaryPatientsReducer;