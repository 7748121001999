import React, { Component } from 'react';
import moment from "moment";

import { v4 as uuidv4 } from 'uuid';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

const initialState = () => {

  return{
    formSearch: {
        date:       "",
        status:     ""
    }
  };

}

const _ref="LogsSQSComponent";

export default class LogsSQSComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    componentWillReceiveProps(nextProps){



    }

    handleOnChangeFilterData = (__e) => {
    
        this.setState({
          formSearch:{
            ...this.state.formSearch,
            [__e.target.name]: __e.target.value
          }
        });
    
      }

    handleOnCallMsgBox = (__button) => {


    }

    renderLogsSQS = (__data) =>{

        let data = [];

        for(let i = 0; i < __data.length; i++){

            let date = moment.utc(__data[i].createdAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite);
        
            data.push(<li key={uuidv4()} style={!func.isEmptyOrNull(__data[i].message) ? {color: "#fd397a", fontWeight: "500"} : {fontWeight: "400"}}>{date + " [" + __data[i].table + "] :  " + (func.isEmptyOrNull(__data[i].message) ? "Success." : __data[i].message)}</li>);

        }

        return data;

    }

      
    render() {

        return(

            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                {
                !configs.scopes.readDashboards
                    ?
                        <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                        <div className="k-iconbox__icon">
                            <div className="k-iconbox__icon-bg"></div>
                            <i className="fa fa-lock"></i>
                        </div>
                        <div className="k-iconbox__title">
                            403 FORBIDDEN
                        </div>
                        <div className="k-iconbox__content">
                            You not enough rights to access to this item
                        </div>
                        </div>
                    :
                        <div style={{listStyleType: "none", padding: "20px", width: "100%", minHeight: "450px", backgroundColor: "rgba(29, 201, 183, 0.1)"}}>
                            {this.renderLogsSQS(this.props.data)}
                        </div>
                }

            </React.Fragment>
        )

    }

}