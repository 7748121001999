import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
  } from 'chart.js';
  
import moment from "moment";
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import * as configs from '../../../../lib/configs';

const optionsChartSummarySystemData = {
    responsive: true,
    scales: {
      y: {
        // min: 0,
        // max: 100,
        beginAtZero: true
      }
    }
};

const tables = ["SQS_file_transfer", "SQS_button", "SQS_message", "SQS_system_check", "SQS_vitals", "SQS_motion"];
const _ref="ChartSummarySystemDataComponent";

export default class ChartSummarySystemDataComponent extends Component{

    constructor(__props) {

      super(__props);

      this.state = {      
          data:{
              labels: [],
              datasets: []       
          },
          formFilterChart:{
            option:       0
          }
      }

      this.chartReference = React.createRef();

    };

    componentWillReceiveProps(nextProps){

        this.setState({
          formFilterChart:{
            option: nextProps.filters.option
          }
        });

        if(nextProps.data !== this.props.data){
          let chart = this.chartReference.current
              chart.data = this.renderSummarySystemData(nextProps.data, parseInt(nextProps.filters.option));
              chart.update();
        }
    }
    
    handleOnCallMsgBox = (__button) => {
  
    }

    renderSummarySystemData = (__data, __option) => {
  
      if(__data.length === 0){
  
        return {
          labels: [],
          datasets: []
        };
  
      }
      
      let start     = 0;
      let end       = 0;
      let collection = {}
      let labels    = {};
      let datasets  = [];

      for(let i = 0; i < tables.length; i++){
        datasets.push(
          {
            label: tables[i],
            backgroundColor: configs.color[i],
            borderColor: configs.borderColor[i],
            borderWidth: 1,
            data:[]
          }
        )
      }

      if(__option === 0){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "week"), "day");

      }

      if(__option === 1){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "week"), "day");

      }

      if(__option === 2){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "month"), "day");

      }

      if(__option === 3){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "month"), "day");

      }

      if(__option === 4){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "year"), "day");

      }

      for(let i = start; i < end; i++){

        let dt = moment.utc().subtract(i, "days").utcOffset(configs.timeZone);
            labels[dt.format(configs.formatDateOutSite)] = dt.format(configs.formatDateOnSite)

      };

      for(let d of __data){

        let createdAt = moment.utc(d["createdAt"], configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateOnSite);

        if(!Object.keys(collection).includes(d["table"])){

          collection[d["table"]] = {};

        }

        collection[d["table"]][createdAt] = d["count"];

      }

      for(let c in collection){

        let arr = Object.keys(collection[c]);
        
        for(let l in labels){

          if(!arr.includes(labels[l])){

            collection[c][labels[l]] = 0;

          }

        }

      }

      for(let da of datasets){
        
        let label = da["label"];

        if(!Object.keys(collection).includes(label)){

          continue;

        }

        let obj = Object.keys(collection[label]).sort().reduce(

          (o, k) => { 

            o[k] = collection[label][k]; 

            return o;

          }, 

          {}

        );

        da["data"] = Object.values(obj)

      }

      let lab = Object.keys(labels).sort().reduce(

        (o, k) => { 

          o[k] = labels[k]; 

          return o;

        }, 

        {}

      );

      return{
          labels: Object.values(lab),
          datasets: datasets
      };
      
    }

    render() {

        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ArcElement,
            PointElement,
            LineElement
        );

        return(

          <React.Fragment>

            <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

             {
              !configs.scopes.readDashboards
                ?
                    <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                    <div className="k-iconbox__icon">
                        <div className="k-iconbox__icon-bg"></div>
                        <i className="fa fa-lock"></i>
                    </div>
                    <div className="k-iconbox__title">
                        403 FORBIDDEN
                    </div>
                    <div className="k-iconbox__content">
                        You not enough rights to access to this item
                    </div>
                    </div>
                :
                <Line ref={this.chartReference} data={this.state.data} options={optionsChartSummarySystemData} height={200} width={600} />
             }
          </React.Fragment>
        )

    }

}