import moment from "moment";
import httpClient from './../lib/httpClient';

import {statePatientDetails} from '../reducers/patientDetailsReducer';

import {getMessages_messagesAction,reset_messagesAction} from './messagesAction';
import {getVitals_vitalsAction, reset_vitalsAction} from './vitalsAction';
import {getSchedules_schedulesAction, reset_schedulesAction} from './schedulesAction';
import {getSummary_chartSummaryComplianceAction, reset_chartSummaryComplianceAction} from './chartSummaryComplianceAction'
import {getNewAbnormal_abnormalFlagsAction, getHistoryAbnormal_abnormalFlagsAction, reset_abnormalFlagsAction} from './abnormalFlagAction';
import {getNewNotifies_notifiesAction, getHistory_notifiesAction, reset_notifiesAction} from './notifiesAction';
import {reset_sendMessageAction} from './sendMessageAction';
import {reset_timerLogsAction} from './timerLogsAction';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';
import func from '../lib/func';

const _ref="PatientDetailsComponent";

export const videoCall_patientDetailsAction = async(__filters) => {

    let query       = [];
    let response    = {};

    for( let k in __filters){

        query.push(k + "=" + encodeURIComponent(__filters[k]));

    }

    response = await httpClient.request(configs.endPoint + "/patients?func=videoCall" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
    
    return response.data;

}

export const complete_patientDetailsAction = (__formData) => async(__dispatch) => {

    try{

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/timerLogs", "POST", __formData, null);

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Success", "Completed !", [{key: "btnOk", title: "Ok"}], "info", null, null));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }
    
}

export const getPatientByMRN_patientDetailsAction = (__filters) => async(__dispatch) => {

    try{

        let query       = [];
        let response    = {};

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));

        };

        __dispatch(showLoading(true));

        response    = await httpClient.request(configs.endPoint + "/patients?func=getPatientByMRN" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
        
        __dispatch(showLoading(false));
        __dispatch(importData_patientDetailsAction(response.data, __filters));

        if(response.data){

            if(!func.isEmptyOrNull(response.data.device)){
                __dispatch(getMessages_messagesAction({target: response.data.device}));
                __dispatch(getVitals_vitalsAction({device: response.data.device}));
                __dispatch(getSchedules_schedulesAction({target: response.data.device}));
                __dispatch(getSummary_chartSummaryComplianceAction(response.data.device));
                __dispatch(getNewAbnormal_abnormalFlagsAction(response.data.device));
                __dispatch(getHistoryAbnormal_abnormalFlagsAction(response.data.device));
                __dispatch(getNewNotifies_notifiesAction(response.data.device));
                __dispatch(getHistory_notifiesAction(response.data.device));
            };

        };

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const getPatientByPersonal_patientDetailsAction = (__filters) => async(__dispatch) => {

    try{

        if(!configs.scopes.readPatients){
            return;
        }

        let query       = [];
        let response    = {};

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));

        }

        __dispatch(showLoading(true));

        response    = await httpClient.request(configs.endPoint + "/patients?func=getPatientByPersonal" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importData_patientDetailsAction(response.data, __filters));

        if(response.data){

            if(!func.isEmptyOrNull(response.data.device)){
                __dispatch(getMessages_messagesAction({target: response.data.device}));
                __dispatch(getVitals_vitalsAction({device: response.data.device}));
                __dispatch(getSchedules_schedulesAction({target: response.data.device}));
                __dispatch(getSummary_chartSummaryComplianceAction(response.data.device));
                __dispatch(getNewAbnormal_abnormalFlagsAction(response.data.device));
                __dispatch(getHistoryAbnormal_abnormalFlagsAction(response.data.device));
                __dispatch(getNewNotifies_notifiesAction(response.data.device));
                __dispatch(getHistory_notifiesAction(response.data.device));
            }

        }

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }
    
}

export const importData_patientDetailsAction = (__data, __filters) => {

    return {
        type: 'IMPORT_PATIENTDETAILS',
        payloads: {
            active:     true,
            data:       __data,
            mrn:        __filters.mrn || "",
            firstName:  __filters.firstName || "",
            lastName:   __filters.lastName || "",
            dob:        __filters.dob || ""
        }
    };

}

export const redirect_patientDetailsAction = (__status, __url) => {

    return {
        type: 'REDIRECT_PATIENTDETAILS',
        payloads: {
            status:     __status,
            url:       __url
        }
    };

}

export const getLastNameByFirstName_patientDetailsAction = (__filters = Object.assign({}, statePatientDetails.lastName.filters), __sorts = Object.assign({}, statePatientDetails.lastName.sorts), __pagination = Object.assign({}, statePatientDetails.lastName.pagination)) => async(__dispatch) => {

    try{

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        response = await httpClient.request(configs.endPoint + "/patients?func=getLastNameByFirstName" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
        
        __dispatch(import_lastName_patientDetailsAction(response.data, response.totalRows, __filters, __sorts, __pagination ));


    } catch (error) {

        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }
    
}

export const import_lastName_patientDetailsAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    let data = [];

    for(let v of __data){
        data.push({key: v, title: v})
    };

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_LASTNAME_PATIENTDETAILS",
        payloads: {
            data:           data,
            firstName:      __filters.firstName || "",
            totalRows:      __totalRows,
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const resetPage_patientDetailsAction = () => (__dispatch) => {

    __dispatch(reset_messagesAction());
    __dispatch(reset_vitalsAction());
    __dispatch(reset_schedulesAction());
    __dispatch(reset_patientDetailsAction());
    __dispatch(reset_timerLogsAction());
    __dispatch(reset_sendMessageAction());
    __dispatch(reset_chartSummaryComplianceAction());
    __dispatch(reset_abnormalFlagsAction());
    __dispatch(reset_notifiesAction());
}

export const reset_patientDetailsAction = () =>{

    return {
        type: "RESET_PATIENTDETAILS",
        payloads: {}
    };

}

