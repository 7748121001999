import moment from "moment";
import * as configs from '../lib/configs';

export const stateLogsSQSReducer = {
    data: [],
    filters: {
        table:          "",
        status:         "Error", 
        fromDate:       moment.utc().utcOffset(configs.timeZone).subtract(1, "week").format(configs.formatDateOutSite),
        toDate:         moment.utc().utcOffset(configs.timeZone).format(configs.formatDateOutSite)
    },
    pagination:{
        begin:          1,
        length:         configs.limitMenu,
        pageSizes:      configs.pageSizes,
        page:           1,
        totalRows:      0
    },
    sorts:{
        sortBy:         "createdAt",
        sortValue:      "DESC"
    }
};

const LogsSQSReducer = (__state = stateLogsSQSReducer, __action) => {

    switch(__action.type){
 
        case "IMPORT_LOGS_SQS":

            return {
                ...__state,
                 data:          __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{
                    ...__state.filters, 
                    table:      __action.payloads.table,
                    status:     __action.payloads.status, 
                    fromDate:   __action.payloads.fromDate,
                    toDate:     __action.payloads.toDate
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_LOGS_SQS":
            
            return stateLogsSQSReducer;

        case "LOAD_MORE_PAGE_LOGS_SQS":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        default: 

            return __state;

    };

};

export default LogsSQSReducer;