
export const stateLayout = {
    property:{
        
    }
};

const LayoutReducer = (__state = stateLayout, __action) => {

    switch(__action.type){

        case "SET_CURRENT_PAGE_LAYOUT":

            return {
                ...__state,
                property:{
                    ...__state.property,
                    pageCurrent: __action.payloads,
                }
            };
            
        default: 

            return __state;

    };

};

export default LayoutReducer;