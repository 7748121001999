import React, { Component } from 'react';

import { NavLink } from "react-router-dom";

import GroupsPatientContainer from '../../../../containers/groupsPatientContainer';
import SummaryPatientsContainer from '../../../../containers/summaryPatientsContainer';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

const initialState = () => {

  return{

  };

}

const _ref="PatientsComponent";

export default class PatientsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentDidMount(){

    this.props.func.OnInit_patientsAction();

  }
  
  componentWillUnmount(){

    this.props.func.resetPage_patientsAction()

  }
  
  handleOnCallMsgBox = (__button) =>{


  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-grid--stretch">
          <div className="k-container k-content-wrapper  k-grid k-grid--ver" id="k_content_wrapper">
            <div className="k-content	k-grid__item k-grid__item--fluid k-grid k-grid--hor" id="k_content">
              <div className="k-content__head	k-grid__item">
                <div className="k-content__head-main">
                  <div className="k-content__head-breadcrumbs">
                    <a className="k-content__head-breadcrumb-home"><i className="flaticon-home-2"></i></a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <a href="#" className="k-content__head-breadcrumb-link">Dashboard</a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <NavLink className="k-content__head-breadcrumb-link" activeClassName="k-content__head-breadcrumb-link--active" to="#">Patients</NavLink>
                    {/* <!-- <span className="k-content__head-breadcrumb-link k-content__head-breadcrumb-link--active">Active link</span> --> */}
                  </div>
                </div>
                <div className="k-content__head-toolbar">
                  
                </div>
              </div>
              <div className="k-content__body	k-grid__item k-grid__item--fluid" id="k_content_body">
                <div className="row">
                  <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                    <div className="k-portlet k-portlet--height-fluid">
                      <div className="k-portlet__body">
                        <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-info" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#summaryPatients" role="tab">
                              <span className="nav-link-icon"><i className="fa fa-chart-bar"></i></span>
                              <span className="nav-link-title">Summary</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#groupsPatient" role="tab">
                              <span className="nav-link-icon"><i className="fa fa-users"></i></span>
                              <span className="nav-link-title">Groups</span>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="summaryPatients" role="tabpanel">
                            <SummaryPatientsContainer/>
                          </div>
                          <div className="tab-pane fade" id="groupsPatient" role="tabpanel">
                            <GroupsPatientContainer/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    )

  }

}