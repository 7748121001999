import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func';
import * as configs from '../../../../lib/configs';

const schema = {
  "fullname":         "^[A-Za-z\\s]+$",
  "email":            "^[a-z0-9\\_\\.]{1,30}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$",
  "customer_id":      "^[A-Za-z0-9\\_\\-]+$",
  "office_phone":     "^[0-9\\s\\+]+$",
  "mobile_phone":     "^[0-9\\s\\+]+$"
}

const initialState = () => {

  return{
    showFormData:   false,
    validation: {
      formData:     {}
    },
    method:             "",
    formData: {
      id:               "",
      customer_id:      "",
      fullname:         "",
      email:            "",
      office_phone:     "",
      mobile_phone:     "",
      groupIds:         [],
      roleIds:          []
    }
  };

}

const _ref="RespondersComponent";

export default class RespondersComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {
      
      this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
      this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);


  }

  handleOnChangePage = (__page) => {

      this.props.pagination.page = __page;
      this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnPreviousPage = () => {

      this.props.pagination.page = this.props.pagination.page - 1;
      this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

      this.props.pagination.page = this.props.pagination.page + 1;
      this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

      this.props.func.loadMorePage_respondersAction(__pagination, __plus);
      
  }

  handleOnSort = (__field) =>{

      if(this.props.data.length === 0){

          return;
    
      };

      let sortValue = "";

      if(__field === this.props.sorts.sortBy){

          if(this.props.sorts.sortValue === "ASC"){
    
              sortValue = "DESC";
    
          };
          
          if(this.props.sorts.sortValue === "DESC"){
    
    
              sortValue = "ASC";
            
          };
    
          this.props.sorts.sortValue = sortValue;

      }else{
    
          this.props.sorts.sortBy       = __field;
          this.props.sorts.sortValue    = "ASC";
    
      };
      
      this.props.func.getResponders_respondersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnYes"){

      this.props.func.delete_respondersAction({id: this.state.formData.id})

    };

    this.handleOnCallFormData(false);

  }

  handleOnCreate = () =>{

    this.handleOnCallFormData(true);
    this.setState({
        method:     "POST"
    });

  }

  handleOnUpdate = (__obj) =>{

      this.handleOnCallFormData(true);
      this.setState({
          method:             "PUT",
          formData:{
            id:               __obj.id,
            customer_id:      __obj.customer_id,
            fullname:         __obj.fullname,
            email:            __obj.email,
            office_phone:     __obj.office_phone,
            mobile_phone:     __obj.mobile_phone,
            groupIds:         __obj.groupIds,
            roleIds:         __obj.roleIds
          }
      });

  }

  handleOnDelete = (__obj) =>{

    this.setState({
        method: "DELETE",
        formData:{
          ...this.state.formData,
          id:         __obj.id
        }
    });

    this.props.func.create_MsgBoxAction(_ref, "Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);

  }

  handleOnChangeFormData = (__e) =>{

    let values = null;

    if(__e.target.name in this.state.validation.formData){

        delete this.state.validation.formData[__e.target.name];

    };

    if(__e.target.name === "groupIds" || __e.target.name === "roleIds"){

      values = Array.from(__e.target.selectedOptions, option => option.value);

    }else{

      values = __e.target.value;

    } 

    this.setState({
        validation: {
            ...this.state.validation,
            formData: {
                ...this.state.validation.formData
            }
        },
        formData:{
            ...this.state.formData,
            [__e.target.name]:  values
        }
    });

  }

  handleOnSubmitFormdata = () =>{

    let error           = {};
    let formData        = Object.assign({}, this.state.formData);

    func.validate(schema, formData, error);

    if(Object.keys(error).length > 0){

        this.setState({
            validation:{
                ...this.state.validation,
                formData: error
            }
        });

        return;

    };


    if(this.state.method === "POST"){
     
      this.props.func.create_respondersAction(formData);

    }else{

        this.props.func.update_respondersAction(formData);

    };

    this.handleOnCallFormData(false);

  }
  
  handleOnCallFormData = (__show) => {

    if(!__show){

      this.handleOnResetComponent();
      
    };

    this.setState({
      showFormData: __show
    })

  }

  handleOnRefreshData = () => {
    
    this.props.func.getResponders_respondersAction();
    this.handleOnResetComponent();

  }

  handleOnResetComponent = () =>{

    this.setState(initialState());

  }

  handleOnUpdateStatus = (__id, __status) => {

    this.props.func.updateStatus_respondersAction({id: __id, status: __status === 1 ? 0 : 1});

  }


  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
        {
          !configs.scopes.readResponders
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
              <React.Fragment>
                <div style={{display: "flex", float: "right", marginBottom: "10px"}}>
                  <button hidden={!configs.scopes.addResponders} onClick={()=>{this.handleOnCreate()}} type="button" style={{marginRight: "10px"}} className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-plus"></i> Add New</button>
                  <button onClick={()=>{this.handleOnRefreshData()}} type="button" style={{}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                </div>

                <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded">
                  <table className="k-datatable__table list-database" style={{marginBottom: "15px", display: "block", minHeight: "300px", overflow: "auto"}}>
                    <thead className="k-datatable__head">
                    <tr className="k-datatable__row">
                        <th onClick={()=>{this.handleOnSort("activate")}}  className={this.props.sorts.sortBy === "activate" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "100px"}}>STATUS {this.props.sorts.sortBy === "activate" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        {/* <th onClick={()=>{this.handleOnSort("activate")}}  className={this.props.sorts.sortBy === "activate" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "150px"}}>CUSTOMER {this.props.sorts.sortBy === "activate" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th> */}
                        <th onClick={()=>{this.handleOnSort("fullName")}}  className={this.props.sorts.sortBy === "fullName" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "150px"}}>FULL NAME {this.props.sorts.sortBy === "fullName" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("email")}}  className={this.props.sorts.sortBy === "email" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "200px"}}>EMAIL {this.props.sorts.sortBy === "email" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("office_phone")}}  className={this.props.sorts.sortBy === "office_phone" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "130px"}}>OFFICE PHONE {this.props.sorts.sortBy === "office_phone" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("mobile_phone")}}  className={this.props.sorts.sortBy === "mobile_phone" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "130px"}}>MOBILE PHONE {this.props.sorts.sortBy === "mobile_phone" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "150px"}}>ACTIONS</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="k-datatable__body">
                      {
                        this.props.data.map((__r)=>(
                          <tr key={__r.id} className="k-datatable__row" style={{left: "0px"}}>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              { __r.activate === 1 ?
                                <span style={{width: "100px"}}>
                                  <span className="k-badge k-badge--accent k-badge--dot"></span>&nbsp;
                                  <span className="k-font-bold k-font-accent">Activated</span>
                                </span>
                                :
                                <span style={{width: "100px"}}>
                                  <span className="k-badge k-badge--danger k-badge--dot"></span>&nbsp;
                                  <span className="k-font-bold k-font-danger">Locked</span>
                                </span>
                              }
                            </td>
                            {/* <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "150px"}}>{__r.customer_id}</span>
                            </td> */}
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "150px"}}>{__r.fullname}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "200px"}}>{__r.email}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "130px"}}>{__r.office_phone}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "130px"}}>{__r.mobile_phone}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "150px"}}>
                                <a hidden={!configs.scopes.editResponders} onClick={()=>{this.handleOnUpdateStatus(__r.id, __r.activate)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                <i className="la la-key"></i>						
                                </a>	
                                <a hidden={!configs.scopes.editResponders} onClick={()=>{this.props.func.getPolicies_managerUsersAction({responderId: __r.id}, "responders")}}className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                <i className="la la-cog"></i>						
                                </a>	
                                <a hidden={!configs.scopes.editResponders} onClick={()=>{this.handleOnUpdate(__r)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                <i className="la la-edit"></i>						
                                </a>						
                                <a hidden={!configs.scopes.delResponders} onClick={()=>{this.handleOnDelete(__r)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                  <i className="la la-trash"></i>						
                                </a>	
                              </span>
                            </td>
                          </tr>
                        ))

                      }
                    </tbody>
                  </table>

                  <PaginationComponent pagination={this.props.pagination} handleLoadMorePage={this.handleOnLoadMorePage} handleChangePage={this.handleOnChangePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                
                </div>

                <Modal show={this.state.showFormData} onHide={()=>{this.handleOnCallFormData(false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-750">
                  <Modal.Header closeButton>
                      <Modal.Title style={{fontSize: "inherit"}}>Create responder</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="row">
                      <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                            <div className="form-group">
                              <label>Customer</label>
                              <select onChange={this.handleOnChangeFormData} value={this.state.formData.customer_id || ""} className="form-control form-control-sm" name="customer_id">
                                <option value="">Please choose . . .</option>
                                {
                                  this.props.customers.map((__r)=>(
                                    <option key={__r.id} value={__r.id}>{__r.fullname}</option>
                                  ))
                                }
                              </select>
                              <span className="k-font-danger">{this.state.validation.formData["customer_id"]}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                            <div className="form-group">
                              <label>Fullname</label>
                              <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.fullname || ""} name="fullname" autoComplete="off"/>
                              <span className="k-font-danger">{this.state.validation.formData["fullname"]}</span>
                            </div>
                            <div className="form-group">
                              <label>Office phone</label>
                              <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.office_phone || ""} name="office_phone" autoComplete="off"/>
                              <span className="k-font-danger">{this.state.validation.formData["office_phone"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "0px"}}>
                              <label>Groups</label>
                              <select onChange={this.handleOnChangeFormData} value={this.state.formData.groupIds || []} name="groupIds" multiple className="form-control" style={{height: "200px"}}>
                                {
                                  this.props.groups.map((__g)=>(
                                    <option key={__g.id} value={__g.id}>{__g.title}</option>
                                  ))
                                }
                              </select>
                              <span className="k-font-danger">{this.state.validation.formData["permission"]}</span>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                            <div className="form-group">
                              <label>Email</label>
                              <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.email || ""} name="email" autoComplete="off"/>
                              <span className="k-font-danger">{this.state.validation.formData["email"]}</span>
                            </div>
                            <div className="form-group">
                              <label>Mobile phone</label>
                              <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.mobile_phone || ""} name="mobile_phone" autoComplete="off"/>
                              <span className="k-font-danger">{this.state.validation.formData["mobile_phone"]}</span>
                            </div>
                            <div className="form-group" style={{marginBottom: "0px"}}>
                              <label>Roles</label>
                              <select onChange={this.handleOnChangeFormData} value={this.state.formData.roleIds || []} name="roleIds" multiple className="form-control" style={{height: "200px"}}>
                                {
                                  this.props.roles.map((__r)=>(
                                    <option key={__r.id} value={__r.id}>{__r.title}</option>
                                  ))
                                }
                              </select>
                              <span className="k-font-danger">{this.state.validation.formData["permission"]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer style={{height: "50px", margin: "auto"}}>
                      <button style={{width: "150px"}} onClick={()=>{this.handleOnSubmitFormdata()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-save"></i> Save</button>
                      <button style={{width: "150px"}} onClick={()=>{this.handleOnCallFormData(false)}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sign-out-alt"></i> Close</button>
                  </Modal.Footer>
                </Modal>
              </React.Fragment>
          }
      </React.Fragment>
    )

  }

}