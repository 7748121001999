import httpClient from '../lib/httpClient';

import {getGroups_groupsResponderAction, reset_groupsResponderAction} from  './groupsResponderAction';
import {getRoles_rolesAction, reset_rolesAction} from  './rolesAction';
import {getPermissions_permissionsAction, reset_permissionsAction} from  './permissionsAction';
import {getResponders_respondersAction, reset_respondersAction} from './respondersAction';
import {getCustomers_customersAction, reset_customersAction} from './customersAction';
import {getScopes_scopesAction, callScopes_scopesAction, reset_scopesAction} from './scopesAction';

import {stateManagerUsers} from '../reducers/managerUsersReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="ManagerUsersComponent";
 
export const OnInit_managerUsersAction = () => async(__dispatch) => {

    try {

        __dispatch(getScopes_scopesAction());

        if(configs.scopes.readRoles){
            __dispatch(getRoles_rolesAction());
        }

        if(configs.scopes.readPermissions){
            __dispatch(getPermissions_permissionsAction());
        }

        if(configs.scopes.readGroupsResponder){
            __dispatch(getGroups_groupsResponderAction());
        }

        if(configs.scopes.readResponders){
            __dispatch(getResponders_respondersAction());
        }

        if(configs.scopes.readCustomers){
            __dispatch(getCustomers_customersAction());
        }

    } catch (error) {

        __dispatch(showLoading(false));

    }

}

export const setPolicies_managerUsersAction = (__formData, __segment) => async(__dispatch) => {

    try{
 
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/" + __segment + "?func=setPolicy", "PUT", __formData, null);

        delete __formData.permissionIds;
        delete __formData.scopeId;

        __dispatch(getPolicies_managerUsersAction(__formData, __segment));
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const getPolicies_managerUsersAction = (__filters = Object.assign({}, stateManagerUsers.filters), __segment) => async(__dispatch) => {

    try {

        let query       = [];
        let response    = {};

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/" +  __segment + "?func=getPolicies" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(import_policies_managerUsersAction(response.data, __segment, __filters));
        __dispatch(callScopes_scopesAction(true));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_policies_managerUsersAction = (__data, __segment, __filters) => {

    return {
        type: "IMPORT_POLICIES_MANAGER_USERS",
        payloads: {
            segment:        __segment,
            data:           __data,
            roleId:         __filters.roleId || "",
            groupId:        __filters.groupId || "",
            responderId:    __filters.responderId || ""
        }
    };

}

export const reset_managerUsersAction = () => {

    return {
        type: "RESET_MANAGER_USERS",
        payloads: {}
    };

}

export const resetPage_managerUsersAction = () => (__dispatch) => {

    __dispatch(reset_groupsResponderAction());
    __dispatch(reset_rolesAction());
    __dispatch(reset_permissionsAction());
    __dispatch(reset_respondersAction());
    __dispatch(reset_customersAction());
    __dispatch(reset_scopesAction());


}
