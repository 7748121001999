import React, { Component } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func';
import * as configs from '../../../../lib/configs';

const schema = {
  "title":   "^[A-Za-z0-9\\s]+$"
}

const initialState = () => {

  return{
    validation: {
      formData:     {}
    },
    formData: {
      id:           "",
      title:        ""
    },
    showFormData: false
  };

}

const _ref="GroupsResponderComponent";

export default class GroupsResponderComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentDidMount(){

    //this.props.func.getCustomers_customersAction()

  }

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnYes"){

      this.props.func.delete_groupsResponderAction({id: this.state.formData.id})

    };
    
    this.handleOnResetComponent();

  }

  handleOnChangeFormData = (__e) =>{

    if(__e.target.name in this.state.validation.formData){

        delete this.state.validation.formData[__e.target.name];

    };

    this.setState({
        validation: {
            ...this.state.validation,
            formData: {
                ...this.state.validation.formData
            }
        },
        formData:{
            ...this.state.formData,
            [__e.target.name]:  __e.target.value
        }
    });

  }

  handleOnSubmitFormdata = () =>{

    let error           = {};
    let formData        = Object.assign({}, this.state.formData);

    func.validate(schema, formData, error);
    
    if(Object.keys(error).length > 0){

        this.setState({
            validation:{
                ...this.state.validation,
                formData: error
            }
        });

        return;

    };

    this.props.func.create_groupsResponderAction(formData);
    this.handleOnResetComponent();

  }

  handleOnResetComponent = () =>{

    this.setState(initialState());

  }

  handleOnUpdateStatus = (__id, __status) => {

    this.props.func.updateStatus_groupsResponderAction({id: __id, status: __status === 1 ? 0 : 1});

  }

  handleOnDelete = (__id) => {
    
    this.setState({
      formData:{
        ...this.state.formData,
        id:         __id
      }
    });

    this.props.func.create_MsgBoxAction(_ref, "Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);

  }

  handleOnCallFormdata = (__show) => {

    this.setState({
      showFormData: __show
    })

  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
          !configs.scopes.readGroupsResponder
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
              <React.Fragment>

                <div className="k-widget-5" style={{marginBottom: "25px", height: "350px"}}>
                  {
                    this.props.data.map((__r)=>(
                        <div key={__r.id} className={__r.activate === 1 ? "k-widget-5__item k-widget-5__item--info" : "k-widget-5__item k-widget-5__item--danger"}>
                        <div className="k-widget-5__item-info">
                          <a href="#" className="k-widget-5__item-title">
                            {String(__r.title).toUpperCase()}
                          </a>
                          <div className="k-widget-5__item-datetime">
                            {__r.createdAt}
                          </div>
                        </div>
                        <div className="k-widget-5__item-check">
                          <span style={{overflow: "visible", position: "relative", width: "100px"}}>					
                            <a hidden={!configs.scopes.editGroupsResponder} onClick={()=>{this.handleOnUpdateStatus(__r.id, __r.activate)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                            {__r.activate === 1  ? 	<i className="la la-key"></i>	: <i className="la la-lock"></i> }							
                            </a>						
                            <a hidden={!configs.scopes.editGroupsResponder} onClick={()=>{this.props.func.getPolicies_managerUsersAction({groupId: __r.id}, "groupsResponder")}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                              <i className="la la-cog"></i>						
                            </a>		
                            <a hidden={!configs.scopes.delGroupsResponder} onClick={()=>{this.handleOnDelete(__r.id)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                              <i className="la la-trash"></i>						
                            </a>					
                          </span>
                        </div>
                      </div>
                    ))
                  }
                </div>

                <OverlayTrigger trigger="focus" show={this.state.showFormData} placement="right" overlay={
                    <Popover id='formGroup' style={{width: "200px", maxWidth: "500px"}}>
                    <Popover.Title  style={{backgroundColor: "#5867dd", color: "white"}} as='h3'>Title
                        <button onClick={()=>{this.handleOnCallFormdata(false)}} type="button" className="close" data-dismiss="alert" aria-label="Close">
                          <span style={{color: "white"}} aria-hidden="true"><i className="la la-close"></i></span>
                        </button>
                      </Popover.Title>
                    <Popover.Content>
                      {/* <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Customer</label>
                          <select onChange={this.handleOnChangeFormData} name="customer_id" value={this.state.formData.customer_id || ""} className="form-control form-control-sm" autoComplete="off">
                              <option key="nnn" value="">Please choose . . .</option>
                              {
                                  this.props.customers.data.map((__r)=>(
                                      <option key={__r.id} value={__r.id}>{__r.fullname}</option>
                                  ))
                              }
                          </select>
                          <span className="k-font-danger">{this.state.validation.formData["customer_id"]}</span>
                      </div> */}
                      <div className="form-group" style={{marginBottom: "15px"}}>
                          <label>Group name</label>
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.title || ""} name="title" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["title"]}</span>
                      </div>
                      <button onClick={()=>{this.handleOnSubmitFormdata()}} type="button" style={{float:"right", marginBottom: "10px", width: "100%"}} className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-paper-plane"></i> Submit</button>
                    </Popover.Content>
                  </Popover>
                }>
                  <button onClick={()=>{this.handleOnCallFormdata(true)}} hidden={!configs.scopes.addGroupsResponder} style={{width: "50%", display: "block", margin: "auto"}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-plus"></i> Add New</button>
                </OverlayTrigger>

              </React.Fragment>
        }

      </React.Fragment>
    )

  }

}