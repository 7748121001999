import * as configs from '../lib/configs';

export const statePermissions = {
    data: [],
    filters: {
        title: ""
    },
    pagination:{
        begin:          1,
        length:         configs.limitMenu,
        pageSizes:      configs.pageSizes,
        page:           1,
        totalRows:      0
    },
    sorts:{
        sortBy:         "",
        sortValue:      ""
    }
};

const PermissionsReducer = (__state = statePermissions, __action) => {

    switch(__action.type){
 
        case "IMPORT_PERMISSIONS":

            return {
                ...__state,
                 data:          __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{
                    ...__state.filters,
                    title: __action.payloads.title
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_PERMISSIONS":
            
            return statePermissions;

        default: 

            return __state;

    };

};

export default PermissionsReducer;