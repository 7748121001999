import React, { Component } from 'react';

import { NavLink } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import MessagesReportsContainer from '../../../../containers/messagesReportsContainer';
import ChartSummaryVitalsContainer from '../../../../containers/chartSummaryVitalsContainer';
import $ from 'jquery';
import func from '../../../../lib/func';
import './reportsStyle.css'

const initialState = () => {

  return{
    validation:{
      formSearch: {}
    },
    formSearch:{
      mrn:    ""
    }
  };

}

const _ref = "ReportsComponent";

export default class ReportsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};
 
  componentDidMount(){

    $.getScript('https://widget.freshworks.com/widgets/500086.js')

  }

  componentWillUnmount(){

    this.props.func.resetPage_reportsAction();

    if(document.getElementById("freshworks-container") !== null){
      document.getElementById("freshworks-container").remove();
    }

    if(document.getElementById("freshworks-frame") !== null){
      document.getElementById("freshworks-frame").remove();
    }
    
  }

  handleOnChangeKeySearch = (__e) =>{
    
    if(__e.target.name in this.state.validation.formSearch){

      delete this.state.validation.formSearch[__e.target.name];

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formFilter: {
            ...this.state.validation.formFilter
        }
      },
      formSearch:{
        ...this.state.formSearch,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handleOnSubmitSearch = () =>{

    if(func.isEmptyOrNull(this.state.formSearch.mrn)){

      return;

    };

    this.props.func.getPatientByMRN_reportsAction(this.state.formSearch);

  }

  handleOnEnterSubmit = (__e) =>{
    
    if (__e.key === 'Enter') {

      this.handleOnSubmitSearch();

    };

  }

  handleOnCallMsgBox = (__button) =>{


  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
         
        <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-grid--stretch">
          <div className="k-container k-content-wrapper  k-grid k-grid--ver" id="k_content_wrapper">
            <div className="k-content	k-grid__item k-grid__item--fluid k-grid k-grid--hor" id="k_content">
              <div className="k-content__head	k-grid__item">
                <div className="k-content__head-main">
                  <div className="k-content__head-breadcrumbs">
                    <a className="k-content__head-breadcrumb-home"><i className="flaticon-home-2"></i></a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <a href="#" className="k-content__head-breadcrumb-link">Dashboard</a>
                    <span className="k-content__head-breadcrumb-separator"></span>
                    <NavLink className="k-content__head-breadcrumb-link" activeClassName="k-content__head-breadcrumb-link--active" to="#">Reports</NavLink>
                  </div>
                </div>
                <div className="k-content__head-toolbar">
                  <div className="k-header__topbar-item_v2 k-header__topbar-item_v2--search">
                    <div className="input-group">
                      <GoogleReCaptchaProvider
                        reCaptchaKey={window.configs.recaptcha_siteKey}
                        scriptProps={{
                          async: true,
                          defer: false,
                          appendTo: 'head',
                          nonce: undefined 
                        }}
                      >
                        <input onChange={this.handleOnChangeKeySearch} onKeyDown={(__e)=>{this.handleOnEnterSubmit(__e)}} value={this.state.formSearch.mrn || ""} name="mrn" style={{borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px"}} type="text" className="form-control form-control-sm" placeholder="Search for..." autoComplete="off"/>
                        <div className="input-group-append">
                          <button onClick={()=>{this.handleOnSubmitSearch()}} style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px"}} className="btn btn-outline-secondary btn-sm" type="button"><i className="flaticon2-search"></i></button>
                        </div>
                    </GoogleReCaptchaProvider>
                    </div>
                  </div>
                </div>
              </div>
              <div className="k-content__body	k-grid__item k-grid__item--fluid">
                <div className="row">
                  <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                    <div className="k-portlet k-portlet--height-fluid">
                      <div className="k-portlet__body ">
                        <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#k_tabs_8_1" role="tab">
                              <span className="nav-link-icon"><i className="fa fa-chart-bar"></i></span>
                              <span className="nav-link-title">Chart</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a id="tabMsg" className="nav-link" data-toggle="tab" href="#k_tabs_8_2" role="tab">
                              <span className="nav-link-icon"><i className="fab fa-facebook-messenger"></i></span>
                              <span className="nav-link-title">Messages</span>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane fade active show" id="k_tabs_8_1" role="tabpanel">

                            <ChartSummaryVitalsContainer/>
          
                          </div>
                          <div className="tab-pane fade" id="k_tabs_8_2" role="tabpanel">	

                            <MessagesReportsContainer/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </React.Fragment>
    )

  }
}