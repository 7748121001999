
export const stateChartSummaryCompliance = {
    data: {},
    filters: {
        device:     "",
        option:     0
    }
};

const ChartSummaryComplianceReducer = (__state = stateChartSummaryCompliance, __action) => {

    switch(__action.type){

        case "IMPORT_SUMMARY_COMPLIANCE":

            return {
                ...__state,
                data:           __action.payloads.data,
                filters:{
                    device:     __action.payloads.device,
                    option:     __action.payloads.option
                }
            };

        case "RESET_SUMMARY_COMPLIANCE":
            
            return stateChartSummaryCompliance;

        default: 

            return __state;

    };

};

export default ChartSummaryComplianceReducer;