import httpClient from '../lib/httpClient';

import {stateChartBatteries} from '../reducers/chartSummaryBatteriesReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="ChartSummaryBatteriesComponent";

export const getSummary_chartSummaryBatteriesAction = (__device, __option = stateChartBatteries.filters.option) => async(__dispatch) => {

    try {

        let response    = {};
        let query       = [];
            query.push("option=" + encodeURIComponent(__option));
            query.push("device=" + encodeURIComponent(__device));
            query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/dashboards?func=getSummaryBatteries"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
   
        __dispatch(import_chartSummaryBatteriesAction(response.data, __device, __option));
        __dispatch(getDevices_chartSummaryBatteriesAction(__device));

        __dispatch(showLoading(false));
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const getDevices_chartSummaryBatteriesAction = (__device) => async(__dispatch) => {

    try {

        let response    = {};
        let query       = [];
            query.push("device=" + encodeURIComponent(__device));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/sensors?func=getDevices"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importDevices_chartSummaryBatteriesAction(response.data));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importDevices_chartSummaryBatteriesAction = (__devices) => {
 
    return {
        type: "IMPORT_DEVICES_SUMMARY_BATTERIES",
        payloads: {
            devices: __devices
        }
    };

}

export const import_chartSummaryBatteriesAction = (__data, __device, __option) => {
 
    return {
        type: "IMPORT_SUMMARY_BATTERIES",
        payloads: {
            device:         __device || "",
            option:         __option || 0,
            data:           __data
        }
    };

}

export const reset_chartSummaryBatteriesAction = () => {

    return {
        type: "RESET_SUMMARY_BATTERIES",
        payloads: {}
    };

}
