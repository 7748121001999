import React, { Component } from 'react';
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement,
    LineElement
  } from 'chart.js';
  
import { Line } from 'react-chartjs-2';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import * as configs from '../../../../lib/configs';
import './reportsStyle.css'

const optionsChartSummaryVitals = {
    responsive: true,
    scales: {
      y: {
        // min: 0,
        // max: 100,
        beginAtZero: true
      }
    }
};

const devices  = {
  oximeter:   ["pulse","spo2"],
  bpm:        ["systolic", "diastolic", "pulse_rate"],
  scale:      ["weight_result"],
  glucometer: ["gluco", "bloodtemp"],
  thermometer:["temperature"]
}
const _ref="ChartSummaryVitalsComponent";

export default class ChartSummaryVitalsComponent extends Component{

    constructor(__props) {

      super(__props);

          this.state = {      
              data:{
                  labels: [],
                  datasets: []       
              },
              formFilterChart:{
                typeDevice:   "oximeter",
                unit:         "",
                option:       0
              }
          }

          this.chartReference = React.createRef();

    };

    componentWillReceiveProps(nextProps){

        this.setState({
          formFilterChart:{
            option:         nextProps.filters.option,
            typeDevice:     nextProps.filters.typeDevice,
            unit:           nextProps.filters.unit
          }
        });

        if(nextProps.data !== this.props.data){

            let chart = this.chartReference.current
                chart.data = this.renderSummaryVitals(nextProps.data, nextProps.filters.typeDevice, parseInt(nextProps.filters.option));
                chart.update();

        }

    }

    handleOnChangeFilterChart = (__e) => {

      let unit = "";

      if(__e.target.name === "typeDevice" && __e.target.value === "scale"){
        unit = "kg"
      };

      if(__e.target.name === "typeDevice" && __e.target.value === "thermometer"){
        unit = "c"
      };

      this.setState({
        formFilterChart:{
          ...this.state.formFilterChart,
          unit: unit,
          [__e.target.name]: __e.target.value
        }
      });
    
    }
  
    handleOnSubmitFilters = () => {
      
      if(window.sessionStorage.getItem("token") !== null){

        this.props.func.getSummaryByUser_chartSummaryVitalsAction(this.props.filters.device, this.state.formFilterChart.typeDevice, this.state.formFilterChart.option, this.state.formFilterChart.unit);

      }else{

        this.props.func.getSummary_chartSummaryVitalsAction(this.props.filters.device, this.state.formFilterChart.typeDevice, this.state.formFilterChart.option, this.state.formFilterChart.unit);
      
      }
    }

    handleOnExportSubmitVitalsChart = async () =>{

      let query     = [];

      for( let k in this.state.formFilterChart){

        query.push(k + "=" + encodeURIComponent(this.state.formFilterChart[k]));

      }

      query.push("allPage=true");
      query.push("device=" + encodeURIComponent(this.props.filters.device));
      query.push("timeZone=" + encodeURIComponent(configs.timeZone));
      query.push("token=" + window.sessionStorage.getItem("tokenClient"));

      window.open(configs.endPoint + "/download/vitals" + "?func=exportSummaryVitals" + (query.length > 0 ? "&" + query.join("&") : ""));
  
  
    }
    
    handleOnCallMsgBox = (__button) => {
  
    }

    renderSummaryVitals = (__data, __typeDevice, __option) => {

      if(__data.length === 0){
  
        return {
          labels: [],
          datasets: []
        };
  
      }

      let start     = 0;
      let end       = 0;
      let collection = {}
      let labels    = {};
      let datasets  = [];

      for(let i = 0; i < devices[__typeDevice].length; i++){
        datasets.push({
          label: devices[__typeDevice][i],
          backgroundColor: configs.color[i],
          borderColor: configs.borderColor[i],
          borderWidth: 1,
          data:[]
        })
      }

      if(__option === 0){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "week"), "day");

      }

      if(__option === 1){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "week"), "day");

      }

      if(__option === 2){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "month"), "day");

      }

      if(__option === 3){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(2, "month"), "day");

      }

      if(__option === 4){

        end = moment.utc().utcOffset(configs.timeZone).diff(moment.utc().utcOffset(configs.timeZone).subtract(1, "year"), "day");

      }

      for(let i = start; i < end; i++){

        let dt = moment.utc().subtract(i, "days").utcOffset(configs.timeZone);
            labels[dt.format(configs.formatDateOutSite)] = dt.format(configs.formatDateOnSite)

      };

      for(let D of __data){

        let createdAt = moment.utc(D["createdAt"], configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateOnSite);

        for(let _d in D){

          if(_d === "createdAt"){

            continue;

          }

          if(!Object.keys(collection).includes(_d)){

            collection[_d] = {};
  
          }
  

          collection[_d][createdAt] = D[_d];

        }

      }

      for(let c in collection){

        let arr = Object.keys(collection[c]);
        
        for(let l in labels){

          if(!arr.includes(labels[l])){

            collection[c][labels[l]] = 0;

          }

        }

      }

      for(let da of datasets){
        
        let label = da["label"];

        if(!Object.keys(collection).includes(label)){

          continue;

        }

        let obj = Object.keys(collection[label]).sort().reduce(

          (o, k) => { 

            o[k] = collection[label][k]; 

            return o;

          }, 

          {}

        );

        da["data"] = Object.values(obj)

      }

      let lab = Object.keys(labels).sort().reduce(

        (o, k) => { 
      
          o[k] = labels[k]; 

          for(let D of __data){

            let createdAt = moment.utc(D["createdAt"], configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateOnSite);
          
            if(o[k]==createdAt){
              if(D["method"]!==undefined && D["method"]!==null && D["method"]!==""){
                o[k] = labels[k] + "\n\nlocation: " + D["method"]; 
              }else{
                o[k] = labels[k]; 
              }
            }

          }
 
          return o;

        }, 

        {}

      );

      return{
          labels: Object.values(lab),
          datasets: datasets
      };
      
    }
    
    render() {

        const keys = this.props.patientDetails.followTask == undefined || this.props.patientDetails.followTask == null ? [] : Object.keys(this.props.patientDetails.followTask)

        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ArcElement,
            PointElement,
            LineElement
        );

        return(

          <React.Fragment>

            <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

            <div className="row" style={{display: "flow-root"}}>
              <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                <div style={{display: "flex", float: "right"}}>
                  <div className="form-group">
                    <select onChange={this.handleOnChangeFilterChart} name="option" value={this.state.formFilterChart.option || 0} className="form-control form-control-sm" style={{width: "150px", float: "right", marginLeft: "10px"}}>
                        <option value="0">01 week</option>
                        <option value="1">02 week</option>
                        <option value="2">01 Month</option>
                        <option value="3">02 Month</option>
                        <option value="4">01 year</option>
                    </select>
                  </div>
                  <div className="form-group" style={{width: "85px", float: "right"}}>
                    <button onClick={()=>{this.handleOnSubmitFilters()}} tabIndex="10" type="button" style={{height: "calc(2.0125rem + 2px)", float: "right"}} className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="la la-filter"></i> Filter</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                <div style={{display: "flex", margin: "auto"}}>
                  <div className="radio-list" style={{display: "inline", margin: "auto", marginBottom: "30px"}}>
                    <label style={{marginRight: "20px"}}>
                      <input disabled={!keys.includes("oximeter")} type="radio" onChange={this.handleOnChangeFilterChart} name="typeDevice" value="oximeter" checked={this.state.formFilterChart.typeDevice === "oximeter"}/> Oximeter
                      <span></span>
                    </label>
                    <label  style={{marginRight: "20px"}}>
                      <input disabled={!keys.includes("bpm")} type="radio" onChange={this.handleOnChangeFilterChart} name="typeDevice" value="bpm" checked={this.state.formFilterChart.typeDevice === "bpm"}/> BPM
                      <span></span>
                    </label>
                    <label  style={{marginRight: "20px"}}>
                      <input disabled={!keys.includes("scale")} type="radio" onChange={this.handleOnChangeFilterChart} name="typeDevice" value="scale" checked={this.state.formFilterChart.typeDevice === "scale"}/> Scale
                      <span></span>
                      <div hidden={this.state.formFilterChart.typeDevice !== "scale"} style={{position: "absolute", marginTop: "5px", width: "200px"}}>
                        <label style={{marginTop: "5px", float: "left", marginRight: "10px"}}>Unit :</label>
                        <select className="form-control form-control-sm" onChange={this.handleOnChangeFilterChart} name="unit" value={this.state.formFilterChart.unit} style={{width: "130px"}}>
                          <option value="kg">Kg</option>
                          <option value="pound">Pound</option>
                        </select>
                      </div>
                    </label>
                    <label style={{marginRight: "20px"}}>
                      <input disabled={!keys.includes("glucometer")}  type="radio" onChange={this.handleOnChangeFilterChart} name="typeDevice" value="glucometer" checked={this.state.formFilterChart.typeDevice === "glucometer"}/> Glucometer
                      <span></span>
                    </label>
                    <label style={{marginRight: "20px"}}>
                      <input disabled={!keys.includes("thermometer")}  type="radio" onChange={this.handleOnChangeFilterChart} name="typeDevice" value="thermometer" checked={this.state.formFilterChart.typeDevice === "thermometer"}/> Thermometer
                      <span></span>
                      <div hidden={this.state.formFilterChart.typeDevice !== "thermometer"} style={{position: "absolute", marginTop: "5px",width: "200px"}}>
                        <label style={{marginTop: "5px", float: "left", marginRight: "10px"}}>Unit :</label>
                        <select className="form-control form-control-sm" onChange={this.handleOnChangeFilterChart} name="unit" value={this.state.formFilterChart.unit} style={{width: "130px"}}>
                          <option value="c">C</option>
                          <option value="f">F</option>
                        </select>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 order-lg-1 order-xl-1">
                <Line ref={this.chartReference} data={this.state.data} options={optionsChartSummaryVitals} height={200} width={600} />
              </div>
            </div>

          </React.Fragment>
        )

    }

}