import React, {Component} from 'react';
import { connect } from 'react-redux';

import GroupsResponderComponent from '../components/dashboards/contents/groupsResponder/groupsResponderComponent';

import {getGroups_groupsResponderAction, create_groupsResponderAction, updateStatus_groupsResponderAction, delete_groupsResponderAction, reset_groupsResponderAction} from '../actions/groupsResponderAction';
import {getPolicies_managerUsersAction} from '../actions/managerUsersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {getCustomers_customersAction} from '../actions/customersAction';

class GroupsResponderContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <GroupsResponderComponent
        	customers={this.props.Customers}
          data = {this.props.GroupsResponder.data} 
          filters = {this.props.GroupsResponder.filters} 
          pagination = {this.props.GroupsResponder.pagination} 
          sorts = {this.props.GroupsResponder.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
		Customers: __state.CustomersReducer,
    GroupsResponder: __state.GroupsResponderReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
      getCustomers_customersAction: (__filters, __sorts, __pagination)=>{
				__dispatch(getCustomers_customersAction(__filters, __sorts, __pagination))
			},
			getGroups_groupsResponderAction: (__filters, __sorts, __pagination) => {
				__dispatch(getGroups_groupsResponderAction(__filters, __sorts, __pagination))
			},
      create_groupsResponderAction: (__formData) => {
				__dispatch(create_groupsResponderAction(__formData))
			},
      updateStatus_groupsResponderAction: (__formData) => {
				__dispatch(updateStatus_groupsResponderAction(__formData))
			},
      delete_groupsResponderAction: (__formData) => {
				__dispatch(delete_groupsResponderAction(__formData))
			},
      reset_groupsResponderAction: () => {
				__dispatch(reset_groupsResponderAction())
			},
      getPolicies_managerUsersAction: (__filters, __segment) => {
				__dispatch(getPolicies_managerUsersAction(__filters, __segment))
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(GroupsResponderContainer);