import httpClient from '../lib/httpClient';

import {stateChartSystemData} from '../reducers/chartSummarySystemDataReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="ChartSummarySystemDataComponent";

export const getSummary_chartSummarySystemDataAction = (__option = stateChartSystemData.filters.option) => async(__dispatch) => {

    try {

        let response    = {};
        let query       = [];
            query.push("option=" + encodeURIComponent(__option));
            query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/dashboards?func=getSummarySystemData"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(import_chartSummarySystemDataAction(response.data, __option));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_chartSummarySystemDataAction = (__data, __option) => {
 
    return {
        type: "IMPORT_SUMMARY_SYSTEM_DATA",
        payloads: {
            option:         __option || 0,
            data:           __data
        }
    };

}

export const reset_chartSummarySystemDataAction = () => {

    return {
        type: "RESET_SUMMARY_SYSTEM_DATA",
        payloads: {}
    };

}