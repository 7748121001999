import moment from "moment";
import httpClient from './../lib/httpClient';

import {stateTimerLogs} from '../reducers/timerLogsReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="TimerLogsComponent";

export const dowloadFile_timerLogsAction = async(__filters) => {

    let query       = [];

    for( let k in __filters){

        query.push(k + "=" + encodeURIComponent(__filters[k]));

    }

    query.push("timeZone=" + encodeURIComponent(configs.timeZone));

    return await httpClient.dowloadFile(configs.endPoint + "/timerLogs?func=shortBill" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

}

export const getLogs_timerLogsAction = (__filters = Object.assign({}, stateTimerLogs.filters), __sorts = Object.assign({}, stateTimerLogs.sorts), __pagination = Object.assign({}, stateTimerLogs.pagination)) => async(__dispatch) => {

    try{

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));
        
        response = await httpClient.request(configs.endPoint + "/timerLogs?func=getTimerLogs" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
     
        __dispatch(showLoading(false));
        __dispatch(importData_TimerLogsAction(response.data, response.categories, response.months, response.totalTimes, response.totalRows, __filters, __sorts, __pagination));
  
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref,"Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));
        
    }

}

export const importData_TimerLogsAction = (__data, __categories, __months, __totalTimes, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage   = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage   = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: 'IMPORT_DATA_TIMERLOGS',
        payloads: {
            data:           __data,
            categories:     __categories,
            months:         __months,
            totalTimes:     __totalTimes,
            totalRows:      __totalRows,
            mrn:            __filters.mrn || "",
            month:          __filters.month || "",
            fromDate:       __filters.fromDate || "",
            toDate:         __filters.toDate || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const loadMorePage_timerLogsAction = (__pagination, __plus) => {

    let totalPage   = Math.ceil(__pagination.totalRows / __pagination.pageSizes);
    let begin       = 1;

    if(__plus){ 

        begin = __pagination.begin + __pagination.length;
        begin = begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : begin;

    }else{

        begin = __pagination.begin - __pagination.length;
        begin = begin < 1 ? 1 : begin;

    };

    return {
        type: "LOAD_MORE_PAGE_TIMERLOGS",
        payloads: begin
    };

}

export const create_timerLogsAction = (__formData) => async(__dispatch) => {

    try{
 
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/timerLogs", "POST", __formData, null);
        
        __dispatch(getLogs_timerLogsAction({mrn:__formData.mrn, month: moment.utc(__formData.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format("YYYY-MM")}));
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref,"Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const update_timerLogsAction = (__formData) => async(__dispatch) => {

    try{

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/timerLogs", "PUT", __formData, null);

        __dispatch(getLogs_timerLogsAction({mrn:__formData.mrn, month: moment.utc(__formData.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format("YYYY-MM")}));
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref,"Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const delete_timerLogsAction = (__formData) => async(__dispatch) => {

    try{

        __dispatch(showLoading(true));
        
        await httpClient.request(configs.endPoint + "/timerLogs", "DELETE", __formData, null);
        
        __dispatch(getLogs_timerLogsAction({mrn:__formData.mrn, month: moment.utc(__formData.date, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format("YYYY-MM")}));
        __dispatch(showLoading(false));
        
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref,"Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const callTimerLogs_timerLogsAction = (__show) =>{
    return {
        type: "SHOW_TIMERLOGS",
        payloads: {
            show: __show
        }
    }
}

export const reset_timerLogsAction = () => {

    return {
        type: "RESET_TIMERLOGS",
        payloads: {}
    };

}