import httpClient from '../lib/httpClient';
 
import {getMessages_messagesReportsAction, reset_messagesReportsAction} from '../actions/messagesReportsAction';
import {getSummary_chartSummaryVitalsAction, reset_chartSummaryVitalsAction} from '../actions/chartSummaryVitalsAction';
import {reset_patientDetailsAction} from '../actions/patientDetailsAction';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';
import func from '../lib/func';

const _ref="ReportsComponent";

export const getPatientByMRN_reportsAction = (__filters) => async(__dispatch) => {

    try{

        let query       = [];
        let response    = {};
        let token       = await httpClient.grecaptcha("reports");
        
        window.sessionStorage.setItem("tokenClient", token);

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        }

        query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        query.push("token=" + token);

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/client/patients?func=getPatientByMRN" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importData_reportsAction(response.data, __filters));
  
        if(response.data){

            if(!func.isEmptyOrNull(response.data.device)){
                __dispatch(getMessages_messagesReportsAction({target: response.data.device}));
                __dispatch(getSummary_chartSummaryVitalsAction(response.data.device));
            };

        };

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importData_reportsAction = (__data, __filters) => {

    return {
        type: 'IMPORT_PATIENTDETAILS',
        payloads: {
            data:       __data,
            mrn:        __filters.mrn || "",
            firstName:  __filters.firstName || "",
            lastName:   __filters.lastName || "",
            dob:        __filters.dob || ""
        }
    };

}

export const resetPage_reportsAction = () => (__dispatch) => {

    __dispatch(reset_messagesReportsAction());
    __dispatch(reset_chartSummaryVitalsAction());
    __dispatch(reset_patientDetailsAction());

}

