
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ManagerUsersComponent from '../components/dashboards/contents/managerUsers/managerUsersComponent';

import {OnInit_managerUsersAction, resetPage_managerUsersAction} from '../actions/managerUsersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ManagerUsersContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<ManagerUsersComponent
					func = {this.props.func}
				/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{
		ManagerUsers: __state.ManagerUsersReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			OnInit_managerUsersAction: () => {
				__dispatch(OnInit_managerUsersAction())
			},
			resetPage_managerUsersAction: () => {
				__dispatch(resetPage_managerUsersAction())
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ManagerUsersContainer);