import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import AppReducer from './AppReducer';


const store = createStore(
  AppReducer,
  applyMiddleware(thunk)
);

ReactDOM.render(


  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById('root')

);


reportWebVitals();
