

export const stateDashboards = {

    filters: {
        device:       ""
    }

};

const DashboardsReducer = (__state = stateDashboards, __action) => {

    switch(__action.type){

        case "IMPORT_DASHBOARDS":

            return {
                ...__state,
                filters:{
                    device:         __action.payloads.device
                }
            };
            
        case "RESET_DASHBOARDS":
            
            return stateDashboards;

        default: 

            return __state;

    };

};

export default DashboardsReducer;