import React, {Component} from 'react';
import { connect } from 'react-redux';

import BatteriesComponent from '../components/dashboards/contents/batteries/batteriesComponent';

import {getBatteries_batteriesAction, loadMorePage_batteriesAction, reset_batteriesAction} from '../actions/batteriesAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class BatteriesContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <BatteriesComponent
          data = {this.props.Batteries.data} 
          filters = {this.props.Batteries.filters} 
          pagination = {this.props.Batteries.pagination} 
          sorts = {this.props.Batteries.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    Batteries: __state.BatteriesReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getBatteries_batteriesAction: (__filters, __sorts, __pagination) => {
				__dispatch(getBatteries_batteriesAction(__filters, __sorts, __pagination))
			},
			loadMorePage_batteriesAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_batteriesAction(__pagination, __plus))
			},
      reset_batteriesAction: () => {
				__dispatch(reset_batteriesAction())
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(BatteriesContainer);