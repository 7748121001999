import httpClient from '../lib/httpClient';

import {stateChartSummaryVitals} from '../reducers/chartSummaryVitalsReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="ChartSummaryVitalsComponent";

export const getSummary_chartSummaryVitalsAction = (__device, __typeDevice, __option, __unit) => async(__dispatch) => {

    try {

        __typeDevice = __typeDevice || stateChartSummaryVitals.filters.typeDevice;
        __option = __option || stateChartSummaryVitals.filters.option;
        __unit = __unit || stateChartSummaryVitals.filters.unit;

        let response    = {};
        let query       = [];
            query.push("device=" + encodeURIComponent(__device));
            query.push("typeDevice=" + encodeURIComponent(__typeDevice));
            query.push("option=" + encodeURIComponent(__option));
            query.push("unit=" + encodeURIComponent(__unit));
            query.push("timeZone=" + encodeURIComponent(configs.timeZone));
            query.push("token=" + window.sessionStorage.getItem("tokenClient"));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/client/vitals?func=summaryVitals"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importSummaryVitals_chartSummaryVitalsAction(response.data, __device, __typeDevice, __option, __unit));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const getSummaryByUser_chartSummaryVitalsAction = (__device, __typeDevice, __option, __unit) => async(__dispatch) => {

    try {
        __typeDevice = __typeDevice || stateChartSummaryVitals.filters.typeDevice;
        __option = __option || stateChartSummaryVitals.filters.option;
        __unit = __unit || stateChartSummaryVitals.filters.unit;

        let response    = {};
        let query       = [];
            query.push("device=" + encodeURIComponent(__device));
            query.push("typeDevice=" + encodeURIComponent(__typeDevice));
            query.push("option=" + encodeURIComponent(__option));
            query.push("unit=" + encodeURIComponent(__unit));
            query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/vitals?func=summaryVitals"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importSummaryVitals_chartSummaryVitalsAction(response.data, __device, __typeDevice, __option, __unit));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importSummaryVitals_chartSummaryVitalsAction = (__data,  __device, __typeDevice, __option, __unit) => {
 
    return {
        type: "IMPORT_SUMMARY_VITALS",
        payloads: {
            device:         __device || "",
            typeDevice:     __typeDevice || "",
            unit:           __unit || "",
            option:         __option || 0,
            data:           __data
        }
    };

}

export const reset_chartSummaryVitalsAction = () => {

    return {
        type: "RESET_SUMMARY_VITALS",
        payloads: {}
    };

}
