import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import * as configs from '../../../../lib/configs';

const initialState = () => {

  return{

  };

}

const _ref="BatteriesComponent"

export default class BatteriesComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentWillReceiveProps(nextProps){

  }

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getBatteries_batteriesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

      this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
      this.props.func.getBatteries_batteriesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {

      this.props.pagination.page = __page;
      this.props.func.getBatteries_batteriesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnPreviousPage = () => {

      this.props.pagination.page = this.props.pagination.page - 1;
      this.props.func.getBatteries_batteriesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

      this.props.pagination.page = this.props.pagination.page + 1;
      this.props.func.getBatteries_batteriesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

      this.props.func.loadMorePage_batteriesAction(__pagination, __plus);

  }

  handleOnCallMsgBox = (__button) => {



  }

  renderBatteries = (__data) =>{
    return (__data.map((__r)=>{
      let percent = __r.percent < 0 ? 0 : __r.percent
      return(

        <div key={uuidv4()} className="k-widget-18__item">
          <div className={__r.flag > 0 || percent === 0  ? "k-widget-18__legend k-bg-danger"  : "k-widget-18__legend k-bg-brand"}></div>
          <div className="k-widget-18__desc">
            <a href="">
              <div className="k-widget-18__title">
              {__r.device}
              </div>
            </a>
            <div className="k-widget-18__desc">
              {__r.mac}
            </div>
          </div>
          <div className="k-widget-18__orders">
            <div className="progress" style={{width: "100px"}}>
              <div className={__r.flag > 0 || percent === 0 ? "progress-bar bg-danger" :"progress-bar bg-info"} role="progressbar" style={{width: percent + "%"}}>{percent + "%"}</div>
            </div>
          </div>
        </div>
        // <div className="k-widget-1__item">
        //   <div className="k-widget-1__item-info">
        //     <a href="#" className="k-widget-1__item-title">
        //       {__r.device}
        //     </a>
        //     <div className="k-widget-1__item-desc">{"Mac: " + __r.mac} <br></br> {"Model: " + __r.model}</div>
        //   </div>
        //   <div className="k-widget-1__item-stats">
        //     <div className="k-widget-1__item-value">{percent}%</div>
        //     <div className="k-widget-1__item-progress">
        //       <div className="progress">
        //         <div className={__r.flag > 0 ? "progress-bar bg-danger" :"progress-bar bg-info"} role="progressbar" style={{width: percent + "%"}}></div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      );

      }));
  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
            !configs.scopes.readDashboards
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
              <React.Fragment>
                <div className="k-widget-18 tab-scroll" style={{marginBottom: "25px", height: "400px", overflow: "auto", overflowX: "hidden"}}>
                  {this.renderBatteries(this.props.data)}
                </div>

                <div style={{display: "flex", width: "100%"}}>
                  <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                </div>
              </React.Fragment>
          }

      </React.Fragment>
    )

  }

}