
import React, { Component } from 'react';

import { connect } from 'react-redux';

import NotifiesComponent from '../components/dashboards/contents/notifies/notifiesComponent';

import { 
	getNewNotifies_notifiesAction, 
	getHistory_notifiesAction,
	updateStatus_notifiesAction,
	deleteNotify_notifiesAction,
	reset_notifiesAction
} from '../actions/notifiesAction';

import {create_MsgBoxAction} from '../actions/msgBoxAction';

class NotifiesContainer extends Component{

	render() {
 
		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<NotifiesComponent 
				    device = {this.props.notifiesReducer.device}
					history = {this.props.notifiesReducer.history} 
					new = {this.props.notifiesReducer.new} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		notifiesReducer: __state.NotifiesReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getNewNotifies_notifiesAction: (__device, __filters, __sorts, __pagination) => {
				__dispatch(getNewNotifies_notifiesAction(__device, __filters, __sorts, __pagination));
			},
			getHistory_notifiesAction: (__device, __filters, __sorts, __pagination) => {
				__dispatch(getHistory_notifiesAction(__device, __filters, __sorts, __pagination));
			},
			updateStatus_notifiesAction: (__formData) => {
				__dispatch(updateStatus_notifiesAction(__formData));
			},
			deleteNotify_notifiesAction: (__formData) => {
				__dispatch(deleteNotify_notifiesAction(__formData));
			},
			reset_notifiesAction: () => {
				__dispatch(reset_notifiesAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(NotifiesContainer);