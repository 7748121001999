import httpClient from '../lib/httpClient';

import {stateChartSummaryCompliance} from '../reducers/chartSummaryComplianceReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="ChartSummaryComplianceComponent";

export const getSummary_chartSummaryComplianceAction = (__device, __option = stateChartSummaryCompliance.filters.option) => async(__dispatch) => {

    try {

        let response    = {};
        let query       = [];
            query.push("device=" + encodeURIComponent(__device));
            query.push("option=" + encodeURIComponent(__option));
            query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=summaryOfCompliance"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
        
        __dispatch(showLoading(false));
        __dispatch(import_chartSummaryComplianceAction(response.data, __device, __option));
    
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_chartSummaryComplianceAction = (__data, __device, __option) => {
 
    return {
        type: "IMPORT_SUMMARY_COMPLIANCE",
        payloads: {
            device:     __device || "",
            option:     __option || 0,
            data:       __data
        }
    };

}

export const reset_chartSummaryComplianceAction = () => {

    return {
        type: "RESET_SUMMARY_COMPLIANCE",
        payloads: {}
    };

}
