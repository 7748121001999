import { combineReducers } from 'redux';

import SummaryPatientsReducer from './reducers/summaryPatientsReducer';
import TimerLogsReducer from './reducers/timerLogsReducer';
import GroupsPatientReducer from './reducers/groupsPatientReducer';
import SendMessageReducer from './reducers/sendMessageReducer';
import PatientDetailsReducer from './reducers/patientDetailsReducer';
import MessagesReducer from './reducers/messagesReducer';
import VitalsReducer from './reducers/vitalsReducer';
import SchedulesReducer from './reducers/schedulesReducer';
import NotifiesReducer from './reducers/notifiesReducer';
import AbnormalFlagsReducer from './reducers/abnormalFlagsReducer';
import MessagesReportsReducer from './reducers/messagesReportsReducer';
import LoadingReducer from './reducers/loadingReducer';
import ChartSummaryVitalsReducer from './reducers/chartSummaryVitalsReducer';
import ChartSummaryComplianceReducer from './reducers/chartSummaryComplianceReducer';
import QuickPanelReducer from './reducers/quickPanelReducer';
import LayoutReducer from './reducers/layoutReducer';
import DashboardsReducer from './reducers/dashboardsReducer';
import BatteriesReducer from './reducers/batteriesReducer';
import SystemAbnormalsReducer from './reducers/systemAbnormalsReducer';
import SystemStatusReducer from './reducers/systemStatusReducer';
import SystemMonitorReducer from './reducers/systemMonitorReducer';
import LogsSQSReducer from './reducers/logsSQSReducer';
import ChartSystemDataReducer from './reducers/chartSummarySystemDataReducer';
import ChartSummaryBatteriesReducer from './reducers/chartSummaryBatteriesReducer';
import ManagerUsersReducer from './reducers/managerUsersReducer';
import RolesReducer from './reducers/rolesReducer';
import GroupsResponderReducer from './reducers/groupsResponderReducer';
import PermissionsReducer from './reducers/permissionsReducer';
import CustomersReducer from './reducers/customersReducer';
import RespondersReducer from './reducers/respondersReducer';
import ScopesReducer from './reducers/scopesReducer';
import MsgBoxReducer from './reducers/msgBoxReducer';

const AppReducer = combineReducers({
    SummaryPatientsReducer,
    TimerLogsReducer,
    GroupsPatientReducer,
    SendMessageReducer,
    PatientDetailsReducer,
    MessagesReducer,
    VitalsReducer,
    SchedulesReducer,
    NotifiesReducer,
    AbnormalFlagsReducer,
    MessagesReportsReducer,
    LoadingReducer,
    ChartSummaryVitalsReducer,
    ChartSummaryComplianceReducer,
    QuickPanelReducer,
    LayoutReducer,
    DashboardsReducer,
    BatteriesReducer,
    SystemAbnormalsReducer,
    SystemStatusReducer,
    SystemMonitorReducer,
    LogsSQSReducer,
    ChartSystemDataReducer,
    ChartSummaryBatteriesReducer,
    ManagerUsersReducer,
    RolesReducer,
    GroupsResponderReducer,
    PermissionsReducer,
    CustomersReducer,
    RespondersReducer,
    ScopesReducer,
    MsgBoxReducer
});

export default AppReducer;