
import React, { Component } from 'react';

import { connect } from 'react-redux';

import GroupsPatientComponent from '../components/dashboards/contents/groupsPatient/groupsPatientComponent';

import { 
	delete_groupsPatientAction, 
	create_groupsPatientAction, 
	update_groupsPatientAction,
	getGroups_groupsPatientAction,  
	loadMorePage_groupsPatientAction, 
	reset_groupsPatientAction,
	getPatientsOutGroup_groupsPatientAction,
	getPatientsInGroup_groupsPatientAction,
	mapPatientsToGroup_groupsPatientAction
} from '../actions/groupsPatientAction';
import {getCustomers_customersAction} from '../actions/customersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {callSendMessage_sendMessageAction} from '../actions/sendMessageAction';

class GroupsPatientContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<GroupsPatientComponent 
					customers={this.props.Customers}
					data = {this.props.groupsPatient.data} 
					filters = {this.props.groupsPatient.filters} 
					pagination = {this.props.groupsPatient.pagination} 
					patientsInGroup = {this.props.groupsPatient.patientsInGroup}
					patientsOutGroup = {this.props.groupsPatient.patientsOutGroup}
					sorts = {this.props.groupsPatient.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

}; 

const mapState = __state => {

	return{
		groupsPatient: __state.GroupsPatientReducer,
		Customers: __state.CustomersReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getCustomers_customersAction: (__filters, __sorts, __pagination)=>{
				__dispatch(getCustomers_customersAction(__filters, __sorts, __pagination))
			},
			getGroups_groupsPatientAction: (__filters, __sorts, __pagination) =>{
				__dispatch(getGroups_groupsPatientAction(__filters, __sorts, __pagination))
			},
			loadMorePage_groupsPatientAction: (__pagination, __plus)=> {
				__dispatch(loadMorePage_groupsPatientAction(__pagination, __plus));
			},
			delete_groupsPatientAction: (__formData) => {
				__dispatch(delete_groupsPatientAction(__formData))
			}, 
			create_groupsPatientAction: (__formData) => {
				__dispatch(create_groupsPatientAction(__formData))
			}, 
			update_groupsPatientAction: (__formData) => {
				__dispatch(update_groupsPatientAction(__formData))
			},
			getPatientsOutGroup_groupsPatientAction: (__filters, __sorts, __pagination) =>{
				__dispatch(getPatientsOutGroup_groupsPatientAction(__filters, __sorts, __pagination))
			},
			getPatientsInGroup_groupsPatientAction: (__filters, __sorts, __pagination) =>{
				__dispatch(getPatientsInGroup_groupsPatientAction(__filters, __sorts, __pagination))
			},
			mapPatientsToGroup_groupsPatientAction: (__formData) =>{
				__dispatch(mapPatientsToGroup_groupsPatientAction(__formData))
			},
			reset_groupsPatientAction:()=>{
				__dispatch(reset_groupsPatientAction());
			},
			////////////////////////////////////////////

			callSendMessage_sendMessageAction: (__show, __sendGroup) => {
				__dispatch(callSendMessage_sendMessageAction(__show, __sendGroup))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
			
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(GroupsPatientContainer);