import React, { Component } from 'react';
import moment from "moment";


import { v4 as uuidv4 } from 'uuid';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

import './abnormalFlagsStyle.css';

const initialState = () => {

  return{
    callAlertAbnormal:  false,
    new:                [],
    history:            []
  };

}

const _ref="AbnormalFlagsComponent";

export default class AbnormalFlagsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentWillReceiveProps(nextProps){

    this.renderAbnormalFlag(nextProps.new.data, false);
    this.renderAbnormalFlag(nextProps.history.data, true);

  }

  handleOnCallMsgBox = (__button) => {


  }

  handelOnCallAlertAbnormal = (__data) => {
    this.props.func.showSubPanel_quickPanelAction(true, __data);
  }
 
  handleSubmitFormData = (__id, __history) => {

    if(!__history){

      this.props.func.updateStatus_abnormalFlagsAction({id: __id, device: this.props.device});

      return;

    };

    this.props.func.deleteHistory_abnormalFlagsAction({id: __id, device: this.props.device});

  }

  renderAbnormalFlag = (__data, __history) => {   

    let arr = [];
   
    for(let r of __data){

      let childEl = [];

      for(let o in r["contents"]){

        childEl.push(
            <div key={uuidv4()} className="k-timeline__item k-timeline__item--warning">
              <div className="k-timeline__item-section">
                <div className="k-timeline__item-section-border">
                  <div className="k-timeline__item-section-icon">
                    <i className="flaticon2-attention-exclamation-triangular-signal k-font-warning"></i>
                  </div>
                </div>
                <span className="k-timeline__item-datetime">{String(o).toUpperCase()}</span>
              </div>
                  {
                    Object.keys(r["contents"][o]).map((__k)=>{

                      let level = r["contents"][o][__k]["high"];

                      return(
                        <ul key={uuidv4()} className="k-nav" style={{marginLeft: "4.4rem"}}>
                          <li className="k-nav__section k-nav__section--first">
                            <span className={parseInt(level)>0 ? "k-nav badge badge-danger" : "k-nav__section-text"}>{__k}</span>
                          </li>
                          <li className="k-nav__item">
                            <a href="" className="k-nav__link">
                                  <span className="k-nav__link-bullet k-nav__link-bullet--line"><span></span></span>
                                  <span className="k-nav__link-text">Date : {moment.utc(r["contents"][o][__k]["date"]).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
                              </a>
                          </li>
                          <li className="k-nav__item"><a href="" className="k-nav__link">
                                  <span className="k-nav__link-bullet k-nav__link-bullet--line"><span></span></span>
                                  <span className="k-nav__link-text">Value : {r["contents"][o][__k]["value"] + " " + r["contents"][o][__k]["operator"] + " " + r["contents"][o][__k]["quota"]}</span>
                              </a>
                          </li>
                          <li className="k-nav__item"><a href="" className="k-nav__link">
                                  <span className="k-nav__link-bullet k-nav__link-bullet--line"><span></span></span>
                                  <span className="k-nav__link-text">Level alert : {r["contents"][o][__k]["high"]}</span>
                              </a>
                          </li>
                          <li className="k-nav__item"><a href="" className="k-nav__link">
                                  <span className="k-nav__link-bullet k-nav__link-bullet--line"><span></span></span>
                                  <span className="k-nav__link-text">By ruleId : {r["contents"][o][__k]["ruleId"]}</span>
                              </a>
                          </li>
                        </ul>
                      )
                    })
                  }
              <div className="k-timeline__item-info">
                PSD, Sketch, AJ
              </div>
            </div>
        );

      };

      arr.push(
        <a key={uuidv4()} className="k-notification-v2__item">
          <div onClick={()=>this.handleSubmitFormData(r["id"], __history)} style={{cursor: "pointer"}} className="k-notification-v2__item-icon">
            <i className={(!__history ? "fa fa-bell k-font-primary" : "flaticon-delete k-font-primary")}></i>
          </div>
            <div className="k-notification-v2__item-wrapper">

                <div onClick={()=>{this.handelOnCallAlertAbnormal(childEl)}} style={{cursor: "default"}} className="k-notification-v2__item-title">
                  {
                    moment.utc(r["createdAt"]).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)
                  }
                </div>

              <div className="k-notification-v2__item-desc">
                {
                  func.noteDateTime(r["createdAt"])
                }
              </div>
            </div>
        </a>
      );



    };

    if(__history){

      this.setState({
        history:arr
      });

    }else{

      this.setState({
        new:arr
      });

    }

}

render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
          !configs.scopes.readPatients
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
            <React.Fragment>
              <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#abnormalFlag_new" role="tab">
                    <span className="nav-link-icon"><i className="fa fa-rss"></i></span>
                    <span className="nav-link-title">New</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#abnormalFlag_history" role="tab">
                    <span className="nav-link-icon"><i className="fa fa-history"></i></span>
                    <span className="nav-link-title">History</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="abnormalFlag_new" role="tabpanel">
                  <div className="container-table">
                    <div className="k-notification-v2 default-scroll">
                      {this.state.new}
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="abnormalFlag_history" role="tabpanel">
                  <div className="container-table">
                    <div className="k-notification-v2 default-scroll">
                      {this.state.history}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
        }
      </React.Fragment>
    )

  }

}