import React, {Component} from 'react';
import { connect } from 'react-redux';

import SystemAbnormalsComponent from '../components/dashboards/contents/systemAbnormals/systemAbnormalsComponent';

import {getAbnormal_systemAbnormalAction, loadMorePage_systemAbnormalAction, reset_systemAbnormalAction} from '../actions/systemAbnormalsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class SystemAbnormalsContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <SystemAbnormalsComponent
            data = {this.props.SystemAbnormals.data} 
            filters = {this.props.SystemAbnormals.filters} 
            pagination = {this.props.SystemAbnormals.pagination} 
            sorts = {this.props.SystemAbnormals.sorts} 
            func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    SystemAbnormals: __state.SystemAbnormalsReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getAbnormal_systemAbnormalAction: (__filters, __sorts, __pagination) => {
				__dispatch(getAbnormal_systemAbnormalAction(__filters, __sorts, __pagination))
			},
			loadMorePage_systemAbnormalAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_systemAbnormalAction(__pagination, __plus))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
      reset_systemAbnormalAction: () => {
				__dispatch(reset_systemAbnormalAction())
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SystemAbnormalsContainer);