
export const stateLoading = {

    status: false

};

const LoadingReducer = (__state = stateLoading, __action) => {

    switch(__action.type){

        case "SHOW_LOADING":

            return {status: __action.payloads};

        default: 

            return __state;

    };

};

export default LoadingReducer;