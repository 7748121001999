
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';

const initialState = () => {
    return{
        validation:     {
            formData:   {}
        },
        formData:{
            messages:   "",
            option:     "email"
        }
    }
}

const _ref="CallUrgenTimeComponent";

export default class CallUrgenTimeComponent extends Component{

    constructor(__props) {

		super(__props);

        this.state = initialState();

	};
          
    componentWillReceiveProps(nextProps){

        if(!nextProps.show){

            this.setState(initialState());

        }

    }

    handleOnChangeformData = (__e) => {

        if(__e.target.name in this.state.validation.formData){

            delete this.state.validation.formData[__e.target.name];

        };

        this.setState({
            validation: {
                ...this.state.validation,
                formData: {
                    ...this.state.validation.formData
                }
            },
            formData:{
                ...this.state.formData,
                [__e.target.name]: __e.target.value
            }
        });

    }

    handleOnSubmit = async () => {
          
        let formData = this.state.formData;
 
        if(formData.option === "email"){
            
            if(!new RegExp("^[a-z0-9\\-\\_\\.]{1,30}@[a-z0-9\\-\\_]{2,}(\\.[a-z0-9]{2,4}){1,2}$").test(String(this.props.email))){

                this.setState({
                    validation: {
                    ...this.state.validation,
                    formData: {
                        ...this.state.validation.formData,
                        email: "Invalid"
                    }
                    }
                });

                return;

            };

            formData["email"] = this.props.email;

        };

        if(formData.option === "mobile"){
    
            if(!new RegExp("^\\+[0-9]+$").test(String(this.props.mobile))){
            
                this.setState({
                    validation: {
                    ...this.state.validation,
                    formData: {
                        ...this.state.validation.formData,
                        mobile: "Invalid"
                    }
                    }
                });

                return;

            };

            let cleannum    = this.props.mobile.replace(new RegExp("[^0-9]","g"), "");
            let lennum      = cleannum.length;
            let firstdigit  = cleannum.substring(0,1);
            let compresult  = firstdigit.localeCompare("1");
            let prefix      = "";

            if (lennum === 10) {
                
                if (compresult === 0) {

                    prefix = "+";

                } else {

                    prefix = "+1";

                }

            } else {

                prefix = "+";

            };

            formData["mobile"] = prefix + cleannum;

        };
    
        if(!new RegExp("^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$").test(formData.messages)){
        
            this.setState({
                validation: {
                ...this.state.validation,
                formData: {
                    ...this.state.validation.formData,
                    messages: "Invalid"
                }
                }
            });

            return;

        };

        window.open(await this.props.func.videoCall_patientDetailsAction(formData), "_blank");
        
        this.props.close(false);

    }

    handleOnCallMsgBox = (__button) =>{


    }
    
	render() {

		return (

            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                <Modal show={this.props.show} onHide={this.props.close} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-300">
                    <Modal.Header closeButton>
                        <Modal.Title style={{fontSize: "inherit"}}>Start Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group" style={{marginBottom: "1rem", height: "30px"}}>
                            <label style={{float: "left", width: "20%"}}>Type:</label>
                            <div style={{float: "left", width: "70%"}} className="k-radio-inline">
                                <input style={{marginRight: "5px"}} type="radio" onChange={this.handleOnChangeformData} name="option" value="email" defaultChecked/> 
                                <label style={{marginRight: "50px"}}>Email</label>
                                <input style={{marginRight: "5px"}}type="radio" onChange={this.handleOnChangeformData} name="option" value="mobile"/>
                                <label style={{marginRight: "5px"}}>Mobile</label>
                            </div>
                        </div>
                        <div className="form-group" style={{marginBottom: "0px"}}>
                            <label>Message:</label>
                            <textarea onChange={this.handleOnChangeformData} value={this.state.formData.messages || ""} name="messages" rows="2" className="form-control"></textarea>
                            <span className="k-font-danger">{this.state.validation.formData["messages"]}</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{height: "50px"}}>
                        <button style={{width: "100%"}} onClick={()=>{this.handleOnSubmit()}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="flaticon2-paper-plane"></i> Submit</button>
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
		);

	};

};
