import {getSummary_summaryPatientsAction, reset_summaryPatientsAction} from '../actions/summaryPatientsAction';
import {getGroups_groupsPatientAction, reset_groupsPatientAction} from '../actions/groupsPatientAction';

import * as configs from '../lib/configs';

export const OnInit_patientsAction = () => (__dispatch) => {

    if(!configs.scopes.readPatients){
        return;
    }
    
    __dispatch(getSummary_summaryPatientsAction());
    __dispatch(getGroups_groupsPatientAction());

}

export const resetPage_patientsAction = () => (__dispatch) => {

    __dispatch(reset_summaryPatientsAction());
    __dispatch(reset_groupsPatientAction());

}