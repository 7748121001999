
export const showMainPanel_quickPanelAction= (__show, __tab) => {

    return {
        type: "SHOW_MAIN_QUICKPANEL",
        payloads: {
            show:   __show,
            tab:   __tab
        }
    };

}

export const showSubPanel_quickPanelAction = (__show, __data) => {

    return {
        type: "SHOW_SUB_QUICKPANEL",
        payloads: {
            show:   __show,
            data:   __data
        }
    };

}

export const reset_quickPanelAction = () => {

    return {
        type: "RESET_ABNORMALFLAGS",
        payloads: {}
    };

}