import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import MappingComponent from '../../../features/mapping/mappingComponent';

import SendMessagesContainer from '../../../../containers/sendMessagesContainer';

import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

import './groupsPatientStyle.css';

const schema = {
    "groupName":    "^[A-Za-z0-9\\_\\-\\s]+$",
    "comment":      "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$"
}

const initialState = () => {

    return{
        showFormData:           false,
        method:                 "",
        mapping:{
            show:               false,
            groupId:            ""
        },
        formData:{
            id:                 "",
            groupName:          "",
            comment:            ""
        },
        validation:{
            formData:           {}
        }
    };
  
  }

const _ref="GroupsPatientComponent";

export default class GroupsPatientComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    componentDidMount(){

        //this.props.func.getCustomers_customersAction()
        
        // if(configs.scopes.readPatients){

        //     this.props.func.getGroups_groupsPatientAction();

        // }

    }

    handleOnFirstPage = () => {

        this.props.pagination.page = 1;
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnLastPage = () => {

        this.props.pagination.page  = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnChangePage = (__page) => {

        this.props.pagination.page  = __page;
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnPreviousPage = () => {

        this.props.pagination.page  = this.props.pagination.page - 1;
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnNextPage = () => {

        this.props.pagination.page  = this.props.pagination.page + 1;
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnLoadMorePage = (__pagination, __plus) => {

        this.props.func.loadMorePage_groupsPatientAction(__pagination, __plus);
        
    }

    handleOnSort = (__field) =>{

        if(this.props.data.length === 0){
    
          return;
    
        };
    
        let sortValue = "";
    
        if(__field === this.props.sorts.sortBy){
    
          if(this.props.sorts.sortValue === "ASC"){
    
            sortValue = "DESC";
    
          };
    
          if(this.props.sorts.sortValue === "DESC"){
    
    
            sortValue = "ASC";
            
          };
    
          this.props.sorts.sortValue = sortValue;
    
        }else{
    
          this.props.sorts.sortBy       = __field;
          this.props.sorts.sortValue    = "ASC";
    
        };
    
        this.props.func.getGroups_groupsPatientAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnCallMsgBox = (__button) => {

        if(__button === "btnYes"){

            this.props.func.delete_groupsPatientAction(this.state.formData);
            this.handelOnCallFormData(false);

        };

    }

    handelOnResetComponent = () =>{

        this.setState(initialState());

    }

    handelOnRefreshData = () => {

        this.props.func.getGroups_groupsPatientAction();
        this.handelOnResetComponent();
    
    }
    
    handleOnChangeFormData = (__e) => {

        if(__e.target.name in this.state.validation.formData){

            delete this.state.validation.formData[__e.target.name];

        };

        this.setState({
            validation: {
                ...this.state.validation,
                formData: {
                    ...this.state.validation.formData
                }
            },
            formData:{
                ...this.state.formData,
                [__e.target.name]: __e.target.value
            }
        });

    }

    handleCallSendMessage = () => {

        this.props.func.callSendMessage_sendMessageAction(true, true);

    }

    handelOnCallFormData = (__bool) => {

        if(!__bool){

            this.handelOnResetComponent();

        };

        this.setState({
            showFormData:   __bool
        });

    }

    handleOnCreate = () => {

        this.handelOnCallFormData(true);
        this.setState({
            method:     "POST"
        });

    }

    handleOnUpdate = (__obj) => {
        console.log(__obj)
        this.handelOnCallFormData(true);
        this.setState({
            method:     "PUT",
            formData:{
                id:             __obj.groupId,
                groupName:      __obj.groupName,
                comment:        __obj.comment
            }
        });

    }

    handleOnDelete = (__obj) => {

        this.setState({
            method: "DELETE",
            formData:{
                ...this.state.formData,
                id: __obj.groupId
            }
        });

        this.props.func.create_MsgBoxAction(_ref, "Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);
    }

    handleOnSubmitFormData = () => {

        let error       = {};
        let formData    = Object.assign({}, this.state.formData);

        func.validate(schema, formData, error);
 
        if(Object.keys(error).length > 0){

            this.setState({
                validation:{
                    ...this.state.validation,
                    formData: error
                } 
            });

            return;
        };

        if(this.state.method === "POST"){

            this.props.func.create_groupsPatientAction(formData);

        }else{

            this.props.func.update_groupsPatientAction(formData);

        };

        this.handelOnCallFormData(false);

    }

    handleOnCallFormMapping = (__groupId) =>{

        this.handleOnClearFiltersFormMapping();

        this.props.patientsOutGroup.filters.outGroupId  = __groupId;
        this.props.patientsInGroup.filters.inGroupId    = __groupId;
    
        this.props.func.getPatientsOutGroup_groupsPatientAction(this.props.patientsOutGroup.filters);
        this.props.func.getPatientsInGroup_groupsPatientAction(this.props.patientsInGroup.filters);
    
        this.handleOnShowFormMapping(true, __groupId);
    
    }
    
    handleOnClearFiltersFormMapping = () =>{

        this.props.patientsInGroup.filters.inGroupId    = "";
        this.props.patientsInGroup.filters.statusRPM    = "";
        this.props.patientsInGroup.filters.firstName    = "";
        this.props.patientsInGroup.filters.lastName     = "";
        this.props.patientsInGroup.filters.dob          = "";
        this.props.patientsInGroup.filters.email        = "";
        this.props.patientsInGroup.filters.mrn          = "";
        this.props.patientsInGroup.filters.phoneMobile  = "";

        this.props.patientsOutGroup.filters.outGroupId   = "";
        this.props.patientsOutGroup.filters.statusRPM    = "";
        this.props.patientsOutGroup.filters.firstName    = "";
        this.props.patientsOutGroup.filters.lastName     = "";
        this.props.patientsOutGroup.filters.dob          = "";
        this.props.patientsOutGroup.filters.email        = "";
        this.props.patientsOutGroup.filters.mrn          = "";
        this.props.patientsOutGroup.filters.phoneMobile  = "";
    
    }
    
    handleOnShowFormMapping = (__bool, __groupId) =>{

        this.setState({
            mapping:{
                show: __bool,
                groupId: __groupId
            }
        });

    }

    handleOnMapping = (__actions, __patientIds) =>{

        this.props.func.mapPatientsToGroup_groupsPatientAction({actions: __actions, patientIds: __patientIds, groupId: this.state.mapping.groupId});

    }

    handleOnSearch = (__filters, __typeSearch) =>{

        if(__typeSearch === "outSide"){

            this.props.patientsOutGroup.filters.statusRPM    = __filters.statusRPM;
            this.props.patientsOutGroup.filters.firstName    = __filters.firstName;
            this.props.patientsOutGroup.filters.lastName     = __filters.lastName;
            this.props.patientsOutGroup.filters.dob          = __filters.dob;
            this.props.patientsOutGroup.filters.email        = __filters.email;
            this.props.patientsOutGroup.filters.mrn          = __filters.mrn;
            this.props.patientsOutGroup.filters.phoneMobile  = __filters.phoneMobile;

            this.props.func.getPatientsOutGroup_groupsPatientAction(this.props.patientsOutGroup.filters);

            return;

        };

        this.props.patientsInGroup.filters.statusRPM    = __filters.statusRPM;
        this.props.patientsInGroup.filters.firstName    = __filters.firstName;
        this.props.patientsInGroup.filters.lastName     = __filters.lastName;
        this.props.patientsInGroup.filters.dob          = __filters.dob;
        this.props.patientsInGroup.filters.email        = __filters.email;
        this.props.patientsInGroup.filters.mrn          = __filters.mrn;
        this.props.patientsInGroup.filters.phoneMobile  = __filters.phoneMobile;

        this.props.func.getPatientsInGroup_groupsPatientAction(this.props.patientsInGroup.filters);

    }

    handleOnNextPageMapping = (__typeAction) => {

        if(__typeAction  === "outSide"){

            this.props.patientsOutGroup.pagination.page = this.props.patientsOutGroup.pagination.page + 1;
            this.props.func.getPatientsOutGroup_groupsPatientAction(this.props.patientsOutGroup.filters, undefined, this.props.patientsOutGroup.pagination);

            return;

        };

        this.props.patientsInGroup.pagination.page = this.props.patientsInGroup.pagination.page + 1;
        this.props.func.getPatientsInGroup_groupsPatientAction(this.props.patientsInGroup.filters, undefined, this.props.patientsInGroup.pagination);

    }
    
    handleOnPreviousPageMapping = (__typeAction) => {
    
        if(__typeAction  === "outSide"){
    
          this.props.patientsOutGroup.pagination.page = this.props.patientsOutGroup.pagination.page - 1;
          this.props.func.getPatientsOutGroup_groupsPatientAction(this.props.patientsOutGroup.filters, undefined, this.props.patientsOutGroup.pagination);
    
          return;
    
        };
    
        this.props.patientsInGroup.pagination.page = this.props.patientsInGroup.pagination.page - 1;
        this.props.func.getPatientsInGroup_groupsPatientAction(this.props.patientsInGroup.filters, undefined, this.props.patientsInGroup.pagination);
    
    }

    render() {

        return(
            <React.Fragment>
                 
                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                {

                    !configs.scopes.readPatients

                    ?
                        <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                        <div className="k-iconbox__icon">
                            <div className="k-iconbox__icon-bg"></div>
                            <i className="fa fa-lock"></i>
                        </div>
                        <div className="k-iconbox__title">
                            403 FORBIDDEN
                        </div>
                        <div className="k-iconbox__content">
                            You not enough rights to access to this item
                        </div>
                        </div>
                    :
                    <React.Fragment>
                        <SendMessagesContainer/>

                        <MappingComponent 
                            show                    = {this.state.mapping.show} 
                            close                   = {()=>{this.handleOnShowFormMapping(false, "")}} 
                            type                    = {"patient"} 
                            permissions             = {configs.scopes.editPatients}
                            outSide                 = {this.props.patientsOutGroup.data} 
                            inSide                  = {this.props.patientsInGroup.data}
                            paginationInSide        = {this.props.patientsInGroup.pagination}
                            paginationOutSide       = {this.props.patientsOutGroup.pagination}
                            handleOnSubmitSearch    = {(__filters, __typeSearch)=>{this.handleOnSearch(__filters, __typeSearch)}} 
                            handleOnPreviousPage    = {(__typeAction)=>{this.handleOnPreviousPageMapping(__typeAction)}} 
                            handleOnNextPage        = {(__typeAction)=>{this.handleOnNextPageMapping(__typeAction)}} 
                            handleOnMapping         = {(__actions, __patientIds)=>{this.handleOnMapping(__actions, __patientIds)}} 
                            handleOnRefresh         = {()=>{this.handleOnCallFormMapping(this.state.mapping.groupId)}}
                        />
                        <div style={{display: "flex", float: "right", marginBottom: "20px"}}>
                            <button style={{marginRight: "5px"}} onClick={()=>{this.handelOnRefreshData()}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="fa fa-sync-alt"></i> Refresh</button>
                            <button hidden={!configs.scopes.addPatients} style={{marginRight: "5px"}} onClick={()=>{this.handleCallSendMessage()}} className="btn btn-secondary btn-elevate btn-pill btn-sm" type="button"><i className="flaticon2-paper-plane"></i> Send Message</button>
                            <button hidden={!configs.scopes.addPatients} onClick={()=>{this.handleOnCreate()}} className="btn btn-secondary btn-elevate btn-pill btn-sm" type="button"><i className="fa fa-plus"></i> Add New</button>
                        </div>
                        <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%", marginBottom: "20px"}}>
                            <table className="k-datatable__table list-database" style={{display: "block", maxHeight: "350px", overflow: "auto"}}>
                                <thead className="k-datatable__head">
                                    <tr className="k-datatable__row"> 
                                        <th onClick={()=>{this.handleOnSort("groupName")}} className={this.props.sorts.sortBy === "groupName" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                                            <span style={{width: "130px"}}>GROUP NAME {this.props.sorts.sortBy === "groupName" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                        </th>
                                        <th onClick={()=>{this.handleOnSort("member")}} className={this.props.sorts.sortBy === "member" ? "k-datatable__cell--center k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--center k-datatable__cell"}>
                                            <span style={{width: "90px"}}>MEMBER {this.props.sorts.sortBy === "member" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                                        </th>
                                        <th className="k-datatable__cell--left k-datatable__cell">
                                            <span style={{width: "220px"}}>COMMENT </span>
                                        </th>
                                        <th className="k-datatable__cell--left k-datatable__cell">
                                            <span style={{width: "100px"}}>ACTIONS </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="k-datatable__body">
                                    {   
                                        this.props.data.map((__r)=>(
                                            <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
                                                <td className="k-datatable__cell--left k-datatable__cell">
                                                    <span style={{width: "130px"}}>{__r.groupName}</span>
                                                </td>
                                                <td className="k-datatable__cell--center k-datatable__cell">
                                                    <span style={{width: "90px"}}><span className="badge btn-brand">{__r.member}</span></span>
                                                </td>
                                                <td className="k-datatable__cell--left k-datatable__cell">
                                                    <span style={{width: "220px"}}>{__r.comment}</span>
                                                </td>
                                                <td className="k-datatable__cell--left k-datatable__cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "100px"}}>
                                                        <a hidden={!configs.scopes.editPatients} onClick={()=>{this.handleOnCallFormMapping(__r.groupId)}} className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-user-plus"></i></a>	
                                                        <a hidden={!configs.scopes.editPatients} onClick={()=>{this.handleOnUpdate(__r)}} className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-edit"></i></a>						
                                                        <a hidden={!configs.scopes.delPatients} onClick={()=>{this.handleOnDelete(__r)}} className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-trash"></i></a>					
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>

                        <Modal show={this.state.showFormData} onHide={()=>{this.handelOnCallFormData(false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-300">
                                <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                                    <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>
                                        {this.state.formData.method === "PUT" ? "Edit Details" : "Add New"}
                                    </Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                    {/* <div className="form-group" style={{marginBottom: "20px"}}>
                                        <label>Customer</label>
                                        <select onChange={this.handleOnChangeFormData} name="customer_id" value={this.state.formData.customer_id || ""} className="form-control form-control-sm" autoComplete="off">
                                            <option key="nnn" value="">Please choose . . .</option>
                                            {
                                                this.props.customers.data.map((__r)=>(
                                                    <option key={__r.id} value={__r.id}>{__r.fullname}</option>
                                                ))
                                            }
                                        </select>
                                        <span className="k-font-danger">{this.state.validation.formData["customer_id"]}</span>
                                    </div> */}
                                    <div className="form-group" style={{marginBottom: "20px"}}>
                                        <label>Group Name</label>
                                        <input onChange={this.handleOnChangeFormData} value={this.state.formData.groupName || ""} type="text" className="form-control form-control-sm" name="groupName" autoComplete="off"/>
                                        <span className="k-font-danger">{this.state.validation.formData["groupName"]}</span>
                                    </div>
                                    <div className="form-group" style={{marginBottom: "0px"}}>
                                        <label>Comment</label>
                                        <textarea onChange={this.handleOnChangeFormData} value={this.state.formData.comment || ""} name="comment" className="form-control form-control-sm" rows="4"></textarea>
                                        <span className="k-font-danger">{this.state.validation.formData["comment"]}</span>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer style={{height: "50px"}}>
                                    <button style={{width: "100%"}} onClick={()=>{this.handleOnSubmitFormData()}} type="button" className="btn btn-secondary btn-pill btn-sm"><i className="flaticon2-paper-plane"></i> Submit</button>
                                </Modal.Footer>
                        </Modal>
                    </React.Fragment>
                }
            </React.Fragment>  
        )

    }

}