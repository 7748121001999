import React, {Component} from 'react';
import { connect } from 'react-redux';

import PermissionsComponent from '../components/dashboards/contents/permissions/permissionsComponent';

import {getPermissions_permissionsAction, create_permissionsAction, updateStatus_permissionsAction, delete_permissionsAction, reset_permissionsAction} from '../actions/permissionsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {getCustomers_customersAction} from '../actions/customersAction';

class PermissionsContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <PermissionsComponent
          customers={this.props.Customers}
          data = {this.props.Permissions.data} 
          filters = {this.props.Permissions.filters} 
          pagination = {this.props.Permissions.pagination} 
          sorts = {this.props.Permissions.sorts} 
          func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    Customers: __state.CustomersReducer,
    Permissions: __state.PermissionsReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
      getCustomers_customersAction: (__filters, __sorts, __pagination)=>{
				__dispatch(getCustomers_customersAction(__filters, __sorts, __pagination))
			},
			getPermissions_permissionsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getPermissions_permissionsAction(__filters, __sorts, __pagination))
			},
      create_permissionsAction: (__formData) => {
				__dispatch(create_permissionsAction(__formData))
			},
      updateStatus_permissionsAction: (__formData) => {
				__dispatch(updateStatus_permissionsAction(__formData))
			},
      delete_permissionsAction: (__formData) => {
				__dispatch(delete_permissionsAction(__formData))
			},
      reset_permissionsAction: () => {
				__dispatch(reset_permissionsAction())
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(PermissionsContainer);