
const stateSendMessage = {
    sendGroup:  false,
    show:       false
}
  
const SendMessageReducer = (__state = stateSendMessage, __action) => {

    switch(__action.type){

        case "RESET_SENDMESSAGE":

            return stateSendMessage;

        case "SHOW_SENDMESSAGE":
            
            return {
                ...__state, 
                show: __action.payloads.show,
                sendGroup: __action.payloads.sendGroup
            };

        default: 

            return __state;

    };

}

export default SendMessageReducer;