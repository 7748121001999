import * as configs from '../lib/configs';

export const stateSystemMonitor = {
    data: [],
    filters: {
        device:         ""
    },
    pagination:{
        begin:          1,
        length:         configs.limitMenu,
        pageSizes:      configs.pageSizes,
        page:           1,
        totalRows:      0
    },
    sorts:{
        sortBy:         "device_sn",
        sortValue:      "DESC"
    }
};

const SystemMonitorReducer = (__state = stateSystemMonitor, __action) => {

    switch(__action.type){
 
        case "IMPORT_SYSTEM_MONITOR":

            return {
                ...__state,
                 data:          __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{
                    ...__state.filters, 
                    device:     __action.payloads.device
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_SYSTEM_MONITOR":
            
            return stateSystemMonitor;

        case "LOAD_MORE_PAGE_SYSTEM_MONITOR":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        default: 

            return __state;

    };

};

export default SystemMonitorReducer;