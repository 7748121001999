import React, { Component } from 'react';

import func from '../../../lib/func';

import './autoCompleteStyle.css';

export default class AutoCompleteComponent extends Component{

	constructor(__props) {

		super(__props);
        this.wrapperRef = React.createRef()
        this.state = {
            elements: [],
            value: ""
        }
	};

    componentDidMount() {

        document.addEventListener('click', this.handleClickOutSide);
        document.addEventListener('keyup', this.handleClickOutSide);

    }
    
    componentWillReceiveProps(nextProps){

        if(nextProps.wait !== this.props.wait){
            
            this.setState({ 
                value: ""
            });

        }

        if(nextProps.value !== this.props.value){

            this.setState({ 
                value: nextProps.value
            });

        };

    }

    componentWillUnmount() {

        document.removeEventListener('click', this.handleClickOutSide);
        document.removeEventListener('keyup', this.handleClickOutSide);

    }
  
      
    handleOnSelect = (__key, __value) => {

        this.setState({ 
            elements:   [],
            value:  __value
        });

        this.props.onChange({target:{name: this.props.name, value : __key}});

    }

    handleOnChange = (__e) => {
 
        let elements    = [];
        let value       = __e.target.value || "";

        if(this.props.resource.length === 0){
            elements.push(
                <div key={1}>{"Not Found"}</div>
            );
        }

        for (let i = 0; i < this.props.resource.length; i++) {

            if(func.isEmptyOrNull(this.props.resource[i]["key"])){
                continue;
            };

            if (String(this.props.resource[i]["title"]).substring(0, value.length).toUpperCase() === value.toUpperCase()) {
                elements.push(
                    <div key={this.props.resource[i]["key"]} onClick={()=>{this.handleOnSelect(this.props.resource[i]["key"], this.props.resource[i]["title"])}}>{this.props.resource[i]["title"]}</div>
                );
            };

        }

        this.setState({ 
            elements:   elements,
            value:      value
        });

        this.props.onChange({target:{name: this.props.name, value : ""}})

    }

    handleClickOutSide = (__e) => {
      
        let { target } = __e

        if (!this.wrapperRef.current.contains(target)) {

            if(!func.isEmptyOrNull(this.state.value)){

                let flag = false;

                for (let i = 0; i < this.props.resource.length; i++) {

                    if (String(this.props.resource[i]["title"]).toUpperCase() == String(this.state.value).toUpperCase()) {

                        this.handleOnSelect(this.props.resource[i]["key"], this.props.resource[i]["title"]);

                        flag = true;
                        break;
                    };

                };

                if(!flag){

                    if(this.props.useStrict){

                        this.handleOnSelect("", "");

                    }else{

                        this.handleOnSelect(this.state.value, this.state.value);

                    };

                };

            }

            this.setState({ 
                elements: [],
            });
            
        }

    }

    render() {
    
        return(
            <div hidden={this.props.hidden} className="autocomplete" style={this.props.style} ref={this.wrapperRef}>
               <div className="input-group">
                    <input tabIndex={this.props.tabIndex} readOnly={this.props.readOnly} ref={this.props.refChild} className="form-control form-control-sm" style={this.props.inputStyle ? this.props.inputStyle : {}} onChange={this.handleOnChange} type={this.props.type} value={this.state.value} name={this.props.name} placeholder={this.props.placeholder} autoComplete="off"/>
                    <div className="input-group-append">
                        <button tabIndex="99" disabled={this.props.readOnly} onClick={this.handleOnChange} className="btn btn-outline-secondary btn-sm" type="button" style={this.props.btnStyle ? this.props.btnStyle : {width: "30px", padding: "2px"}}>
                            {!this.props.wait ? <i className="fa fa-caret-down" style={{padding: "5px"}}></i> : 
                            <div className="k-spinner k-spinner--sm k-spinner--info" style={{width: "20px", margin: "auto", left: "7%"}}></div>
                            }
                        </button>
                    </div>
                </div>
                <div className="autocomplete-items">
                    {this.state.elements}
                </div>
            </div>
        )

    }

}