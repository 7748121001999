
export const stateChartSystemData = {
 
    data: [],
    filters: {
        option: 2
    }

};

const ChartSystemDataReducer = (__state = stateChartSystemData, __action) => {

    switch(__action.type){

        case "IMPORT_SUMMARY_SYSTEM_DATA":

            return {
                ...__state,
                data:               __action.payloads.data,
                filters:{
                    option:         __action.payloads.option
                }
            };
            
        case "RESET_SUMMARY_SYSTEM_DATA":
            
            return stateChartSystemData;

        default: 

            return __state;

    };

};

export default ChartSystemDataReducer;