
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardsComponent from '../components/dashboards/contents/dashboards/dashboardsComponent';

import {OnInit_dashboardsAction, resetPage_dashboardsAction} from '../actions/dashboardsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class DashboardsContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<DashboardsComponent
					filters = {this.props.Dashboards.filters} 
					func = {this.props.func}
				/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{
		Dashboards: __state.DashboardsReducer,
	};

};
 
const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			OnInit_dashboardsAction: (__device) => {
				__dispatch(OnInit_dashboardsAction(__device))
			},
			resetPage_dashboardsAction: () => {
				__dispatch(resetPage_dashboardsAction())
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(DashboardsContainer);