import * as configs from '../lib/configs';

export const stateMessagesReports = {
    data: [],
    filters: {
        uuid:       "", 
        status:     "",
        target:     "",
        fromDate:   "",
        toDate:     "",
        msgType:    ""
    },
    pagination:{
        begin:      1,
        length:     configs.limitMenu,
        pageSizes:  configs.pageSizes,
        page:       1,
        totalRows:  0
    },
    sorts:{
        sortBy:         "createdAt",
        sortValue:      "DESC"
    }
};

const MessagesReportsReducer = (__state = stateMessagesReports, __action) => {

    switch(__action.type){

        case "IMPORT_MESSAGES_REPORTS":

            return {
                ...__state,
                 data: __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{
                     uuid:      __action.payloads.uuid,
                     status:      __action.payloads.status,
                     target:    __action.payloads.target,
                     fromDate:  __action.payloads.fromDate,
                     toDate:    __action.payloads.toDate,
                     msgType:   __action.payloads.msgType
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_MESSAGES_REPORTS":

            return stateMessagesReports;

        case "LOAD_MORE_PAGE_MESSAGES_REPORTS":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        default: 

            return __state;

    };

};

export default MessagesReportsReducer;