import React, { Component } from 'react';

import moment from "moment";

import { v4 as uuidv4 } from 'uuid';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import func from '../../../../lib/func'
import * as configs from '../../../../lib/configs';

import './notifiesStyle.css';

const initialState = () => {

  return{
    new:    [],
    history:[]
  };

}

const _ref="NotifiesComponent";

export default class NotifiesComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentWillReceiveProps(nextProps){

    this.renderNotifies(nextProps.new.data, false);
    this.renderNotifies(nextProps.history.data, true);

  }

  handleOnCallMsgBox = (__button) => {


  }
 
  handleOnSubmitFormData = (__id, __history) => {

    if(!__history){

      this.props.func.updateStatus_notifiesAction({id: __id, device: this.props.device});

      return;

    };

    this.props.func.deleteNotify_notifiesAction({id: __id, device: this.props.device});

  }

  renderNotifies = (__data, __history) => {   

    let arr = [];

    for(let r of __data){

      arr.push(
        <a key={uuidv4()} className="k-notification-v2__item">
          <div onClick={()=>this.handleOnSubmitFormData(r["id"], __history)} style={{cursor: "pointer"}} className="k-notification-v2__item-icon">
            <i className={(!__history ? "fa fa-bell k-font-primary" : "flaticon-delete k-font-primary")}></i>
          </div>
          <div className="k-notification-v2__item-wrapper">
            <div style={{cursor: "default"}} className="k-notification-v2__item-title">
              {
                moment.utc(r["notify_at"]).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)
              }
            </div>
            <div className="k-notification-v2__item-desc">
              {
                func.noteDateTime(r["notify_at"])
              }
            </div>
          </div>
        </a>
      );

    };

    if(__history){

      this.setState({
        history: arr
      });

      return;

    };

    this.setState({
      new: arr
    });

}

render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
          !configs.scopes.readPatients
          ?
              <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
              <div className="k-iconbox__icon">
                  <div className="k-iconbox__icon-bg"></div>
                  <i className="fa fa-lock"></i>
              </div>
              <div className="k-iconbox__title">
                  403 FORBIDDEN
              </div>
              <div className="k-iconbox__content">
                  You not enough rights to access to this item
              </div>
              </div>
          :
            <React.Fragment>
              <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#notifies_new" role="tab">
                    <span className="nav-link-icon"><i className="fa fa-rss"></i></span>
                    <span className="nav-link-title">New</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#notifies_history" role="tab">
                    <span className="nav-link-icon"><i className="fa fa-history"></i></span>
                    <span className="nav-link-title">History</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="notifies_new" role="tabpanel">
                  <div className="container-table">
                    <div className="k-notification-v2 default-scroll">
                      {this.state.new}
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="notifies_history" role="tabpanel">
                  <div className="container-table">
                    <div className="k-notification-v2 default-scroll">
                      {this.state.history}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
            
        }

      </React.Fragment>
    )

  }

}