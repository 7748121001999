
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChartSummaryBatteriesComponent from '../components/dashboards/contents/chartSummaryBatteries/chartSummaryBatteriesComponent';


import {getSummary_chartSummaryBatteriesAction, reset_chartSummaryBatteriesAction} from '../actions/chartSummaryBatteriesAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ChartSummaryBatteriesContainer extends Component{

	render() {

		return(
   
			<ChartSummaryBatteriesComponent 
				data = {this.props.ChartSummaryBatteries.data} 
				devices = {this.props.ChartSummaryBatteries.devices} 
				filters = {this.props.ChartSummaryBatteries.filters} 
				func = {this.props.func}
			/>

		);

	};

};

const mapState = __state => {

	return{
		ChartSummaryBatteries: __state.ChartSummaryBatteriesReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummary_chartSummaryBatteriesAction: (__device, __option) => {
				__dispatch(getSummary_chartSummaryBatteriesAction(__device, __option));
			},
			reset_chartSummaryBatteriesAction: () => {
				__dispatch(reset_chartSummaryBatteriesAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ChartSummaryBatteriesContainer);