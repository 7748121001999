
import React, { Component } from 'react';

import { connect } from 'react-redux';

import LogsSQSComponent from '../components/dashboards/contents/logsSQS/logsSQSComponent';

import {create_MsgBoxAction} from '../actions/msgBoxAction';
import {getLogsSQS_logsSQSAction, loadMorePage_logsSQSAction, reset_logsSQSAction} from '../actions/logsSQSAction';

class LogsSQSContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<LogsSQSComponent 
					data = {this.props.LogsSQS.data} 
					filters = {this.props.LogsSQS.filters} 
					pagination = {this.props.LogsSQS.pagination} 
					sorts = {this.props.LogsSQS.sorts} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		LogsSQS: __state.LogsSQSReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getLogsSQS_logsSQSAction: (__filters, __sorts, __pagination) => {
				__dispatch(getLogsSQS_logsSQSAction(__filters, __sorts, __pagination))
			},
			loadMorePage_logsSQSAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_logsSQSAction(__pagination, __plus))
			},
			reset_logsSQSAction: () => {
				__dispatch(reset_logsSQSAction())
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(LogsSQSContainer);