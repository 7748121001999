import React, {Component} from 'react';
import moment from "moment";
import { NavLink, Link } from "react-router-dom";
import { Redirect } from 'react-router'

import QuickPanelContainer from '../../../containers/quickPanelContainer';
import MsgBoxContainer from '../../../containers/msgBoxContainer';
import func from '../../../lib/func';
import * as configs from '../../../lib/configs';

const _ref="LayoutComponent";

export default class LayoutComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = {
      url:                    "",
      showTopbarMobile:       false,
      showMenuMobile:         false,
      showAlertAbnormal:      false,
      dismissAlertAbnormal:   false,
      signOut:                false,
      intervalId:             null,
    }

  }

  componentDidMount(){

    let token =  window.sessionStorage.getItem("token");
 
    if(!token && window.location.pathname !== "/dashboars/reports"){
        
      this.setState({
        signOut: true
      });
      
      return;

    }

    if(token){
      this.setState({
        intervalId: this.startInterval(),
      });
    }
    
  }

  startInterval=()=>{

    return setInterval(() => {

      try {
        
        let token =  window.sessionStorage.getItem("token");
        let arr = String(token).split(".");

        if(arr.length == 3){

          let obj = JSON.parse(Buffer.from(arr[1], "base64").toString("utf-8"));

          if(obj.exp <= moment().unix()){
            this.handleOnSignOut();
          }

        }else{
          this.handleOnSignOut();
        }

      } catch (error) {
        this.handleOnSignOut();
      }

    }, 60000);

  }

  stopInterval = () => {

    clearInterval(this.state.intervalId);

  };

  componentWillReceiveProps(nextProps){

    if(nextProps.abnormalFlags.new.data.length > 0 && this.state.dismissAlertAbnormal === false){

      this.setState({
        showAlertAbnormal: true
      });

    }else{
      
      this.setState({
        showAlertAbnormal: false
      });

    }

  }

  handleOnCallMsgBox = (__button) => {
 
    if(this.props.property.pageCurrent === "patient-details"){
      if(__button === "btnContinue"){
        this.props.func.redirect_patientDetailsAction(true, this.state.url)
      }
    }
    
  }

  handleOnCallQuickPanel = (__show, __tab) =>{

    this.props.func.showMainPanel_quickPanelAction(__show, __tab);

  }

  handelOnDismissAlertAbnormal = async() => {

    await this.setState({
      showAlertAbnormal: false,
      dismissAlertAbnormal: true
    });
    
    this.handleOnCallQuickPanel(true, 2);

  }

  handleOnSignOut = () =>{

    this.stopInterval();

    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("accesscode");

    this.setState({
      intervalId: null,
      signOut: true
    });

  }

  handleOnShowMenuMoBile = (__show) => {

    this.setState({
      showMenuMobile: __show
    });

  }

  handleOnShowTopbarMobile = () => {

    this.setState({
      showTopbarMobile: !this.state.showTopbarMobile
    });

  }
 
  handleOnClickMenu = (__e, __url) => {

    if(this.props.property.pageCurrent === "patient-details" && this.props.patientDetails.active){

      __e.preventDefault();

      this.setState({
        url: __url
      });

      this.props.func.create_MsgBoxAction(_ref, "Info", "You need to save before you leave this page !", [{key: "btnContinue", title: "Continue"},{key: "btnCancel", title: "Cancel"}], "info", null, null)
      
    }

  }

  render() {

    if(this.state.signOut){
      return <Redirect to='/login'/>
    }

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
        <QuickPanelContainer/>

        <div id="k_header_mobile" className="k-header-mobile  k-header-mobile--fixed ">
          <div className="k-header-mobile__logo" style={{padding: "5px 0px"}}>
            <a href="index.html">
              <img alt="Logo" style={{height: "26px"}} src={configs.logoMobile} />
            </a>
          </div>
          <div hidden={!func.isEmptyOrNull(this.props.patientDetails.data.device) && this.props.property.pageCurrent === "patient-details"} className="k-header-mobile__toolbar">
            <button onClick={()=>{this.handleOnShowMenuMoBile(true)}} className="k-header-mobile__toolbar-toggler" id="k_header_mobile_toggler"><span></span></button>
            <button onClick={()=>{this.handleOnShowTopbarMobile()}} className="k-header-mobile__toolbar-topbar-toggler" id="k_header_mobile_topbar_toggler"><i className="flaticon-more"></i></button>
          </div>
        </div>

        <div className="k-grid k-grid--hor k-grid--root">
          <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-page">
            <div className="k-grid__item k-grid__item--fluid k-grid k-grid--hor k-wrapper " id="k_wrapper">
              <div id="k_header" className="k-header k-grid__item  k-header--fixed " data-kheader-minimize="on" style={{backgroundImage: "url(../assets/demo/demo3/media/layout/header-bg.jpg)"}}>
               
                <div hidden={!this.state.showAlertAbnormal} style={{zIndex: "999999", width: "100%", position: "fixed"}} className="alert alert-warning fade show" role="alert">
                  <div className="alert-icon"><i className="flaticon-warning"></i></div>
                  <div className="alert-text">{"There are " + "" + " warnings you have not confirmed!"} <a onClick={()=>{this.handelOnDismissAlertAbnormal()}} style={{cursor: "pointer", textDecoration: "underline", color: "#5867dd"}}>Click view</a></div>
                </div>

                <div className="k-header__top">
                  <div className="k-container">
                    <div className="k-header__brand   k-grid__item" id="k_header_brand">
                      <div className="k-header__brand-logo">
                        <a href="index.html">
                          <img  style={{height:"50px"}} alt="Logo" src={configs.logoDesktop} className="k-header__brand-logo-default" />
                          <img  style={{height:"50px"}} alt="Logo" src={configs.logoDesktop} className="k-header__brand-logo-sticky" />
                        </a>
                      </div>
                    </div>

                    <div style={this.state.showTopbarMobile ? {marginTop: "0px"} : {}} className="k-header__topbar">
   
                        <div hidden={window.sessionStorage.getItem("token")==null} className="k-header__topbar-item" data-toggle="k-tooltip" title="Setting" data-placement="top">
                          <div className="k-header__topbar-wrapper">
                            <Link className="k-header__topbar-icon" onClick={(__e)=>{this.handleOnClickMenu(__e,"/dashboars/manager-users")}} to="/dashboars/manager-users">
                              <i className="flaticon-cogwheel-1"></i>
                            </Link>
                          </div>

                        </div>

                      <div hidden={this.props.property.pageCurrent !== "patient-details"} onClick={()=>{this.handleOnCallQuickPanel(true, 1)}} className="k-header__topbar-item dropdown" data-toggle="k-tooltip" title="Notification" data-placement="top">
                        <div className="k-header__topbar-wrapper">
                          <span className="k-header__topbar-icon"><i className="flaticon-alarm"></i></span>
                        </div>
                      </div>

                      <div hidden={window.sessionStorage.getItem("token")==null} onClick={()=>{this.handleOnSignOut()}} className="k-header__topbar-item dropdown" data-toggle="k-tooltip" title="Sign out" data-placement="top">
                        <div className="k-header__topbar-wrapper">
                          <span className="k-header__topbar-icon"><i className="fa fa-sign-out-alt"></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={()=>{}} className="k-header__bottom">
                  <div className="k-container">
                    <button onClick={()=>{this.handleOnShowMenuMoBile(false)}} style={this.state.showMenuMobile ? {right: "249px"} : {}} className="k-header-menu-wrapper-close" id="k_header_menu_mobile_close_btn"><i className="la la-close"></i></button>
                    <div className={this.state.showMenuMobile ? "k-header-menu-wrapper k-header-menu-wrapper--on" : "k-header-menu-wrapper"} id="k_header_menu_wrapper">
                      <div id="k_header_menu" className="k-header-menu k-header-menu-mobile ">
                        <ul className="k-menu__nav ">
                          <NavLink onClick={(__e)=>{this.handleOnClickMenu(__e,"/dashboars/index")}} className="k-menu__item" aria-haspopup="true" activeClassName="k-menu__item--active" to="/dashboars/index">
                            <div className="k-menu__link">
                              <i className="k-menu__link-icon flaticon2-grids"></i><span className="k-menu__link-text">Dashboards</span>
                            </div>
                          </NavLink>
                          <NavLink onClick={(__e)=>{this.handleOnClickMenu(__e,"/dashboars/patients")}} className="k-menu__item" aria-haspopup="true" activeClassName="k-menu__item--active" to="/dashboars/patients">
                            <div className="k-menu__link">
                              <i className="k-menu__link-icon fa fa-notes-medical"></i><span className="k-menu__link-text">List Patients</span>
                            </div>
                          </NavLink>
                          <NavLink onClick={(__e)=>{this.handleOnClickMenu(__e,"/dashboars/patient-details")}} className="k-menu__item" aria-haspopup="true" activeClassName="k-menu__item--active" to="/dashboars/patient-details">
                            <div className="k-menu__link">
                              <i className="k-menu__link-icon flaticon2-magnifier-tool"></i><span className="k-menu__link-text">Patient Details</span>
                            </div>
                          </NavLink>
                          <NavLink onClick={(__e)=>{this.handleOnClickMenu(__e,"/dashboars/reports")}} className="k-menu__item" aria-haspopup="true" activeClassName="k-menu__item--active" to="/dashboars/reports">
                            <div className="k-menu__link">
                              <i className="k-menu__link-icon fa fa-chart-bar"></i><span className="k-menu__link-text">Reports</span>
                            </div>
                          </NavLink>
                        </ul>
                      </div>
                      <div className="k-header-toolbar">

                      </div>
                    </div>
                    {
                      this.state.showMenuMobile ? <div className="k-header-menu-wrapper-overlay"></div> : ""
                    }

                  </div>
                </div>
              </div>
            
              {this.props.children}

              <div className="k-footer k-grid__item" style={{backgroundImage: "url(../assets/demo/demo3/media/layout/footer-bg.jpg"}} id="k_footer">
                <div className="k-container">
                  <div className="k-footer__top">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="k-footer__section">
                          <h3 className="k-footer__title">About</h3>
                          <div className="k-footer__about">
                            .....
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="k-footer__section">
                          <h3 className="k-footer__title">Quick Links</h3>
                          <div className="row">
                            <div className="col">
                              <ul className="k-footer__nav">
                                <li><a href="/#" className="k-link">Pravicy Policy</a></li>
                                <li><a href="/#" className="k-link">Terms & Conditions</a></li>
                                <li><a href="/#" className="k-link">Help Center</a></li>
                              </ul>
                            </div>
                            <div className="col">
                              <ul className="k-footer__nav">
                                <li><a href="/#" className="k-link">Community</a></li>
                                <li><a href="/#" className="k-link">Documentation</a></li>
                                <li><a href="/#" className="k-link">Support</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="k-footer__section">
                          <h3 className="k-footer__title">Get In Touch</h3>
                          <form action="#" className="k-footer__subscribe">
                            <div className="k-input-icon k-input-icon--right">
                              <input type="text" className="form-control" placeholder="Subscribe..."/>
                              <span className="k-input-icon__icon k-input-icon__icon--right">
                                <span><i className="la la-arrow-right"></i></span>
                              </span>
                            </div>
                          </form>
                          <div className="k-footer__social">
                            <div className="k-footer__social-label">
                              Social links:
                            </div>
                            <ul className="k-footer__social-nav">
                              <li><a href="/#"><i className="flaticon-facebook-logo-button"></i></a></li>
                              <li><a href="/#"><i className="flaticon-twitter-logo-button"></i></a></li>
                              <li><a href="/#"><i className="flaticon-instagram-logo"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="k-footer__bottom">
                    <div className="k-footer__copyright">
                      2022&nbsp;&copy;&nbsp;<a href="#" target="_blank" className="k-link">Evexia</a>
                    </div>
                    <div className="k-footer__menu">
                      <a href="#" target="_blank" className="k-link">About</a>
                      <a href="#" target="_blank" className="k-link">Team</a>
                      <a href="#" target="_blank" className="k-link">Contact</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="k_scrolltop" className="k-scrolltop">
          <i className="la la-arrow-up"></i>
        </div>

      </React.Fragment>
    )

  }

}