import httpClient from '../lib/httpClient';

import {stateGroupsResponder} from '../reducers/groupsResponderReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="GroupsResponderComponent";

export const getGroups_groupsResponderAction = (__filters = Object.assign({}, stateGroupsResponder.filters), __sorts = Object.assign({}, stateGroupsResponder.sorts), __pagination = Object.assign({}, stateGroupsResponder.pagination)) => async(__dispatch) => {

    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));
        
        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/groupsResponder?func=getGroups"+ (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
     
        __dispatch(showLoading(false));
        __dispatch(import_groupsResponderAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const import_groupsResponderAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_GROUPS_RESPONDER",
        payloads: {
            title:          __filters.title || "",
            data:           __data,
            totalRows:      __totalRows,
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const create_groupsResponderAction= (__formData) => async(__dispatch) => {

    try{
  
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsResponder?func=create", "POST", __formData, null);
        
        __dispatch(getGroups_groupsResponderAction());
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const updateStatus_groupsResponderAction= (__formData) => async(__dispatch) => {

    try{
 
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsResponder?func=updateStatus", "PUT", __formData, null);
        
        __dispatch(getGroups_groupsResponderAction());
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const delete_groupsResponderAction= (__formData) => async(__dispatch) => {

    try{

        __dispatch(showLoading(true));
        
        await httpClient.request(configs.endPoint + "/groupsResponder", "DELETE", __formData, null);
        
        __dispatch(getGroups_groupsResponderAction());
        __dispatch(showLoading(false));
        
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const reset_groupsResponderAction = () => {

    return {
        type: "RESET_GROUPS_RESPONDER",
        payloads: {}
    };

}