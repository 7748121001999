import React, { Component } from 'react';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import func from '../../../../lib/func';
import * as configs from '../../../../lib/configs';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
  
import { Bar, getElementsAtEvent } from 'react-chartjs-2';

const optionsChartSummaryOfCompliance = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        beginAtZero: true
      }
    }
};

const _ref="ChartSummaryComplianceComponent";

export default class ChartSummaryComplianceComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = {      
            data:{
                labels: [],
                datasets: []       
            },
            formFilterChart:{
              option:       0
            }
        }

        this.chartReference = React.createRef();

	};

  componentWillReceiveProps(nextProps){

    this.setState({
      formFilterChart:{
        option:         nextProps.filters.option
      }
    });

    if(nextProps.data !== this.props.data){

        let chart = this.chartReference.current
            chart.data = this.renderSummaryOfCompliance(nextProps.data);
            chart.update();

    };
  
  }

  //handleOnClick=(event)=>{

    // console.log(getElementsAtEvent(this.chartReference.current, event));
  //}

  handleOnChangeFilterChart = (__e) => {

    this.props.func.getSummary_chartSummaryComplianceAction(this.props.filters.device, __e.target.value);
  
  }

  handleOnCallMsgBox = (__button) => {


  }

  renderSummaryOfCompliance = (__data) => {

      if(!func.isType(__data, "object") || Object.keys(__data).length === 0){
  
        return {
          labels: [],
          datasets: []
        };
  
      }

      let data=[];
      let labels=[];
      let set = Object.keys(__data).sort(function(a, b){return b - a});
      let keys = Object.keys(__data[set[0]]);
  
      for(let y = 0; y < keys.length; y++){
  
          let obj={
              label: keys[y],
              backgroundColor: configs.color[y],
              borderColor: configs.borderColor[y],
              borderWidth: 1,
              data:[]
          };
          
          data.push(obj);
  
      }
  
      for(let i = 0; i < set.length; i++){
  
          keys = Object.keys(__data[set[i]]);
  
          for(let y = 0; y < keys.length; y++){
  
              for(let z = 0; z < data.length; z++){
  
                  if(data[z]["label"] === keys[y]){
  
                      data[z]["data"].push(Math.ceil(__data[set[i]][keys[y]]["percent"]));
                  }
              }
          }
  
          labels.push(set[i]);
      }
  
      return{
          labels: labels,
          datasets: data
      };
      
  }
  
    render() {
    
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
            ArcElement
        );

        return(
          <React.Fragment>

            <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

            {!configs.scopes.readPatients
                ?
                    <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                    <div className="k-iconbox__icon">
                        <div className="k-iconbox__icon-bg"></div>
                        <i className="fa fa-lock"></i>
                    </div>
                    <div className="k-iconbox__title">
                        403 FORBIDDEN
                    </div>
                    <div className="k-iconbox__content">
                        You not enough rights to access to this item
                    </div>
                    </div>
                :
                <React.Fragment>
                  <div style={{display: "flex", float: "right", marginBottom: "20px"}}>
                    <label style={{marginTop: "5px", fontWeight: "500"}}>Choose: </label>
                    <select onChange={this.handleOnChangeFilterChart} value={this.state.formFilterChart.option || 0} className="form-control form-control-sm" style={{width: "150px", marginLeft: "10px"}}>
                        <option value="0">by week</option>
                        <option value="1">by month</option>
                        <option value="2">by year</option>
                    </select>
                  </div>

                  <Bar ref={this.chartReference} data={this.state.data } options={optionsChartSummaryOfCompliance} height={150} width={600} />
                </React.Fragment>
              }
            </React.Fragment>
        )

    }

}