import * as configs from '../lib/configs';
 
export const stateGroupsPatient = {

    data:                       [],
    filters: {
        groupName:              "",
    },
    sorts:{
        sortBy:                 "groupName",
        sortValue:              "ASC"
    },
    pagination:{
        begin:                  1,
        length:                 configs.limitMenu,
        pageSizes:              configs.pageSizes,
        page:                   1,
        totalRows:              0
    },
    patientsInGroup:{
        data: [],
        filters: {
            inGroupId:          "", 
            statusRPM:          "",
            firstName:          "",
            lastName:           "",
            dob:                "",
            email:              "",
            mrn:                "",
            phoneMobile:        ""
        },
        pagination:{
            begin:              1,
            length:             configs.limitMenu,
            pageSizes:          configs.pageSizes,
            page:               1,
            totalRows:          0
        },
        sorts:{
            sortBy:             "mrn",
            sortValue:          "ASC"
        }
    },
    patientsOutGroup:{
        data: [],
        filters: {
            outGroupId:         "", 
            statusRPM:          "",
            firstName:          "",
            lastName:           "",
            dob:                "",
            email:              "",
            mrn:                "",
            phoneMobile:        ""
        },
        pagination:{
            begin:              1,
            length:             configs.limitMenu,
            pageSizes:          configs.pageSizes,
            page:               1,
            totalRows:          0
        },
        sorts:{
            sortBy:             "mrn",
            sortValue:          "ASC"
        }
    }

};
  
const GroupsPatientReducer = (__state = stateGroupsPatient, __action) => {

    switch(__action.type){

        case "IMPORT_GROUPSPATIENT":

            return {
                ...__state,  
                data:               __action.payloads.data, 
                sorts:{
                    sortBy:         __action.payloads.sortBy || __state.sorts.sortBy, 
                    sortValue:      __action.payloads.sortValue || __state.sorts.sortValue
                },  
                filters:{
                    groupName:      __action.payloads.groupName || __state.filters.groupName,
                }, 
                pagination:{
                    ...__state.pagination, 
                    page:           __action.payloads.page || __state.pagination.page, 
                    pageSizes:      __action.payloads.pageSizes || __state.pagination.pageSizes, 
                    totalRows:      __action.payloads.totalRows,
                    begin:          __action.payloads.begin 
                }
            };

        case "IMPORT_PATIENTS_IN_GROUP_GROUPSPATIENT":

            return {
                ...__state,
                patientsInGroup:{
                    data:                  __action.payloads.data,
                    sorts:{
                        sortBy:            __action.payloads.sortBy, 
                        sortValue:         __action.payloads.sortValue
                    }, 
                    filters:{
                        inGroupId:          __action.payloads.inGroupId, 
                        statusRPM:          __action.payloads.statusRPM,
                        firstName:          __action.payloads.firstName,
                        lastName:           __action.payloads.lastName,
                        dob:                __action.payloads.dob,
                        email:              __action.payloads.email,
                        mrn:                __action.payloads.mrn,
                        phoneMobile:        __action.payloads.phoneMobile
                    }, 
                    pagination:{
                        ...__state.patientsInGroup.pagination, 
                        page:              __action.payloads.page, 
                        pageSizes:         __action.payloads.pageSizes, 
                        totalRows:         __action.payloads.totalRows,
                        begin:             __action.payloads.begin 
                    }
                }
            };

        case "IMPORT_PATIENTS_OUT_GROUP_GROUPSPATIENT":
            
            return {
                ...__state,
                patientsOutGroup:{
                    data:                  __action.payloads.data,
                    sorts:{
                        sortBy:            __action.payloads.sortBy, 
                        sortValue:         __action.payloads.sortValue
                    }, 
                    filters:{
                        outGroupId:         __action.payloads.outGroupId, 
                        statusRPM:          __action.payloads.statusRPM,
                        firstName:          __action.payloads.firstName,
                        lastName:           __action.payloads.lastName,
                        dob:                __action.payloads.dob,
                        email:              __action.payloads.email,
                        mrn:                __action.payloads.mrn,
                        phoneMobile:        __action.payloads.phoneMobile
                    }, 
                    pagination:{
                        ...__state.patientsOutGroup.pagination, 
                        page:              __action.payloads.page, 
                        pageSizes:         __action.payloads.pageSizes, 
                        totalRows:         __action.payloads.totalRows,
                        begin:             __action.payloads.begin 
                    }
                }
            }

        case "LOAD_MORE_PAGE_GROUPSPATIENT":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        case "RESET_GROUPSPATIENT":
            
            return stateGroupsPatient;
        
        default: 

            return __state;
            
    };

};

export default GroupsPatientReducer;