import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import * as configs from '../../../../lib/configs';

const initialState = () => {

  return{

  };

}

const _ref="SystemAbnormalsComponent";

export default class SystemAbnormalsComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = initialState();

	};

    componentWillReceiveProps(nextProps){



    }

    handleOnFirstPage = () => {

        this.props.pagination.page = 1;
        this.props.func.getAbnormal_systemAbnormalAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnLastPage = () => {

        this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
        this.props.func.getAbnormal_systemAbnormalAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnChangePage = (__page) => {
    
        this.props.pagination.page = __page;
        this.props.func.getAbnormal_systemAbnormalAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnPreviousPage = () => {

        this.props.pagination.page = this.props.pagination.page - 1;
        this.props.func.getAbnormal_systemAbnormalAction(this.props.filters, this.props.sorts, this.props.pagination);

    }

    handleOnNextPage = () => {

        this.props.pagination.page = this.props.pagination.page + 1;
        this.props.func.getAbnormal_systemAbnormalAction(this.props.filters, this.props.sorts, this.props.pagination);
    
    }

    handleOnLoadMorePage = (__pagination, __plus) => {

        this.props.func.loadMorePage_systemAbnormalAction(__pagination, __plus);

    }

    handleOnCallMsgBox = (__button) => {


    }

    renderAbnormals = (__data) =>{
        return (__data.map((__r)=>{

          return(
            <div key={uuidv4()} className="k-widget-5__item k-widget-5__item--warning">
                <div className="k-widget-5__item-info">
                    <a href="#" className="k-widget-5__item-title">
                        {__r.device}
                    </a>
                    <div className="k-widget-5__item-datetime">
                        {__r.warning}
                    </div>
                </div>
                <div className="k-widget-5__item-check">

                </div>
            </div>
          );
    
          }));
      }

      
    render() {

        return(

            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
                {
                    !configs.scopes.readDashboards
                    ?
                        <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                        <div className="k-iconbox__icon">
                            <div className="k-iconbox__icon-bg"></div>
                            <i className="fa fa-lock"></i>
                        </div>
                        <div className="k-iconbox__title">
                            403 FORBIDDEN
                        </div>
                        <div className="k-iconbox__content">
                            You not enough rights to access to this item
                        </div>
                        </div>
                    :
                        <React.Fragment>
                            <div className="k-widget-5 tab-scroll" style={{marginBottom: "25px", height: "400px", overflow: "auto", overflowX: "hidden"}}>
                                {this.renderAbnormals(this.props.data)}
                            </div>
                            
                            <div style={{display: "flex", width: "100%"}}>
                                <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                            </div>
                        </React.Fragment>
                }
            </React.Fragment>
        )

    }

}