import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import PaginationComponent from '../../../features/pagination/paginationComponent';

import func from '../../../../lib/func';
import * as configs from '../../../../lib/configs';

const schema = {
  "fullname":         "^[A-Za-z\\s]+$",
  "email":            "^[a-z0-9\\_\\.]{1,30}@[a-z0-9]{2,}(\\.[a-z0-9]{2,4}){1,2}$",
  "SMS":              "^[0-9\\s\\+]+$",
  "file_folder":      "^[A-Za-z0-9\\_\\s]*$",
  "sFTP_endpoint":    "^[A-Za-z0-9\\:\\/\\?\\=\\\\_\\-\\s]*$"
}

const initialState = () => {

  return{
    showFormData:   false,
    validation: {
      formData:     {}
    },
    method:             "",
    formData: {
      id:               "",
      fullname:         "",
      email:            "",
      SMS:              "",
      file_folder:      "",
      sFTP_endpoint:    ""
    }
  };

}

const _ref="CustomersComponent";

export default class CustomersComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {
      
      this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
      this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);


  }

  handleOnChangePage = (__page) => {

      this.props.pagination.page = __page;
      this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnPreviousPage = () => {

      this.props.pagination.page = this.props.pagination.page - 1;
      this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

      this.props.pagination.page = this.props.pagination.page + 1;
      this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

      this.props.func.loadMorePage_customersAction(__pagination, __plus);
      
  }

  handleOnSort = (__field) =>{

    if(this.props.data.length === 0){

        return;
  
    };

    let sortValue = "";

    if(__field === this.props.sorts.sortBy){

        if(this.props.sorts.sortValue === "ASC"){
  
            sortValue = "DESC";
  
        };
        
        if(this.props.sorts.sortValue === "DESC"){
  
  
            sortValue = "ASC";
          
        };
  
        this.props.sorts.sortValue = sortValue;

    }else{
  
        this.props.sorts.sortBy       = __field;
        this.props.sorts.sortValue    = "ASC";
  
    };
    
    this.props.func.getCustomers_customersAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnYes"){

      this.props.func.delete_customersAction({id: this.state.formData.id})

    };

    this.handleOnCallFormData(false);

  }

  handleOnChangeFormData = (__e) =>{

    if(__e.target.name in this.state.validation.formData){

        delete this.state.validation.formData[__e.target.name];

    };

    this.setState({
        validation: {
            ...this.state.validation,
            formData: {
                ...this.state.validation.formData
            }
        },
        formData:{
            ...this.state.formData,
            [__e.target.name]:  __e.target.value
        }
    });

  }

  handleOnCreate = () =>{

    this.handleOnCallFormData(true);
    this.setState({
        method:     "POST"
    });

  }

  handleOnUpdate = (__obj) =>{

      this.handleOnCallFormData(true);
      this.setState({
          method:             "PUT",
          formData:{
            id:               __obj.id,
            fullname:         __obj.fullname,
            email:            __obj.email,
            SMS:              __obj.SMS,
            file_folder:      __obj.file_folder,
            sFTP_endpoint:    __obj.sFTP_endpoint
          }
      });

  }

  handleOnDelete = (__obj) =>{

    this.setState({
        method: "DELETE",
        formData:{
          ...this.state.formData,
          id:         __obj.id
        }
    });

    this.props.func.create_MsgBoxAction(_ref, "Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);

  }

  handleOnSubmitFormdata = () =>{

    let error           = {};
    let formData        = Object.assign({}, this.state.formData);

    func.validate(schema, formData, error);

    if(Object.keys(error).length > 0){

        this.setState({
            validation:{
                ...this.state.validation,
                formData: error
            }
        });

        return;

    };


    if(this.state.method === "POST"){
     
      this.props.func.create_customersAction(formData);

    }else{

      this.props.func.update_customersAction(formData);

    };

    this.handleOnCallFormData(false);

  }
  
  handleOnCallFormData = (__show) => {

    if(!__show){

      this.handelOnResetComponent();
      
    };

    this.setState({
      showFormData: __show
    })

  }

  handleOnRefreshData = () => {

    this.props.func.getCustomers_customersAction();
    this.handelOnResetComponent();

  }

  handelOnResetComponent = () =>{

    this.setState(initialState());

  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
          !configs.scopes.readCustomers
            ?
                <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                <div className="k-iconbox__icon">
                    <div className="k-iconbox__icon-bg"></div>
                    <i className="fa fa-lock"></i>
                </div>
                <div className="k-iconbox__title">
                    403 FORBIDDEN
                </div>
                <div className="k-iconbox__content">
                    You not enough rights to access to this item
                </div>
                </div>
            :
              <React.Fragment>
                <div style={{display: "flex", float: "right", marginBottom: "10px"}}>
                  <button hidden={!configs.scopes.addCustomers} onClick={()=>{this.handleOnCreate()}} type="button" style={{marginRight: "10px"}} className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-plus"></i> Add New</button>
                  <button onClick={()=>{this.handleOnRefreshData()}} type="button" style={{}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                </div>

                <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded">
                  <table className="k-datatable__table list-database" style={{marginBottom: "15px", display: "block", minHeight: "300px", overflow: "auto"}}>
                    <thead className="k-datatable__head">
                    <tr className="k-datatable__row">
                    <th onClick={()=>{this.handleOnSort("fullName")}}  className={this.props.sorts.sortBy === "fullName" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "150px"}}>FULL NAME {this.props.sorts.sortBy === "fullName" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("email")}}  className={this.props.sorts.sortBy === "email" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "200px"}}>EMAIL {this.props.sorts.sortBy === "email" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("sms")}}  className={this.props.sorts.sortBy === "sms" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "130px"}}>SMS {this.props.sorts.sortBy === "sms" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "200px"}}> FILE FOLDER</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "250px"}}> SFTP ENDPOINT</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "100px"}}> ACTIONS</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="k-datatable__body">
                      {
                        this.props.data.map((__r)=>(
                          <tr key={__r.id} className="k-datatable__row" style={{left: "0px"}}>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "150px"}}>{__r.fullname}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "200px"}}>{__r.email}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "130px"}}>{__r.SMS}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "200px"}}>{__r.file_folder}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "250px"}}>{__r.sFTP_endpoint}</span>
                            </td>
                            <td className="k-datatable__cell--left k-datatable__cell">
                              <span style={{width: "100px"}}>
                                <a hidden={!configs.scopes.editCustomers} onClick={()=>{this.handleOnUpdate(__r)}} title="Edit details" className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                <i className="la la-edit"></i>						
                                </a>						
                                <a hidden={!configs.scopes.delCustomers} onClick={()=>{this.handleOnDelete(__r)}} title="Delete" className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                                  <i className="la la-trash"></i>						
                                </a>	
                              </span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                  <PaginationComponent pagination={this.props.pagination} handleLoadMorePage={this.handleOnLoadMorePage} handleChangePage={this.handleOnChangePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                
                </div>

                <Modal show={this.state.showFormData} onHide={()=>{this.handleOnCallFormData(false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-750">
                  <Modal.Header closeButton>
                      <Modal.Title style={{fontSize: "inherit"}}>Create customer</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                        <div className="form-group">
                          <label>Fullname</label>
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.fullname || ""} name="fullname" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["fullname"]}</span>
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.email || ""}  name="email" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["email"]}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-1">
                        <div className="form-group">
                          <label>SMS</label> 
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.SMS || ""}  name="SMS" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["SMS"]}</span>
                        </div>
                        <div className="form-group">
                          <label>File folder</label>
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.file_folder || ""}  name="file_folder" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["file_folder"]}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12 order-lg-2 order-xl-1">
                        <div className="form-group" style={{marginBottom: "0px"}}>
                          <label>SFTP endpoint</label>
                          <input type="text" className="form-control form-control-sm" onChange={this.handleOnChangeFormData} value={this.state.formData.sFTP_endpoint || ""}  name="sFTP_endpoint" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["sFTP_endpoint"]}</span>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer style={{height: "50px", margin: "auto"}}>
                      <button style={{width: "150px"}} onClick={()=>{this.handleOnSubmitFormdata()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-save"></i> Save</button>
                      <button style={{width: "150px"}} onClick={()=>{this.handleOnCallFormData(false)}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sign-out-alt"></i> Close</button>
                  </Modal.Footer>
                </Modal>
              </React.Fragment>
        }
      </React.Fragment>
    )

  }

}