
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TimerLogsComponent from '../components/dashboards/contents/timerLogs/timerLogsComponent';

import {loadMorePage_timerLogsAction, dowloadFile_timerLogsAction, getLogs_timerLogsAction, create_timerLogsAction, update_timerLogsAction, delete_timerLogsAction, reset_timerLogsAction, callTimerLogs_timerLogsAction} from '../actions/timerLogsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class TimerLogsContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<TimerLogsComponent 
					data = {this.props.timerLogs.data} 
					filters = {this.props.timerLogs.filters} 
					pagination = {this.props.timerLogs.pagination} 
					sorts = {this.props.timerLogs.sorts} 
					categories = {this.props.timerLogs.categories}
					months = {this.props.timerLogs.months}
					totalTimes = {this.props.timerLogs.totalTimes}
					show = {this.props.timerLogs.show}
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		timerLogs: __state.TimerLogsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			dowloadFile_timerLogsAction: async (__filters) => {
				return await dowloadFile_timerLogsAction(__filters);
			},
			getLogs_timerLogsAction: (__mrn, __month, __sortBy, __sortValue, __page, __pageSizes) => {
				__dispatch(getLogs_timerLogsAction(__mrn, __month, __sortBy, __sortValue, __page, __pageSizes));
			},
			loadMorePage_timerLogsAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_timerLogsAction(__pagination, __plus));
			},
			create_timerLogsAction: (__formData) => {
				__dispatch(create_timerLogsAction(__formData));
			},
			update_timerLogsAction: (__formData) => {
				__dispatch(update_timerLogsAction(__formData));
			},
			delete_timerLogsAction: (__formData) => {
				__dispatch(delete_timerLogsAction(__formData));
			},
			callTimerLogs_timerLogsAction: (__bool) => {
				__dispatch(callTimerLogs_timerLogsAction(__bool))
			},
			reset_timerLogsAction: () => {
				__dispatch(reset_timerLogsAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(TimerLogsContainer);