import httpClient from '../lib/httpClient';

import {stateGroupsPatient} from '../reducers/groupsPatientReducer';

import {showLoading} from './loadingAction';
import {create_MsgBoxAction} from './msgBoxAction';

import * as configs from '../lib/configs';

const _ref="GroupsPatientComponent";
 
export const create_groupsPatientAction = (__formData) => async(__dispatch) => {
    try {
        
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsPatient?func=createGroup", "POST", __formData, null);
        
        __dispatch(getGroups_groupsPatientAction());
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const update_groupsPatientAction = (__formData) => async(__dispatch) => {
    try {

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsPatient?func=updateGroup", "PUT", __formData, null);

        __dispatch(getGroups_groupsPatientAction());
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const delete_groupsPatientAction = (__formData) => async(__dispatch) => {
    try {
        
        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsPatient", "DELETE", __formData, null);

        __dispatch(getGroups_groupsPatientAction());
        __dispatch(showLoading(false));
        
    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const getGroups_groupsPatientAction = (__filters = Object.assign({}, stateGroupsPatient.filters), __sorts = Object.assign({}, stateGroupsPatient.sorts), __pagination = Object.assign({}, stateGroupsPatient.pagination)) => async(__dispatch) => {
    
    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/groupsPatient?func=getGroups" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);
 
        __dispatch(showLoading(false));
        __dispatch(importGroups_groupsPatientAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importGroups_groupsPatientAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_GROUPSPATIENT",
        payloads: {
            groupName:    __filters.groupName || "",
            data:         __data,
            totalRows:    __totalRows,
            sortBy:       __sorts.sortBy,
            sortValue:    __sorts.sortValue,
            page:         __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:    __pagination.pageSizes,
            begin:        __pagination.begin
        }
    };

} 

export const loadMorePage_groupsPatientAction = (__pagination, __plus) => {

    let totalPage   = Math.ceil(__pagination.totalRows / __pagination.pageSizes);
    let begin       = 1;

    if(__plus){ 

        begin = __pagination.begin + __pagination.length;
        begin = begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : begin;

    }else{

        begin = __pagination.begin - __pagination.length;
        begin = begin < 1 ? 1 : begin;

    };

    return {
        type: "LOAD_MORE_PAGE_GROUPSPATIENT",
        payloads: begin
    };

}

export const reset_groupsPatientAction = () => {

    return {
        type: "RESET_GROUPSPATIENT",
        payloads: {}
    };

}

export const getPatientsOutGroup_groupsPatientAction = (__filters = Object.assign({}, stateGroupsPatient.patientsOutGroup.filters), __sorts = Object.assign({}, stateGroupsPatient.patientsOutGroup.sorts), __pagination = Object.assign({}, stateGroupsPatient.patientsOutGroup.pagination)) => async(__dispatch) => {
 
    try {
        
        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;

        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=getPatients" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importPatientsOutGroup_groupsPatientAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importPatientsOutGroup_groupsPatientAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_PATIENTS_OUT_GROUP_GROUPSPATIENT",
        payloads: {
            data:           __data,
            totalRows:      __totalRows,
            outGroupId:     __filters.outGroupId || "", 
            statusRPM:      __filters.statusRPM || "",
            firstName:      __filters.firstName || "",
            lastName:       __filters.lastName || "",
            dob:            __filters.dob || "",
            email:          __filters.email || "",
            mrn:            __filters.mrn || "",
            phoneMobile:    __filters.phoneMobile || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const getPatientsInGroup_groupsPatientAction = (__filters = Object.assign({}, stateGroupsPatient.patientsInGroup.filters), __sorts = Object.assign({}, stateGroupsPatient.patientsInGroup.sorts), __pagination = Object.assign({}, stateGroupsPatient.patientsInGroup.pagination)) => async(__dispatch) => {
 
    try {

        let query       = [];
        let response    = {};

        __pagination.pageSizes   = isNaN(parseInt(__pagination.pageSizes)) || parseInt(__pagination.pageSizes) < 5 ? configs.pageSizes : __pagination.pageSizes;
        __pagination.page        = isNaN(parseInt(__pagination.page)) || parseInt(__pagination.page) < 1 ? 1 : __pagination.page;
        
        for( let k in __filters){

            query.push(k + "=" + encodeURIComponent(__filters[k]));
 
        };
 
        query.push("page=" + __pagination.page);
        query.push("pageSizes=" + __pagination.pageSizes);
        query.push("sortBy=" + encodeURIComponent(__sorts.sortBy));
        query.push("sortValue=" + encodeURIComponent(__sorts.sortValue));
        query.push("timeZone=" + encodeURIComponent(configs.timeZone));

        __dispatch(showLoading(true));

        response = await httpClient.request(configs.endPoint + "/patients?func=getPatients" + (query.length > 0 ? "&" + query.join("&") : ""), "GET", {}, null);

        __dispatch(showLoading(false));
        __dispatch(importPatientsInGroup_groupsPatientAction(response.data, response.totalRows, __filters, __sorts, __pagination));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}

export const importPatientsInGroup_groupsPatientAction = (__data, __totalRows, __filters, __sorts, __pagination) => {

    let totalPage = Math.ceil(__totalRows / __pagination.pageSizes);
        totalPage = totalPage === 0 ? 1 : totalPage;

    if(__pagination.page === 1){

        __pagination.begin = 1;

    };

    if(__pagination.page >= totalPage){

        __pagination.begin = (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1;

    };

    if(__pagination.page >= __pagination.begin + __pagination.length){

        __pagination.begin = __pagination.begin + __pagination.length;
        __pagination.begin = __pagination.begin > totalPage ? (Math.ceil(totalPage / __pagination.length) - 1)  * __pagination.length + 1  : __pagination.begin;

    };

    if(__pagination.page < __pagination.begin){

        __pagination.begin = __pagination.begin - __pagination.length;
        __pagination.begin = __pagination.begin < 1 ? 1 : __pagination.begin;

    };

    return {
        type: "IMPORT_PATIENTS_IN_GROUP_GROUPSPATIENT",
        payloads: {
            data:           __data,
            totalRows:      __totalRows,
            inGroupId:      __filters.inGroupId || "",
            statusRPM:      __filters.statusRPM || "",
            firstName:      __filters.firstName || "",
            lastName:       __filters.lastName || "",
            dob:            __filters.dob || "",
            email:          __filters.email || "",
            mrn:            __filters.mrn || "",
            phoneMobile:    __filters.phoneMobile || "",
            sortBy:         __sorts.sortBy,
            sortValue:      __sorts.sortValue,
            page:           __pagination.page > totalPage ? totalPage : __pagination.page,
            pageSizes:      __pagination.pageSizes,
            begin:          __pagination.begin
        }
    };

}

export const mapPatientsToGroup_groupsPatientAction = (__formData) => async(__dispatch) => {
 
    try {

        __dispatch(showLoading(true));

        await httpClient.request(configs.endPoint + "/groupsPatient?func=mapPatientsToGroup", "PUT", __formData, null);
        
        __dispatch(getPatientsOutGroup_groupsPatientAction({outGroupId: __formData.groupId}));
        __dispatch(getPatientsInGroup_groupsPatientAction({inGroupId: __formData.groupId}));
        __dispatch(showLoading(false));

    } catch (error) {

        __dispatch(showLoading(false));
        __dispatch(create_MsgBoxAction(_ref, "Error", error.message, [{key: "btnClose", title: "Close"}], "error", null, null));

    }

}