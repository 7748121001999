import React, {Component} from 'react';
import { connect } from 'react-redux';

import SystemMonitorComponent from '../components/dashboards/contents/systemMonitor/systemMonitorComponent';

import {getSummary_SystemMonitorAction, loadMorePage_SystemMonitorAction, reset_SystemMonitorAction} from '../actions/systemMonitorAction';
import {getSummary_chartSummaryBatteriesAction, reset_chartSummaryBatteriesAction} from '../actions/chartSummaryBatteriesAction';
import {showLoading} from '../actions/loadingAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class SystemMonitorContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <SystemMonitorComponent
            data = {this.props.SystemMonitor.data} 
			chartData = {this.props.ChartSummaryBatteries}
            filters = {this.props.SystemMonitor.filters} 
            pagination = {this.props.SystemMonitor.pagination} 
            sorts = {this.props.SystemMonitor.sorts} 
            func = {this.props.func}
        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
		SystemMonitor: __state.SystemMonitorReducer,
		ChartSummaryBatteries: __state.ChartSummaryBatteriesReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getSummary_SystemMonitorAction: (__filters, __sorts, __pagination) => {
				__dispatch(getSummary_SystemMonitorAction(__filters, __sorts, __pagination))
			},
			loadMorePage_SystemMonitorAction: (__pagination, __plus) => {
				__dispatch(loadMorePage_SystemMonitorAction(__pagination, __plus))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
      		//////////////////////////////////
      		getSummary_chartSummaryBatteriesAction: (__device, __option) => {
				__dispatch(getSummary_chartSummaryBatteriesAction(__device, __option));
			},
			reset_chartSummaryBatteriesAction: () => {
				__dispatch(reset_chartSummaryBatteriesAction());
			},
			/////////////////////////////
			showLoading: (__show) => {
				__dispatch(showLoading(__show));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(SystemMonitorContainer);