
import React, { Component } from 'react';
import { connect } from 'react-redux';

import QuickPanelComponent from '../components/dashboards/contents/quickPanel/quickPanelComponent';

import {showMainPanel_quickPanelAction,showSubPanel_quickPanelAction,reset_quickPanelAction} from '../actions/quickPanelAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class QuickPanelContainer extends Component{

	render() {

		return(
			<React.Fragment>
				<QuickPanelComponent 
					show = {this.props.quickPanelReducer.show}
					tab	= {this.props.quickPanelReducer.tab}
					subPanel = {this.props.quickPanelReducer.subPanel}
                    func = {this.props.func}
                />
			</React.Fragment>
		);

	};

};

const mapState = __state => {

	return{
		quickPanelReducer: __state.QuickPanelReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			showMainPanel_quickPanelAction: (__show, __tab) => {
				__dispatch(showMainPanel_quickPanelAction(__show, __tab));
			},
			showSubPanel_quickPanelAction: (__show, __data) => {
				__dispatch(showSubPanel_quickPanelAction(__show, __data));
			},
			reset_quickPanelAction: () => {
				__dispatch(reset_quickPanelAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(QuickPanelContainer);