
import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';

import './msgBoxStyle.css';

export default class MsgBoxComponent extends Component{

    constructor(__props) {

		super(__props);

	};

	render() {

		return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={()=>{this.props.onClose(this.props.id)}} backdrop="static" style={{top: "200px", zIndex: "99999999999999999999999"}} dialogClassName="modal-dialog modal-md">
                    <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                        <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>{this.props.title || ""}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{marginBottom: "10px", minHeight: "70px"}}>
                            <div className="alert-icon" style={{width: "50px", height: "50px", float: "left", marginRight: "15px", fontSize: "xx-large",textAlign: "center"}}>
                                {
                                    String(this.props.type).toLowerCase() === "error" ? <i className="fa fa-times-circle" style={{color: "#ff0000"}}></i> : 
                                    String(this.props.type).toLowerCase() === "question" ? <i className="fa fa-question-circle" style={{color: "#5867dd"}}></i>:
                                    String(this.props.type).toLowerCase() === "warning" ? <i className="fa fa-exclamation-triangle" style={{color: "#ffb822"}}></i> : 
                                    <i className="fa fa-exclamation-circle" style={{color: "#5867dd"}}></i>
                                }
                            </div>
                            <div className="alert-text" style={{display: "contents", float: "left"}}>{this.props.message || ""}</div>
                        </div>
                        {
                            this.props.process ?  
                                <div className="progress" style={{width: "100%"}}>
                                    <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: this.props.percent || 0 + "%"}}></div>
                                </div>
                            : ""
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.props.buttons.map((__b)=>(
                                <button key={__b.key + "_" + this.props.id} onClick={()=>{this.props.onClick(__b.key + "_" + this.props.id)}} className="btn btn-secondary btn-sm btn-sm" type="button">{__b.title || ""}</button>
                            ))
                        }
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
		);

	};

};
