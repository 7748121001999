
import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from "moment";
import crypto from "crypto";

import { Modal } from 'react-bootstrap';
import { Redirect } from 'react-router'
import {showLoading} from '../../actions/loadingAction';

import MsgBoxContainer from '../../containers/msgBoxContainer';

import httpClient from '../../lib/httpClient';
import * as configs from '../../lib/configs';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import {create_MsgBoxAction} from '../../actions/msgBoxAction';

import './loginStyle.css'

const initialState = () => {
    return{
        username:               "",
        password:               "",
        newpassword:            "",
        accesscode:             "",
        token:                  "",
        qrCode:                 "",
        cognitoUser:            null,
        accountStatus:          null,
        userAttributes:         null,
        showFormSecondLogin:    false,
        redirect:               false
    };
}

const _ref="LoginComponent";

class LoginComponent extends Component{

	constructor(__props) {

		super(__props)

        this.state = initialState();

	}

    componentDidMount(){

        let token =  window.sessionStorage.getItem("token");

        if(token){

            let arr = token.split(".");

            if(arr.length === 3){

                let obj = JSON.parse(Buffer.from(arr[1], "base64").toString("utf-8"));

                if(obj.exp >= moment().unix()){
                    this.setState({
                        redirect: true
                    });
                }

            }

        }

    }

    componentWillUnmount(){

    }

    handelOnResetComponent = () => {

        this.setState(initialState());
    
    }

    handleOnChange = (__e) => {

        if(__e.target.name === "username"){
            this.setState({
                password: "",
                username: __e.target.value
            });
        }else{
            this.setState({
                [__e.target.name]: __e.target.value
            });
        }

    }

    handleOnLogin = async () => {

        try {

            this.props.func.showLoading(true);

            await this.handleOnAuthenticate();

            this.props.func.showLoading(false);

        } catch (error) {

            this.props.func.showLoading(false);
            this.props.func.create_MsgBoxAction("LoginComponent","Login failed", error.message, [{key: "btnClose", title: "Close"}], "error", null, null);
                
        }

    }

    handleOnAfterLogin = async (__token) => {

        try{
            let buffer  = Buffer.from(__token + this.state.password);
            let hash    = crypto.createHash("sha256").update(buffer).digest("base64");

            let response = await httpClient.request(configs.endPoint + "/getScopesAuthorized", "GET", {}, {"Content-Type": "application/json", "Authorization": __token});

            window.sessionStorage.setItem("user", Buffer.from(__token.split(".")[1], 'base64').toString('utf-8'));
            window.sessionStorage.setItem("token", __token);
            window.sessionStorage.setItem("accesscode", hash);
            window.sessionStorage.setItem("scopes", response.data);

            configs.setupScopes();

            this.setState({
                showFormSecondLogin:    false,
                redirect:               true
            });
            
        } catch (error) {

            this.props.func.showLoading(false);
            this.props.func.create_MsgBoxAction("LoginComponent","Verify failed", error.message, [{key: "btnClose", title: "Close"}], "error", null, null);
            
        }

    }

    handleOnSecondLogin = async () => {

        try {

            this.props.func.showLoading(true);

            if(this.state.accountStatus=="sms"){

                await this.handleOnVerifySMS();

            }else if(this.state.accountStatus=="change_pass"){

                await this.handleOnChangePass();

            }else if(this.state.accountStatus=="otp"){

                await this.handleOnVerifyOTP();

            }
            
            this.props.func.showLoading(false);

        } catch (error) {

            this.props.func.showLoading(false);
            this.props.func.create_MsgBoxAction("LoginComponent","Verify failed", error.message, [{key: "btnClose", title: "Close"}], "error", null, null);
            
        }

    }

    handleOnChangePass = () => {

        return new Promise((__reso, __reje) => {
            this.state.cognitoUser.completeNewPasswordChallenge(this.state.newpassword, this.state.userAttributes, {
                onSuccess: async(result) => {

                    if(String(configs.verifyMF).toLowerCase()=="otp"){

                        let qrcode = result.idToken.payload["custom:qrcode"];

                        this.setState({
                            showFormSecondLogin:    true,
                            accountStatus:          "otp",
                            token:                  result.idToken.jwtToken,
                            qrCode:                 qrcode
                        });

                        return __reso();
                    }

                    await this.handleOnAfterLogin(result.idToken.jwtToken);

                    return __reso();
                },
                onFailure: (error)=>{
                    return __reje(error)
                },
                mfaRequired: (codeDeliveryDetails)=>{

                    this.setState({
                        accountStatus:  "sms",
                        newpassword:    "",
                        accesscode:     ""
                    });

                    return __reso();

                }
            });
        })

    }


    handleOnVerifySMS = () => {

        return new Promise((__reso, __reje) => {
            this.state.cognitoUser.sendMFACode(this.state.accesscode, {
                onSuccess: async (result) => {

                    await this.handleOnAfterLogin(result.idToken.jwtToken);

                    return __reso();

                },
                onFailure: (error) => {
                    return __reje(error)
                }
            });
        })

    }

    handleOnVerifyOTP = async() => {

        await httpClient.request(configs.endPoint + "/2MF?code=" + this.state.accesscode, "POST", {}, {"Content-Type": "application/json", "Authorization": this.state.token});
        await this.handleOnAfterLogin(this.state.token);

    }

    handleOnAuthenticate = () => {

        return new Promise((__reso, __reje) => {

            let authenticationData = {
                Username: this.state.username,
                Password: this.state.password,
            };
            
            let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
            let poolData = {
                UserPoolId: configs.userPoolId,
                ClientId: configs.userPoolClientId
            };

            let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            let userData = {
                Username: this.state.username,
                Pool: userPool,
            };

            let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            
            cognitoUser.authenticateUser(authenticationDetails,{
                onSuccess: async(result)=>{
                
                    if(String(configs.verifyMF).toLowerCase()=="otp"){
                        
                        let qrcode = result.idToken.payload["custom:qrcode"];

                        this.setState({
                            showFormSecondLogin:    true,
                            accountStatus:          "otp",
                            token:                  result.idToken.jwtToken,
                            qrCode:                 qrcode
                        });

                        return __reso();
                    }

                    // await this.handleOnAfterLogin(result.idToken.jwtToken);
                    
                    return __reso();
                    
                },
                onFailure: __reje,
                mfaRequired: (codeDeliveryDetails)=>{

                    this.setState({
                        showFormSecondLogin:    true,
                        accountStatus:          "sms",
                        cognitoUser:            cognitoUser
                    });

                    return __reso();

                },
                newPasswordRequired: (userAttributes, requiredAttributes)=>{
            
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;
                    delete userAttributes.phone_number;
                    delete userAttributes.email;

                    this.setState({
                        showFormSecondLogin:    true,
                        accountStatus:          "change_pass",
                        userAttributes:         userAttributes,
                        cognitoUser:            cognitoUser
                    });
                  
                    return __reso();

                }
            });

        });

    }

    handleCallFormSecondLogin = (__bool) => {

        if(!__bool){
            this.handelOnResetComponent();
        }

        this.setState({
            showFormSecondLogin: __bool
        });

    }

    handleOnCallMsgBox = (__button) => {
  
    }

	render() {

        if (this.state.redirect) {
            return <Redirect to='/dashboars/patients'/>
        }
         
		return(
            <div style={{backgroundImage: "url('./images/graphic.png')", backgroundRepeat: "repeat", backgroundSize: "cover", backgroundPosition: "center"}} className="k-login-v1--enabled k-header--fixed k-header-mobile--fixed k-aside--enabled k-aside--fixed" cz-shortcut-listen="true">
                
                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                <Modal show={this.state.showFormSecondLogin} onHide={()=>{this.handleCallFormSecondLogin(false)}} style={{top: "120px"}} backdrop="static" dialogClassName="modal-dialog  modal-sm form-timer-logs">
                    <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}} closeButton>
                        <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>{this.state.accountStatus != "change_pass"? "Access Code" : "Change Password"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                            
                        <div hidden={this.state.accountStatus == "otp" && this.state.qrCode != "" ? false : true} className="form-group" style={{marginBottom: "0px"}}>
                            <img src={this.state.qrCode} style={{width:"200px",height:"200px",margin: "auto",display: "block"}}/>
                        </div>
                

                        <div className="form-group" style={{marginBottom: "0px"}}>
                            {
                                this.state.accountStatus != "change_pass" ?
                                    <input tabIndex="4" onKeyDown={async(__e)=>{if(__e.key === "Enter"){await this.handleOnSecondLogin()}}} onChange={this.handleOnChange} type="text" name="accesscode" className="form-control form-control-sm" autoComplete="off" />
                                :
                                    <input tabIndex="4" onKeyDown={async(__e)=>{if(__e.key === "Enter"){await this.handleOnSecondLogin()}}} onChange={this.handleOnChange} type="password" name="newpassword" className="form-control form-control-sm" autoComplete="off" />
                            }
                       </div>

                    </Modal.Body>
                    <Modal.Footer style={{height: "50px"}}>
                        <button tabIndex="5" style={{width: "100%"}} onKeyDown={async(__e)=>{if(__e.key === "Enter"){await this.handleOnSecondLogin()}}} onClick={this.handleOnSecondLogin} type="button" className="btn btn-secondary btn-pill btn-sm"><i className="flaticon2-paper-plane"></i> Submit</button>
                    </Modal.Footer>
                </Modal>
              
                <div className="k-grid k-grid--ver k-grid--root k-page">
                    <div className="k-grid__item k-grid__item--fluid k-grid  k-grid k-grid--hor k-login-v1" id="k_login_v1">
                        <div className="k-grid__item  k-grid--hor">
                            <div className="k-login-v1__head">
                                <div className="k-login-v1__head-logo">
                                    <a href="#">
                                        <img src={configs.logoDesktop} alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="k-grid__item  k-grid  k-grid--ver  k-grid__item--fluid ">
                            <div className="k-login-v1__body">
                                <div className="k-login-v1__body-wrapper" style={{margin: "auto", marginTop: "20px"}}>
                                    <div className="k-login-v1__body-container" style={{margin: "auto"}}>
                                        {/* <h3 className="k-login-v1__body-title">
                                            Sign To Account
                                        </h3> */}
                                        <form className="k-login-v1__body-form k-form" action="#" autoComplete="off" _lpchecked="1">
                                            <div className="form-group">
                                                <input tabIndex="1" onChange={this.handleOnChange} value={this.state.username} className="form-control login_txt" type="text" placeholder="Email Address" id="emailInputSignin" name="username" autoComplete="off"/>
                                            </div>
                                            <div className="form-group">
                                                <input tabIndex="2" value={this.state.password} onKeyDown={(__e)=>{if(__e.key === 'Enter'){this.handleOnLogin()}}} onChange={this.handleOnChange}  className="form-control login_txt" type="password" placeholder="Password" id="passwordInputSignin" name="password" autoComplete="off"/>
                                            </div>

                                            <div className="k-login-v1__body-action">
                                                {/* <a href="#" className="k-link">
                                                    <span>Forgot Password ?</span>
                                                </a> */}
                                                <button tabIndex="3" style={{width: "100%"}} onKeyDown={(__e)=>{if(__e.key === 'Enter'){this.handleOnLogin()}}} onClick={this.handleOnLogin} type="button" className="btn btn-pill btn-elevate">Sign In</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="k-grid__item">
                            <div className="k-login-v1__footer" style={{marginTop: "20px"}}>
                                <div className="k-login-v1__footer-link">
                                    <a href="#" className="k-link">Privacy</a>
                                    <a href="#" className="k-link">Legal</a>
                                    <a href="#" className="k-link">Contact</a>
                                </div>
                                <div className="k-login-v1__footer-info">
                                    2022&nbsp;©&nbsp;<a href="https://evexia.tadahcorp.com" target="_blank" className="k-link">Website Evexia</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = __state => {

	return{
        LayoutReducer: __state.LayoutReducer
    }

}

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
        showLoading: (__show) => {
            __dispatch(showLoading(__show));
        },
        create_MsgBoxAction:(__ref, __title, __message, __buttons, __type, __process, __percent)=>{
            __dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent))
        }
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(LoginComponent)