import React, { Component } from 'react';

import moment from "moment";

import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import AutoCompleteComponent from '../../../features/autoComplete/autoCompleteComponent'
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';

import * as definitions from '../../../../lib/definitions';
import * as configs from '../../../../lib/configs';

import func from '../../../../lib/func';

const initialState = () => {

  return{
    validation:{
      formFilter: {}
    },
    formFilter:{
      uuid:               "",
      status:             "",
      fromDateSchedules:  "",
      toDateSchedules:    "",
      filterBy:           "1",
    },
    formData:{
      id:                 "",
      target:             ""
    }
  };

}

const _ref="SchedulesComponent";

export default class SchedulesComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

    this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {
    
    this.props.pagination.page = __page;
    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);
  
  }

  handleOnPreviousPage = () => {

    this.props.pagination.page = this.props.pagination.page - 1;
    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

    this.props.pagination.page = this.props.pagination.page + 1;
    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

    this.props.func.loadMorePage_schedulesAction(__pagination, __plus);

  }

  handleOnSort = (__field) => {

    if(this.props.data.length === 0){

      return;

    };

    let sortValue = "";

    if(__field === this.props.sorts.sortBy){


      if(this.props.sorts.sortValue === "ASC"){

        sortValue = "DESC";

      }

      if(this.props.sorts.sortValue === "DESC"){

        sortValue = "ASC";
        
      };

      this.props.sorts.sortValue = sortValue;

    };

    if(__field !== this.props.sorts.sortBy){

      this.props.sorts.sortBy    = __field;
      this.props.sorts.sortValue = "ASC";

    };

    this.props.func.getSchedules_schedulesAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnSubmitFilter = (__e) => {

    let formData  =     Object.assign({}, this.state.formFilter);
    let error     = {};

    if(formData.filterBy === "1"){

      if(func.isEmptyOrNull(formData.status)){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: {status: "Required"}
          }
        });

        return;

      };

      this.props.filters.status     = formData.status;
      this.props.filters.fromDate   = "";
      this.props.filters.toDate     = "";
      this.props.filters.uuid       = "";

    };

    if(formData.filterBy === "2"){

      let error     = {};
      let fromDate  = moment.utc(formData.fromDateSchedules, configs.formatDateOnSite) ;
      let toDate    = moment.utc(formData.toDateSchedules, configs.formatDateOnSite);

      if(!fromDate.isValid() || fromDate > toDate){

        error.fromDateSchedules = "Invalid";

      };

      if(!toDate.isValid()){

        error.toDateSchedules = "Invalid";

      };

      if(Object.keys(error).length > 0){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: error
          }
        });

        return;

      };

      this.props.filters.fromDate    = fromDate.format(configs.formatDateOutSite);
      this.props.filters.toDate      = toDate.format(configs.formatDateOutSite);
      this.props.filters.status      = "";
      this.props.filters.uuid       = "";

    };

    if(formData.filterBy === "3"){

      func.validate({uuid: "^[A-Za-z0-9]{8}$"}, formData, error);

      if(Object.keys(error).length > 0){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: error
          }
        });

        return;

      };

      this.props.filters.uuid       = formData.uuid;
      this.props.filters.status     = "";
      this.props.filters.fromDate   = "";
      this.props.filters.toDate     = "";

    };

    this.props.func.getSchedules_schedulesAction(this.props.filters);

  }

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnYes"){

      this.props.func.delete_schedulesAction(this.state.formData);
      this.handelOnResetComponent();

    };

  }

  handleOnSubmitDelete = (__obj) => {

    this.setState({
      formData:{id: __obj.id, target: __obj.target}
    });

    this.props.func.create_MsgBoxAction(_ref, "Confirm", "Are you want to delete ?", [{key: "btnYes", title: "Yes"}, {key: "btnNo", title: "No"}], "warning", null, null);

  }

  handleOnChangeFilterData = (__e) => {


    if(__e.target.name in this.state.validation.formFilter){

      delete this.state.validation.formFilter[__e.target.name];

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formFilter: {
          ...this.state.validation.formFilter,
        }
      },
      formFilter:{
        ...this.state.formFilter,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handelOnResetComponent = () => {

    this.setState(initialState());

  }

  handelOnRefreshData = () => {

    this.props.func.getSchedules_schedulesAction({target: this.props.filters.target});
    this.handelOnResetComponent();

  }

  renderListSchedules = (__data) => {

    return (__data.map((__r)=>{

        let status = null;

        if(__r.status !== null && parseInt(__r.status)>0){

          if(JSON.parse(__r.response).status === 200){

            status = <span style={{width: "80px"}}><span className="k-badge k-badge--accent k-badge--dot"></span>&nbsp;<span className="k-font-bold k-font-accent">Spoken</span></span>
          
          }else{

            status =  <span style={{width: "80px"}}><span className="k-badge k-badge--danger k-badge--dot"></span>&nbsp;<span className="k-font-bold k-font-danger">Error</span></span>
          
          }

        }else{
          
          status = <span style={{width: "80px"}}><span className="k-badge k-badge--primary k-badge--dot"></span>&nbsp;<span className="k-font-bold k-font-primary">Pending</span></span>
       
        };

        return(
          <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
              <td className="k-datatable__cell--left k-datatable__cell">
                  <span style={{width: "150px"}}>{moment.utc(__r.createdAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className="k-datatable__cell--left k-datatable__cell">
                  <span style={{width: "80px"}}>{__r.uuid}</span>
              </td>
              <td className="k-datatable__cell--left k-datatable__cell">
                <span style={{width: "200px"}}>
                  {__r.content}
                </span>
              </td>
              <td className="k-datatable__cell--left k-datatable__cell">
                  <span style={{width: "150px"}}>{moment.utc(__r.dateTime, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
              </td>
              <td className="k-datatable__cell--left k-datatable__cell">
                {status}
              </td>
              <td className="k-datatable__cell--left k-datatable__cell">
                <span style={{width: "50px"}}>
                  <a hidden={!configs.scopes.delPatients} onClick={()=>{this.handleOnSubmitDelete(__r)}} title="Delete" className="btn btn-sm btn-clean btn-icon btn-icon-md"><i className="la la-trash"></i></a>					
                </span>
              </td>
          </tr>
        );
    }));
  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
        {
          !configs.scopes.readPatients
          ?
            <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
              <div className="k-iconbox__icon">
                <div className="k-iconbox__icon-bg"></div>
                <i className="fa fa-lock"></i>
              </div>
              <div className="k-iconbox__title">
                403 FORBIDDEN
              </div>
              <div className="k-iconbox__content">
                You not enough rights to access to this item
              </div>
            </div>
          :
            <React.Fragment>
              <div className="row">
                <div className="col-lg-3 col-xl-3 order-lg-1 order-xl-1">
                </div>
                <div className="col-lg-9 col-xl-9 order-lg-1 order-xl-1">  
                  <button onClick={()=>{this.handelOnRefreshData()}} type="button" style={{float:"right", margin: "auto",  marginLeft: "5px"}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                  <button tabIndex="10" onClick={()=>{this.handleOnSubmitFilter()}} type="button" style={{height: "calc(2.0125rem + 2px)", float: "right"}} className="btn btn-sm btn-secondary btn-pill"><i className="la la-filter"></i> Filter</button>
                  <div className="form-group" style={{width: "100px", float: "right", marginRight: "5px"}}>
                      <select tabIndex="6" onChange={this.handleOnChangeFilterData} className="form-control form-control-sm" value={this.state.formFilter.filterBy} name="filterBy">
                        <option value="1">STATUS</option>
                        <option value="2">DATE</option>
                        <option value="3">UUID</option>
                      </select>
                  </div>
                  <div className="form-group" hidden={this.state.formFilter.filterBy !== "1"} style={{float: "right", marginRight: "5px", width: "250px"}}>
                    <AutoCompleteComponent tabIndex="7" useStrict={true} onChange={this.handleOnChangeFilterData} value={this.state.formFilter.status} name="status" placeholder="Choose Type . . ." resource={
                        definitions.typesSchedules.map((__el)=>(
                        {key: __el, title: __el}
                      ))
                    }></AutoCompleteComponent>
                    <span className="k-font-danger">{this.state.validation.formFilter["status"]}</span>
                  </div>
                  <div hidden={this.state.formFilter.filterBy !== "2"} style={{display: "flex", float: "right", marginRight: "5px"}} >
                    <span style={{padding: "5px"}}>From:</span>
                    <div>
                      <DateTimePickerComponent tabIndex="8" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite} value={this.state.formFilter.fromDateSchedules || ""} name="fromDateSchedules" />  
                      <span className="k-font-danger">{this.state.validation.formFilter["fromDateSchedules"]}</span>
                    </div>
                    <span style={{padding: "5px"}}>To:</span>
                    <div>
                      <DateTimePickerComponent tabIndex="9" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite} value={this.state.formFilter.toDateSchedules || ""} name="toDateSchedules" />
                      <span className="k-font-danger">{this.state.validation.formFilter["toDateSchedules"]}</span>
                    </div>
                  </div>
                  <div className="form-group" hidden={this.state.formFilter.filterBy !== "3"} style={{float: "right", marginRight: "5px", width: "250px"}}>
                    <input onChange={this.handleOnChangeFilterData} type="text" value={this.state.formFilter.uuid || ""} name="uuid" className="form-control form-control-sm" placeholder=" Enter UUID . . ." autoComplete="off"/>
                    <span className="k-font-danger">{this.state.validation.formFilter["uuid"]}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%"}}>
                  <table className="k-datatable__table list-database" style={{display: "block", overflow: "auto"}}>
                    <thead className="k-datatable__head">
                      <tr className="k-datatable__row">
                        <th onClick={()=>{this.handleOnSort("createdAt")}} className={this.props.sorts.sortBy === "createdAt" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "150px"}}>CREATED AT {this.props.sorts.sortBy === "createdAt" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("id")}}  className={this.props.sorts.sortBy === "id" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                            <span style={{cursor: "pointer", width: "80px"}}>UUID {this.props.sorts.sortBy === "id" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "200px"}}>CONTENT</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("dateTime")}} className={this.props.sorts.sortBy === "dateTime" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "150px"}}>TASK SCHEDULER {this.props.sorts.sortBy === "dateTime" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("status")}} className={this.props.sorts.sortBy === "status" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "80px"}}>STATUS {this.props.sorts.sortBy === "status" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "50px"}}>ACTION</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="k-datatable__body">
                      {    
                          this.renderListSchedules(this.props.data)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{width: "100%"}}>
                  <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                </div>
              </div>
            </React.Fragment>
        }
      </React.Fragment>
    )

  }

}