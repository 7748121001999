import * as configs from '../lib/configs';

export const statePatientDetails = {
    data: {
        patientId:      null,
        device:         null,
        statusRPM:      null,
        mrn:            null,
        firstName:      null,
        lastName:       null,
        dob:            null,
        email:          null,
        phoneHome:      null,
        phoneBusiness:  null,
        phoneMobile:    null,
        phoneVideo:     null,
        language:       null,
        sex:            null,
        group_id:       null,
        responderName:  null,
        responderRole:  null,
        countReminder:  null,
        lastVitals:     null,
        systemCheck:    null,
        followTask:     null
    },
    redirect:{
        status:         false,
        url:            ""
    },
    filters: {
        firstName:      "",
        lastName:       "",
        dob:            "",
        mrn:            ""
    },
    lastName:{
        data:           [],
        filters: {
            firstName:  ""
        },
        pagination:{
            begin:              1,
            length:             configs.limitMenu,
            pageSizes:          configs.pageSizes,
            page:               1,
            totalRows:          0
        },
        sorts:{
            sortBy:         "lastName",
            sortValue:      "ASC"
        }
    },
    active: false
};


const PatientDetailsReducer = (__state = statePatientDetails, __action) => {

    switch(__action.type){

        case "IMPORT_PATIENTDETAILS":

            return {
                ...__state, 
                data:           __action.payloads.data || __state.data,  
                active:         __action.payloads.active,
                filters:{
                    firstName:  __action.payloads.firstName,
                    lastName:   __action.payloads.lastName,
                    dob:        __action.payloads.dob,
                    mrn:        __action.payloads.mrn
                }
            };

        case "IMPORT_LASTNAME_PATIENTDETAILS":

            return {
                ...__state, 
                lastName:{
                    data:          __action.payloads.data,  
                    sorts:{
                        sortBy:    __action.payloads.sortBy, 
                        sortValue: __action.payloads.sortValue
                    }, 
                    filters:{
                        firstName: __action.payloads.firstName
                    }, 
                    pagination:{
                        ...__state.lastName.pagination, 
                        begin:      __action.payloads.begin,
                        page:       __action.payloads.page, 
                        pageSizes:  __action.payloads.pageSizes, 
                        totalRows:  __action.payloads.totalRows
                    }
                }
            };

        case "RESET_PATIENTDETAILS":

            return statePatientDetails;

        case "REDIRECT_PATIENTDETAILS":

            return {
                ...__state, 
                redirect:{
                    status:         __action.payloads.status,
                    url:            __action.payloads.url
                }
            };

        default: 

            return __state;

    };

};

export default PatientDetailsReducer;