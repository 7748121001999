
import React, { Component } from 'react';

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import AutoCompleteComponent from '../../../features/autoComplete/autoCompleteComponent'
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';

import * as configs from '../../../../lib/configs';
import func from '../../../../lib/func';

const schema = {
    "msgtype":    "^(NOT|REM|EVT|SPO|Other){1}$",
    "ACK":        "^[A-Za-z]*$", 
    "contents":{
        "req":    "^[\\w\\s\\-]+\\.[A-Za-z]{2,}$",
        "file":   "^[\\w\\s\\-]+\\.[A-Za-z]{2,}$",
    }
}

const reference = {
    "message":{
        "expiration":     "^([0-9]|[1-9][0-9]+)$",
        "language":       "^(English|Spanish|French){1}$",
        "notification":   "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]+$"
    },
    "SPO":{
        "expiration":     "^([0-9]|[1-9][0-9]+)$",
        "dateStart":      "^([12]\\d{3}\\-(0[1-9]|1[0-2])\\-(0[1-9]|[12]\\d|3[01]))\\s(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))$",
        "repeat":         "^([1-9]|[1-9][0-9]+)$",
        "numberDays":     "^([1-9]|[1-9][0-9]+)$",
        "language":       "^(English|Spanish|French){1}$",
        "notification":   "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]+$"
    }
}

const initialState = () => {
    return{
        showReceipt:                    false,
        checkReceipt:                   false,
        groupElement:                   0,
        validation:{
            formData:                   {},
        },
        intervalId:                     null,
        timers: {
            minutes:                    0,
            seconds:                    0
        },
        formData:{
            groupId:                    "",
            msgtype:                    "NOT",
            language:                   "English",
            notification:               "",
            hours:                      0,
            minutes:                    0,
            repeat:                     0,
            numberDays:                 0,
            expireSpoken:               0,
            dateStart:                  "",
            schedules:                  [],
            expireReminder:             0
        }
    }
}

const _ref="SendMessageComponent";

export default class SendMessageComponent extends Component{

    constructor(__props) {

		super(__props);

        this.state = initialState()

	};

    componentWillReceiveProps(nextProps){
   
        if(nextProps.show && this.state.intervalId === null){

            this.setState({
                intervalId: this.playInterval(),
                timers:{
                    seconds: 0,
                    minutes: 0
                }
            });

        };

    }

    playInterval = () => {

        return setInterval(() => {

            let timers = this.state.timers;
                timers.seconds = timers.seconds + 1;

            if(timers.seconds >= 60){
                timers.seconds = 0;
                timers.minutes = timers.minutes + 1 ;
            }

            this.setState({timers});

        }, 1000);

    };

    stopInterval = () => {

        clearInterval(this.state.intervalId);

        this.setState({
            intervalId: null
        });

    };

    handleOnChangeFormData = (__e) => {
        
        let val = __e.target.value;

        if(__e.target.name === "msgtype" && __e.target.value === "REM"){
            
            this.setState({
                validation: {
                    ...this.state.validation,
                    formData: {}
                },
                showReceipt:        true,
                checkReceipt:       true,
                formData:{
                    ...this.state.formData,
                    msgtype:        __e.target.value,
                    hours:          0,
                    minutes:        0,
                    expireReminder: 60
                },
                groupElement:       1
            });

            return;

        };
        
        if(__e.target.name === "msgtype" && __e.target.value === "SPO"){

            this.setState({
                validation: {
                    ...this.state.validation,
                    formData: {}
                },
                showReceipt:        true,
                checkReceipt:       false,
                formData:{
                    ...this.state.formData,
                    msgtype:        __e.target.value,
                    repeat:         0,
                    numberDays:     0,
                    expireSpoken:   60,
                    dateStart:      "",
                    schedules:      [],
                },
                groupElement:       2
            });

            return;

        };
        
        if(__e.target.name === "msgtype" && __e.target.value === "NOT"){
            
            this.setState({
                validation: {
                    ...this.state.validation,
                    formData: {}
                },
                showReceipt:        true,
                checkReceipt:       false,
                formData:{
                    ...this.state.formData,
                    msgtype:        __e.target.value,
                    hours:          0,
                    minutes:        0
                },
                groupElement:       0
            });

            return;

        };
        
        if(__e.target.name === "msgtype"){

            this.setState({
                validation: {
                    ...this.state.validation,
                    formData: {}
                },
                showReceipt:        false,
                checkReceipt:       false,
                formData:{
                    ...this.state.formData,
                    msgtype:        __e.target.value,
                    hours:          0,
                    minutes:        0
                },
                groupElement:       0
            });

            return ;

        };

        if(__e.target.name === "notification"){

            val = val.replace(/(\r\n|\n|\r)/gm," ");

        };

        if(__e.target.name in this.state.validation.formData){

            delete this.state.validation.formData[__e.target.name];

        };
        
        if( __e.target.name === "hours" || __e.target.name === "minutes"){

            delete this.state.validation.formData["delay"];
        };

        if( __e.target.name === "expireSpoken" || __e.target.name === "expireReminder"){

            delete this.state.validation.formData["expiration"];

        };

        if( __e.target.name === "numberDays" || __e.target.name === "dateStart"){

            delete this.state.validation.formData["schedules"];

        };

        this.setState({
            validation: {
                ...this.state.validation,
                formData: {
                    ...this.state.validation.formData
                }
            },
            formData:{
                ...this.state.formData,
                [__e.target.name]: val
            }
        });

    }

    handleOnSubmitFormData = async() => {

        this.stopInterval();

        let error       = {};
        let schedules   = [];
        let delay       = parseInt(this.state.formData.hours) * 60 + parseInt(this.state.formData.minutes);
        let data        = {
            msgtype:        this.state.formData.msgtype,
            ACK:            this.state.formData.msgtype === "NOT" ? "na" : this.state.formData.msgtype === "REM" ? "no" : null, 
            contents:{
                req: "note.add",
                file: "message.qi",
                body: {}
            }
        };

        if(this.state.formData.msgtype === "SPO"){

            let current     = moment.utc();
            let select      =  moment.utc(this.state.formData.dateStart + " " + configs.timeZone, configs.formatDateTimeTZ);

            if(current >= select){

                error.dateStart = "Invalid";

            }

            data.contents.body  = {
                dateStart:      select.format(configs.formatDateTimeOutSite),
                expiration:     parseInt(this.state.formData.expireSpoken),
                repeat:         parseInt(this.state.formData.repeat),
                numberDays:     parseInt(this.state.formData.numberDays),
                language:       this.state.formData.language,
                notification:   this.state.formData.notification
            };

            schema.contents["body"] = reference["SPO"];

            func.validate(schema, data, error);
            
        }else{

            data.contents.body = {
                expiration:     this.state.formData.msgtype !== "REM" ? 0 : parseInt(this.state.formData.expireReminder),
                language:       this.state.formData.language,
                notification:   this.state.formData.notification
            };
     
            schema.contents["body"] = reference["message"];

            func.validate(schema, data, error);

        };

        if(this.props.sendGroup){

            func.validate({groupId: "^[A-Za-z0-9\\_\\-]+$", delay: "^([0-9]|[1-9][0-9]+)$"}, {groupId: this.state.formData.groupId, delay: delay}, error);

        }

        if(!this.props.sendGroup){

            func.validate({target: "^dev:[0-9]{15}$", delay: "^([0-9]|[1-9][0-9]+)$"}, {target: this.props.patientDetails.device, delay: delay}, error);

        };

        if(this.state.formData.msgtype === "SPO"){

            for(let i = 0; i < this.state.formData.numberDays; i++){

                schedules.push(

                    moment.utc(this.state.formData.dateStart + " " + configs.timeZone, configs.formatDateTimeTZ).add(i, "days").format(configs.formatDateTimeOutSite).toString()
                
                );
            
            };

            if(schedules.length === 0){

                error.schedules = "Required";

            };

        };

        if(Object.keys(error).length > 0){

            this.setState({
                intervalId: this.playInterval(),
                validation:{
                    ...this.state.formData,
                    formData: error
                }
            });

            return;

        };

        let timerLogs={
            mrn:        this.props.patientDetails.mrn || "",
            category:   "99457",
            sub_cat:    String(this.state.formData.msgtype).toLowerCase(),
            date:       moment.utc().format(configs.formatDateTimeOutSite),
            time:       Math.ceil(this.state.timers.seconds / 60) + this.state.timers.minutes,
            notes:      ""
        };

        let formData={
            target:         this.props.patientDetails.device,
            groupId:        this.state.formData.groupId,
            delay:          delay,
            schedules:      schedules,
            data:           data,
            types:          this.state.formData.msgtype === "SPO" ? true : false,
            timerLogs:      timerLogs
        };

        if(!this.props.sendGroup){

            this.props.func.sendMessagesToPatient_sendMessageAction(formData);

        }else{

            this.props.func.sendMessagesToGroup_sendMessageAction(formData);

        }

        this.handelOnResetComponent();
        this.props.func.callSendMessage_sendMessageAction(false, false);

    }

    handleOnCallMsgBox = (__button) => {


    }

    handelOnResetComponent = () => {

        this.setState(initialState());

    }

    handleOnCloseSendMessage = () =>{

        this.handelOnResetComponent();
        this.props.func.reset_sendMessageAction();

    }

    renderSchedules = () =>{

        let schedules   = [];

        for(let i = 0; i < this.state.formData.numberDays; i++){

            let val = moment.utc(this.state.formData.dateStart + " " + configs.timeZone, configs.formatDateTimeTZ).add(i, "days").utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite);
            
            schedules.push(
                <option key={val} value={val}>{val}</option>
            );
        
        };

        return schedules;
    }

	render() {

		return (
            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
                {
                    !configs.scopes.addPatients
                    ?
                        <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                        <div className="k-iconbox__icon">
                            <div className="k-iconbox__icon-bg"></div>
                            <i className="fa fa-lock"></i>
                        </div>
                        <div className="k-iconbox__title">
                            403 FORBIDDEN
                        </div>
                        <div className="k-iconbox__content">
                            You not enough rights to access to this item
                        </div>
                        </div>
                    :
                        <Modal show={this.props.show} onHide={()=>{this.handleOnCloseSendMessage()}} backdrop="static" dialogClassName="modal-dialog modal-lg">
                            <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}}>
                                <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>
                                    {
                                        this.props.sendGroup ?
                                            <div>
                                                <div className="form-group" style={{width: "100px", float: "left", marginRight: "5px"}}>Send To Group: </div>
                                                <AutoCompleteComponent name="groupId" useStrict={true} placeholder="Please Group . . ." style={{width: "200px", color:"#646c9a", fontSize: "1rem", fontWeight: 400}} inputStyle={{height: "20px"}} btnStyle={{height: "20px", width: "30px", padding: "0", backgroundColor: "white"}} onChange={this.handleOnChangeFormData} resource={
                                                    this.props.groupsPatient.map((__r)=>(
                                                        {key: __r.groupId, title: __r.groupName}
                                                    ))
                                                }></AutoCompleteComponent>
                                                <span className="k-font-danger" style={{fontWeight: "300", marginLeft: "10px"}}>{this.state.validation.formData["groupId"]}</span>
                                            </div>
                                        :
                                            <div className="form-group" style={{width: "100%", float: "left", marginRight: "5px",}}>Send To: {this.props.patientDetails.firstName + " " + this.props.patientDetails.lastName}</div>
                                    }
                                </Modal.Title>
                                <span style={{width: "70px", padding: "0.35em 0.75em"}} className="badge badge-pill badge-secondary"><i class="flaticon-stopwatch"></i> {(this.state.timers.minutes < 10 ? "0" + this.state.timers.minutes : this.state.timers.minutes) + ":" + (this.state.timers.seconds < 10 ? "0" + this.state.timers.seconds : this.state.timers.seconds)}</span>
                            </Modal.Header>
                            <form>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6 className="panel-title">
                                            <a href="#"><i className="fa fa-caret-right"></i> Type</a>
                                        </h6>
                                        <div className="form-group" style={{marginLeft: "10px", marginTop: "15px", marginBottom: "10px"}}>
                                            <div>
                                                <input style={{marginLeft: "5px", marginRight: "5px"}} onChange={this.handleOnChangeFormData} type="radio" name="msgtype" value="NOT" defaultChecked />
                                                <label>Notification</label>
                                            </div>
                                            <div>
                                                <input style={{marginLeft: "5px", marginRight: "5px"}} onChange={this.handleOnChangeFormData} type="radio" name="msgtype" value="REM" />
                                                <label>Reminder</label>
                                                <div hidden={this.state.groupElement !== 1 } style={{paddingLeft: "16px", marginBottom: "10px"}}>
                                                    <div>
                                                        <label style={{float: "left", marginTop: "5px", marginRight: "10px"}}>Expired:</label>
                                                        <input type="number" style={{width: "70px", height: "30px", float: "left"}} onChange={this.handleOnChangeFormData} className="form-control" value={this.state.formData.expireReminder || 0}  name="expireReminder" min="0" step="1" />
                                                        <label style={{marginTop: "5px", marginLeft: "5px"}}>minutes</label>
                                                    </div>
                                                    <span className="k-font-danger">{this.state.validation.formData["expiration"]}</span>
                                                </div>
                            
                                            </div>
                                            <div>
                                                <input style={{marginLeft: "5px", marginRight: "5px"}} onChange={this.handleOnChangeFormData} type="radio" name="msgtype" value="SPO"/>
                                                <label>Spoken message</label>
                                                <div hidden={this.state.groupElement !== 2 } style={{paddingLeft: "16px", marginBottom: "10px"}}>
                                                    <div>
                                                        <label style={{float: "left", marginRight: "10px", marginTop: "5px"}}>Say it first at:</label>
                                                        <DateTimePickerComponent today={false} onChange={this.handleOnChangeFormData} times={true} type="text" value={this.state.formData.dateStart || ""} format={configs.formatDateTimeOnSite} name="dateStart"/>
                                                        <span className="k-font-danger">{this.state.validation.formData["dateStart"]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <input style={{marginLeft: "5px", marginRight: "5px"}} onChange={this.handleOnChangeFormData} type="radio" name="msgtype" value="EVT" />
                                                <label>Event</label>
                                                </div>
                                            <div>
                                                <input style={{marginLeft: "5px", marginRight: "5px"}} onChange={this.handleOnChangeFormData} type="radio" name="msgtype" value="Other" />
                                                <label>Other</label>
                                            </div>
                                            <span className="k-font-danger">{this.state.validation.formData["msgtype"]}</span>
                                        </div>
                                        <div className="form-group" style={{width: "100%", marginBottom: "10px"}}>
                                            <div>
                                                <label>Language:</label>
                                                <input type="radio" onChange={this.handleOnChangeFormData} style={{marginLeft: "5px", marginRight: "5px"}} name="language" value="English" defaultChecked />
                                                <label>English</label>
                                                <input type="radio" onChange={this.handleOnChangeFormData} style={{marginLeft: "5px", marginRight: "5px"}} name="language" value="Spanish" />
                                                <label>Spanish</label>
                                                <input type="radio" onChange={this.handleOnChangeFormData} style={{marginLeft: "5px", marginRight: "5px"}} name="language" value="French" />
                                                <label>French</label>
                                            </div>
                                            <span className="k-font-danger">{this.state.validation.formData["language"]}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="panel-title">
                                            <a href="#"><i className="fa fa-caret-right"></i> Options</a>
                                        </h6>
                            
                                        <div className="form-group">
                                            <div hidden={this.state.groupElement !== 2 } className="row" style={{marginLeft: "0px", marginTop: "15px"}}>
                                                <div className="form-group" style={{width: "100%", marginBottom: "10px"}}>
                                                    <div style={{display: "table"}}>
                                                        <label style={{width: "70px", marginTop: "5px", float: "left"}}>Repeats:</label>
                                                        <input type="number" onChange={this.handleOnChangeFormData} min="0" step="1" className="form-control" value={this.state.formData.repeat || 0} name="repeat" style={{width: "70px", display: "block", float: "left", height: "30px"}}/>
                                                        <label style={{float: "left", marginRight: "5px", marginTop: "5px", marginLeft: "5px"}}>days</label>
                                                    </div>
                                                    <span className="k-font-danger">{this.state.validation.formData["repeat"]}</span>
                                                </div>

                                                <div className="form-group" style={{width: "100%", marginBottom: "10px"}}>
                                                    <div>
                                                        <label style={{width: "70px", marginTop: "5px", float: "left"}}>For:</label>
                                                        <input type="number" onChange={this.handleOnChangeFormData} min="0" step="1" className="form-control" value={this.state.formData.numberDays || 0} name="numberDays" style={{width: "70px", display: "block", float: "left", height: "30px"}}/>
                                                        <label style={{marginTop: "5px", marginLeft: "5px"}}>days</label>
                                                    </div>
                                                    <span className="k-font-danger">{this.state.validation.formData["numberDays"]}</span>
                                                </div>
                    
                                                <div className="form-group" style={{width: "100%", marginBottom: "10px"}}>
                                                    <div>
                                                    <label style={{width: "70px", marginTop: "5px", float: "left"}}>Expired:</label>
                                                    <input type="number" onChange={this.handleOnChangeFormData} min="0" step="1" className="form-control" value={this.state.formData.expireSpoken || 0} name="expireSpoken" style={{width: "70px", height: "30px", display: "block", float: "left"}}/>
                                                    <label style={{marginTop: "5px", marginLeft: "5px"}}>minutes</label>
                                                    </div>
                                                    <span className="k-font-danger">{this.state.validation.formData["expiration"]}</span>
                                                </div>
                            
                                                <div style={{width: "100%"}}>
                                                    <label style={{marginRight: "10px", marginTop: "5px"}}>Schedules:</label>
                                                    <div className="form-group" style={{width: "100%", marginBottom: "10px"}}>
                                                        <select size="5" multiple="" style={{width: "95%"}} name="schedules" className="form-control">
                                                            {
                                                                this.renderSchedules()
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <span className="k-font-danger">{this.state.validation.formData["schedules"]}</span>
                                            </div>

                                            <div hidden={this.state.groupElement === 2 } className="row" style={{marginLeft: "10px", marginTop: "10px"}}>
                                                <div style={{width: "100%", marginBottom: "10px"}}>Delay send:</div>
                                                <div className="form-group">
                                                    <div>
                                                        <div style={{float: "left", marginLeft: "20px", marginRight: "20px"}}>
                                                            <div>
                                                                <label>Hours:</label>
                                                                <input type="number" onChange={this.handleOnChangeFormData} min="0" step="1" style={{height: "30px", width: "70px"}} className="form-control" value={this.state.formData.hours || 0} name="hours"/>
                                                            </div>
                                                        </div>
                                                        <div style={{float: "left"}}>
                                                            <div>
                                                                <label>Minutes:</label>
                                                                <input type="number" onChange={this.handleOnChangeFormData} min="0" step="1" style={{height: "30px", width: "70px"}} className="form-control" value={this.state.formData.minutes || 0} name="minutes"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="k-font-danger">{this.state.validation.formData["delay"]}</span>
                                                </div>
                                            </div>

                                            <div hidden={!this.state.showReceipt} className="row" style={{marginLeft: "10px"}}>
                                                <label style={{width: "100%", marginBottom: "10px"}}>Return Receipt:</label>
                                                <div className="form-group" style={{marginLeft: "20px", marginBottom: "0px"}}>
                                                    <div>
                                                        <input style={{marginLeft: "5px", marginRight: "5px"}} type="radio" name="receipt" checked={this.state.checkReceipt}  readOnly/>
                                                        <label>Yes</label>
                                                    </div>
                                                    <div>
                                                        <input style={{marginLeft: "5px", marginRight: "5px"}} type="radio" name="receipt" checked={!this.state.checkReceipt} readOnly/>
                                                        <label>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Message</label>
                                        <div className="form-group" style={{marginBottom: "0px"}}>
                                            <textarea className="form-control rounded-0" onChange={this.handleOnChangeFormData} name="notification" value={this.state.formData.notification || ""}></textarea>
                                            <span className="k-font-danger">{this.state.validation.formData["notification"]}</span>
                                        </div>
                                    </div>
                                </div>
                            
                            </Modal.Body>
                            <Modal.Footer style={{height: "50px"}}>
                                <label className="k-checkbox k-checkbox--solid k-checkbox--brand" style={{marginRight: "25px", marginTop: "auto"}}>
                                    <input type="checkbox" name="billable" disabled /> Billable
                                    <span></span>
                                </label>
                                <button onClick={()=>{this.handleOnSubmitFormData()}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="flaticon2-paper-plane"></i> Submit</button>
                                <button onClick={()=>{this.handleOnCloseSendMessage()}} type="button" className="btn btn-secondary btn-elevate btn-pill btn-sm"><i className="fa fa-sign-out-alt"></i> Close</button>
                            </Modal.Footer>
                            </form>
                        </Modal>
                }
            </React.Fragment>
		);

	};

};
