
export const stateChartSummaryVitals = {
 
    data: {},
    filters: {
        device:       "",
        typeDevice:   "oximeter",
        unit:         "",
        option:       0
    }

};

const ChartSummaryVitalsReducer = (__state = stateChartSummaryVitals, __action) => {

    switch(__action.type){

        case "IMPORT_SUMMARY_VITALS":

            return {
                ...__state,
                data:               __action.payloads.data,
                filters:{
                    device:         __action.payloads.device,
                    typeDevice:     __action.payloads.typeDevice,
                    unit:           __action.payloads.unit,
                    option:         __action.payloads.option
                }
            };
            
        case "RESET_SUMMARY_VITALS":
            
            return stateChartSummaryVitals;

        default: 

            return __state;

    };

};

export default ChartSummaryVitalsReducer;