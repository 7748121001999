import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import * as configs from '../../../../lib/configs';
import './scopesStyle.css'

const schema = {
  "title":   "^[A-Za-z0-9\\s]+$"
}

const initialState = () => {

  return{
    validation: {
      formData:       {}
    },
    formData:{
      responderId:    "",
      groupId:        "",
      roleId:         "",
      scopeId:        "",
      permissionIds:  []
    },
    fullControl:      false,
    showMenuActions:  false
  };

}

const _ref="ScopesComponent";

export default class ScopesComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  handleOnCallMsgBox = (__button) => {

    if(__button === "btnYes"){

      this.props.func.delete_scopesAction({id: this.state.formData.id})

    };

    this.handleOnResetComponent();
 
  }

  handleOnCallMenuActions = (__scopeId, __show) => {

    if(!__show){

      this.handleOnResetComponent();

      return;

    }

    let arr = this.props.Policies.filter((el) => {

      return el.scopeId === __scopeId;

    })

    this.setState({
      fullControl: arr.map((__r)=>(
        __r.permissionId
      )).length === this.props.Permissions.length ? true : false,
      showMenuActions:  __show,
      formData:{
        responderId:    this.props.Object.responderId || "",
        groupId:        this.props.Object.groupId  || "",
        roleId:         this.props.Object.roleId  || "",
        scopeId:         __scopeId,
        permissionIds:  arr.map((__r)=>(
          __r.permissionId
        ))
      }
    })

  }

  handleOnChangeMenuActions = (__e) => {

    let values = Array.from(__e.target.selectedOptions, option => option.value);

    this.setState({
      validation: {
        formData: {}
      },
      formData:{
        ...this.state.formData,
        [__e.target.name]: values
      },
      fullControl: values.length === this.props.Permissions.length ? true : false
    });

  }

  handleOnSubmitFormdata = () => {

    let error = {};
    let formData = Object.assign({}, this.state.formData);

    if(formData.permissionIds.length === 0){

      error.permission = "Required";

    }

    if(Object.keys(error).length > 0){
    
      this.setState({
        validation:{
            ...this.state.validation,
            formData: error
        }
      });

      return;

    }

    this.props.func.setPolicies_managerUsersAction(formData, this.props.Segment);
    this.handleOnResetComponent();

  }

  handleOnFullControl = () => {

    let arr =[];

    if(!this.state.fullControl){

      for( let pe of this.props.Permissions){

        arr.push(pe.id);

      }

    }

    this.setState({
      validation: {
        formData: {}
      },
      formData:{
        ...this.state.formData,
        permissionIds: arr
      },
      fullControl: !this.state.fullControl
    })

  }

  handleOnClearPermissions = () => {

    this.setState({
      validation: {
        formData: {}
      },
      formData:{
        ...this.state.formData,
        permissionIds: []
      },
      fullControl: false
    })

  }

  handleOnResetComponent = () =>{

    this.setState(initialState());

  }
  
  handleOnUpdateStatus = (__id, __status) => {

    this.props.func.updateStatus_scopesAction({id: __id, status: __status === 1 ? 0 : 1});

  }

  render() {

    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
        <Modal enforceFocus={false} show={this.props.show} onHide={()=>{this.props.func.callScopes_scopesAction(false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-350">
          <Modal.Header closeButton>
              <Modal.Title style={{fontSize: "inherit"}}>Scopes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="k-widget-5" style={{height: "600px", overflow: "auto"}}>
                {
                  this.props.data.map((__s)=>(
                      <div key={uuidv4()} className={
                        __s.activate === 1 
                        ? 
                          this.props.Policies.filter((el) => {
                            return el.scopeId === __s.id
                          }).length > 0
                          ?
                          "k-widget-5__item k-widget-5__item--info" 
                          :
                          "k-widget-5__item k-widget-5__item--metal" 
                        :
                          "k-widget-5__item k-widget-5__item--danger"
                      } style={{paddingRight: "20px"}}>
                      <div className="k-widget-5__item-info">
                        <a href="#" className="k-widget-5__item-title">
                        {String(__s.title).toUpperCase()}
                        </a>
                        <div className="k-widget-5__item-datetime">
                          {
                            this.props.Policies.filter((el) => {
                              return el.scopeId === __s.id
                            }).map((__r)=>(
                              this.props.Permissions.filter((el) => {
                                return el.id === __r.permissionId
                              }).map((__rr)=>{
                                return __rr.title
                              })
                            )).join(" | ")
                          }
                        </div>
                      </div>
                      <div className="k-widget-5__item-check">
                        <a hidden={!configs.scopes.editScopes} onClick={()=>{this.handleOnUpdateStatus(__s.id, __s.activate)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">		
                          {__s.activate === 1  ? 	<i className="la la-key"></i>	: <i className="la la-lock"></i> }										
                        </a>					
                        <span style={{overflow: "visible", position: "relative", width: "100px"}}>				
                          <a onClick={()=>{this.handleOnCallMenuActions(__s.id, true)}} className="btn btn-sm btn-clean btn-icon btn-icon-md">							
                            <i className="la la-cog"></i>						
                          </a>					
                        </span>
                      </div>
                    </div>
                  ))
                }
              </div>
          </Modal.Body>
          {/* <Modal.Footer style={{height: "50px"}}>
            <button onClick={()=>{this.props.func.callScopes_scopesAction(false)}} type="button" style={{width: "50%", marginLeft: "auto", marginRight: "auto"}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sign-out-alt"></i> Close</button>
          </Modal.Footer> */}
        </Modal>

        <Modal enforceFocus={false} show={this.state.showMenuActions} onHide={()=>{this.handleOnCallMenuActions(null, false)}} backdrop="static" dialogClassName="modal-dialog modal-timerlogs-300">
          <Modal.Header closeButton>
              <Modal.Title style={{fontSize: "inherit"}}>Permissions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group" style={{marginBottom: "20px"}}>
              <select onChange={this.handleOnChangeMenuActions} value={this.state.formData.permissionIds || []} name="permissionIds" multiple className="form-control" style={{height: "200px"}}>
                {
                  this.props.Permissions.map((__pe)=>(
                    <option key={__pe.id} value={__pe.id}>{__pe.title}</option>
                  ))
                }
              </select>
              <span className="k-font-danger">{this.state.validation.formData["permission"]}</span>
            </div>
            <label className="k-checkbox k-checkbox--bold">
              <input onChange={()=>{this.handleOnFullControl()}} checked={this.state.fullControl} type="checkbox"/> Full control
              <span></span>
            </label>
          </Modal.Body>
          <Modal.Footer style={{height: "50px"}}>
            <button onClick={()=>{this.handleOnSubmitFormdata()}} type="button" style={{float:"right", width: "45%"}} className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-paper-plane"></i> Submit</button>
              <button onClick={()=>{this.handleOnClearPermissions()}} type="button" style={{float:"right", marginRight: "10px", width: "45%"}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-broom"></i> Clear</button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }

}