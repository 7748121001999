
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PatientDetailsComponent from '../components/dashboards/contents/patientDetails/patientDetailsComponent';

import { 
	getPatientByMRN_patientDetailsAction,
	getPatientByPersonal_patientDetailsAction,
	resetPage_patientDetailsAction,
	complete_patientDetailsAction,
	videoCall_patientDetailsAction,
	getLastNameByFirstName_patientDetailsAction,
	redirect_patientDetailsAction
} from '../actions/patientDetailsAction';

import {callSendMessage_sendMessageAction} from '../actions/sendMessageAction';
import {callTimerLogs_timerLogsAction, getLogs_timerLogsAction, reset_timerLogsAction} from '../actions/timerLogsAction';
import {setCurrentPage_layoutAction} from '../actions/layoutAction';
import {getSummaryByUser_chartSummaryVitalsAction, reset_chartSummaryVitalsAction} from '../actions/chartSummaryVitalsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class PatientDetailsContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<PatientDetailsComponent 
					active={this.props.patientDetails.active}
					property = {this.props.LayoutReducer.property}
					patientDetails={this.props.patientDetails}
					filters={this.props.patientDetails.filters} 
					timerLogs={this.props.timerLogs}
					lastName={this.props.patientDetails.lastName}
					func={this.props.func}
				/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{
		patientDetails: __state.PatientDetailsReducer,
		timerLogs: __state.TimerLogsReducer,
		LayoutReducer: __state.LayoutReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getSummaryByUser_chartSummaryVitalsAction: (__device, __typeDevice) => {
				__dispatch(getSummaryByUser_chartSummaryVitalsAction(__device, __typeDevice));
			},
			reset_chartSummaryVitalsAction: () => {
				__dispatch(reset_chartSummaryVitalsAction());
			},
			redirect_patientDetailsAction: (__status, __url) => {
				__dispatch(redirect_patientDetailsAction(__status, __url));
			},
			videoCall_patientDetailsAction: (__formData) => {
				return videoCall_patientDetailsAction(__formData)
			},
			getPatientByMRN_patientDetailsAction: (__filters) => {
				__dispatch(getPatientByMRN_patientDetailsAction(__filters))
			},
			getPatientByPersonal_patientDetailsAction: (__filters) => {
				__dispatch(getPatientByPersonal_patientDetailsAction(__filters))
			},
			resetPage_patientDetailsAction: () => {
				__dispatch(resetPage_patientDetailsAction())
			},
			complete_patientDetailsAction: (__formData) => {
				__dispatch(complete_patientDetailsAction(__formData))
			},
			getLastNameByFirstName_patientDetailsAction: (__filters, __sorts, __pagination) => {
				__dispatch(getLastNameByFirstName_patientDetailsAction(__filters, __sorts, __pagination))
			},
		
			/////////////////////////////////////////////////////////////

			callTimerLogs_timerLogsAction: (__show) => {
				__dispatch(callTimerLogs_timerLogsAction(__show))
			},
			getLogs_timerLogsAction:(__filters, __sorts, __pagination) => {
				__dispatch(getLogs_timerLogsAction(__filters, __sorts, __pagination))
			},
			reset_timerLogsAction:() => {
				__dispatch(reset_timerLogsAction())
			},
			/////////////////////////////////////////////////////////////
			
			callSendMessage_sendMessageAction: (__show, __sendGroup) => {
				__dispatch(callSendMessage_sendMessageAction(__show, __sendGroup))
			},
			////////////////////////////////////////////////////////////
			setCurrentPage_layoutAction: (__props) => {
				__dispatch(setCurrentPage_layoutAction(__props))
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(PatientDetailsContainer);