import React, { Component } from 'react';

import moment from "moment";
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import ChartSummaryVitalsContainer from '../../../../containers/chartSummaryVitalsContainer';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'react-bootstrap';
import * as configs from '../../../../lib/configs';

const _ref="FollowTaskComponent";

export default class FollowTaskComponent extends Component{

	constructor(__props) {

		super(__props);

        this.state = {
            followTask: [],
            showChartReport: false
        }

	};

    componentWillReceiveProps(nextProps){

        this.renderFollowTask(nextProps.data);

    }

    handleOnshowChartReport = (__bool) => {
        
        this.setState({
            ...this.state,
            showChartReport:__bool
        })

    }

    handleOnCallChartReport = (__key) => {

        this.props.func.reset_chartSummaryVitalsAction()
        this.props.func.getSummaryByUser_chartSummaryVitalsAction(this.props.device, __key)

        this.handleOnshowChartReport(true)

    }

    renderFollowTask = (__data) => {

        let followTask = [];

        if(__data ===  undefined || __data === null){

            this.setState({
                followTask: followTask
            });

            return false;
    
        }

        for(let k in __data){
    
            let recently = "";
        
            if(String(__data[k]["recently"]).trim() !== ""){

                recently = moment.utc(__data[k]["recently"]).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite);

            }

            followTask.push(
                <div key={uuidv4()} className="col-lg-4 col-xl-4 order-lg-2 order-xl-1">
                    <div className="k-portlet">
                        <div className="k-portlet__body">
                            <div className="k-widget-13">
                                <div className="k-widget-13__body">
                                    <a className="k-widget-13__title" onClick={()=>{this.handleOnCallChartReport(k)}}>{k.toUpperCase()}</a>
                                    <div className="k-widget-13__desc">
                                        <li>{"Specified: " + __data[k]["times"] + "/" +__data[k]["unit"]}</li>
                                        <li>{"Recently: " + recently}</li>
                                    </div>
                                </div>
                                <div className="k-widget-13__foot">
                                    <div className="k-widget-13__progress">
                                        <div className="k-widget-13__progress-info">
                                            <div className="k-widget-13__progress-status">
                                                Completed
                                            </div>
                                            <div className="k-widget-13__progress-value"></div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar bg-brand progress-bar-striped progress-bar-animated" role="progressbar" style={{width: __data[k]["percent"] + "%"}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        };
    
        this.setState({
            followTask: followTask
        });
    
    }

    handleOnCallMsgBox = (__button) =>{


    }
    
    render() {
    
        return(
            <React.Fragment>

                <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

                {!this.props.permission
                ?
                    <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
                    <div className="k-iconbox__icon">
                        <div className="k-iconbox__icon-bg"></div>
                        <i className="fa fa-lock"></i>
                    </div>
                    <div className="k-iconbox__title">
                        403 FORBIDDEN
                    </div>
                    <div className="k-iconbox__content">
                        You not enough rights to access to this item
                    </div>
                    </div>
                :
                    <React.Fragment>
                        <div className="row">
                            {this.state.followTask}
                        </div>

                        <Modal show={this.state.showChartReport} onHide={()=>{this.handleOnshowChartReport(false)}} backdrop="static" dialogClassName="modal-dialog modal-lg">
                            <Modal.Header style={{height: "30px", backgroundImage:"linear-gradient(to right, #5867dd, #6f42c173)"}}>
                                <Modal.Title style={{fontSize: "inherit", padding: "0px", color: "#ffffff"}}>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ChartSummaryVitalsContainer/>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={()=>{this.handleOnshowChartReport(false)}} className="btn btn-secondary btn-sm">Close</button>
                            </Modal.Footer>
                        </Modal>
                    </React.Fragment>
                }
            </React.Fragment>
        )

    }

}