import React, { Component } from 'react';

import moment from "moment";

import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import PaginationComponent from '../../../features/pagination/paginationComponent';
import AutoCompleteComponent from '../../../features/autoComplete/autoCompleteComponent'
import DateTimePickerComponent from '../../../features/dateTimePicker/dateTimePickerComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';

import * as definitions from '../../../../lib/definitions';
import * as configs from '../../../../lib/configs';

import func from '../../../../lib/func';

import './vitalsStyle.css'

const schema = {
  "device":     "^dev:[0-9]{15}$",
  "createdAt":  "^[0-9]{10,}$",
  "method":     "^(WEB){1}$"
}

const reference = {
  "thermometer":{
      "type":                     "^(thermometer){1}$",
      "mac":                      "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                     "^[0-9]{10,}$",
      "comment":                  "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "temperature":              "^([1-9]|[1-9][0-9])+$",
      "unit":                     "^(f|c){1}$"
  },
  "scale":{
      "type":                     "^(scale){1}$",
      "mac":                      "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                     "^[0-9]{10,}$",
      "comment":                  "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "weight_result":            "^([1-9]|[1-9][0-9])+$", 
      "unit_measurement":         "^(kg|pound){1}$"
  },
  "oximeter":{
      "type":                     "^(oximeter){1}$",
      "mac":                      "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                     "^[0-9]{10,}$",
      "comment":                  "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "spo2":                     "^([1-9]|[1-9][0-9])+$", 
      "pulse":                    "^([1-9]|[1-9][0-9])+$"
  },
  "wristband":{
      "type":                       "^(wristband){1}$",
      "mac":                        "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                       "^[0-9]{10,}$",
      "comment":                    "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "pulse_rate":                 "^([1-9]|[1-9][0-9])+$"
  },
  "glucometer":{
      "type":                     "^(glucometer){1}$",
      "mac":                      "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                     "^[0-9]{10,}$",
      "comment":                  "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "gluco":                    "^([1-9]|[1-9][0-9])+$", 
      "bloodtemp":                "^([1-9]|[1-9][0-9])+$"
  },
  "bpm":{
      "type":                     "^(bpm){1}$",
      "mac":                      "^[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}\\:[a-z0-9]{2}$",
      "date":                     "^[0-9]{10,}$",
      "comment":                  "^[A-Za-z0-9\\<\\>\\(\\)\\s\\%\\,\\.\\@\\;\\'\\|\\=\\?\\+\\-\\^\\!\\:\\$\\_\\*\\/\\\\]*$",
      "systolic":                 "^([1-9]|[1-9][0-9])+$", 
      "diastolic":                "^([1-9]|[1-9][0-9])+$", 
      "pulse_rate":               "^([1-9]|[1-9][0-9])+$", 
      "mean_arterial_pressure":   "^([1-9]|[1-9][0-9])+$"
  }
}

const initialState = () => {

  return{

    showFormData:               false,
    validation:{
      formFilter:               {},
      formData:                 {}
    },
    formFilter:{
      typeDevice:               "",
      fromDateVitals:           "",
      toDateVitals:             "",
      filterBy:                 "1",
    },
    formData:{
      mac:                      "aa:bb:cc:dd:ee:ff",
      comment:                  "",
      type:                     "oximeter",
      date:                     "",
      temperature:              "",
      unit:                     "c",
      weight_result:            "", 
      unit_measurement:         "kg",
      spo2:                     "", 
      pulse:                    "",
      pulse_rate:               "",
      gluco:                    "", 
      bloodtemp:                "",
      systolic:                 "", 
      diastolic:                "", 
      mean_arterial_pressure:   ""
    }

  };

}

const _ref="VitalsComponent";

export default class VitalsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};
  
  componentDidMount(){

  }

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

    this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {
    
    this.props.pagination.page = __page;
    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);
  
  }

  handleOnPreviousPage = () => {

    this.props.pagination.page = this.props.pagination.page - 1;
    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

    this.props.pagination.page = this.props.pagination.page + 1;
    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);
    
  }

  handleOnLoadMorePage = (__pagination, __plus) => {

    this.props.func.loadMorePage_vitalsAction(__pagination, __plus);

  }

  handleOnSubmitFilter = () => {

    let formData  = Object.assign({}, this.state.formFilter);

    if(formData.filterBy === "1"){

      if(func.isEmptyOrNull(formData.typeDevice)){

          this.setState({
            validation: {
              ...this.state.validation,
              formFilter: {typeDevice: "Required"}
            }
          });
  
          return;
          
      };

      this.props.filters.typeDevice      = formData.typeDevice;
      this.props.filters.fromDate        = "";
      this.props.filters.toDate          = "";
 
    };

    if(formData.filterBy === "2"){
      
      let error     = {};
      let fromDate  = moment.utc(formData.fromDateVitals, configs.formatDateOnSite);
      let toDate    = moment.utc(formData.toDateVitals, configs.formatDateOnSite);

      if(!fromDate.isValid() || fromDate > toDate){

        error.fromDateVitals = "Invalid";

      };

      if(!toDate.isValid()){

        error.toDateVitals = "Invalid";

      };

      if(Object.keys(error).length > 0){

        this.setState({
          validation: {
            ...this.state.validation,
            formFilter: error
          }
        });

        return;

      };

      this.props.filters.fromDate      = fromDate.format(configs.formatDateOutSite);
      this.props.filters.toDate        = toDate.format(configs.formatDateOutSite);
      this.props.filters.typeDevice    = "";

    };

    this.props.func.getVitals_vitalsAction(this.props.filters);

  }

  handleOnSort = (__field) => {

    let sortValue = "";

    if(this.props.data.length === 0){

      return;

    };

    if(__field === this.props.sorts.sortBy){


      if(this.props.sorts.sortValue === "ASC"){

        sortValue = "DESC";

      }

      if(this.props.sorts.sortValue === "DESC"){

        sortValue = "ASC";
        
      };

      this.props.sorts.sortValue = sortValue;

    }else{

      this.props.sorts.sortBy    = __field;
      this.props.sorts.sortValue = "ASC";

    };

    this.props.func.getVitals_vitalsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnCallMsgBox = (__button) => {

  }
  
  handleOnChangeFilterData = (__e) => {

    if(__e.target.name in this.state.validation.formFilter){

      delete this.state.validation.formFilter[__e.target.name];

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formFilter: {
          ...this.state.validation.formFilter
        }
      },
      formFilter:{
        ...this.state.formFilter,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handleOnChangeFormData = (__e) => {

    if(__e.target.name in this.state.validation.formData){

      delete this.state.validation.formData[__e.target.name];

    };

    this.setState({
      validation: {
        ...this.state.validation,
        formData: {
          ...this.state.validation.formData,
          [__e.target.name]: ""
        }
      },
      formData: {
        ...this.state.formData,
        [__e.target.name]: __e.target.value
      }
    });

  }

  handleOnBuildFormData = () => {

    let data  = {
        device:     this.props.filters.device,
        createdAt:  moment(this.state.formData.date + " " + configs.timeZone, configs.formatDateTimeTZ).utcOffset("+00:00").unix(),
        method:     "WEB",
        body:       {}
    };


    for (let k of Object.keys(reference[this.state.formData.type])){

      if(k === "date"){

        data.body[k] = data.createdAt;

        continue;

      };

      data.body[k] = this.state.formData[k];

    };
  
    return data;

  }

  handleOnSubmitFormData = () => {

    let formData  = this.handleOnBuildFormData();
    let error     = {};

    schema.body = reference[this.state.formData.type];

    func.validate(schema, formData, error);

    if(Object.keys(error).length > 0){

      this.setState({
        validation:{
          ...this.state.validation,
          formData: error
        }
      });

      return;

    };

    this.props.func.create_vitalsAction(formData);
    this.handelOnCallFormData(false);

  }

  handelOnResetComponent = () => {

    this.setState(initialState());

  }

  handelOnRefreshData = () => {

    this.props.func.getVitals_vitalsAction({device: this.props.filters.device});
    this.handelOnResetComponent();
    
  }

  handelOnCallFormData = (__bool) => {

    if(!__bool){

      this.handelOnResetComponent();
      
    };

    this.setState({
      showFormData: __bool
    });

  }

  renderListVitals = (__data) => {

    return (__data.map((__r)=>{

        let ignore      = ["mac","date","type"];
        let arr         = [];
        let measurement = Object.keys(__r.measurement);
    
        for(let y = 0; y < measurement.length; y++){

            let key = measurement[y];
            let val = __r.measurement[key];

            if(ignore.indexOf(key) !== -1){
                continue;
            };

            if (String(key).toLowerCase() === "weight_result"){

              val = parseFloat(val / 10).toFixed(2);

            };

            if (String(key).toLowerCase() === "temperature"){

              val = parseFloat(val / 10).toFixed(2);

            };
            
            arr.push(<span key={uuidv4()} style={{width: "330px"}}>{func.formatText(String(key).replace("_", " "), "upperSentence")}: {val}</span>)

        };

        return(

            <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
                <td className='k-datatable__cell--left k-datatable__cell'>
                  <span style={{width: "150px"}}> {moment.utc(__r.createdAt, configs.formatDateTimeOutSite).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
                </td>
                <td className='k-datatable__cell--left k-datatable__cell'>
                  <span style={{width: "80px"}}> {__r.measurement.type}</span>
                </td>
                <td className='k-datatable__cell--left k-datatable__cell'>
                  {
                    __r.alert !== null && String(__r.alert).trim() !== "" ?
                    <span style={{width: "100px"}}>
                      <span className="k-badge k-badge--danger k-badge--dot"></span>&nbsp;<span className="k-font-bold k-font-danger">{__r.alert}</span>
                    </span>
                    : <span style={{width: "100px"}}></span>
                  }
                </td>
                <td className="k-datatable__cell--left k-datatable__cell">
    
                    {
                        arr.map((__r)=>{
                            return __r
                        })
                    }

                </td>
            </tr>
        );
    }));
  }

  render() {

    return(

      <React.Fragment>
        
        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>

        {
          !configs.scopes.readPatients
          ?
            <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
              <div className="k-iconbox__icon">
                <div className="k-iconbox__icon-bg"></div>
                <i className="fa fa-lock"></i>
              </div>
              <div className="k-iconbox__title">
                403 FORBIDDEN
              </div>
              <div className="k-iconbox__content">
                You not enough rights to access to this item
              </div>
            </div>
          :
            <React.Fragment>
              <div className="row">
                <div className="col-lg-3 col-xl-3 order-lg-1 order-xl-1">
                  <button hidden={!configs.scopes.addPatients} onClick={()=>{this.handelOnCallFormData(true)}} type="button" style={{float:"left", margin: "auto"}} className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-plus"></i> Add New</button>
                </div>
                <div className="col-lg-9 col-xl-9 order-lg-1 order-xl-1">
                <button onClick={()=>{this.handelOnRefreshData()}} type="button" style={{float:"right", margin: "auto", marginLeft: "5px"}} className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                <button tabIndex="10" onClick={()=>{this.handleOnSubmitFilter()}} type="button" style={{height: "calc(2.0125rem + 2px)", float: "right"}} className="btn btn-sm btn-secondary btn-pill"><i className="la la-filter"></i> Filter</button>
                <div className="form-group" style={{width: "100px", float: "right", marginRight: "5px"}}>
                    <select tabIndex="6" onChange={this.handleOnChangeFilterData} className="form-control form-control-sm" value={this.state.formFilter.filterBy} name="filterBy">
                        <option value="1">TYPE</option>
                        <option value="2">DATE</option>
                    </select>
                </div>
                <div className="form-group" hidden={this.state.formFilter.filterBy !== "1"} style={{float: "right", marginRight: "5px", width: "250px"}}>
                  <AutoCompleteComponent tabIndex="7" useStrict={true} onChange={this.handleOnChangeFilterData} value={this.state.formFilter.typeDevice} name="typeDevice" placeholder="Choose Type . . ." resource={
                    definitions.typesVitals.map((__el)=>(
                      {key: __el, title: __el}
                    ))
                  }></AutoCompleteComponent>
                  <span className="k-font-danger">{this.state.validation.formFilter["typeDevice"]}</span>
                </div>
                <div hidden={this.state.formFilter.filterBy !== "2"} style={{display: "flex", float: "right", marginRight: "5px"}} >
                  <span style={{padding: "5px"}}>From:</span>
                  <div>
                  <DateTimePickerComponent tabIndex="8" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite} value={this.state.formFilter.fromDateVitals} name="fromDateVitals" />  
                  <span className="k-font-danger">{this.state.validation.formFilter["fromDateVitals"]}</span>
                  </div>
                  <span style={{padding: "5px"}}>To:</span>
                  <div>
                  <DateTimePickerComponent tabIndex="9" today={true} onChange={this.handleOnChangeFilterData} times={false} format={configs.formatDateOnSite} value={this.state.formFilter.toDateVitals} name="toDateVitals" />
                  <span className="k-font-danger">{this.state.validation.formFilter["toDateVitals"]}</span>
                  </div>
                </div>
              </div>
              </div>

              <div className="row">
                <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%"}}>
                  <table className="k-datatable__table list-database" style={{display: "block", overflow: "auto"}}>
                    <thead className="k-datatable__head">
                      <tr className="k-datatable__row">
                        <th onClick={()=>{this.handleOnSort("createdAt")}}  className={this.props.sorts.sortBy === "createdAt" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                            <span style={{cursor: "pointer", width: "150px"}}>DATE TIME {this.props.sorts.sortBy === "createdAt" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th onClick={()=>{this.handleOnSort("type")}} className={this.props.sorts.sortBy === "type" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{cursor: "pointer", width: "80px"}}>TYPE {this.props.sorts.sortBy === "type" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "100px"}}>ALERT</span>
                        </th>
                        <th className="k-datatable__cell--left k-datatable__cell">
                          <span style={{width: "330px"}}>MEASUREMENT</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="k-datatable__body">
                      {   
                          this.renderListVitals(this.props.data)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{width: "100%"}}>
                  <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
                </div>
              </div>

              <Modal show={this.state.showFormData} onHide={()=>{this.handelOnCallFormData(false)}} style={{backgroundColor: "rgb(108 117 125 / 53%)"}} backdrop="static" dialogClassName="modal-dialog modal-vitals">
                  <Modal.Header closeButton>
                      <Modal.Title style={{fontSize: "inherit"}}>Create Vital Of Observation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group" style={{marginBottom: "20px"}}>
                      <label>Date Time</label>
                      <DateTimePickerComponent today={true} onChange={this.handleOnChangeFormData} times={true} format={configs.formatDateTimeOnSite} value={this.state.formData.date} name="date"/>
                      <span className="k-font-danger">{this.state.validation.formData["date"]}</span>
                    </div>
                    <div className="form-group" style={{marginBottom: "20px"}}>
                        <label>Type</label>
                        <select onChange={this.handleOnChangeFormData} value={this.state.formData.type} name="type" className="form-control form-control-sm">
                            {
                              definitions.typesVitals.map((__el)=>(
                                <option key={__el} value={__el}>{__el}</option>
                              ))
                            }
                        </select>
                        <span className="k-font-danger">{this.state.validation.formData["type"]}</span>
                    </div>
                    <div hidden={this.state.formData.type !== "thermometer"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Temperature</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.temperature} type="number" min="0" step="1" name="temperature" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["temperature"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Unit</label>
                          <select onChange={this.handleOnChangeFormData} value={this.state.formData.unit} name="unit" className="form-control form-control-sm">
                            <option value="f">F</option>
                            <option value="c">C</option>
                          </select>
                          <span className="k-font-danger">{this.state.validation.formData["unit"]}</span>
                      </div>
                    </div>
                    <div hidden={this.state.formData.type !== "scale"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Weight</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.weight_result} type="number" min="0" step="1" name="weight_result" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["weight_result"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Unit measurement</label>
                          <select onChange={this.handleOnChangeFormData} value={this.state.formData.unit_measurement} name="unit_measurement" className="form-control form-control-sm">
                            <option value="kg">Kg</option>
                            <option value="pound">Pound</option>
                          </select>
                          <span className="k-font-danger">{this.state.validation.formData["unit_measurement"]}</span>
                      </div>
                    </div>
                    <div hidden={this.state.formData.type !== "oximeter"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Spo2</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.spo2} type="number" min="0" step="1" name="spo2" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["spo2"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Pulse</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.pulse} type="number" min="0" step="1" name="pulse" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["pulse"]}</span>
                      </div>
                    </div>
                    <div hidden={this.state.formData.type !== "wristband"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Wristband</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.pulse_rate} type="number" min="0" step="1" name="pulse_rate" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["pulse_rate"]}</span>
                      </div>
                    </div>
                    <div hidden={this.state.formData.type !== "glucometer"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Gluco</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.gluco} type="number" min="0" step="1" name="gluco" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["gluco"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Bloodtemp</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.bloodtemp} type="number" min="0" step="1" name="bloodtemp" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["bloodtemp"]}</span>
                      </div>
                    </div>
                    <div hidden={this.state.formData.type !== "bpm"}>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Systolic</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.systolic} type="number" min="0" step="1" name="systolic" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["systolic"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Diastolic</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.diastolic} type="number" min="0" step="1" name="diastolic" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["diastolic"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Pulse rate</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.pulse_rate} type="number" min="0" step="1" name="pulse_rate" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["pulse_rate"]}</span>
                      </div>
                      <div className="form-group" style={{marginBottom: "20px"}}>
                          <label>Mean arterial pressure</label>
                          <input onChange={this.handleOnChangeFormData} value={this.state.formData.mean_arterial_pressure} type="text" name="mean_arterial_pressure" className="form-control form-control-sm" autoComplete="off"/>
                          <span className="k-font-danger">{this.state.validation.formData["mean_arterial_pressure"]}</span>
                      </div>
                    </div>
                    <div className="form-group" style={{marginBottom: "0px"}}>
                        <label>Comment</label>
                        <textarea onChange={this.handleOnChangeFormData} value={this.state.formData.comment} name="comment" className="form-control form-control-sm" rows="3"></textarea>
                        <span className="k-font-danger">{this.state.validation.formData["comment"]}</span>
                    </div>
                  </Modal.Body>
                  <Modal.Footer style={{height: "50px"}}>
                    <button style={{width: "100%"}} onClick={()=>{this.handleOnSubmitFormData()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="flaticon2-paper-plane"></i> Submit</button>
                  </Modal.Footer>
              </Modal>
            </React.Fragment>
        }
      </React.Fragment>
    )

  }

}