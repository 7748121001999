import React, {Component} from 'react';
import { connect } from 'react-redux';

import ScopesComponent from '../components/dashboards/contents/scopes/scopesComponent';

import {getScopes_scopesAction, callScopes_scopesAction, create_scopesAction, updateStatus_scopesAction, delete_scopesAction, reset_scopesAction} from '../actions/scopesAction';
import {setPolicies_managerUsersAction} from '../actions/managerUsersAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class ScopesContainer extends Component{

  render() {

    return(

      <React.Fragment>

        <ScopesComponent
          show = {this.props.Scopes.show}
          data = {this.props.Scopes.data} 
          filters = {this.props.Scopes.filters} 
          pagination = {this.props.Scopes.pagination} 
          sorts = {this.props.Scopes.sorts} 
          func = {this.props.func}
          Object = {this.props.ManagerUsers.filters}
          Segment = {this.props.ManagerUsers.segment}
          Policies = {this.props.ManagerUsers.policies}
					Permissions = {this.props.Permissions.data}

        />

      </React.Fragment>
    )

  }

}

const mapState = __state => {

	return{
    ManagerUsers: __state.ManagerUsersReducer,
		Roles: __state.RolesReducer,
		GroupsUser: __state.GroupsUserReducer,
		Permissions: __state.PermissionsReducer,
		Responders: __state.RespondersReducer,
    Scopes: __state.ScopesReducer,
	};

};

const mapDispatch = (__dispatch, __props) => {

	return {		
    func:{
			getScopes_scopesAction: (__filters, __sorts, __pagination) => {
				__dispatch(getScopes_scopesAction(__filters, __sorts, __pagination))
			},
      create_scopesAction: (__formData) => {
				__dispatch(create_scopesAction(__formData))
			},
      updateStatus_scopesAction: (__formData) => {
				__dispatch(updateStatus_scopesAction(__formData))
			},
      delete_scopesAction: (__formData) => {
				__dispatch(delete_scopesAction(__formData))
			},
      callScopes_scopesAction: (__show) => {
				__dispatch(callScopes_scopesAction(__show))
			},
      setPolicies_managerUsersAction: (__formData, __segment) => {
				__dispatch(setPolicies_managerUsersAction(__formData, __segment))
			},
      reset_scopesAction: () => {
				__dispatch(reset_scopesAction())
			},
      create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
    }
  }
}

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ScopesContainer);