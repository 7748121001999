import moment from "moment";
import * as configs from '../lib/configs';

export const stateTimerLogs = {

    show:           false,
    data:           [],
    categories:     [],
    months:         [],
    totalTimes:     0,
    filters: {
        mrn:        "",
        month:      moment.utc().utcOffset(configs.timeZone).format("YYYY-MM"),
        fromDate:   "",
        toDate:     ""
    },
    pagination:{
        begin:      1,
        length:     configs.limitMenu,
        pageSizes:  configs.pageSizes,
        page:       1,
        totalRows:  0
    },
    sorts:{
        sortBy:     "createdAt",
        sortValue:  "DESC"
    }

};

const TimerLogsReducer = (__state = stateTimerLogs, __action) => {

    switch(__action.type){

        case "IMPORT_DATA_TIMERLOGS":
 
            return {
                ...__state, 
                data:           __action.payloads.data, 
                categories:     __action.payloads.categories, 
                months:         __action.payloads.months, 
                totalTimes:     __action.payloads.totalTimes, 
                sorts:{
                    sortBy:     __action.payloads.sortBy, 
                    sortValue:  __action.payloads.sortValue
                }, 
                filters:{
                    mrn:        __action.payloads.mrn,
                    month:      __action.payloads.month,
                    fromDate:   __action.payloads.fromDate,
                    toDate:     __action.payloads.toDate
                }, 
                pagination:{
                    ...__state.pagination, 
                    page:       __action.payloads.page,
                    pageSizes:  __action.payloads.pageSizes, 
                    totalRows:  __action.payloads.totalRows,
                    begin:      __action.payloads.begin 
                }
            };

        case "LOAD_MORE_PAGE_TIMERLOGS":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        case "RESET_TIMERLOGS":

            return stateTimerLogs;

        case "SHOW_TIMERLOGS":

            return {
                ...__state, 
                show: __action.payloads.show,
            };

        default: 

            return __state;

    };

};

export default TimerLogsReducer;