
import React, { Component } from 'react';

import { connect } from 'react-redux';

import AbnormalFlagsComponent from '../components/dashboards/contents/abnormalFlags/abnormalFlagsComponent';

import { 
	getNewAbnormal_abnormalFlagsAction,
	getHistoryAbnormal_abnormalFlagsAction,
	updateStatus_abnormalFlagsAction,
	deleteHistory_abnormalFlagsAction,
	reset_abnormalFlagsAction
} from '../actions/abnormalFlagAction';

import {showMainPanel_quickPanelAction, showSubPanel_quickPanelAction} from '../actions/quickPanelAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

class AbnormalFlagsContainer extends Component{

	render() {

		return(
            <div className="k-content__body	k-grid__item k-grid__item--fluid">
				<AbnormalFlagsComponent 
				    device = {this.props.abnormalFlagsReducer.device}
					history = {this.props.abnormalFlagsReducer.history} 
					new = {this.props.abnormalFlagsReducer.new} 
                    func = {this.props.func}
                />
			</div>
		);

	};

};

const mapState = __state => {

	return{
		abnormalFlagsReducer: __state.AbnormalFlagsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getNewAbnormal_abnormalFlagsAction: (__device, __filters, __sorts, __pagination) => {
				__dispatch(getNewAbnormal_abnormalFlagsAction(__device, __filters, __sorts, __pagination));
			},
			getHistoryAbnormal_abnormalFlagsAction: (__device, __filters, __sorts, __pagination) => {
				__dispatch(getHistoryAbnormal_abnormalFlagsAction(__device, __filters, __sorts, __pagination));
			},
			updateStatus_abnormalFlagsAction: (__formData) => {
				__dispatch(updateStatus_abnormalFlagsAction(__formData));
			},
			deleteHistory_abnormalFlagsAction: (__formData) => {
				__dispatch(deleteHistory_abnormalFlagsAction(__formData));
			},
			reset_abnormalFlagsAction: () => {
				__dispatch(reset_abnormalFlagsAction());
			},
			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			},
			///////////////////////////////////////////

			showMainPanel_quickPanelAction: (__show, __tab) => {
				__dispatch(showMainPanel_quickPanelAction(__show, __tab));
			},
			showSubPanel_quickPanelAction: (__show, __data) => {
				__dispatch(showSubPanel_quickPanelAction(__show, __data));
			}
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(AbnormalFlagsContainer);