
import React, { Component } from 'react';

import { connect } from 'react-redux';
import {getPatientByMRN_reportsAction, resetPage_reportsAction} from '../actions/reportsAction';
import {create_MsgBoxAction} from '../actions/msgBoxAction';

import ReportsComponent from '../components/dashboards/contents/reports/reportsComponent';

class ReportsContainer extends Component{

	render() {

		return(
			<div className="k-page--loading-enabled k-page--loading k-page--fixed k-header--fixed k-header--minimize-menu k-header-mobile--fixed" style={{height: "100%"}} >

				<ReportsComponent 
					data = {this.props.patientDetails.data} 
					filters = {this.props.patientDetails.filters} 
					func = {this.props.func}
				/>
			
			</div>
		);

	};

};

const mapState = __state => {

	return{
		patientDetails: __state.PatientDetailsReducer
	};

};

const mapDispatch = (__dispatch, __props) => {

	return{
		func:{
			getPatientByMRN_reportsAction: (__filters) =>{
				__dispatch(getPatientByMRN_reportsAction(__filters));
			},
			resetPage_reportsAction: () => {
				__dispatch(resetPage_reportsAction())
			},
			
			/////////////////////////////////////////////////////////////

			create_MsgBoxAction: (__ref, __title, __message, __buttons, __type, __process, __percent) => {
				__dispatch(create_MsgBoxAction(__ref, __title, __message, __buttons, __type, __process, __percent));
			}
			
		}
	};

};

export default connect(mapState, mapDispatch, null, {forwardRef: true})(ReportsContainer);