import * as configs from '../lib/configs';

export const stateSchedules = {
    data: [],
    filters: {
        target:         "",      
        uuid:           "",
        status:         "",
        fromDate:       "",
        toDate:         ""
    },
    pagination:{
        begin:          1,
        length:         configs.limitMenu,
        pageSizes:      configs.pageSizes,
        page:           1,
        totalRows:      0
    },
    sorts:{
        sortBy:         "createdAt",
        sortValue:      "DESC"
    }
};

const SchedulesReducer = (__state = stateSchedules, __action) => {

    switch(__action.type){

        case "IMPORT_SCHEDULES":

            return {
                ...__state,
                 data: __action.payloads.data,
                 sorts:{
                     sortBy:    __action.payloads.sortBy, 
                     sortValue: __action.payloads.sortValue
                }, 
                 filters:{ 
                    target:     __action.payloads.target,
                    uuid:       __action.payloads.uuid,
                    status:     __action.payloads.status,
                    fromDate:   __action.payloads.fromDate,
                    toDate:     __action.payloads.toDate,
                }, 
                 pagination:{
                     ...__state.pagination, 
                     page:      __action.payloads.page, 
                     pageSizes: __action.payloads.pageSizes, 
                     totalRows: __action.payloads.totalRows,
                     begin:     __action.payloads.begin 
                }
            };

        case "RESET_SCHEDULES":

            return stateSchedules;


        case "LOAD_MORE_PAGE_SCHEDULES":

            return {
                ...__state, 
                pagination:{
                    ...__state.pagination, 
                    begin:      __action.payloads 
                }
            };

        default: 

            return __state;

    };

};

export default SchedulesReducer;