import React, { Component } from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import moment from "moment";
import crypto from "crypto";

import { Redirect } from 'react-router'
import { v4 as uuidv4 } from 'uuid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import PaginationComponent from '../../../features/pagination/paginationComponent';
import MsgBoxContainer from '../../../../containers/msgBoxContainer';
import MappingComponent from '../../../features/mapping/mappingComponent';

import TimerLogsContainer from '../../../../containers/timerLogsContainer';
import * as configs from '../../../../lib/configs';
import func from '../../../../lib/func';

import './summaryPatientsStyle.css';
 
const initialState = () => {

  return{
    mapping:{
      show:                     false,
      patientId:                ""
    },
    queryString:{
      firstName:                "",
      lastName:                 "",
      dob:                      "",
      mrn:                      ""
    },
    formFilter:{
      month:                    "",
      statusRPM:                "enabled",
      device:                   "",
      typeNotify:               "",
      statusReadings:           ""
    },
    passWord:               "",
    PID:                    false,
    checkedPID:             false,
    showFormConfirmPass:    false,
    redirectToPatientDetails:   false
  };

}

const _ref="SummaryPatientsComponent";

export default class SummaryPatientsComponent extends Component{

	constructor(__props) {

		super(__props);

    this.state = initialState();

	};

  componentDidMount(){

    this.setState({
      formFilter:{
        month:                    this.props.filters.month,
        statusRPM:                this.props.filters.statusRPM,
        device:                   this.props.filters.device,
        typeNotify:               this.props.filters.typeNotify,
        statusReadings:           this.props.filters.statusReadings
      }
    });
 
    // if(configs.scopes.readPatients){

    //   this.props.func.getSummary_summaryPatientsAction();

    // }

  }

  handleOnFirstPage = () => {

    this.props.pagination.page = 1;
    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLastPage = () => {

    this.props.pagination.page = Math.ceil(this.props.pagination.totalRows / this.props.pagination.pageSizes);
    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnChangePage = (__page) => {
    
    this.props.pagination.page = __page;
    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);
  
  }

  handleOnPreviousPage = () => {

    this.props.pagination.page = this.props.pagination.page - 1;
    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnNextPage = () => {

    this.props.pagination.page = this.props.pagination.page + 1;
    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }

  handleOnLoadMorePage = (__pagination, __plus) => {

    this.props.func.loadMorePage_summaryPatientsAction(__pagination, __plus);

  }

  handleOnSort = (__field) =>{

    if(this.props.data.length === 0){

      return;

    };

    let sortValue = "";

    if(__field === this.props.sorts.sortBy){

      if(this.props.sorts.sortValue === "ASC"){

        sortValue = "DESC";

      };

      if(this.props.sorts.sortValue === "DESC"){


        sortValue = "ASC";
        
      };

      this.props.sorts.sortValue = sortValue;

    }else{

      this.props.sorts.sortBy = __field;
      this.props.sorts.sortValue = "ASC";

    };

    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts, this.props.pagination);

  }
  
  handleOnFilter = (__e) => {

    switch(__e.target.name){

      case "statusRPM":

        this.props.filters.statusRPM = __e.target.value;

        break
      case "month":

        this.props.filters.month = __e.target.value;

        break
      case "device":

        this.props.filters.device = __e.target.value;

        break
      case "typeNotify":

        this.props.filters.typeNotify = __e.target.value;

        break
      case "statusReadings":

        this.props.filters.statusReadings = __e.target.value;

        break
      default: 

    };

    this.setState({
      formFilter:{
        ...this.state.formFilter,
        [__e.target.name]: __e.target.value
      }
    });

    this.props.func.getSummary_summaryPatientsAction(this.props.filters, this.props.sorts);
    
  }

  handleOnChangeInputPass = (__e) => {

    if(__e.target.name === "PID"){

      let showForm = false;

      if(!this.state.checkedPID){

        showForm = true;

      };

      this.setState({
        ...this.state,
        passWord:             "",
        PID:                  false,
        checkedPID:           !this.state.checkedPID,
        showFormConfirmPass:  showForm
      });

      return;

    };

    this.setState({

      passWord: __e.target.value
      
    });

  }

  handleOnVerify = (__e) => {

    let pass    = window.sessionStorage.getItem("accesscode");
    let token   = window.sessionStorage.getItem("token");
    let buffer  = Buffer.from(token + this.state.passWord);
    let hash    = crypto.createHash("sha256").update(buffer).digest("base64");
  
    if(pass === hash){

      this.setState({
        passWord:             "",
        PID:                  true,
        showFormConfirmPass:  false
      });

      return;

    };

    this.props.func.create_MsgBoxAction(_ref, "Access",  "You don't have permission to access", [{key: "btnClose", title: "Close"}], "error", null, null);
  
    this.setState({
      passWord:             "",
      PID:                  false,
      checkedPID:           false,
      showFormConfirmPass:  false
    });

  }

  handleOnRedirectToPatientDetails = (__fullName, __dob, __mrn) =>{

    let arr         = [];
    let firstName   = "";
    let lastName    = "";
    let dob         = "";

    if(__mrn === null){

      if(!this.state.PID){

        return;

      };

      if(!func.isEmptyOrNull(__fullName)){

        arr = String(__fullName).split("|");
        firstName = arr[0];

      };

      if(arr.length > 1){

        lastName = arr[1];

      };

      if(!func.isEmptyOrNull(__dob)){

        dob = moment.utc(__dob, configs.formatDateOutSite).format(configs.formatDateOnSite);

      };

      this.setState({
        queryString:{
          firstName: firstName,
          lastName: lastName,
          dob: dob,
          mrn: ""
        },
        redirectToPatientDetails: true
      });

      return;

    };

    this.setState({
      queryString:{
        firstName: "",
        lastName: "",
        dob: "",
        mrn: __mrn
      },
      redirectToPatientDetails: true
    });

  }

  handelOnRefreshData = () => {

    this.props.func.getSummary_summaryPatientsAction();
    this.handelOnResetComponent();

  }

  handelOnResetComponent = () => {

    this.setState(initialState());

  }

  handleOnCallMsgBox = (__button) => {


  }

  handelOnCallTimerLog = (__mrn) => {

    if(configs.scopes.readTimerLogs){

      this.props.func.getLogs_timerLogsAction({mrn: __mrn, month: this.state.formFilter.month});
      
    }

    this.props.func.callTimerLogs_timerLogsAction(true);

  }

  handleOnClearFiltersFormMapping = () =>{

    this.props.goupsOutSide.filters.groupName     = "";
    this.props.goupsOutSide.filters.notPatientId  = "";

    this.props.goupsInSide.filters.groupName      = "";
    this.props.goupsInSide.filters.byPatientId    = "";

  }

  handleOnCallFormMapping = (__patientId) =>{

    this.handleOnClearFiltersFormMapping();

    if(configs.scopes.editPatients){

      this.props.goupsOutSide.filters.notPatientId  = __patientId;
      this.props.goupsInSide.filters.byPatientId    = __patientId;

      this.props.func.getGroupsOutSide_summaryPatientsAction(this.props.goupsOutSide.filters);
      this.props.func.getGroupsInSide_summaryPatientsAction(this.props.goupsInSide.filters);

    }

    this.handleOnShowFormMapping(true, __patientId);

  }

  handleOnShowFormMapping = (__bool, __patientId) =>{

    this.setState({
      mapping:{
        show: __bool,
        patientId: __patientId
      }
    });

  }

  handleOnMapping = (__actions, __groupIds) =>{

    this.props.func.mapGroupsToPatient_summaryPatientsAction({actions: __actions, groupIds: __groupIds, patientId: this.state.mapping.patientId});

  }

  handleOnSearch = (__filters, __typeSearch) =>{

    if(__typeSearch === "outSide"){

      this.props.goupsOutSide.filters.groupName = __filters.groupName;
      this.props.func.getGroupsOutSide_summaryPatientsAction(this.props.goupsOutSide.filters);

      return;

    };

    this.props.goupsInSide.filters.groupName = __filters.groupName;
    this.props.func.getGroupsInSide_summaryPatientsAction(this.props.goupsInSide.filters);

  }

  handleOnNextPageMapping = (__typeAction) => {

    if(__typeAction  === "outSide"){

      this.props.goupsOutSide.pagination.page = this.props.goupsOutSide.pagination.page + 1;
      this.props.func.getGroupsOutSide_summaryPatientsAction(this.props.goupsOutSide.filters, undefined, this.props.goupsOutSide.pagination);


      return;

    };

    this.props.goupsInSide.pagination.page = this.props.goupsInSide.pagination.page + 1;
    this.props.func.getGroupsInSide_summaryPatientsAction(this.props.goupsInSide.filters, undefined, this.props.goupsInSide.pagination);

  }

  handleOnPreviousPageMapping = (__typeAction) => {

    if(__typeAction  === "outSide"){

      this.props.goupsOutSide.pagination.page = this.props.goupsOutSide.pagination.page - 1;
      this.props.func.getGroupsOutSide_summaryPatientsAction(this.props.goupsOutSide.filters, undefined, this.props.goupsOutSide.pagination);

      return;

    };

    this.props.goupsInSide.pagination.page = this.props.goupsInSide.pagination.page - 1;
    this.props.func.getGroupsInSide_summaryPatientsAction(this.props.goupsInSide.filters, undefined, this.props.goupsInSide.pagination);

  }
  
  renderListMonths = () => {

    let elements = [];

    for(let i = 0; i < 11; i++){

      elements.push(<option key={i} value={moment.utc().subtract(i, "months").utcOffset(configs.timeZone).format("YYYY-MM")}>{moment.utc().subtract(i, "months").utcOffset(configs.timeZone).format('MMM-YYYY').toUpperCase()}</option>);
    
    };

    return elements;

  }

  renderListPatient = () => {

    let data  = this.props.data;

    return (data.map((__p)=>{

      let notifies  = [];
      let devices   = [];
      let minutes   = <span className="badge btn-metal">{__p.minutes}</span>;
      let lastRead  = <span style={{width: "100px", textAlign: "left"}}></span>;
      let readings  = <span className="badge btn-metal">{__p.readings}</span>;
      
      if( __p.typeNotifies.length === 0) {
        notifies.push(
          <div key={uuidv4()} className="btn btn-brand" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="No Problem" title="No Problem" data-placement="bottom" data-toggle="tooltip">
            <a data-content="" style={{display: "block", backgroundImage: "url('../images/ok.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
          </div>
        );
      };

      __p.typeNotifies.forEach(el => {

        if(el === "alert") {

          notifies.push(
            <div key={uuidv4()} className="btn btn-brand" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Contact Requested" title="Contact Requested" data-placement="bottom" data-toggle="tooltip">
              <a data-content="" style={{display: "block", backgroundImage: "url('../images/bell.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
            </div>
          );

        };

        if(el === "abnormal"){

          let high = 0;

          for(let abnormal of __p.abnormalDetails){
              for(let K in abnormal){

                  let obj = abnormal[K];
  
                  for(let k in obj){
                      if(obj[k]["high"]>high){
                          high = obj[k]["high"];
                      };
                  };
              };
          };

          if(high > 0){

            notifies.push(
                <div key={uuidv4()} className="btn btn-warning" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Health Alert" title="Health Alert" data-placement="bottom" data-toggle="tooltip">
                  <a data-content="" style={{display: "block", backgroundImage: "url('../images/warning.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
                </div>
            );

          };

        };

        if(el === "reminder"){

          notifies.push(
            <OverlayTrigger key={uuidv4()} trigger={['hover', 'focus']} placement="right" 
              overlay={<Tooltip id="button-tooltip">{__p.reminderContent}</Tooltip>}>

              <div className="btn btn-brand" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Missed Reminder" title="Missed Reminder" data-placement="bottom" data-toggle="tooltip">
                <a data-content="" style={{display: "block", backgroundImage: "url('../images/clock.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
              </div>

            </OverlayTrigger>
    
          );
          
        };

      });

      if(!func.isEmptyOrNull(__p.lastCheck)){

        minutes = <span className="badge btn-brand">{__p.minutes}</span>;

        if(moment.utc(__p.lastCheck).utcOffset(configs.timeZone) < moment.utc().utcOffset(configs.timeZone)){

          minutes = <span className={parseInt(__p.minutes) < 20 ? "badge badge-danger" : "badge btn-brand"}>{__p.minutes}</span>;

        };
        
      };

      if(!func.isEmptyOrNull(__p.lastRead)){

        lastRead = <span style={{width: "100px", textAlign: "left"}}>{func.noteDateTime(__p.lastRead)}</span>;

      };

      let arr = !func.isEmptyOrNull(__p.devices) ?__p.devices.split(",") : [];

      arr.filter(function(item, pos, self) {

        return self.indexOf(item) === pos;

      }).forEach(el => {

        if(el === "oximeter") {
          devices.push(
            <div key={uuidv4()} className="badge badge-light" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Oximeter" title="Oximeter" data-placement="bottom" data-toggle="tooltip">
              <a data-content="" style={{display: "block", backgroundImage: "url('../images/oxi.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
            </div>
          );
        };
        
        if(el === "bpmonitor"){
          devices.push(
              <div key={uuidv4()} className="badge badge-light" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Bpmonitor" title="Bpmonitor" data-placement="bottom" data-toggle="tooltip">
                <a data-content="" style={{display: "block", backgroundImage: "url('../images/bpm.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
              </div>
            );
        };
        
        if(el === "glucometer"){
            devices.push(
                <div key={uuidv4()} className="badge badge-light" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Glucometer" title="Glucometer" data-placement="bottom" data-toggle="tooltip">
                  <a data-content="" style={{display: "block", backgroundImage: "url('../images/glucometer.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
                </div>
              );
        };

        if(el === "scale"){
          devices.push(
              <div key={uuidv4()} className="badge badge-light" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Scale" title="Scale" data-placement="bottom" data-toggle="tooltip">
                <a data-content="" style={{display: "block", backgroundImage: "url('../images/scale.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
              </div>
            );
        };

        if(el === "thermometer"){
          devices.push(
              <div key={uuidv4()} className="badge badge-light" style={{borderRadius: "3px", height: "30px", width: "30px", float: "left", padding: "2px", display: "block", marginRight: "5px"}} data-original-title="Thermometer" title="Thermometer" data-placement="bottom" data-toggle="tooltip">
                <a data-content="" style={{display: "block", backgroundImage: "url('../images/thermometer.png')", backgroundRepeat: "no-repeat", backgroundSize: "contain", height: "25px", width: "25px", margin: "auto"}}></a>
              </div>
            );
        };

      });

      if(__p.readings >= 16){

        readings=<span className="badge badge-success">{__p.readings}</span>;

      }else{

        if(!func.isEmptyOrNull(__p.lastRead)){

          let dateInput   = moment.utc(__p.lastRead).utcOffset(configs.timeZone);
          let dateCurrent = moment.utc().utcOffset(configs.timeZone);

          readings = <span className="badge badge-danger">{__p.readings}</span>;

          if(!moment(dateInput).isAfter(dateCurrent, "month")){
    
            let remain  = parseInt((dateInput.endOf('month') - dateCurrent) / 24 / 60 / 60 / 1000);
            let miss    = 16 - __p.readings;

            readings = <span className={miss > remain ? "badge badge-danger" : remain - miss < 7 ? "badge badge-warning" : "badge btn-brand"}>{__p.readings}</span>;

          };

        };

      };

      let eleId = uuidv4();

      return(
    
        <tr key={uuidv4()} className="k-datatable__row" style={{left: "0px"}}>
          <td onDoubleClick={()=>{this.handleOnRedirectToPatientDetails(null, null, __p.mrn)}} className="k-datatable__cell--left k-datatable__cell">
            <ContextMenuTrigger id={eleId}>

              <span>{__p.mrn}</span>

            </ContextMenuTrigger>
            <ContextMenu id={eleId}>
              <MenuItem onClick={()=>{this.handleOnCallFormMapping(__p.id)}}>
                <i className="fa fa-tags"></i> Mapping Groups
              </MenuItem>
            </ContextMenu>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell">
            <span style={{width: "120px"}}>
              {notifies}
            </span>
          </td>
          <td onDoubleClick={()=>{this.handleOnRedirectToPatientDetails(__p.fullName, __p.dob, null)}} className="k-datatable__cell--left k-datatable__cell">
            <span style={{width: "100px"}}>{!this.state.PID? "**********" : String(__p.fullName).replace("|", " ")}</span>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell">
            <span style={{width: "80px"}}>{!this.state.PID ? "**********" : moment.utc(__p.dob, configs.formatDateOutSite).format(configs.formatDateOnSite)}</span>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell">
            <span style={{width: "100px"}}>{__p.provider}</span>
          </td>
          <td className="k-datatable__cell--center k-datatable__cell">
            <span style={{width: "100px"}}>{minutes}</span>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell fieldLastCheck">
            <span className="showBtn" style={{width: "140px", display: "none"}}>
              <button style={{display: "block", margin: "auto"}} onClick={()=>{this.handelOnCallTimerLog(__p.mrn)}} type="button" className="btn btn-outline-brand btn-elevate btn-pill btn-sm"><i className="flaticon-eye"></i> View logs</button>
            </span>
            <span className="showDate" style={{width: "140px"}}>{func.isEmptyOrNull(__p.lastCheck) ? "" : moment.utc(__p.lastCheck).utcOffset(configs.timeZone).format(configs.formatDateTimeOnSite)}</span>
          </td>
          <td className="k-datatable__cell--center k-datatable__cell">
            <span style={{width: "120px"}}>{readings}</span>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell">
            {lastRead}
          </td>
          <td className="k-datatable__cell--center k-datatable__cell">
            <span style={{width: "70px"}}>
              <span className="badge btn-brand">{__p.readingsBydevice}</span>
            </span>
          </td>
          <td className="k-datatable__cell--left k-datatable__cell">
            <span style={{width: "180px"}}>{devices}</span>
          </td>
        </tr>
      );

    }));

  }

  render() {

    if(this.state.redirectToPatientDetails) {

      return <Redirect to={"/dashboars/patient-details?firstName=" + this.state.queryString.firstName + "&lastName=" + this.state.queryString.lastName + "&dob=" + this.state.queryString.dob + "&mrn=" + this.state.queryString.mrn}/>
    
    };
 
    return(

      <React.Fragment>

        <MsgBoxContainer _ref={_ref} onClick={this.handleOnCallMsgBox}/>
          
        {  
          !configs.scopes.readPatients
          ?
            <div className="k-iconbox k-iconbox--active k-iconbox--danger" style={{border: "none"}}>
              <div className="k-iconbox__icon">
                <div className="k-iconbox__icon-bg"></div>
                <i className="fa fa-lock"></i>
              </div>
              <div className="k-iconbox__title">
                403 FORBIDDEN
              </div>
              <div className="k-iconbox__content">
                You not enough rights to access to this item
              </div>
            </div>
          :
          
            <React.Fragment>

              <MappingComponent 
                show                    = {this.state.mapping.show} 
                close                   = {()=>{this.handleOnShowFormMapping(false, "")}} 
                type                    = {"group"} 
                permissions             = {configs.scopes.editPatients}
                outSide                 = {this.props.goupsOutSide.data} 
                inSide                  = {this.props.goupsInSide.data}
                paginationInSide        = {this.props.goupsInSide.pagination}
                paginationOutSide       = {this.props.goupsOutSide.pagination}
                handleOnSubmitSearch    = {(__filters, __typeSearch)=>{this.handleOnSearch(__filters, __typeSearch)}} 
                handleOnPreviousPage    = {(__typeAction)=>{this.handleOnPreviousPageMapping(__typeAction)}} 
                handleOnNextPage        = {(__typeAction)=>{this.handleOnNextPageMapping(__typeAction)}} 
                handleOnMapping         = {(__actions, __groupIds)=>{this.handleOnMapping(__actions, __groupIds)}} 
                handleOnRefresh         = {()=>{this.handleOnCallFormMapping(this.state.mapping.patientId)}}
              />

              <TimerLogsContainer/>

              <div className="row">
                <div style={{width: "100%", paddingRight: "10px", paddingBottom: "10px"}}>
                  <button style={{float: "right"}} onClick={()=>{this.handelOnRefreshData()}} type="button" className="btn btn-sm btn-secondary btn-pill"><i className="fa fa-sync-alt"></i> Refresh</button>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 order-2 order-xl-1">
                  <div style={{display: "flex", marginBottom: "30px"}}>
                    <div style={{display: "block", margin: "auto"}}>
                      <div className="form-group" style={{width: "140px", float: "left", margin: "5px 5px"}}>
                        <label>Status RPM</label>
                          <select onChange={this.handleOnFilter} name="statusRPM" value={this.state.formFilter.statusRPM || ""} className="form-control form-control-sm">
                            <option value="">All</option>
                            <option value="enabled">Enabled</option>
                          </select>
                      </div>

                      <div className="form-group" style={{width: "140px", float: "left", margin: "5px 5px"}}>
                        <label>Months</label>
                          <select onChange={this.handleOnFilter} name="month" value={this.state.formFilter.month || moment.utc().utcOffset(configs.timeZone).format("YYYY-MM")} className="form-control form-control-sm">
                            {this.renderListMonths()}
                          </select>
                      </div>

                      <div className="form-group" style={{width: "140px", float: "left", margin: "5px 5px"}}>
                        <label>Devices</label>
                          <select onChange={this.handleOnFilter} name="device" value={this.state.formFilter.device || ""} className="form-control form-control-sm">
                            <option value="">All</option>
                            <option value="bpm">BPM</option>
                            <option value="glucometer">Glucometer</option>
                            <option value="oximeter">Oximeter</option>
                            <option value="scale">Scale</option>
                            <option value="thermometer">Thermometer</option>
                          </select>
                      </div>

                      <div className="form-group" style={{width: "140px", float: "left", margin: "5px 5px"}}>
                        <label>Notifies</label>
                          <select onChange={this.handleOnFilter} name="typeNotify" value={this.state.formFilter.typeNotify || ""} className="form-control form-control-sm">
                            <option value="">All</option>
                            <option value="alert">Contact Requested</option>
                            <option value="abnormal">Health Alert</option>
                            <option value="reminder">Missed Reminder</option>
                          </select>
                      </div>

                      <div className="form-group" style={{width: "140px", float: "left", margin: "5px 5px"}}>
                        <label>Readings</label>
                        <select onChange={this.handleOnFilter} name="statusReadings" value={this.state.formFilter.statusReadings || ""} className="form-control form-control-sm">
                          <option value="">All</option>
                          <option value="success">Success</option>
                          <option value="atRisk">At Risk</option>
                          <option value="missed">Missed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="k_datatable k-datatable k-datatable--default k-datatable--loaded" style={{width: "100%"}}>
                <table className="k-datatable__table list-database" style={{display: "block", minHeight: "300px", overflow: "auto"}}>
                  <thead className="k-datatable__head">
                    <tr className="k-datatable__row">
                      <th onClick={()=>{this.handleOnSort("mrn")}}  className={this.props.sorts.sortBy === "mrn" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                          <span style={{width: "80px"}}>MRN {this.props.sorts.sortBy === "mrn" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("typeNotifies")}} className={this.props.sorts.sortBy === "typeNotifies" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "120px"}}>STATUS {this.props.sorts.sortBy === "typeNotifies" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("fullname")}} className={this.props.sorts.sortBy === "fullname" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "100px"}}>FULLNAME {this.props.sorts.sortBy === "fullname" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("dob")}} className={this.props.sorts.sortBy === "dob" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "80px"}}>DOB {this.props.sorts.sortBy === "dob" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("provider")}} className={this.props.sorts.sortBy === "provider" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "100px"}}>PROVIDER {this.props.sorts.sortBy === "provider" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("minutes")}} className={this.props.sorts.sortBy === "minutes" ? "k-datatable__cell--center k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--center k-datatable__cell"}>
                        <span style={{width: "100px"}}>MINUTES {this.props.sorts.sortBy === "minutes" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("lastCheck")}} className={this.props.sorts.sortBy === "lastCheck" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "140px"}}>LAST CHECK {this.props.sorts.sortBy === "lastCheck" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("readings")}} className={this.props.sorts.sortBy === "readings" ? "k-datatable__cell--center k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--center k-datatable__cell"}>
                        <span style={{width: "120px"}}>COMPLIANCE {this.props.sorts.sortBy === "readings" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th onClick={()=>{this.handleOnSort("lastRead")}} className={this.props.sorts.sortBy === "lastRead" ? "k-datatable__cell--left k-datatable__cell k-datatable__cell--sorted" : "k-datatable__cell--left k-datatable__cell"}>
                        <span style={{width: "100px"}}>LAST READ {this.props.sorts.sortBy === "lastRead" ? (this.props.sorts.sortValue === "DESC" ? <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-down"></i> : <i style={{paddingRight: "15px"}} className="fa fa-sort-amount-up"></i>) : ""}</span>
                      </th>
                      <th className="k-datatable__cell--center k-datatable__cell">
                        <span style={{width: "70px"}}>READINGS</span>
                      </th>
                      <th className="k-datatable__cell">
                        <span style={{width: "180px"}}>DEVICES</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="k-datatable__body">
                    {this.renderListPatient()}
                  </tbody>
                </table>
              </div>

              <div style={{height: "50px"}}>

                <OverlayTrigger trigger="focus" show={this.state.showFormConfirmPass} placement="right" overlay={
                  <Popover id='dashboarVerify'>
                  <Popover.Title as='h3'>Verify</Popover.Title>
                  <Popover.Content>
                      <div className="form-group">
                          <label>Password</label>
                          <input type="password" className="form-control" onChange={this.handleOnChangeInputPass} value={this.state.passWord} name="passWord" />
                      </div>
                      <button onClick={this.handleOnVerify} type="button" style={{marginBottom: "10px", width: "100%"}} className="btn btn-pill btn-label-primary btn-sm">Submit</button>
                  </Popover.Content>
                </Popover>
                }>
                  <div style={{display: "flex", float: "left", width: "150px"}}>
                    <label style={{float: "right", marginRight: "10px", marginTop: "3px"}}>Shown/Hidden</label>
                    <span className="k-switch k-switch--sm k-switch--outline k-switch--icon k-switch--primary" style={{float: "right"}}>
                      <label>
                        <input checked={this.state.checkedPID} onChange={this.handleOnChangeInputPass} type="checkbox" name="PID"/>
                        <span></span>
                      </label>
                    </span>
                  </div>
                </OverlayTrigger>

                <PaginationComponent pagination={this.props.pagination} handleChangePage={this.handleOnChangePage} handleLoadMorePage={this.handleOnLoadMorePage} handleNextPage={this.handleOnNextPage} handlePreviousPage={this.handleOnPreviousPage} handleFirstPage={this.handleOnFirstPage} handleLastPage={this.handleOnLastPage}/>
              </div>
              
            </React.Fragment>

        }
      </React.Fragment>
    )

  }

}